import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth'
import {getStorage} from "firebase/storage"
import { getRemoteConfig } from "firebase/remote-config";
import {getMessaging, getToken,onMessage} from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyDZyQOEIkkk0Rhv2JV1XSq7Q69JWE5nEgg",
    authDomain: "xtrad-cbba8.firebaseapp.com",
    projectId: "xtrad-cbba8",
    storageBucket: "xtrad-cbba8.appspot.com",
    messagingSenderId: "305754470271",
    appId: "1:305754470271:web:feab5810d3d86e852b4c4a",
    measurementId: "G-0W3YRPJ8WR"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebaseApp.firestore();
const auth = firebase.auth();
const storage = getStorage(firebaseApp)

let remoteConfig
let messaging
let analytics
if (typeof window !== 'undefined') {
     remoteConfig = getRemoteConfig(firebaseApp)
     messaging = getMessaging(firebaseApp);
     analytics = getAnalytics(firebaseApp);
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = "BMp_yVvD6XxqRxWgB2N0hk65PqZS6YVMjjY_Oovy9m6E-IwvRgrWJYnrk3C2NgszWeCvdtcxdtSWwmAlnWOku-Q";
export const getTokens = async () => {
    let currentToken = null;
    try {
        currentToken = await getToken(messaging,{vapidKey : publicKey})
    } catch (error) {
    }
    return currentToken;
    // try {
           // // await Notification.requestPermission()
           //      const currentToken = await getToken(messaging,{vapidKey : publicKey});
           //      console.log('Notification token:', currentToken);
           //      return currentToken
    // } catch (error) {
    //     console.log('Unable to get permission to notify.', error);
    // }
};
 
// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging,(payload)=>{
//             resolve(payload);
//         })
//     });

export {auth, storage,remoteConfig,messaging,analytics};

export default database;