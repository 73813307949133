import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import database from "../../Controller/Firebase/firebase";
import {AuthContext} from "../../Context/AuthProvider";
import loading from "../Images/loading.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import {GiCommercialAirplane, GiQueenCrown} from "react-icons/gi";
import {ImFire, ImLocation2} from "react-icons/im";
import {
    BsChatLeftDots,
    BsFillChatDotsFill,
    BsFillCheckCircleFill,
    BsFillPatchCheckFill,
    BsPlayCircleFill
} from "react-icons/bs";
import StateContext from "../../Context/StateContext";
import VerificationVideo from "./VerificationVideo";
import {getFunctions, httpsCallable} from "firebase/functions";
import moment from "moment";
import {ChatContext} from "../../Context/ChatContext";
import VerificationVideoQuery from "./VerificationVideoQuery";
import newUserLogo from "../Images/newUsersLogo.svg";
import premiumIconWhite from "../Images/premiumIconWhite.png";
import locationIconWhite from "../Images/locationIconWhite.svg";
import favWhiteSvg from "../Images/favIconWhite.svg";
import favGradientSvg from "../Images/favIconGradient.svg";
import chatSvg from "../Images/chat.svg";
import favIconGradient from "../Images/favIconGradient.svg";
import favIconWhite from "../Images/favIconWhite.svg";
import pinkDiamond from "../Images/pinkDiamond.png";
import blueDiamond from "../Images/blueDiamond.png";

function SearchResult() {
    const location = useLocation()
    const {
        loginUser,
        myBlockList,
        goUserProfile,
        OnlineOrOffline,
        FavOrNot,searchResultUser, setSearchResultUser,
        myFavorite,myFavoriteClone,
        newUsers,
        showVerificationVideoQuery
    } = useContext(AuthContext);

    let data = location.state
    let travels = data.travels
    let minAge = data.minAge
    let maxAge = data.maxAge
    let country = data.country
    let lat = data.lat
    let lon = data.lon
    let temp = []
    const [user, setUser] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastVisible, setLastVisible] = useState("");
    let [totalLength, setTotalLength] = useState(null);
    let [tetik, setTetik] = useState(false);
    let [requestCompleted, setRequestCompleted] = useState(null);
    const [stat2, setStat2] = useState(null);
    let querySnapshot;
    const functions = getFunctions();

    const {goChat,femaleVerificationVideo, setFemaleVerificationVideo,VerifactionVideoToggle,
        videoOpenState , setVideoOpenState} = useContext(ChatContext);

    async function searchResult() {
        if (searchResultUser.length > 0)
        {
            return
        }
        const userGender = loginUser?.gender === "female" ? "male" : "female";
        if (location.state.travels !== undefined) {
            let data = {lat: lat, lon: lon, gender: userGender}
            const requestBackEnd = await httpsCallable(functions, 'searchTravel')(data)
            const result = requestBackEnd.data["isCompleted"]
            setRequestCompleted(result)
            // setUser(requestBackEnd.data.curated)
            setSearchResultUser(requestBackEnd.data.curated)
            if (result === false) {
                setStat2(false)
            }
        } else {
            if (location.state.minAge === 0) // demekki sadece country değeri gelmiş
            {
                let data = {lat: lat, lon: lon, minAge: minAge, maxAge: maxAge, gender: userGender}
                const requestBackEnd = await httpsCallable(functions, 'nearbySearch')(data)
                const result = requestBackEnd.data["isCompleted"]
                setRequestCompleted(result)
                // setUser(requestBackEnd.data.curated)
                setSearchResultUser(requestBackEnd.data.curated)

                if (requestBackEnd.data.curated.length === 0) {
                    setStat2(false)
                }
            }
            if (location.state.minAge > 0 && location.state.country.trim() !== "") // demek ki hem age hem de country değerleri var
            {
                let data = {lat: lat, lon: lon, minAge: minAge, maxAge: maxAge, gender: userGender}
                const requestBackEnd = await httpsCallable(functions, 'nearbySearch')(data)
                const result = requestBackEnd.data["isCompleted"]
                setRequestCompleted(result)
                setSearchResultUser(requestBackEnd.data.curated)

                // setUser(requestBackEnd.data.curated)
                if (requestBackEnd.data?.curated.length === 0) {
                    setStat2(false)
                }

            } else if (location.state.minAge > 0 && country === "") // sadece age değeri
            {

                let data = {lat: lat, lon: lon, minAge: minAge, maxAge: maxAge, gender: userGender}
                const requestBackEnd = await httpsCallable(functions, 'searchAge')(data)
                const result = requestBackEnd.data["isCompleted"]
                setRequestCompleted(result)
                // setUser(requestBackEnd.data.curated)
                setSearchResultUser(requestBackEnd.data.curated)


                if (requestBackEnd.data.curated.length === 0) {
                    setStat2(false)
                }
            }
        }
    }
    useEffect(() => {
        searchResult()
    }, [tetik]);
    return (
        <>
            {showVerificationVideoQuery && <VerificationVideoQuery/>}

            {location.state.travels === undefined ?
                <div className="w-full flex flex-col items-center  justify-center  gap-y-2    ">
                    <button
                        className="flex  w-full h-16 bg-stone-50 text-xl text-blue-600 justify-center items-center space-x-3 my- font-bold shadow-lg">
                        <p className={`${location.state.maxAge === 0 && "hidden"}`}> Age: {minAge}-{maxAge}
                        </p>
                        <p className={`${country === "" && "hidden"}`}> Location : {country}</p>
                    </button>
                    {
                        (requestCompleted === null && searchResultUser.length === 0) && <div className="flex flex-col w-full items-center gap-y-2 justify-center">
                        <p className="font-bold text-pink-500 animate-pulse ">Loading...</p>
                            <img className={`w-12 h-12 object-cover mx-auto`} src={loading}/>
                        </div>
                    }
                    {
                        requestCompleted === true && ""
                    }
                    {
                        stat2 === false &&
                        <div className="flex w-full justify-center text-xl text-red-600 font-bold">Sorry, No Users Found for Selected Travel Destination</div>
                    }
                    <div
                        className={`mx-2 grid lg:grid-cols-5 lg:gap-x-1 xs:grid-cols-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  py-5 xs:w-full xs:p-2   `}
                    >
                        {searchResultUser.map(({
                                          id,
                                          viewCount,
                                          isSeen,
                                          viewTime,
                                          favTrue,
                                          data: {
                                              name,
                                              age,
                                              location,
                                              profileImage,
                                              userID,
                                              gender,
                                              verificationVideo,
                                              lastSeen,
                                              is_premium,
                                              token
                                          }

                                      }, index) => (
                            <div className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                                 key={index}
                            >
                                <img onClick={()=> goUserProfile(searchResultUser[index].data,loginUser?.name,loginUser?.profileImage)} src={profileImage} className=" absolute w-full h-full border-2 border-[#D81E71] object-cover rounded-2xl" alt=""/>
                                {newUsers.find(el => el.id === userID) &&
                                    <div className="absolute  w-full top-3 rotate-12">
                                        <div className="flex w-full items-center justify-start ">
                                            <img src={newUserLogo}
                                                 className="w-14 h-14 " alt=""/>
                                        </div>
                                    </div>
                                }
                                <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                                    <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(searchResultUser[index].data, loginUser?.name, loginUser?.profileImage)}>
                                        <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                            {20 > OnlineOrOffline(lastSeen) ?
                                                <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                                <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                            {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                            {is_premium === true &&
                                                <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                        </div>
                                        {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                            <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                            <p className={`w-full `}>
                                                {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                            </p>
                                        </div>}
                                    </div>
                                    <div
                                        className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : "bg-[#D81E71] bg-opacity-80 "}`}>
                                        {verificationVideo === "null" || !verificationVideo ?
                                            <p className="text-sm">Waiting for verification video</p>
                                            : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                                 className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                                <BsPlayCircleFill className="text-lg animate-pulse"/>
                                                Verification
                                                Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                        }
                                    </div>
                                    <div
                                        className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">
                                        <button onClick={() => FavOrNot(userID,searchResultUser[index])}
                                                className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                            {myFavoriteClone.find(r => r.id === userID) ?
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                        </button>

                                        <button onClick={(e) => goChat(e, searchResultUser[index].data, token)}
                                                className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                            <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                        </button>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>

                </div>
                :
                <>
                    <button
                        className="inline-flex w-full h-16 bg-stone-50 text-xl text-blue-600 justify-center items-center space-x-3 my- font-bold shadow-lg">
                        <GiCommercialAirplane className="w-7 h-7 "/>
                        <p>{travels} Travels</p>
                    </button>
                    {
                        (requestCompleted === null && searchResultUser.length === 0) && <div className="flex flex-col w-full items-center gap-y-2 justify-center">
                            <p className="font-bold text-pink-500 animate-pulse ">Loading...</p>
                            <img className={`w-12 h-12 object-cover mx-auto`} src={loading}/>
                        </div>
                    }
                    {
                        requestCompleted === true && ""
                    }
                    {
                        stat2 === false &&
                        <div className="flex w-full justify-center text-xl text-red-600 font-bold">Sorry, No Users Found for Selected Travel Destination</div>
                    }
                    <div
                        className={`mx-2 grid lg:grid-cols-5 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5   `}
                    >
                        {searchResultUser && searchResultUser.map(({
                                       id,
                                       viewCount,
                                       isSeen,
                                       viewTime,
                                       favTrue,
                                       data: {
                                           name,
                                           age,
                                           location,
                                           profileImage,
                                           userID,
                                           gender,
                                           verificationVideo,
                                           lastSeen,
                                           is_premium,
                                           token
                                       }

                                   }, index) => (
                            <div className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                                 key={index}
                            >
                                <img onClick={()=> goUserProfile(searchResultUser[index].data,loginUser?.name,loginUser?.profileImage)} src={profileImage} className=" absolute w-full h-full border-2 border-[#D81E71] object-cover rounded-2xl" alt=""/>
                                {newUsers.find(el => el.id === userID) &&
                                    <div className="absolute  w-full top-3 rotate-12">
                                        <div className="flex w-full items-center justify-start ">
                                            <img src={newUserLogo}
                                                 className="w-14 h-14 " alt=""/>
                                        </div>
                                    </div>
                                }
                                <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                                    <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(searchResultUser[index].data, loginUser?.name, loginUser?.profileImage)}>
                                        <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                            {20 > OnlineOrOffline(lastSeen) ?
                                                <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                                <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                            {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                            {is_premium === true &&
                                                <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                        </div>
                                        {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                            <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                            <p className={`w-full `}>
                                                {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                            </p>
                                        </div>}
                                    </div>
                                    <div
                                        className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : "bg-[#D81E71] bg-opacity-80 "}`}>
                                        {verificationVideo === "null" || !verificationVideo ?
                                            <p className="text-sm">Waiting for verification video</p>
                                            : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                                 className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                                <BsPlayCircleFill className="text-lg animate-pulse"/>

                                                Verification
                                                Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                        }
                                    </div>
                                    <div
                                        className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">
                                        <button onClick={() => FavOrNot(userID,searchResultUser[index])}
                                                className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                            {myFavoriteClone.find(r => r.id === userID) ?
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                        </button>

                                        <button onClick={(e) => goChat(e, searchResultUser[index].data, token)}
                                                className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                            <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                        </button>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                </>
            }
            {videoOpenState && <VerificationVideo/>}

        </>
        // </InfiniteScroll>
    );
}

export default SearchResult;