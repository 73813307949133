import React, {useContext, useEffect} from 'react';
import SignUpContent from "../../Layouts/signUpContent";


function SignUp() {

    return (
        <div className="flex flex-col  w-full h-auto my-4   ">
            <div className=" mx-auto flex w-1/3 h-auto xs:w-full">
                <SignUpContent/>
            </div>


        </div>
    );
}

export default SignUp;