import React from 'react';
import logo from "../Images/modelClubMobilSvg.svg"
function EmptyChat() {
    return (
        <div className="bg-white h-full items-center flex justify-center xs:hidden " >
            <img src={logo} alt="" className="animate-pulse w-80 h-auto"/>
            {/*<p className="animate-bounce">Model Club Chat</p>*/}
        </div>
    );
}

export default EmptyChat;