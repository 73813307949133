import React from 'react';
import {useContext, useEffect, useState} from "react";
import {ChatContext} from "../../Context/ChatContext";
import database, {auth, storage} from "../../Controller/Firebase/firebase";
import {collection, doc, serverTimestamp, setDoc, Timestamp, updateDoc} from "firebase/firestore";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {v4 as uuid} from "uuid";
import {BsFillImageFill} from "react-icons/bs";
import {AiOutlineSend} from "react-icons/ai";
import {getFunctions, httpsCallable} from "firebase/functions";
import {AuthContext} from "../../Context/AuthProvider";
import {BiArrowBack} from "react-icons/bi";

function ChatInput() {
    const {
        dispatch,
        setDeleteRoomsBoolean,
        updatePage,
        setUpdatePage,
        data3,
        newChatDispatch, otherUserActiveChatRoom, setOtherUserActiveChatRoom
    } = useContext(ChatContext);
    const {loginUser} = useContext(AuthContext)
    const [text, setText] = useState("");
    const [count, setCount] = useState();
    const [img, setImg] = useState(null);
    const {data, chatPermission} = useContext(ChatContext);
    const [sendPhoto, setSendPhoto] = useState(false);
    let messagesRef = "rooms/" + data.chat.id + "/messages"
    const docRef = "rooms/" + data.chat.id;
    const [url, setUrl] = useState(null);
    let countt
    let otherUserCount;
    const functions = getFunctions();

    let aaa;
    useEffect(() => {
        if (data.chat.data !== "null") {
            aaa = data.chat.otherUser.userID
            database.collection("rooms").doc(`${data.chat.id}`).onSnapshot(res => {
                setCount(res.data()[aaa])
            })
        }
    }, [data.chat.data, count]);
    const handleSend = async () => {
        if (chatPermission === false)
            return
        if (text.trim() === "")
            return
        otherUserCount = data.chat.otherUser["userID"]
        countt = data.chat.data[otherUserCount]

        if (data.chat.data === "null") {
            const combinedId = auth.currentUser.uid > data.chat.otherUser.userID ? auth.currentUser.uid + data.chat.otherUser.userID : data.chat.otherUser.userID + auth.currentUser.uid
            const newData = {
                messages: {},
                agentId: 0,
                [auth.currentUser.uid]: 0,
                [data.chat.otherUser.userID]: 1,
                authorName: data.chat.otherUser.userID,
                combinedId: combinedId,
                createdAt: serverTimestamp(),
                lastMessage: text,
                metadata: {deletedIds: []},
                receiverId: auth.currentUser.uid,
                updatedAt: serverTimestamp(),
                userRoles: null,
                userIds: [data.chat.otherUser.userID, auth.currentUser.uid]
            }
            await setDoc(doc(database, `rooms`, `${data.chat.id}`), newData)
            let reff = "rooms/" + data.chat.id + "/messages"
            const chatRoomReff = await doc(collection(database, messagesRef))

            const u = {
                data: newData,
                id: data.chat.id,
                otherUser: {
                    name: data.chat.otherUser.name,
                    profileImage: data.chat.otherUser.profileImage,
                    userID: data.chat.otherUser.userID,
                    token: data.chat.otherUser.token,
                    age: data.chat.otherUser.age,
                    bio: data.chat.otherUser.bio,
                    location: data.chat.otherUser.location,
                    premium: data.chat.otherUser.is_premium,
                    gender: data.chat.otherUser.gender,
                    verificationVideo: data.chat.otherUser.verificationVideo,
                    activeChatRoom: data.chat.otherUser.activeChatRoom
                }
            }
            await dispatch({type: "CHANGE_USER", payload: u})
            await database.collection("users").doc(auth.currentUser.uid).set({activeChatRoom: combinedId}, {merge: true})

            if (img) {
                const storageRef = ref(storage, uuid())
                const uploadTask = uploadBytesResumable(storageRef, img);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // İlerleme veya diğer durum güncellemeleri
                    },
                    (error) => {
                        // Hata durumunda yapılacaklar
                    },
                    () => {
                        // Yükleme tamamlandığında yapılacaklar

                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            await setDoc(chatRoomReff, {
                                authorId: auth.currentUser.uid,
                                createdAt: Timestamp.now(),
                                text,
                                type: "img",
                                updatedAt: Timestamp.now(),
                                img: downloadURL,
                                messageId: chatRoomReff.id

                            })
                        }).then(setText("") & (document.getElementById("PhotoSelected").innerText = "") & setImg(null));
                    }
                );

            }
            else {
                await setDoc(chatRoomReff, {
                    authorId: auth.currentUser.uid,
                    createdAt: Timestamp.now(),
                    text,
                    type: "text",
                    updatedAt: Timestamp.now(),
                    messageId: chatRoomReff.id

                }).then(setText(""));
            }
            newChatDispatch({type: "newChat", payload: data3.newChatCount + 1}) //yeni bir room oluşturduğunda chats componenti yeniden render ediliyor
        } else {
            const chatRoomRef = doc(collection(database, messagesRef))
            let roomId = data.chat.id
            await setDoc(chatRoomRef, {
                authorId: auth.currentUser.uid,
                createdAt: Timestamp.now(),
                text,
                type: "text",
                updatedAt: Timestamp.now(),
                messageId: chatRoomRef.id

            }).then(setText(""));
            await updateDoc(doc(database, docRef), {
                lastMessage: text,
                updatedAt: serverTimestamp(),
                metadata: {deletedIds: []},
                [otherUserCount]: otherUserActiveChatRoom === roomId ? 0 : count + 1
            })
        }
        setText("")
        if (data.chat.otherUser?.token !== undefined) {
            let notificationData = {
                title: "Model Club",
                body: `${loginUser.name} sent you a new message!`,
                token: data.chat.otherUser.token,
                image: loginUser.profileImage,
                click_action: `https://staging.modelclub.com`
            }
            const requestBackEnd = await httpsCallable(functions, 'sendNotification')(notificationData)
            const result = requestBackEnd.data["isCompleted"]
        }
    }

    async function SendPhoto() {
        document.getElementById("completeSendPhoto").style.display = "flex"
        document.getElementById("completeSendPhoto").innerText = "Please wait loading"
        const chatRoomRef = doc(collection(database, messagesRef))
        let roomId = data.chat.id
        const storageRef = ref(storage, uuid())
        const uploadTask = uploadBytesResumable(storageRef, img);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // İlerleme veya diğer durum güncellemeleri
            },
            (error) => {
                // Hata durumunda yapılacaklar
            },
            () => {
                // Yükleme tamamlandığında yapılacaklar

                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await setDoc(chatRoomRef, {
                        authorId: auth.currentUser.uid,
                        createdAt: Timestamp.now(),
                        text,
                        type: "img",
                        updatedAt: Timestamp.now(),
                        img: downloadURL,
                        messageId: chatRoomRef.id

                    })
                }).then(setText("") & setImg(null) &
                    (document.getElementById("completeSendPhoto").innerText = "Successful...") &
                    (document.getElementById("completeSendPhoto").style.color = "green") & setSendPhoto(!sendPhoto)
                ).catch(() => {
                    (document.getElementById("completeSendPhoto").innerText = "An unexpected error has occurred")
                })
            }
        );
        await updateDoc(doc(database, docRef), {
            lastMessage: "Photo",
            updatedAt: serverTimestamp(),
            metadata: {deletedIds: []},
            [otherUserCount]: otherUserActiveChatRoom === roomId ? 0 : count + 1
        })

    }

    return (
        <div className={`flex gap-x-1 w-full h-full  ${data.chat.data.receiverId === "1stHA3nyeJNm3zA9xA2qla50Np23" && "hidden"}`}>
            <div className="w-[5%] xs:w-[15%] h-full flex items-center justify-center border-2 bg-[#545456] rounded-full">
                <input
                    type="file"
                    style={{display: "none"}}
                    id="file"
                    accept="image/*"
                    onChange={(e) => {
                        setImg(e.target.files[0])
                        setUrl(URL.createObjectURL(e.target.files[0]))
                        setSendPhoto(!sendPhoto)
                    }}
                />
                <label htmlFor="file">
                    <BsFillImageFill className="text-white text-lg hover:cursor-pointer"/>
                </label>
            </div>
            {sendPhoto ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className=" flex flex-col w-1/3 h-full xs:w-full xs:h-11/12 bg-white rounded-2xl overflow-hidden justify-center space-y-1">
                            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                <button className=" text-white text-xl px-2 rounded-full"
                                        onClick={() => setSendPhoto(!sendPhoto)}><BiArrowBack/>
                                </button>
                            </div>
                            <div
                                className=" w-full flex flex-col h-full gap-y-3 overflow-hidden items-center justify-center p-2">
                                <div className=" w-full h-3/4 overflow-hidden items-center flex justify-center">
                                    <img src={url} className="w-full h-full object-cover " alt=""/>
                                </div>
                                <div className=" flex flex-col gap-y-3 ">
                                    <span id="completeSendPhoto"
                                          className="hidden w-full justify-center text-center text-green-600 font-bold animate-bounce"/>
                                    <div className="flex gap-x-10">
                                        <button className="px-5 py-1 bg-red-600 rounded-2xl text-white font-bold"
                                                onClick={() => setSendPhoto(!sendPhoto)}>Cancel
                                        </button>
                                        <button className="px-5 py-1 bg-green-700 rounded-2xl text-white font-bold"
                                                onClick={() => SendPhoto()}>Send
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className="flex w-[90%] xs:w-[70%] ">
                <input type="text"
                       className="w-full h-full rounded-full px-5 bg-white"
                       placeholder="Type something"
                       autoComplete="off"
                       onChange={(e) => setText(e.target.value)}
                       value={text}
                       id="id"
                       onKeyPress={(e) => {
                           if (e.key === "Enter") {
                               if (text !== "") {
                                   handleSend()
                               }
                           }
                       }}
                />
            </div>
            <div
                onClick={handleSend}
                className="w-[5%] xs:w-[15%] h-full flex items-center justify-center h-full bg-[#545456] rounded-full hover:cursor-pointer">
                <AiOutlineSend className="text-white text-2xl "/>
            </div>
        </div>
        // <div className="w-full h-full flex  space-x-2   xs:p-5 ">
        //     <div className="w-full h-full flex space-x-1  ">
        //         <div className="items-center flex  ">
        //             <span className="text-[green-700]  items-center flex " id="PhotoSelected"/>
        //             <input
        //                 type="file"
        //                 style={{display: "none"}}
        //                 id="file"
        //                 accept="image/*"
        //                 onChange={(e) => setImg(e.target.files[0]) & (document.getElementById("PhotoSelected").innerHTML = "Photo Selected") & setText("Photo")}
        //             />
        //             <div className="flex bg-[#545456] rounded-full h-full w-10 h-10 items-center justify-center">
        //                 <label htmlFor="file" className="text-white text-2xl">
        //                     <BsFillImageFill/>
        //                 </label>
        //             </div>
        //         </div>
        //         <input type="text"
        //                className="w-full h-full rounded-full px-5"
        //                placeholder="Type something"
        //                autoComplete="off"
        //                onChange={(e) => setText(e.target.value)}
        //                value={text}
        //                id="id"
        //                onKeyPress={(e) => {
        //                    if (e.key === "Enter") {
        //                       if (text !== "") {
        //                            handleSend()
        //                        }
        //                    }
        //                }}
        //         />
        //     </div>
        //     <div className="w-1/12 h-full flex items-center justify-center xs:w-10 xs:h-10 ">
        //         <button
        //             className="border w-full h-full flex text-white text-center items-center justify-center text-2xl font-bold rounded-full bg-[#128C7E]"
        //             onClick={handleSend}><AiOutlineSend/></button>
        //     </div>
        // </div>
    );
}

export default ChatInput;