import React, {useEffect} from 'react';
import Sidebar from "../../Layouts/Sidebar";
import Footer from "../../Layouts/footer";
import {ImFire} from "react-icons/im";
import Header from "../../Layouts/header";
import FavoriteUsers from "../../Layouts/FavoriteUsers";

const Favorites = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center  ">
             <div className="xs:hidden"><Header/></div>

            <div className="flex justify-end lg:w-full  mx-auto my-4 space-x-1 xs:space-x-0 ">
                <div className="flex lg:w-1/4 md:w-0  xs:w-0  invisible  md:invisible lg:visible ">
                    <Sidebar/>
                </div>
                <div className=" flex lg:overflow-hidden lg:w-full md:w-full xs:w-full ">
                    <FavoriteUsers/>
                </div>
            </div>
            <div>
                {/*<Footer/>*/}
                {/*<div className="lg:hidden"><MobileMenu/></div>*/}
            </div>
        </div>
    );
}

export default Favorites;