import React, {useContext, useEffect, useState} from 'react';
import Footer from "../../Layouts/footer";
import {BsFillCameraVideoFill, BsFillChatDotsFill, BsFillEyeFill} from "react-icons/bs";
import Header from "../../Layouts/header";
import '../../../App.css'
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ViewedContent from "../../Layouts/viewedContent";
import Sidebar from "../../Layouts/Sidebar";

function Viewed() {
    return (
        <div className="lg:w-full  mx-auto lg:h-full md:justify-center  xs:h-full">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex justify-end lg:w-full  mx-auto  my-4 space-x-1 ">
                <div className="flex lg:w-1/4 md:hidden xs:hidden ">
                    <Sidebar/>
                </div>
                <div className=" flex lg:overflow-hidden lg:w-full md:w-full xs:w-full ">
                    <ViewedContent/>
                </div>
            </div>
            <div>
                {/*<Footer/>*/}
            </div>
        </div>
    );
}
export default Viewed;
