import React, {useEffect, useRef, useState, useContext} from 'react';
import StateContext from "../../Context/StateContext";
import firebase, {auth} from "../../Controller/Firebase/firebase";
import {Link, useNavigate} from "react-router-dom";
import database from "../../Controller/Firebase/firebase";
import {storage} from "../../Controller/Firebase/firebase";
import {ref, uploadBytes, getDownloadURL,uploadString} from "firebase/storage"
import user from "../../Components/Pages/SignUp/user.png"
import {doc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import {BsFillCameraVideoFill} from "react-icons/bs";
import {HiOutlinePhotograph} from "react-icons/hi";
import {AiFillCheckCircle} from "react-icons/ai";
import VerificationVideo from "./VerificationVideo";
import VerificationVideoRecord from "./VerificationVideoRecord";
import VerificationVideoQuery from "./VerificationVideoQuery";
import {AuthContext} from "../../Context/AuthProvider";
import logo from '../Images/modelClubMobilSvg.svg';
import {BiArrowBack} from "react-icons/bi";
import  Resizer from 'react-image-file-resizer';




const SignUpContent = () => {
    const functions = getFunctions();
    const {
        showVerificationVideoQuery, setShowVerificationVideoQuery, signUpVerificationVideoUrl, blobVideo,
        signUpVerificationVideoSaved, setSignUpVerificationVideoSaved,
        setBlobVideo
    } = useContext(AuthContext);
    const [emptyWarningState, setEmptyWarningState] = useState(false);
    const [emptyWarningText, setEmptyWarningText] = useState("");
    const navigate = useNavigate()
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const [emailAlreadyUserError, setEmailAlreadyUserError] = useState(false);
    const [geohash, setGeohash] = useState(null);
    const fileName = Math.random().toString(36).slice(2);
    const [remainingChars, setRemainingChars] = useState(200);
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [name, setName] = useState(null);
    const [age, setAge] = useState(0);
    const [profileImage, setProfileImage] = useState(null);
    const [bio, setBio] = useState("");
    const initialUserPhoto = user;
    const [aaa, setAaa] = useState(false);
    const [resizedUrl, setResizedUrl] = useState('');
    const [resizedDataUrl, setResizedDataUrl] = useState(''); // Ekledik

    const [url, setUrl] = useState(null)
    const [image, setImage] = useState(null)
    const [searchLocation, setSearchLocation] = useState([]);
    const [state, setState] = useState(false);
    const [videoTest, setVideoTest] = useState(false);
    const [videoUrl, setVideoUrl] = useState("null");
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
    const [videoBlobUrl, setVideoBlobUrl] = useState(null)
    // const [blobVideo, setBlobVideo] = useState(null);
    const [show, setShow] = useState(false)
    const [recordingComplete, setRecordingComplete] = useState(true);
    let stream;
    const [deneme, setDeneme] = useState("false")
    const [gender, setGender] = useState(null);
    const videoRef = useRef(null);

    useEffect(() => {
        setRemainingChars(200 - bio.length);
    }, [bio, remainingChars, aaa, videoRef.current, signUpVerificationVideoSaved]);

    const handleInputChange = async (country) => {
        let data = {loc: country}
        const requestBackEnd = await httpsCallable(functions, 'searchLocation')(data)
        setSearchLocation(requestBackEnd.data)
        if (country.trim() === "") {
            setState(false)
        } else {
            setState(true)
        }
    };

    const startRecording = async () => {
        try {
            stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true})
            setShow(true)

            videoRef.current.srcObject = stream
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
            mediaRecorderRef.current.start();
        } catch {
        }
    };
    // const startRecording = async () => {
    //     setShow(true)
    //     stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
    //     const videoUrl = URL.createObjectURL(blob);
    //     await setBlobVideo(blob)
    //     await setVideoBlobUrl(videoUrl);
    //     // do something with the recorded video URL
    //     if (stream) {
    //         stream.getTracks().forEach(track => track.stop());
    //     }
    // };


    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        videoRef.current.srcObject = null;
    };


    const handleDataAvailable = async (event) => {
        chunksRef.current.push(event.data);
        if (mediaRecorderRef.current.state === "inactive") {
            const blob = new Blob(chunksRef.current, {type: "video/mp4"});
            const videoUrl = URL.createObjectURL(blob);
            await setBlobVideo(blob)
            await setVideoBlobUrl(videoUrl);
            // do something with the recorded video URL
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                setRecordingComplete(false)

            }
            videoRef.current.srcObject = null;
        }
    };

    const retakeVideo = () => {
        setRecordingComplete(true);
        setVideoBlobUrl(null)
        startRecording();
    };
    const [selectedVideo, setSelectedVideo] = useState(false);

    function saveVideo() {
        setVideoTest(false)
        setSelectedVideo(!selectedVideo)
    }
    const mediaQuery = window.matchMedia('(max-width: 897px)')

    const handleCountryClick = (locationName, geohash, lat, lon) => {
        setCountry(locationName)
        setGeohash(geohash)
        setLat(lat)
        setLon(lon)
        setState(false)
        document.getElementById("location").value = locationName

    };
    const handleImageChange = async (e) => {
        const selectedFile = e.target.files[0];
        const imageUrl = URL.createObjectURL(selectedFile);
        setImage(e.target.files[0])
        const img = new Image();
        img.src = imageUrl;
        img.onload = async () => {
            if (img.naturalWidth > 700) {
                const resizedImageUri = await resizeImage(selectedFile, 700, img.naturalHeight);
                setUrl(imageUrl);
                setResizedUrl(resizedImageUri);
                setResizedDataUrl(resizedImageUri); // Base64 verisi direkt olarak atanıyor
            } else {
                setUrl(imageUrl);
            }
        };
    };
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                'JPEG',
                55,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });
    };
    const signUp = e => {
        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
        ).then();
        const errors = document.getElementById("errors");
        e.preventDefault();
        if (name === null) {
            // document.getElementById("nameError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("nameDiv").style.borderWidth = "4px"
            document.getElementById("nameDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Name field cannot be left blank")
            return
        }
        else{
            document.getElementById("nameDiv").style.borderWidth = "2px"
        }
        if (age < 18 || age > 65) {
            // document.getElementById("ageError").innerText = "This field cannot be left blank!"
            // document.getElementById("ageErrors").innerText = "* Age must be between 18-65 "
            document.getElementById("ageDiv").style.borderWidth = "4px"
            document.getElementById("ageDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Age field cannot be left blank and age must be between 18-65")
            return
        }
        else{
            document.getElementById("ageDiv").style.borderWidth = "2px"
            document.getElementById("ageErrors").style.display = "none"

        }
        if (gender === null) {
            // document.getElementById("genderError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("genderDiv").style.borderWidth = "4px"
            document.getElementById("genderDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Gender field cannot be left blank")
            return
        }
        else{
            document.getElementById("genderDiv").style.borderWidth = "2px"
        }
        if (country === "") {
            // document.getElementById("locationError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("locationDiv").style.borderWidth = "4px"
            document.getElementById("locationDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Please select your location from the list ")
            return
        }
        else{
            document.getElementById("locationDiv").style.borderWidth = "2px"
        }
        if (image === null) {
            // document.getElementById("profileImageError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("photoDiv").style.borderWidth = "4px"
            document.getElementById("photoDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Profile photo field cannot be left blank")
            return
        }
        else{
            document.getElementById("photoDiv").style.borderWidth = "2px"
        }
        if (email === null) {
            // document.getElementById("profileImageError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("emailDiv").style.borderWidth = "4px"
            document.getElementById("emailDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Email field cannot be left blank")
            return
        }
        else{
            document.getElementById("emailDiv").style.borderWidth = "2px"
        }
        if (password === null) {
            // document.getElementById("profileImageError").innerText = "This field cannot be left blank!"
            // document.getElementById("mustlyError").innerText = "* Please fill in the required fields. "
            document.getElementById("passwordDiv").style.borderWidth = "4px"
            document.getElementById("passwordDiv").scrollIntoView({ behavior: "smooth" })
            setEmptyWarningState(!emptyWarningState)
            setEmptyWarningText("Password field cannot be left blank")
            return
        }
        else{
            document.getElementById("passwordDiv").style.borderWidth = "2px"
        }
        // if (bio === null) {
        //     // document.getElementById("bioError").innerText = "This field cannot be left blank!"
        //
        //     return
        // }

        const numberAge = Number(age)
        let verificationVideoFilePath;
        auth.createUserWithEmailAndPassword(
            email,
            password,
        )
            .then(async user => {
                document.getElementById("registerButton").innerText = "Please Wait"
                document.getElementById("registerButton").style.backgroundColor = "green"
                document.getElementById("registerButton").style.animation = "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"

                if (user) {
                    const userToken = user.user;
                    const uid = userToken.uid;
                    localStorage.setItem('uid', uid);
                    userToken.getIdToken()
                        .then((idToken) => {
                            // Token alındı, localStorage'a kaydediliyor
                            localStorage.setItem('token', idToken);
                            // Oturum durumunu doğrulama işlemleri gerçekleştirilebilir
                            // ...
                        })
                        .catch((error) => {
                            // Token alınamadı, hata işleme yapılabilir
                        });
                    const fileName = Math.random().toString(36).slice(2);
                    const filePath = `https://storage.googleapis.com/xtrad-cbba8.appspot.com/profileImages/${auth.currentUser.uid}/${fileName}`
                    if (gender === "female") {
                        await database.collection('users').doc(auth.currentUser.uid).set({
                            name: name,
                            age: numberAge,
                            gender: gender,
                            location: country,
                            bio: bio,
                            nameCanonical: name.toLowerCase(),
                            userID: auth.currentUser.uid,
                            isAgent: false,
                            lastSeen: serverTimestamp(),
                            verificationVideo: "null",
                            createdAt: serverTimestamp(),
                            is_premium: false,
                            lat: lat,
                            lon: lon,
                            geohash: geohash,
                            accountStatus : 2,
                            videoWatchLimit : 5
                        }, {merge: true});

                        if (signUpVerificationVideoUrl !== null) {

                            let verificationVideoFilePath = ref(storage, `verificationVideo/${auth.currentUser.uid}/${fileName}`)
                            await uploadBytes(verificationVideoFilePath, blobVideo).then(async () => {
                                await getDownloadURL(verificationVideoFilePath).then(async (url) => {
                                    await database.collection("VerificationVideo").doc(auth.currentUser.uid).set({
                                        url: url,
                                        createdAt: serverTimestamp(),
                                        isReview: false,
                                        deleted : false
                                    })
                                })
                            })

                        }
                    } else {
                        await database.collection('users').doc(auth.currentUser.uid).set({
                            name: name,
                            age: numberAge,
                            gender: gender,
                            location: country,
                            bio: bio,
                            nameCanonical: name.toLowerCase(),
                            userID: auth.currentUser.uid,
                            isAgent: false,
                            lastSeen: serverTimestamp(),
                            createdAt: serverTimestamp(),
                            is_premium: false,
                            lat: lat,
                            lon: lon,
                            geohash: geohash,
                            accountStatus : 2,
                            videoWatchLimit : 5
                        });
                    }

                    // await setDoc(doc(database, "userChats", auth.currentUser.uid), {});
                    const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}/${fileName}`); // Yükleme yolu
                    await uploadString(storageRef, resizedDataUrl, 'data_url'); // Base64 verisini yükle
                    await getDownloadURL(storageRef).then(async (url) => {
                        await database.collection("users").doc(auth.currentUser.uid).collection("profileImages").add({
                            "url": url,
                            "createdAt": new Date(),
                            "isReview": false,
                            "isProfileImage": true
                        })
                        await database.collection("PhotoApprovals").doc(auth.currentUser.uid).set({
                            approvalNecessary: true,
                            userID: auth.currentUser.uid,
                            name: name,
                            gender: gender,
                            age: age,
                            approvalRequestTime: serverTimestamp(),
                            profileImage: url,
                        }).then(async () => {
                            await database.collection("users").doc(auth.currentUser.uid).set({profileImage: url}, {merge: true})

                        })
                    }).catch(error => {
                    })

                    // await uploadBytes(imageRef, image).then(async () => {
                    //     await getDownloadURL(imageRef).then((url) => {
                    //         setUrl(url)
                    //         database.collection("users").doc(auth.currentUser.uid).collection("profileImages").add({
                    //             "url": url,
                    //             "createdAt": new Date(),
                    //             "isReview": false,
                    //             "isProfileImage": true
                    //         }).then(async () => {
                    //             await database.collection("PhotoApprovals").doc(auth.currentUser.uid).set({
                    //                 approvalNecessary: true,
                    //                 userID: auth.currentUser.uid,
                    //                 name: name,
                    //                 gender: gender,
                    //                 age: age,
                    //                 approvalRequestTime: serverTimestamp(),
                    //                 profileImage: url,
                    //             }).then(async () => {
                    //                 await database.collection("users").doc(auth.currentUser.uid).set({profileImage: url}, {merge: true})
                    //             })
                    //         })
                    //     }).catch(error => {
                    //     })
                    // }).catch((error) => {
                    // })
                    document.getElementById("successful").innerText = "Registration Successful! You are being redirected to the Home Page."
                    document.getElementById("successful").style.textAlign = "center"
                    await delay(1000);
                    navigate("/", {replace: true})
                }
            }).catch(error => {
            const errorCode = error.code;
            if (errorCode === 'auth/invalid-email') {
                document.getElementById("emailError").innerText = "İnvalid email"
                document.getElementById("passwordDiv").style.borderWidth = "4px"
            }

            if (errorCode === 'auth/weak-password') {
                document.getElementById("passwordError").innerText = "Your password should be at least 6 characters.";
                document.getElementById("passwordDiv").style.borderWidth = "4px"
            }
            if (errorCode === 'auth/internal-error' || errorCode === 'auth/missing-email') {
                document.getElementById("passwordError").innerText = "Enter your information completely";
                document.getElementById("passwordDiv").style.borderWidth = "4px"
            }
            if(errorCode === `auth/email-already-in-use`)
            {
                setEmailAlreadyUserError(true)
            }
        })
    }

    function clearEmailandPassword(){
        setEmail(null)
        setPassword(null)
        document.getElementById("email").value = ""
        document.getElementById("password").value = ""
        setEmailAlreadyUserError(false)
    }
    async function Denemem() {
        setVideoTest(!videoTest)
    }
    return (
        <>
        <div   className=" w-full h-full flex flex-col items-center justify-center gap-y-3 text-gray-300 m ">
            {mediaQuery.matches ?
            <div className="flex w-full justify-between">
                <button className=" text-black text-xl px-2 rounded-full  left-0 flex"
                        onClick={()=>navigate(-1)}><BiArrowBack/>
                </button>
                <img src={logo} className="hover:cursor-pointer w-auto h-7 flex justify-center" onClick={()=>navigate("/")} alt=""/>
                <button className=" invisible text-black text-xl px-2 rounded-full  left-0 flex"
                        onClick={()=>navigate(-1)}><BiArrowBack/>
                </button>
            </div>
                :
                <img src={logo} className="hover:cursor-pointer w-auto h-7" onClick={()=>navigate("/")} alt=""/>

            }

            <p className="text-black font-bold text-xl">Create an Account!</p>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]" id="nameDiv">
                <p className=" font-light">Name <span className="text-[#D81E71]">*</span> </p>
                <input
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                       id="name"
                       type="text"
                       placeholder="Please enter your name"
                       autoComplete="off" className="bg-transparent text-sm  " />
                <span className="text-[#D81E71] font-bold text-sm px-5" id="nameError"/>
            </div>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]" id="ageDiv">
                <p className=" font-light">Age <span className="text-[#D81E71]">*</span></p>
                <input  className="bg-transparent text-sm "
                       onChange={(e) => {
                           setAge(e.target.value)
                       }}
                       id="age"
                       type="number"
                       placeholder="Please enter your age"
                />
                <span className="text-[#D81E71] font-bold text-sm" id="ageError"/>

            </div>
            <div id="gender" className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl  border-[#D81E71]" id="genderDiv">
                <p className=" font-light ">Gender <span className="text-[#D81E71]">*</span></p>
                <div className="w-full flex items-center justify-between ">
                    <label htmlFor="" className="space-x-2">
                        <input type="radio" name="gender" value="male" id="male"
                               onChange={(e) => setGender(e.target.value)}/>
                        <span className="text-white">Male</span>
                    </label>
                    <label htmlFor="" className="space-x-2">
                        <input type="radio" name="gender" value="female" id="female"
                               onChange={(e) => setGender(e.target.value)}/>
                        <span className="text-white">Female</span>
                    </label>
                </div>
                <span className="text-[#D81E71] font-bold text-sm px-5" id="genderError"/>
            </div>
            <div id="verificationVideo" className={`border-2 w-3/4 flex flex-col p-2 py-3 h-auto bg-black rounded-2xl gap-y-1 border border-[#D81E71] ${(gender === "male" || gender === null) && "hidden"}`}>
                {signUpVerificationVideoSaved === true && <AiFillCheckCircle className="text-green-600 text-3xl"/>}
                <p className=" font-light">Verification Video</p>
                <button onClick={() => setShowVerificationVideoQuery(!showVerificationVideoQuery)}
                        className="flex items-center justify-center space-x-1 text-white border-2 bg-[#D81E71] rounded-2xl w-1/2 mx-auto">
                    <BsFillCameraVideoFill/><p>Record a video</p></button>
            </div>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]" id="locationDiv">
                <p className=" font-light">Location <span className="text-[#D81E71]">*</span></p>
                <input  id="location"
                        type="text"
                        autoComplete="off"
                        onChange={(event) => handleInputChange(event.target.value.toLowerCase())}
                        placeholder="Please enter your city" className="bg-transparent text-sm " />
                <span className="text-red-800 font-bold text-sm px-5" id="locationError"/>
                {state && (
                    <div
                        className="border-2 border-black w-full rounded-md my-1 "
                    >
                        <div className="w-full text-center rounded-md overflow-hidden">
                            {searchLocation.map((item, index) => (
                                <p
                                    key={index}
                                    className="border-b-2 hover:bg-gray-500 hover:text-white hover:font-bold hover:cursor-pointer"
                                    onClick={() =>
                                        handleCountryClick(searchLocation[index].n, searchLocation[index].g, searchLocation[index].l.lat,
                                            searchLocation[index].l.lon)}>
                                    <span className="whitespace-nowrap">{item.c}, {item.n}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div  className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl  border-[#D81E71] gap-y-1" id="photoDiv">
                <p className="font-light">Please upload a profile photo <span className="text-[#D81E71]">*</span></p>
                <div className="flex w-full   items-center" id="photoError">
                    {url === null ? <HiOutlinePhotograph className="text-3xl"/> : <img src={url} className="w-12 h-12 rounded-full object-cover" alt=""/>}
                    <input
                        className=" px-3 py-2 items-center justify-center  text-center flex my-auto text-xs leading-tight text-white rounded  appearance-none focus:outline-none focus:shadow-outline"
                        id="profileImage"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </div>
                <span className="text-[#D81E71] font-bold text-sm px-5" id="profileImageError"/>
            </div>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]" id="bioDiv">
                <p className=" font-light">Bio</p>
                <div className="flex flex-col">
                    <textarea
                        className="block w-full   text-sm placeholder-gray-400 bg-black  rounded-md focus:outline-none focus:border-[#D81E71]"
                        id="bio"
                        onChange={(e) => setBio(e.target.value)}
                        maxLength={200}
                        rows={4}
                        placeholder="Please tell a little about yourself"/>
                    <p className="text-xs text-right text-gray-400">{remainingChars} characters
                        remaining</p>
                </div>
            </div>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]"  id="emailDiv">
                <p className="text-sm font-light">Email <span className="text-[#D81E71]">*</span></p>
                <input   id="email"
                         type="text"
                         onChange={(e) => {
                             setEmail(e.target.value)
                         }}
                         autoComplete="off"
                         placeholder="Email" className="bg-transparent  text-sm" />
                <span className="text-[#D81E71] font-bold text-sm px-5" id="emailError"/>

            </div>
            <div className="border-2 w-3/4 flex flex-col p-3 h-auto bg-black rounded-2xl border border-[#D81E71]"  id="passwordDiv">
                <p className="text-sm font-light">Password <span className="text-[#D81E71]">*</span></p>
                <input  id="password"
                        autoComplete="off"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        type="password" className="bg-transparent text-sm " placeholder="******" />
                <span className="text-[#D81E71] font-bold text-sm px-5" id="passwordError"/>

            </div>
            <button id="registerButton"  type="button"
                    onClick={signUp} className="w-3/4 bg-[#D81E71] flex text-white text-2xl text-center mx-auto items-center justify-center rounded-xl py-2 ">
                <p>Create Account</p>
            </button>
            <span className="text-green-600 font-bold mx-auto" id="successful"/>
            <span className="text-[#D81E71] font-bold text-lg px-5" id="mustlyError"/>
            <span className="text-[#D81E71] font-bold text-lg px-5" id="ageErrors"/>

            <p className="flex text-black gap-x-2 ">Already have an account! <button onClick={()=>navigate("/login")} className="font-extrabold underline" > Login!</button></p>
        </div>
            {emptyWarningState ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/4 h-1/6 xs:w-5/6 xs:h-1/4 bg-white rounded-2xl overflow-hidden justify-center space-y-1">
                            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                <button className=" text-white text-xl px-2 rounded-full"
                                        onClick={()=>setEmptyWarningState(false)}><BiArrowBack/>
                                </button>
                            </div>
                            <div className=" w-full flex h-full overflow-hidden items-center justify-center p-2">
                                <div className=" w-full h-3/4 overflow-hidden items-center flex justify-center text-md text-center font-bold text-[#D81E71]">
                                    <p className="animate-pulse">{emptyWarningText} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {emailAlreadyUserError ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/3 h-1/2 xs:w-11/12 xs:h-1/2 bg-white rounded-2xl overflow-hidden justify-center ">
                            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                <button className=" text-white text-xl px-2 rounded-full"
                                        onClick={clearEmailandPassword}><BiArrowBack/>
                                </button>
                            </div>
                            <div className=" w-full flex text-white flex-col gap-y-3 bg-black h-full overflow-hidden items-center justify-center p-2">
                                <p className="underline">"{email}"</p>
                                <p className="">Email Already in Use </p>
                                <p className="text-center">The email you entered is already associated with an existing account. Please use a different email address to create a new account <br/> or</p>
                                <button onClick={()=>navigate("/login")} className="px-3 py-1.5 bg-[#D81A71] rounded-lg text-sm font-semibold">Try Login</button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <StateContext.Provider value={{
                videoTest,
                Denemem
            }}>
                {showVerificationVideoQuery && <VerificationVideoQuery value="signUpVerificationVideo"/>}
            </StateContext.Provider>
        </>
    );
}
export default SignUpContent;