import React from 'react';

function Page404() {
    return (
        <div className=" w-full h-screen flex flex-col gap-y-5 items-center justify-center text-[#D81E71]">
            <p className="font-bold text-4xl  ">404 Page Not Found</p>
            <p>Sorry, the page you are looking for does not exist.</p>
            <a href="/" className="underline">Turn Homepage</a>
        </div>
    );
}

export default Page404;