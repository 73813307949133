import React, {useContext, useEffect, useState} from 'react';
import NewUserList from "./newUserList";
import TravelList from "./travelList";
import FashionShowsBrandsBar from "./FashionShowsBrandsBar";
import Test from "./test";
import {AuthContext} from "../../Context/AuthProvider";
import VerificationVideoQuery from "./VerificationVideoQuery";
import database, {auth, remoteConfig} from "../../Controller/Firebase/firebase";
import DiscountModal from "./discountModal";
import MakeWebAppModal from "./MakeWebAppModal";
import NotificationAlertModal from "./NotificationAlertModal";
import SuccessfullPremiumModal from "./SuccessfullPremiumModal";
import CancelPremiumModal from "./CancelPremiumModal";
import SuccessfullTravelModal from "./SuccessfullTravelModal";
import DeletedAccountAlertModal from "./DeletedAccountAlertModal";
import RejectModal from "../Layouts/RejectModal"

function Content() {
    let {
        loginUser,setSuccessfullTravel,successfullTravel,
        showVerificationVideoQuery,
        setShowDiscountPremiumModal,
        discountModalViewsLimit, setDiscountModalViewsLimit,
        NotificationAlertViewsLimit, setNotificationAlertViewsLimit,
        VerificationVideoAlertViewsLimit, setVerificationVideoAlertViewsLimit,
        setOpenMakeWebAppModal,openRejectVideoModal, setOpenRejectVideoModal,
        openMakeWebAppModal,
        setShowVerificationVideoQuery,
        discountPremium,
        videoWaitingForApproval,
        showDiscountPremiumModal,
        notificationAlertState,
        setNotificationAlertState,
        openBrowserSettingForNotification,successfullPremium,cancelPremium, setCancelPremium,
        setOpenBrowserSettingForNotification,waitAllow, setWaitAllow,deletedAccountAlertState, setDeletedAccountAlertState
    } = useContext(AuthContext);
    const [value, setValue] = useState(null);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const isWebApp = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');

    useEffect(() => {
        if (loginUser?.gender === "female" && loginUser?.verificationVideo === "null" && videoWaitingForApproval === false) {
            setShowVerificationVideoQuery(true);
        }
        if (auth.currentUser !== null && showDiscountPremiumModal !== null && loginUser?.is_premium === false) {
            setShowDiscountPremiumModal(!showDiscountPremiumModal)
        }
        if (waitAllow === false && auth.currentUser !== null && loginUser?.token === null) //Bildirim tokenı boşsa
        {
            if (mediaQuery.matches) // mobilse
            {
                const isiPhone = /iPhone/i.test(navigator.userAgent);
                const isWebApp = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');
                if (isiPhone === true) // iphone ise
                {
                    if (!isWebApp) // web App değilse
                    {
                        setNotificationAlertState(!notificationAlertState) //web App yapma modalı
                    } else if (isWebApp) {
                        //bildirimleri aç Modalı
                        setNotificationAlertState(!notificationAlertState)
                        setOpenBrowserSettingForNotification(true)
                    }
                } else if (isiPhone === false) {
                    if (!isWebApp) {
                        //web app yapma modalı
                        setNotificationAlertState(!notificationAlertState)

                    } else if (isWebApp) {
                        //bildirimleri aç Modalı
                        setNotificationAlertState(!notificationAlertState)
                        setOpenBrowserSettingForNotification(true)
                    }
                }
            } else {
                //bildirimleri aç modalı
                setNotificationAlertState(!notificationAlertState) //web App yapma modalı
                setOpenBrowserSettingForNotification(true)
            }

        }
    }, [loginUser?.gender, loginUser?.verificationVideo, value]);
    return (
        <div className="overflow-hidden w-full h-full space-y-2 xs:p-3 ">
            {openRejectVideoModal === true && <RejectModal/>}
            {(loginUser?.VerificationVideoModal?.views < VerificationVideoAlertViewsLimit && showVerificationVideoQuery) && <VerificationVideoQuery/>}
            {(loginUser?.DiscountModal?.views < discountModalViewsLimit && showDiscountPremiumModal) && <DiscountModal/>}
            {(loginUser?.NotificationAlertModal?.views < NotificationAlertViewsLimit && notificationAlertState) && <NotificationAlertModal/>}
            {openMakeWebAppModal && <MakeWebAppModal/>}
            {successfullPremium && <SuccessfullPremiumModal/>}
            {successfullTravel && <SuccessfullTravelModal/>}
            {cancelPremium && <CancelPremiumModal/>}
            <NewUserList/>
            <TravelList/>
                <FashionShowsBrandsBar/>
                <Test/>
        </div>
    );
}
export default Content;