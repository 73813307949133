import React, {useContext, useState} from 'react';
import {getFunctions, httpsCallable} from "firebase/functions";
import {auth} from "../../Controller/Firebase/firebase";
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import paymentFailed from "../Images/paymentFailed.gif"
import paymentSuccessfull from "../Images/paymentSuccessfull.gif"
import paymentPos from "../Images/post.gif"
import {AuthContext} from "../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
function PaymentForm() {
    const context = useContext(StateContext);
    const navigate = useNavigate()
    const [paymentResult, setPaymentResult] = useState(false);
    const [checkInfo, setCheckInfo] = useState(null);
    let [successfullOrFailed, setSuccessfullOrFailed] = useState(null);
    const [cardNumber, setCardNumber] = useState('');
    const [expMonth, setExpMonth] = useState('');
    const [expYear, setExpYear] = useState('');
    const [cvc, setCvc] = useState('');
    const [cardholderName, setCardHolderName] = useState('');
    const functions = getFunctions();
    const {loginUser,showDiscountPremiumModal,
        setOpenPaymentModal,setShowDiscountPremiumModal,setSuccessfullPremium,successfullPremium, planId, setPlanId,
        openPaymentModal,
        amount, setAmount,PaymentShift4,
        trick, setTrick,} = useContext(AuthContext);


    function Close(){
        setOpenPaymentModal(!openPaymentModal)
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    async function  payment()  {
        setSuccessfullOrFailed(null)
        if (
            cardholderName.trim() === "" ||
            cardNumber.trim() === "" ||
            expMonth.trim() === "" ||
            expYear.trim() === "" ||
            cvc.trim() === ""
        ) {
            document.getElementById("error").style.display = "flex";
        }
        else {
            document.getElementById("paymentButton").style.display = "none"
            let data = {
                email: auth.currentUser.email,
                userID : auth.currentUser.uid,
                description :`userID : ${auth.currentUser.uid} , name: ${loginUser.name} , platform: MCW` ,
                number: cardNumber,
                customerId : loginUser?.shift4CustomerId,
                expMonth: expMonth,
                expYear: expYear,
                cvc: cvc,
                amount : 0.50,
                cardholderName: cardholderName,
                currency: `EUR`,
                planId: planId
            }
            setPaymentResult(!paymentResult)
            const requestBackEnd =  await httpsCallable(functions, 'Shift4Payment')(data)
            const result = requestBackEnd.data["isPremiumCompleted"]
            setSuccessfullOrFailed(result)
            if (result === true)
            {
                setSuccessfullPremium(!successfullPremium)
                await delay(4000)
                Close()
                navigate("/")
            }

            if(result === false)
            {
                setCheckInfo("Please check your card information")
                await delay(4000)
                setCardHolderName("")
                document.getElementById("cardNumber").value = ""
                document.getElementById("cardHolderName").value = ""
                document.getElementById("securityCode").value = ""
                document.getElementById("expDate").value = ""
                document.getElementById("expYear").value = ""
                document.getElementById("securityCode").value = ""
                setCardNumber("")
                setExpYear("")
                setExpMonth("")
                setCvc("")
                setPaymentResult(false)
                document.getElementById("paymentButton").style.display = "flex"
            }
            if(trick === true)
            {
                setShowDiscountPremiumModal(!showDiscountPremiumModal)
            }
        }
    }
    return (
        <Modal
            isOpen={openPaymentModal}
            onRequestClose={()=>setOpenPaymentModal(!openPaymentModal)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-11/12 xs:w-4/5 shadow-2xl xs:h-auto bg-white overflow-hidden rounded-md ">
                <div className="w-full  flex justify-end p-1 xs:h-1/12 ">
                    <button className="bg-red-600 text-white px-2 rounded-full"
                            onClick={()=>setOpenPaymentModal(!openPaymentModal)}>X
                    </button>
                </div>
                <div className="w-full   flex items-center justify-center xs:h-11/12  ">
                    <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700">
                        <div className="mb-10">
                            <h1 className="text-center font-bold text-xl uppercase">Secure payment info</h1>
                        </div>
                        <p className="text-center  font-bold text-red-500 my-5">{checkInfo}</p>
                        <div className="mb-3">
                            <label className="font-bold text-sm mb-2 ml-1">Card Holder Name</label>
                            <div>
                                <input
                                    id="cardHolderName"
                                    className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                    placeholder="John Walker" type="text" name="cardholderName"
                                    onChange={(event) => setCardHolderName(event.target.value)}/>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="font-bold text-sm mb-2 ml-1">Card number</label>
                            <div>
                                <input
                                    id="cardNumber"
                                    className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                    placeholder="0000 0000 0000 0000"
                                    type="text"
                                    name="number"
                                    maxLength="19"
                                    onChange={(event) => {
                                        // Girilen değeri al
                                        const input = event.target.value;

                                        // Girilen değerden boşlukları kaldır
                                        const cardNumber = input.replace(/\s/g, '');

                                        // Her 4 karakterden sonra boşluk ekleyerek düzenle
                                        let formattedCardNumber = '';
                                        for (let i = 0; i < cardNumber.length; i++) {
                                            if (i > 0 && i % 4 === 0) {
                                                formattedCardNumber += ' ';
                                            }
                                            formattedCardNumber += cardNumber[i];
                                        }

                                        // Düzenlenmiş numarayı input değerine set et
                                        event.target.value = formattedCardNumber;

                                        // Düzenlenmiş numarayı state'e kaydet
                                        setCardNumber(cardNumber);
                                    }}
                                    onBlur={(event) => {
                                        // Input'tan odak çıktığında, boşlukları kaldırarak düzenleme yap
                                        const input = event.target.value;
                                        const cardNumber = input.replace(/\s/g, '');
                                        event.target.value = cardNumber;
                                        setCardNumber(cardNumber);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-3 -mx-2 flex items-end">
                            <div className="px-2 w-1/2">
                                <label className="font-bold text-sm mb-2 ml-1">Expiration date</label>
                                <div>
                                    <select
                                        id="expDate"
                                        className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                        name="expMonth"
                                        onChange={(event) => setExpMonth(event.target.value) }
                                    >
                                        <option value="">Month</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                </div>
                            </div>
                            <div className="px-2 w-1/2">
                                <select
                                    id="expYear"

                                    className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                    name="expYear"
                                    onChange={(event) => setExpYear(event.target.value)}
                                >
                                    <option value="">Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2029">2030</option>
                                    <option value="2029">2031</option>
                                    <option value="2029">2032</option>
                                    <option value="2029">2033</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-10">
                            <label className="font-bold text-sm mb-2 ml-1">CVC</label>
                            <div>
                                <input
                                     className="w-32 px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                    placeholder="000"
                                    type="number"
                                    id="securityCode" name="cvv"
                                    onChange={(event) => setCvc(event.target.value)}
                                />
                            </div>
                        </div>
                        <div id="error" className="w-full flex justify-center items-center text-center text-red-600 font-bold hidden">
                            <span>Fill in all fields completely</span>
                        </div>
                        <div>
                            <button onClick={payment}
                                    id="paymentButton"
                                    className="block w-full flex flex-col justify-center items-center max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-1 font-semibold">
                                <span className="text-lg font-bold">$ {amount}</span>
                                <p className="text-lg">PAY NOW</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {paymentResult ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/3 h-1/2 xs:w-full flex flex-col xs:h-1/2 bg-white overflow-hidden rounded-2xl overflow-hidden justify-center p-3 space-y-1 justify-center text-center shadow-2xl">
                            {successfullOrFailed === null && <img src={paymentPos} alt=""/>}
                            {successfullOrFailed === true && <img src={paymentSuccessfull} alt=""/>}
                            {successfullOrFailed === false && <div className="flex flex-col gap-y-2 w-full h-full items-center justify-center">
                                <p className="text-lg text-red-500 font-bold text-center">Purchase Failed</p>
                                <img src={paymentFailed} className="w-40 h-40 " alt=""/>
                            </div>}
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </Modal>
    )
}
export default PaymentForm;