import React, {useEffect, useRef, useState} from 'react'
import RouteS from "./Components/Layouts/RouteS";
import Loading from "./Components/Layouts/Loading";
import {useLocation} from "react-router-dom";
import database, {auth} from "./Controller/Firebase/firebase";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation()


    useEffect(() => {
        if (localStorage.getItem("uid") !== null)
        {
            if (location.pathname !== "/chat")
            {
                database.collection("users").doc(localStorage.getItem("uid")).set({activeChatRoom : "null"},{merge : true})
            }
        }
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        document.body.style.backgroundColor = "white"
        return () => clearTimeout(timeout);
    }, [location]);

    const isWebApp = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');
    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState();
    const refreshCont = useRef(0);
    useEffect(() => {
      if (isWebApp && location.pathname === "/")
      {
          window.addEventListener("touchstart", pullStart);
          window.addEventListener("touchmove", pull);
          window.addEventListener("touchend", endPull);
          return () => {
              window.removeEventListener("touchstart", pullStart);
              window.removeEventListener("touchmove", pull);
              window.removeEventListener("touchend", endPull);
          };
      }
    });
    const initLoading = () => {
        refreshCont.current.classList.add("loading");
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };
    const pullStart = (e) => {
        const {screenY} = e.targetTouches[0];
        setStartPoint(screenY);
    };
    const pull = (e) => {
        const touch = e.targetTouches[0];
        const {screenY} = touch;
        let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
        if (pullLength > 150) { // Eğer pullLength 200'den büyükse, loading ekranını görünür hale getir
            setPullChange(pullLength);
        } else {
            setPullChange(0); // Eğer pullLength 200'den küçükse, loading ekranını gizle
        }
    };
    // const endPull = (e) => {
    //     setStartPoint(0);
    //     setPullChange(0);
    //     if (pullChange > 220) initLoading();
    // };
    const endPull = () => {
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) {
            // Sayfayı yenileme işlemi
            initLoading();

            // Kullanıcıya titreşim verme
            if ("vibrate" in navigator) {
                navigator.vibrate([200, 100, 200]); // Titreşim deseni (200ms titreş, 100ms dur, 200ms titreş)
            }
        }
    };
    return (
           <>
               {isLoading ? (
                   <Loading />
               ) : (
                  <>
                      {/*<div*/}
                      {/*    ref={refreshCont}*/}
                      {/*    className={`lg:hidden refresh-container w-full text-center border-4 flex justify-center items-center  `}*/}
                      {/*    style={{ marginTop: pullChange / 3.118 || '' }}*/}
                      {/*>*/}
                      <div
                          ref={refreshCont}
                          className={`lg:hidden refresh-container w-full text-center  flex justify-center items-center  ${
                              pullChange > 0 ? "" : "hidden"
                          }  mt-${pullChange / 3.118}  `}
                          // style={{ marginTop: pullChange / 3.118 }}
                      >
                          <div className="refresh-icon p-2 rounded-full">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#D81E71"
                                  className="w-9 h-9"
                                  style={{ transform: `rotate(${pullChange}deg)` }}
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                              </svg>
                          </div>
                      </div>
                      <RouteS />
                  </>
               )}
           </>
    );
}

export default App;
