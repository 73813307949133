import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../../Context/AuthProvider";
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {signOut} from "firebase/auth";
import {auth} from "../../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";

function DeletedAccountAlertModal() {
    const {loginUser,deletedAccountAlertState, setDeletedAccountAlertState,setDownMobilMenu,downMobilMenu} = useContext(AuthContext)
    const navigate = useNavigate()
    useEffect(() => {
        setDownMobilMenu(true)
    }, [downMobilMenu]);
    async function logout(){
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.removeItem('token'); // Tokenı localStorage'dan silin
            localStorage.removeItem('uid')
            navigate("/", {replace: true})
            window.location.reload()
        }).catch((error) => {
            // An error happened.
        });
    }
    return (
        <Modal
            isOpen={deletedAccountAlertState}
            onRequestClose={()=>setDeletedAccountAlertState(!deletedAccountAlertState)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/2 md:w-1/3 md:h-4/5 lg:h-1/2 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={logout}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                    <div className="flex flex-col items-center justify-center gap-y-2 text-center w-full ">
                        <p className="text-2xl">Your account has been suspended.</p>
                        <p className="text-xl text-[#D81E71] font-semibold   ">
                            If you think there is a mistake, please contact "info@modelclub.com".
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default DeletedAccountAlertModal;