import React, {useContext} from 'react';
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import {AuthContext} from "../../Context/AuthProvider";

function StaySafe() {
    const { staySafe, setStaySafe} = useContext(AuthContext);

    return (
        <Modal
            isOpen={staySafe}
            onRequestClose={()=>setStaySafe(!staySafe)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="w-1/2 h-4/5 xs:w-11/12 shadow-2xl xs:h-3/4 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={()=>setStaySafe(!staySafe)}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex flex-col overflow-hidden p-10  w-full h-full   items-center justify-center gap-x-5 text-white">
                    <div className="w-full h-full flex flex-col  overflow-hidden overflow-y-scroll">
                        <p className="text-2xl font-bold text-[#D81E71] mx-auto">About Online Safety Tips</p>
                        <p>
                            Online dating and socializing can be great fun and we hope you meet your
                            ideal partner using one of our sites. We hope to make online dating
                            enjoyable for all our members and make every effort to provide a high
                            level of privacy and security on our website. Like all online
                            communities there are people who seek to take advantage of other
                            users through scamming, or not being who they really claim to be and
                            we urge all users to exercise caution. For instance, some users join
                            the site but aren't looking for dating. Instead they may use your
                            personal details to send unwanted advertisements or ask for money
                            (which is known as a scam). To prevent yourself from undesirable
                            contacts we have compiled a list of tips below to help you be on the
                            safe side. As stated in our terms and conditions of use, we do not
                            conduct background checks of any nature, nor do we conduct criminal
                            checks on any user so you must be responsible for your own use of the
                            site, safely.
                        </p>
                        <br/>
                        <h4>Online Safety Tips</h4>
                        <br/>
                        <h4>Protect your finances</h4>
                        <p>
                            Ignore any request to send money, especially overseas or by wire transfer,
                            and report it to us immediately – even if the person claims to be
                            in an emergency. Wiring money is like sending cash: the sender has no
                            protections against loss and it's nearly impossible to reverse the
                            transaction or trace the money.
                        </p>
                        <p>
                            Never give financial information (such as you social security number,
                            credit card number or bank information) to people you don't know or
                            you haven't met in person.
                        </p>
                        <br/>
                        <h4>Guard your personal and online access information</h4>
                        <br/>
                        <p>
                            Be careful about sharing other personal information, such as your
                            full name, phone number, email and address. You are in control of
                            your online dating experience at all times – remain anonymous until
                            you feel ready. Take advantage of our member-to-member technology
                            that protects your identity. Also, leave any personal contact
                            information out of your profile or username.
                        </p>
                        <p>
                            Use extra caution when accessing your account from a public or shared
                            computer so that others are not able to view or record your password
                            or other personal information. If you share your computer with
                            others, disable the auto sign-in feature to your account and clear
                            all saved passwords.
                        </p>
                        <p>
                            <i>Note:
                                We will never send you an email asking for your username and password
                                information. Any such communication should be reported immediately.
                            </i>
                        </p>
                        <br/>
                        <h4>Be Web Wise</h4>
                        <br/>
                        <p>
                            Be wary of communications that ask you to act immediately, offer
                            something that sounds too good to be true, or asks for personal
                            information. There are certain red flags to watch for that may
                            indicate you're dealing with a scammer. Be aware of anyone who…
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Quickly asks to talk or chat on an outside email or messaging service
                                </p>
                            </li>
                            <li>
                                <p>
                                    Asks you for money
                                </p>
                            </li>
                            <li>
                                <p>
                                    Vanishes mysteriously from the site, then reappears under a different name
                                </p>
                            </li>
                            <li>
                                <p>
                                    Talks about destiny or fate
                                </p>
                            </li>
                            <li>
                                <p>
                                    Claims to be recently widowed
                                </p>
                            </li>
                            <li>
                                <p>
                                    Asks for your address under the guise of sending flowers or gifts
                                </p>
                            </li>
                            <li>
                                <p>
                                    Sends you emails containing strange links to third-party websites
                                </p>
                            </li>
                        </ul>
                        <p>
                            The U.S. Federal Trade Commission's advice to avoid online romance scams is available here:
                            <u>http://onguardonline.gov/articles/0004-online-dating-scams</u>
                        </p>
                        <p>
                            Be cautious and use common sense. If personal details provided by
                            another person don't seem to add up, they behave suspiciously or
                            pressure you for personal or financial information – block
                            communications with that person and report them to us immediately.
                        </p>
                        <br/>
                        <h4>Report suspicious users</h4>
                        <br/>
                        <p>
                            You can block any unwanted user. Additionally, please report
                            anyone who violates our terms of use. Examples of terms of use
                            violations include:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Asking you for money or donations
                                </p>
                            </li>
                            <li>
                                <p>
                                    Members sending harassing or offensive emails/IMs
                                </p>
                            </li>
                            <li>
                                <p>
                                    Members behaving inappropriately after meeting in person
                                </p>
                            </li>
                            <li>
                                <p>
                                    Fraudulent registration or profiles
                                </p>
                            </li>
                            <li>
                                <p>
                                    Spam or solicitation, attempts to sell products or services
                                </p>
                            </li>
                        </ul>
                        <p>
                            Your involvement will help keep the community fun and enjoyable.
                        </p>
                        <br/>
                        <h4>Offline Safety Tips</h4>
                        <p >
                            First meetings are exciting, however, always take precautions and use the
                            following guidelines.
                        </p>
                        <br/>
                        <h4>Get to know the other person before meeting them offline</h4>
                        <br/>
                        <p>
                            Use the technology on our Service that protects your anonymity to
                            get to know someone before disclosing personal information or meeting
                            in person. We don't conduct criminal background checks on our users,
                            so if you would like more information about someone, we recommend
                            using the Internet and government resources available to everyone.
                            This can include typing the person's name into a search engine,
                            reviewing public information made available by government offices, or
                            using a paid service to obtain a full background report.
                        </p>
                        <br/>
                        <h4>Always meet in public</h4>
                        <br/>
                        <p>
                            Meet for the first time in a populated, public location – never in a
                            private or remote location, and never at your date's home or
                            apartment.
                        </p>
                        <br/>
                        <h4>Stay in a public place</h4>
                        <br/>
                        <p>
                            It is best not to go back to your date's home or bring them back
                            to yours on the first date. If your date pressures you, end the date
                            and leave at once.
                        </p>
                        <br/>
                        <h4>Tell a friend</h4>
                        <br/>
                        <p>
                            Inform a friend or family member of your plans and when and where
                            you're going. If you own a mobile phone, make sure you have it with
                            you.
                        </p>
                        <br/>
                        <h4>Stay sober</h4>
                        <br/>
                        <p>
                            Keep a clear mind and avoid doing anything that would impair your
                            judgment and cause you to make a decision you could regret.
                        </p>
                        <br/>
                        <h4>Drive yourself to and from the first meeting</h4>
                        <br/>
                        <p>
                            Just in case things don't work out, you need to be in control of
                            your own ride – even if you take a taxi.
                        </p>
                        <br/>
                        <h4>Keep personal items with you at all times</h4>
                        <br/>
                        <p>
                            You don't want to risk having personal information stolen. If
                            you're drinking, keep your drink with you at all times so it can't be
                            tampered with.
                        </p>
                        <br/>
                        <h4>Long Distance Meetings</h4>
                        <br/>
                        <p>
                            Long distance meetings pose special safety concerns to take into
                            account. Keep these tips in mind.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <i>Stay in a hotel</i>.
                                    Check into your own room and avoid staying in the other person's
                                    home. If you can't afford a hotel, you shouldn't go.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i>Use a taxi or rental car to get to and from the airport</i>.
                                    Securing your own ride is important. Even if the person you're
                                    meeting volunteers to pick you up from the airport, do not get into
                                    a personal vehicle with someone you've never met.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i>Keep your hotel location confidential</i>.
                                    The person you're meeting should respect your privacy until you know
                                    each other.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i>Remember to keep your family and friends posted</i>.
                                    Tell someone who you are meeting, where you are going and when you
                                    will return.
                                </p>
                            </li>
                        </ul>
                        <br/>
                    </div>
                </div>

            </div>

        </Modal>
    );
}

export default StaySafe;