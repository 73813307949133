import React, {useEffect} from 'react';
import Header from "../../Layouts/header";
import LoginContent from "../../Layouts/LoginContent";
import Footer from "../../Layouts/footer";

function Login() {
    return (
        <div className="flex w-full h-full   items-center justify-center">
            <div className="w-full " >
               <div className="">
                   <Header/>
               </div>
                <LoginContent/>
                <Footer/>
            </div>

        </div>
    );
}

export default Login;