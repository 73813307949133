import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-modal";
import StateContext from "../../Context/StateContext";
import Carousel from 'nuka-carousel';
import {BiArrowBack} from "react-icons/bi"
import {FcNext, FcPrevious} from "react-icons/fc";
import {AuthContext} from "../../Context/AuthProvider";
import {doc, updateDoc} from "firebase/firestore";
import database, {auth} from "../../Controller/Firebase/firebase";
const UserPhotos = () => {
    const context = useContext(StateContext);
    const {loginUser,downMobilMenu,setDownMobilMenu} = useContext(AuthContext);
    const select = context.selectPhoto
    const [photos, setPhotos] = useState([]);
    const [current, setCurrent] = useState(0);
    const [changePhoto, setChangePhoto] = useState(false);
    const [deletePhoto, setDeletePhoto] = useState(false);
    const [selectPhoto, setSelectPhoto] = useState(null);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    useEffect(() => {
        setPhotos(context.photos)
        setCurrent(select)
        setDownMobilMenu(true)
    }, [photos, select,loginUser]);
    const nextSlide = () => {
        const length = context.photos.length

        setCurrent(current === length - 1 ? 0 : current + 1);
    }
    const prevSlide = () => {
        const length = context.photos.length
        setCurrent(current === 0 ? length - 1 : current - 1);
    }
    if (!Array.isArray(context.photos) || context.photos.length <= 0) {
        return null
    }
    function ChangeProfilePhoto(img) {
        setChangePhoto(!changePhoto)
        setSelectPhoto(img)
    }
    function DeletePhoto(img) {
        setDeletePhoto(!deletePhoto)
        setSelectPhoto(img)
    }
    function Change() {
        document.getElementById("complate").innerText = "This photo will your profile image, please wait before closing.";
        updateDoc(doc(database, "users", auth.currentUser.uid), {
            profileImage: selectPhoto,
        }).then(r => {
                setChangePhoto(false)
                context.openPhoto()
            setDownMobilMenu(false)
            }
        ).catch(err => {
        })
    }
    function Delete () {
        var docQuery = database.collection("users").doc(auth.currentUser.uid).collection("profileImages").where("url", "==", selectPhoto);
        docQuery.get().then(function (querySnopshot) {
            querySnopshot.forEach(function (doc) {
                doc.ref.delete()
            })
        }).then(()=>{
            document.getElementById("delete").innerText = "Successful Delete!"
            setDeletePhoto(false)
            context.openPhoto()
            setDownMobilMenu(false)
        })
    }
    function close(){
        context.openPhoto()
        setDownMobilMenu(false)

    }
    return (
        <div>
            <Modal isOpen={context.openUserPhoto} onRequestClose={close}
                   className="w-full h-full px-52 py-12 xs:px-0 xs:py-0 xs:w-screen xs:h-full   mx-auto  top-40 overflow-y-scroll xs:flex xs:items-center xs:justify-center ">
                <div
                    className="w-full h-full xs:h-full xs:w-full mx-auto  bg-black rounded-xl xs:rounded-none overflow-hidden">
                    <div
                        className="flex   items-center bg-[#D81E71] xs:bg-black justify-start  xs:justify-start xs:p-4">
                        <button onClick={close} className=" text-white px-2 text-xl rounded-full text-end font-bold py-1 xs:hidden"><BiArrowBack/></button>
                        <button onClick={close}
                                className="w-8 h-8 rounded-full text-3xl xs:text-white  font-bold items-center justify-end xs:text-xl lg:hidden md:hidden">
                            <BiArrowBack/></button>
                    </div>
                    {mediaQuery.matches ?
                        <div className="w-full h-full  overflow-hidden  ">
                            <Carousel withoutControls={true} dragging={false} wrapAround={false} adaptiveHeight={false}
                                      slideIndex={current}>
                                {photos.map((img, i) => (
                                    <div key={i} className="h-full ">
                                        {loginUser !== null && context.userID === loginUser.userID ? (
                                            <div  className="w-full h-full flex flex-col overflow-hidden">
                                                <img src={img} className={`w-auto h-96 object-contain overflow-hidden flex`} alt="" />
                                                {img === loginUser.profileImage ? (
                                                    <div className="w-full p-10 flex items-center justify-center text-green-600 font-bold">
                                                        <p>If you want to delete your profile photo, you have to change the profile photo first.</p>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col gap-y-5 border-black bottom-0 text-white justify-center items-center my-8">
                                                        <button className={`flex w-32 h-12 bg-green-600 font-bold rounded-md `} onClick={() => ChangeProfilePhoto(img)}>Make Profile Photo</button>
                                                        <button className="w-32 h-12 bg-red-700 font-bold rounded-md" onClick={() => DeletePhoto(img)}>Delete</button>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div key={i} className="w-full h-4/5 flex flex-col">
                                                <img src={img} className={`w-full h-full object-contain overflow-hidden flex`} alt="" />
                                            </div>
                                        )}
                                    </div>
                                ))}

                            </Carousel>
                        </div>
                        :
                        <section
                            className="relative w-5/6 h-5/6  mx-auto my-auto xs:w-full flex justify-center items-center  overflow-y-hidden ">
                            <button className="text-white"><FcPrevious
                                className=" absolute text-white text-5xl font-bold text-white lg:hover:text-5xl left-4 cursor-pointer"
                                onClick={prevSlide}/></button>
                            <button><FcNext
                                className="absolute  text-5xl font-bold lg:hover:text-5xl right-4 cursor-pointer"
                                onClick={nextSlide}/></button>
                            <div className="  w-1/2 h-4/5  ">
                                {photos && <img src={photos[current]} alt=""
                                                className="w-full h-full object-contain opacity-100  transition duration-200 ease-in "/>}
                            </div>
                        </section>
                    }
                    {loginUser !== null &&
                        <>
                            {context.userID === loginUser.userID &&
                                <>{photos[current] !== loginUser.profileImage ?
                                    <div className={` w-full flex items-center justify-center gap-x-4 text-white ${mediaQuery.matches && "hidden"}`}>

                                        <button className=" w-32 h-12 bg-green-600 font-bold rounded-md" onClick={()=>ChangeProfilePhoto(photos[current])}>Make Profile Photo</button>


                                        <button className=" w-32 h-12 bg-red-700 font-bold rounded-md" onClick={()=>DeletePhoto(photos[current])}>Delete</button>
                                    </div>
                                    :
                                    <div className="w-full flex items-center justify-center text-green-600 font-bold text-xl">
                                        <p>If you want to delete your profile photo, you have to change the profile photo first.</p>
                                    </div>
                                }
                                </>


                            }
                        </>
                    }
                </div>
                {changePhoto ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className=" flex flex-col w-1/3 h-1/3 xs:w-full xs:h-1/2 bg-white rounded-2xl overflow-hidden justify-center p-3 space-y-1">
                                <div className="flex justify-start items-center  ">
                                    <button className="text-black  text-3xl xs:py-2 font-bold rounded-full px-2 " onClick={()=>setChangePhoto(false)}><BiArrowBack  /></button>
                                </div>
                                <div className=" w-full h-full overflow-hidden items-center flex justify-center">
                                    <p className="text-2xl text-black font-bold text-center italic ">Your photo
                                        will profile photo. Are you sure?</p>
                                </div>
                                <span id="complate"
                                      className="flex  justify-center text-center  animate-bounce tran  text-green-600 text-md font-bold"/>
                                <div className="flex justify-center items-center ">
                                    <button className="bg-green-600 lg:py-2 xs:py-2 text-white font-bold rounded-full px-2 " onClick={Change}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
                {deletePhoto ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className=" flex flex-col w-1/3 h-1/3 xs:w-full xs:h-1/2 bg-white rounded-2xl overflow-hidden justify-center p-3 space-y-1">
                                <div className="flex justify-start items-center ">
                                    <button className="text-green-600 text-2xl xs:py-2 text-white font-bold rounded-full px-2 " onClick={()=>setDeletePhoto(false)}><BiArrowBack/></button>
                                </div>
                                <div className=" w-full h-full overflow-hidden items-center flex justify-center">
                                    <p className="text-2xl text-black font-bold text-center italic ">Your photo will be deleted. Are you sure?</p>
                                </div>
                                <span id="delete"
                                      className="flex  justify-center text-center  animate-bounce tran  text-green-600 text-md font-bold"/>
                                <div className="flex justify-center items-center ">
                                    <button className="bg-green-600 lg:py-2 xs:py-2 text-white font-bold rounded-full px-2 " onClick={Delete}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </Modal>
        </div>
    );
}
export default UserPhotos;