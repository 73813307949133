import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Modal from "react-modal";
import StateContext from "../../Context/StateContext";
import {BsFillCameraVideoFill} from "react-icons/bs";

function VerificationVideoRecord() {
    const context = useContext(StateContext)
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
    const [videoBlob, setVideoBlob] = useState(null)
    const [show, setShow] = useState(false)
    const [recordingComplete, setRecordingComplete] = useState(true);
    let stream;
    const [deneme, setDeneme] = useState("false");
    const startRecording = async () => {
        setShow(true)
        stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        videoRef.current.srcObject = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.start();
    };
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        videoRef.current.srcObject = null;
    };
    let url;
    let lastUrl;
    const handleDataAvailable = (event) => {
        chunksRef.current.push(event.data);
        if (mediaRecorderRef.current.state === "inactive") {
            const blob = new Blob(chunksRef.current, {type: "video/mp4"});
             url = URL.createObjectURL(blob);
            setVideoBlob(url);
            // do something with the recorded video URL
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                setRecordingComplete(false)

            }
            videoRef.current.srcObject = null;
        }
    };
    const saveVideo = () =>{
    }
    const retakeVideo = () => {
        setRecordingComplete(true);
        setVideoBlob(null)
        startRecording();
    };
    return (
        <Modal
            isOpen={context.videoTest}
            onRequestClose={context.Denemem}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
            <div
                className={`w-1/4 h-3/4  xs:w-full xs:h-full p-1 rounded-md overflow-hidden bg-white border-4 border-green-600 rounded-3xl xs:border-none xs:rounded-none`}>
                <div className="closeModal  w-full h-[10%]  flex justify-end p-1  items-center">
                    <button
                        className="w-7 h-7 xs:w-9 xs:h-9  flex rounded-full bg-red-700 justify-center items-center text-white font-bold"
                        onClick={context.Denemem}>X
                    </button>
                </div>
                <div
                    className="w-full h-[90%] flex flex-col items-center justify-center  ">
                    {!show ?
                        <div className="w-full  gap-y-5 flex flex-col">
                            <div className="p-10 font-bold text-lg border-b-4 border-t-4 border-green-600">
                                <p>NOT upload nude, partially nude, discriminatory, unlawful, infringing,
                                    hateful, pornographic or sexually
                                    suggestive videos.</p>
                                <p>We may delete any inappropriate photos or may block your account. <br/>
                                </p>
                            </div>
                            <div className="w-full  flex justify-center">
                                <button
                                    className="text-lg border px-4 py-2 rounded-full text-white font-bold bg-green-600"
                                    onClick={startRecording}>Start
                                    Recording
                                </button>
                            </div>
                        </div>
                        :
                        <div className="w-full h-full  flex flex-col overflow-hidden">
                            {recordingComplete ?  <p className="flex h-[4%] items-center justify-center gap-x-3 font-bold text-xl animate-pulse text-red-700">
                                <BsFillCameraVideoFill/> Recording..</p> :
                                <p className="flex h-[4%] items-center justify-center gap-x-3 font-bold text-xl animate-pulse text-green-600">
                                    Successful</p>
                            }
                            <div className="w-full  h-[85%]   ">
                                {videoBlob !== null &&
                                    <video src={videoBlob} className="h-full w-full xs:w-full  xs:h-3/4" autoPlay
                                           loop/>}
                                <video ref={videoRef} className="h-full w-full" playsInline autoPlay muted/>
                            </div>

                            <div className={`flex w-full justify-center h-[6%] ${!recordingComplete && "hidden"}`}>
                                <button onClick={stopRecording}
                                        className="w-14 h-14 border-8 border-black bg-red-600 rounded-full"/>
                            </div>
                        </div>
                    }
                    {!recordingComplete &&
                        <div className=" flex flex-col gap-y-6">
                        <button className={` bg-green-600 text-white font-bold px-3 py-2 rounded-xl`} onClick={saveVideo}>Save Video</button>
                        <button className={`bg-gray-600 text-white font-bold px-3 py-2 rounded-xl`} onClick={retakeVideo} >Retake Video</button>
                    </div>}
                </div>

            </div>
        </Modal>
    );
}

export default VerificationVideoRecord;


//
// <div
//     className="w-full h-[90%] flex flex-col items-center justify-center  ">
//     {!show ?
//         <div className="w-full  gap-y-5 flex flex-col">
//             <div className="p-10 font-bold text-lg border-b-4 border-t-4 border-green-600">
//                 <p>NOT upload nude, partially nude, discriminatory, unlawful, infringing,
//                     hateful, pornographic or sexually
//                     suggestive videos.</p>
//                 <p>We may delete any inappropriate photos or may block your account. <br/>
//                 </p>
//             </div>
//             <div className="w-full  flex justify-center">
//                 <button
//                     className="text-lg border px-4 py-2 rounded-full text-white font-bold bg-green-600"
//                     onClick={startRecording}>Start
//                     Recording
//                 </button>
//             </div>
//         </div>
//         :
//         <div className="w-full h-full  flex flex-col overflow-hidden">
//             {recordingComplete === false?
//                 <p className="flex h-[4%] items-center justify-center gap-x-3 font-bold text-xl animate-pulse text-red-700"><BsFillCameraVideoFill/> Recording..</p>
//                 :
//                 <p className="flex h-[4%] items-center justify-center gap-x-3 text-xl font-bold text-green-600"> Successful</p>
//             }
//             <div className="w-full  h-[85%]  ">
//                 {videoBlob !== null && <video src={videoBlob} className="h-full w-full xs:w-full  xs:h-3/4" autoPlay loop/>}
//                 <video ref={videoRef} className="h-full w-full" playsInline autoPlay muted  />
//             </div>
//             {recordingComplete === false ?
//                 <div className="flex w-full justify-center h-[6%]">
//                     <button onClick={stopRecording}
//                             className="w-14 h-14 border-8 border-black bg-red-600 rounded-full"/>
//                 </div>
//                 :
//                 <div className="flex flex-col  gap-y-4 w-full justify-center h-[6%]">
//                     <button className="bg-green-600 text-white font-bold px-2 py-1 " onClick={console.log("adassad")}>Save Video</button>
//                     <button className="bg-gray-600  text-white font-bold px-2 py-1 flex  items-center justify-center space-x-2"
//                             onClick={()=> setShow(true)}>
//                         <GrRefresh className="text-xl"/>
//                         <span>Retake Video</span>
//                     </button>
//                 </div>
//             }
//
//         </div>
//     }
// </div>
// ------------------------------------------------------------------------------------------------------------

