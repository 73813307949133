import React, {useContext} from 'react';
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import {BsFillCheckCircleFill} from "react-icons/bs";
import StateContext from "../../Context/StateContext";
import EditProfile from "./EditProfile";
import PremiumSettings from "./PremiumSettings";
import {AuthContext} from "../../Context/AuthProvider";
import blueDiamond from "../Images/blueDiamond.png"
import pinkDiamond from "../Images/pinkDiamond.png"


function SuccessfullPremiumModal() {
    const {loginUser,successfullPremium, setSuccessfullPremium} = useContext(AuthContext)
    return (
        <Modal
            isOpen={successfullPremium}
            onRequestClose={()=>setSuccessfullPremium(!successfullPremium)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={()=>setSuccessfullPremium(!successfullPremium)}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                    <div className="flex flex-col items-center justify-center gap-y-2 ">
                        <BsFillCheckCircleFill className="text-green-700 text-4xl"/>
                        <p className="text-2xl">Successfully Purchased</p>
                        <p className="text-xl text-[#D81E71] font-semibold  w-full flex text-center">
                            Welcome aboard, you now have premium features.
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessfullPremiumModal;