import React, { useContext, useState, useEffect } from 'react';
import StateContext from "../../Context/StateContext";
import { BiArrowBack } from "react-icons/bi";
import Modal from "react-modal";
import { AuthContext } from "../../Context/AuthProvider";
import { ChatContext } from "../../Context/ChatContext";
import ReactPlayer from 'react-player';

import loading from "../Images/loading.gif"

Modal.setAppElement('#root')

function VerificationVideo() {
    const { loginUser } = useContext(AuthContext);
    const { videoOpenState, setVideoOpenState, femaleVerificationVideo } = useContext(ChatContext);
    const context = useContext(StateContext);
    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        const videoElement = document.createElement('video');
        videoElement.src = femaleVerificationVideo;
        videoElement.addEventListener('loadeddata', handleVideoLoaded);
        return () => {
            videoElement.removeEventListener('loadeddata', handleVideoLoaded);
        };
    }, [femaleVerificationVideo,videoLoaded]);

    const handleVideoLoaded = () => {
        setVideoLoaded(true);
    };
    return (
        <Modal
            isOpen={videoOpenState}
            onRequestClose={() => setVideoOpenState(!videoOpenState)}
            className="lg:w-1/3 lg:h-3/5 xs:w-screen xs:h-full xs:rounded-none md:w-1/2 md:top-24 mx-auto relative lg:top-40 overflow-hidden rounded-xl"
        >
            <div className="w-full h-full flex flex-col">
                <div className="bg-gray-700 flex items-center p-2 xs:p-5 text-white text-2xl">
                    <BiArrowBack className="hover:cursor-pointer" onClick={() => setVideoOpenState(!videoOpenState)} />
                    {loginUser?.is_premium === false && (
                        <p className="mx-auto text-[#D81E71] font-bold text-lg">Remaining free video views: <span className="text-white animate-pulse">{loginUser?.videoWatchLimit}</span></p>
                    )}
                </div>
                <div className="w-full h-full bg-gray-700 flex overflow-hidden p-2">

                    <ReactPlayer  url={femaleVerificationVideo}
                                  loop={true}
                                  playing={true}
                                  playsinline={true}
                                  controls
                    />
                </div>
            </div>
        </Modal>
    );
}

export default VerificationVideo;