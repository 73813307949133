import React, {useContext, useRef, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import StateContext from "../../Context/StateContext";
import {auth} from "../../Controller/Firebase/firebase";
import Sign from "./Sign";
import database from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {ChatContext} from "../../Context/ChatContext";
import {AuthContext} from "../../Context/AuthProvider";
import logo from "../Images/modelClubMobilSvg.svg"
import {BiArrowBack} from "react-icons/bi";



const LoginContent = () => {
    const context = useContext(StateContext);
    const {updatePage, setUpdatePage} = useContext(ChatContext)
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [resetSent, setResetSent] = useState(null);

    const [signup, setSignup] = useState(false);
    const [sendResetPassword, setSendResetPassword] = useState(false);

    function signupToggle() {
        setSignup(!signup)
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    const signIn = async e => {
        document.getElementById("waiting").style.display = "flex"
        document.getElementById("loginButton").style.display = "none"
        const errors = document.getElementById("errors")
        auth.signInWithEmailAndPassword(
            email,
            password
        ).then(async user => {

            if (user) {
                const userToken = user.user;

                userToken.getIdToken()
                    .then((idToken) => {
                        // Token alındı, localStorage'a kaydediliyor
                        localStorage.setItem('token', idToken);
                        // Oturum durumunu doğrulama işlemleri gerçekleştirilebilir
                        // ...
                    })
                    .catch((error) => {
                        // Token alınamadı, hata işleme yapılabilir
                    });
                const uid = userToken.uid;
                localStorage.setItem('uid', uid);
                errors.style.color = "green"
                errors.innerText = "Login successful";
                await delay(1000);
                setUpdatePage(true)
                await database.collection("users").doc(auth.currentUser.uid).update({lastSeen: serverTimestamp()})
                navigate("/", {replace: true})
                // context.loginToggle()
            }
        }).catch(error => {
            document.getElementById("waiting").style.display = "none"
            document.getElementById("loginButton").style.display = "flex"
            const errorCode = error.code;
            const message = error.message;
            if (errorCode === 'auth/user-not-found') {
                errors.innerText = "Check your email or password.";

            } else if (errorCode === 'auth/invalid-email') {
                errors.innerText = "Please enter your email format correctly.";

            } else if (errorCode === 'auth/wrong-password') {
                errors.innerText = "Check your email or password."
            } else if (email === '' || password === '') {
                errors.innerText = "Please do not leave your email or password blank. "
            }
        })
    }

    function SendResetPassword() {
        setSendResetPassword(!sendResetPassword)
    }

    function handleReset() {
        if (confirmEmail !== "") {
            auth.fetchSignInMethodsForEmail(confirmEmail)
                .then(async (signInMethods) => {
                    if (signInMethods.length > 0) {
                        // Kullanıcı kayıtlıdır
                        auth.sendPasswordResetEmail(confirmEmail)
                            .then(async () => {
                                setResetSent(true);
                                await delay(1300)
                                window.location.reload()
                            })

                    } else {
                        setResetSent(false)
                        await delay(1000)
                        window.location.reload()
                    }
                })
        } else return

    }
    return (
        <div className="flex  mx-auto flex-col gap-y-10 justify-center  ">
            <p className="text-center text-xl font-bold text-black ">Start Now!</p>
            <div className=" w-1/4 mx-auto xs:w-full xs:p-3 ">
                <div className="bg-white  flex flex-col w-full  rounded-xl ">
                    <div
                        className="w-full flex flex-col gap-y-3 ">
                        <div id="input" className="flex flex-col w-full py-1 border-2 bg-black border-[#D81E71] text-white  px-3 rounded-3xl overflow-hidden ">
                            <label htmlFor="username"
                                   className=" mb-2  text-sm px-4 text-gray-30">Email</label>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                type="email"
                                ref={emailRef}
                                placeholder="Please enter your mail"
                                className="appearance-none  bg-black border-gray-100 px-4 py-1 border-none rounded-full  "
                            />
                        </div>
                        <div id="input" className="flex flex-col w-full border-2 bg-black border-[#D81E71] text-white py-  px-3 rounded-3xl overflow-hidden ">
                            <label htmlFor="password"
                                   className=" mb-2  text-sm px-4 text-gray-30">Password</label>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                type="password"
                                ref={passwordRef}
                                placeholder="Please enter your password"
                                className="appearance-none  bg-black border-gray-100 px-4 py-1 border-none rounded-full  "
                            />
                        </div>
                        <div
                            className={`w-full  flex text-black justify-end text-sm underline`}>
                            <button onClick={SendResetPassword}>Did you forget your password?
                            </button>
                        </div>
                        <span id="errors" className="text-[#DD3A82] font-bold w-full text-center animate-pulse "/>
                        <div id="button" className="flex flex-col w-full my-5">
                            {sendResetPassword ? (
                                <>
                                    <div
                                        className="justify-center items-center  flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                                    >
                                        <div
                                            className=" flex flex-col w-1/3 h-1/3 xs:w-full xs:h-1/2 bg-white overflow-hidden rounded-2xl  p-3 space-y-1  text-center shadow-2xl">
                                            <div
                                                className="w-full flex items-center justify-end text-white font-bold">
                                                <button onClick={SendResetPassword}
                                                        className="bg-red-600 w-7 h-7 rounded-full">X
                                                </button>
                                            </div>
                                            <div
                                                className="w-full h-full text-xl flex justify-center items-center">
                                                {resetSent === null &&
                                                    <div className="flex flex-col gap-y-2">
                                                        <p className="text-black font-bold">Enter
                                                            your e-mail address</p>
                                                        <input
                                                            onChange={(e) => setConfirmEmail(e.target.value)}
                                                            type="text"
                                                            className="border-2 border-gray-500 text-sm px-4 rounded-full"
                                                            placeholder="test@test.com"/>
                                                        <button onClick={handleReset}
                                                                className=" text-sm py-1 px-2 border-2 bg-green-700 text-white font-bold flex justify-center rounded-full">Send
                                                            reset link
                                                        </button>
                                                    </div>}
                                                {resetSent === true && <div
                                                    className="  text-green-600 font-bold">Password
                                                    reset link sent to "{confirmEmail}"</div>}
                                                {resetSent === false && <div
                                                    className="text-red-700 font-bold">No account found for this e-mail  address.</div>}


                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                            <button
                                onClick={signIn}
                                id="loginButton"
                                type="button"
                                className="w-full py-4 bg-[#DD3A82] rounded-lg text-green-100 flex items-center justify-center"
                            >
                                <div className="flex flex-row items-center justify-center">
                                    <div className="mr-2">
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className="font-bold">Login!</div>
                                </div>
                            </button>
                            <button
                                id="waiting"
                                type="button"
                                className="hidden w-full py-4 bg-green-600 rounded-lg text-white  flex items-center justify-center"
                            >
                                    <div className="font-bold animate-pulse">Please Waiting</div>
                            </button>
                        </div>
                        <Link to="/signUp" className=" w-full flex justify-center ">
                            <p className="text-black font-bold underline text-sm">Don't have an
                                account? <span className=" font-extrabold">Register now!</span>
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginContent;