import {
    createContext, useContext, useEffect,
    useReducer, useState,
} from "react";
import database, {auth} from "../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthProvider";
export const ChatContext = createContext();
export const ChatContextProvider = ({children}) => {
    let navigate = useNavigate();
    const{loginUser,verificationVideoChatPremission,showVerificationVideoQuery, setShowVerificationVideoQuery} = useContext(AuthContext);
    const [deleteRoomsBoolean, setDeleteRoomsBoolean] = useState(false);
    const [updatePage, setUpdatePage] = useState(false);
    const [otherUserActiveChatRoom, setOtherUserActiveChatRoom] = useState(null);
    let verificationPermission = (verificationVideoChatPremission === "false" && loginUser?.verificationVideo === "null") ? false : true
    let chatPermission = (loginUser?.is_premium === false || verificationPermission === false ) ? false : true
    const INITIAL_STATE = {
        chat: "null",
    };
    const deleteInitialState = {deleteCount : 0};
    const newChatInitialState = {chatCount : 0};
    const chatReducer = (state, action) => {
        switch (action.type) {
            case "CHANGE_USER":
                return {
                    chat: action.payload,
                };
            default:
                return state;
        }
    };
    const deleteReducer = (deleteState,action)=>
    {
        switch (action.type)
        {
            case "delete":
                return {deleteCount:deleteState.deleteCount + 1}
            default : throw new Error();
        }
    }
    const newChatReduce = (chatState,action)=>
    {
        switch (action.type)
        {
            case "newChat":
                return {newChatCount:chatState.newChatCount + 1}
            default : throw new Error();
        }
    }
    const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);
    const [chatState, newChatDispatch] = useReducer(newChatReduce, newChatInitialState);
    const [deleteState, deleteDispatch] = useReducer(deleteReducer, deleteInitialState);
    const [femaleVerificationVideo, setFemaleVerificationVideo] = useState(null);
    const [videoOpenState , setVideoOpenState] = useState(false)

    async function VerifactionVideoToggle(verificationVideo) {
        if( localStorage.getItem('token') === null)
        {
            navigate("/login")
        }
        else {
            if (loginUser?.is_premium === false) {
                if (loginUser?.videoWatchLimit > 0) {
                    await database.collection("users").doc(auth.currentUser.uid).update({"videoWatchLimit" : loginUser?.videoWatchLimit-1 }).then(()=>
                        {
                          }
                    )
                    setVideoOpenState(!videoOpenState)
                    setFemaleVerificationVideo(verificationVideo)
                } else {
                    navigate("/premium")
                }
            }
            else {
                setVideoOpenState(!videoOpenState)
                setFemaleVerificationVideo(verificationVideo)
            }
        }

    }
    async function goChat(e, data,token) {
        if (auth.currentUser === null) {
            navigate("/login")
        } else {
            if (chatPermission === false)
            {
                if (loginUser.is_premium === false)
                {
                    navigate("/premium")
                }
                if (loginUser.verificationVideo === "null")
                {
                    setShowVerificationVideoQuery(!showVerificationVideoQuery)
                }
                return
            }
                let temp10 = []
                let temp11 = []
                // setDeleteRoomsBoolean(false)
                let combinedId = auth.currentUser.uid > data["userID"] ? auth.currentUser.uid + data["userID"] : data["userID"] + auth.currentUser.uid
                await database.collection("rooms").where("combinedId", "==", combinedId).get().then((doc) => {
                    temp10 = []
                    doc.docs.forEach(res => {
                        temp10.push(res.data())
                        temp11.push(res.id)
                    })
                })
                if (temp10.length > 0) {
                    const u = {
                        data: temp10[0],
                        id: temp11.toString(),
                        otherUser: {name: data["name"], profileImage: data["profileImage"], userID: data["userID"] , token,
                            age:data["age"], gender:data["gender"] , bio:data["bio"] , location:data.location,
                            is_premium:data["is_premium"],activeChatRoom : data["activeChatRoom"]

                        }
                    }
                    dispatch({type: "CHANGE_USER", payload: u})
                    navigate(`/chat`)
                } else {
                    const u = {
                        data: "null",
                        id: combinedId,
                        otherUser: {name: data["name"], profileImage: data["profileImage"], userID: data["userID"] , token,
                            age:data["age"], gender:data["gender"] , bio:data["bio"] , location:data.location,
                            is_premium:data["is_premium"],activeChatRoom : data["activeChatRoom"]
                        }                    }
                    dispatch({type: "CHANGE_USER", payload: u})
                    navigate(`/chat`)
                }
        }
    }
    return (
        <ChatContext.Provider value={{data: state,state, dispatch,deleteRoomsBoolean, goChat,chatPermission,setDeleteRoomsBoolean,updatePage, setUpdatePage, data2:deleteState ,deleteDispatch,deleteInitialState,data3:chatState,newChatDispatch,
            femaleVerificationVideo, setFemaleVerificationVideo,otherUserActiveChatRoom, setOtherUserActiveChatRoom,
            videoOpenState , setVideoOpenState,VerifactionVideoToggle
        }}>
            {children}
        </ChatContext.Provider>
    );
};