import React, {useContext, useEffect, useState} from 'react';
import {

    BsFillCheckCircleFill, BsPlayCircleFill,
} from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import {ChatContext} from "../../Context/ChatContext";
import {AuthContext} from "../../Context/AuthProvider";
import VerificationVideo from "./VerificationVideo";
import database, {auth} from "../../Controller/Firebase/firebase";
import loading from "../Images/loading.gif"
import VerificationVideoQuery from "./VerificationVideoQuery";
import newUserLogo from "../Images/newUsersLogo.svg";
import locationIconWhite from "../Images/locationIconWhite.svg";
import favWhiteSvg from "../Images/favIconWhite.svg"
import favGradientSvg from "../Images/favIconGradient.svg"
import chatSvg from "../Images/chat.svg";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import pinkDiamond from "../Images/pinkDiamond.png";
import blueDiamond from "../Images/blueDiamond.png";
import favIconWhite from "../Images/favIconWhite.svg";

function FavoriteUsers() {
    const {
        goChat, VerifactionVideoToggle,
        videoOpenState, setVideoOpenState
    } = useContext(ChatContext);
    const {
        myFavorite,
        myBlockList,
        OnlineOrOffline,
        newUsers,
        goUserProfile,
        listenFavHasMore, myFavoriteClone, setMyFavoriteClone,ListenToFav,
        showVerificationVideoQuery, loginUser
    } = useContext(AuthContext);
    const navigate = useNavigate()
    // myFavorite ile başlangıçta klonlanmış haliyle aynı
    const [removeFav, setRemoveFav] = useState([]);
    // const ListenToFav = async () => {
    //     const snapshot = await database.collection('users').doc(auth.currentUser.uid).collection("favorites").get()
    //     setTotalLengthListenFav(snapshot.size);
    //     await database
    //         .collection("users")
    //         .doc(auth.currentUser.uid)
    //         .collection("favorites")
    //         .orderBy("favDate", "desc")
    //         .startAfter(lastVisibleListenFav)
    //         .limit(200)
    //         .onSnapshot(async (querySnapshot) => {
    //             const temp = [];
    //             querySnapshot.forEach(async (doc) => {
    //                 const userDoc = await database.collection("users").doc(doc.id).get();
    //                 const userData = userDoc.data();
    //                 temp.push({id: userDoc.id, data: userData});
    //                 // Set lastVisible to the last document in the current query
    //                 setLastVisibleListenFav(querySnapshot.docs[querySnapshot.docs.length - 1]);
    //             })
    //             ;
    //             myFavorite.forEach((favorite) => {
    //                 temp.push(favorite);
    //             });
    //             setMyFavorite(temp);
    //             if (myFavorite.length === totalLengthListenFav) {
    //                 setListenFavHasMore(false);
    //             }
    //         });
    // };
    const handleClick = async (id) => {
        if (removeFav.includes(id)) {
            // ID zaten removeFav dizisinde varsa, onu çıkararak güncelle
            setRemoveFav(removeFav.filter((item) => item !== id));
            await database
                .collection("users")
                .doc(auth.currentUser.uid)
                .collection("favorites")
                .doc(id)
                .set({favDate: new Date()})
                .catch((error) => {
                });
        } else {
            // ID removeFav dizisinde yoksa, ekleyerek güncelle
            setRemoveFav([...removeFav, id]);
            await database.collection("users").doc(auth.currentUser.uid).collection("favorites").doc(id).delete().then((r) => {
            })

        }
    };

    useEffect(() => {
        setMyFavoriteClone([...myFavorite])
    }, []);

    return (
        <div className="flex flex-col w-full xs:w-full xs:px-3 mx-auto">
            <div className="flex  text-2xl text-black justify-between items-center  lg:hidden  md:hidden   ">
                <BiArrowBack onClick={() => navigate(-1)}/>
                <div
                    className="flex w-full h-auto  text-xl text-black justify-center items-center space-x-3 font-bold">
                    <img src={favGradientSvg} className="w-10 h-10" alt=""/>
                    <p className="text-orange-600">My Favorites</p>
                </div>
            </div>
            <InfiniteScroll dataLength={myFavorite.length}
                            next={ListenToFav}
                            hasMore={listenFavHasMore}
                // loader={<img className={`w-12 h-12 object-cover mx-auto`} src={loading}/>}
            >
                {showVerificationVideoQuery && < VerificationVideoQuery/>}
                {myFavoriteClone.length === 0 ?
                    <div className="w-full  ">< p
                        className="text-xl text-red-600 font-bold text-center animate-pulse"> You
                        haven 't added anyone to your favorites list yet ! </p></div> :
                    <div
                        className={`grid lg:grid-cols-4 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5`}
                    >
                        {
                            myFavoriteClone.map(({
                                                     id,
                                                     viewCount,
                                                     isSeen,
                                                     viewTime,
                                                     favTrue,
                                                     data: {
                                                         name,
                                                         age,
                                                         location,
                                                         profileImage,
                                                         userID,
                                                         gender,
                                                         verificationVideo,
                                                         lastSeen, is_premium, token
                                                     }
                                                 }, index) => (
                                <div
                                    className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                                    key={index}
                                >
                                    <img
                                        onClick={() => goUserProfile(myFavorite[index].data, loginUser?.name, loginUser?.profileImage)}
                                        src={profileImage}
                                        className=" absolute w-full h-full border-2 border-[#D81E71] object-cover rounded-2xl"
                                        alt=""/>
                                    {newUsers.find(el => el.id === userID) &&
                                        <div className="absolute  w-full top-3 rotate-12">
                                            <div className="flex w-full items-center justify-start ">
                                                <img src={newUserLogo}
                                                     className="w-14 h-14 " alt=""/>
                                            </div>
                                        </div>
                                    }
                                    <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                                        <div className="flex flex-col w-full px-2 py-1 "
                                             onClick={() => goUserProfile(myFavorite[index].data, loginUser?.name, loginUser?.profileImage)}>
                                            <div
                                                className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                                {20 > OnlineOrOffline(lastSeen) ?
                                                    <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                                    <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                                {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                                {is_premium === true &&
                                                    <img src={gender === "female" ? pinkDiamond : blueDiamond}
                                                         className="text-white w-5 h-5 " alt=""/>}
                                            </div>
                                            {location.length !== 0 && <div
                                                className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                                <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                                <p className={`w-full `}>
                                                    {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                                </p>
                                            </div>}
                                        </div>
                                        <div
                                            className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : " bg-[#D81E71] bg-opacity-80 "}`}>
                                            {verificationVideo === "null" || !verificationVideo ?
                                                <p className="text-sm">Waiting for verification video</p>
                                                : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                                     className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                                    <BsPlayCircleFill className="text-lg animate-pulse"/>
                                                    Verification
                                                    Video <BsFillCheckCircleFill className="text-green-500 text-sm"/>
                                                </p>
                                            }
                                        </div>
                                        <div
                                            className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">

                                            <button onClick={() => handleClick(id)}
                                                    className={`w-1/2 flex items-center justify-center gap-x-2 border-r-2 border-[#D81E71] `}>
                                                {removeFav.includes(id) ?
                                                    <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite}
                                                         alt=""/> :
                                                    <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favGradientSvg}
                                                         alt=""/>} Fav
                                            </button>
                                            <button onClick={(e) => goChat(e, myFavorite[index].data, token)}
                                                    className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                                <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                }
                {videoOpenState && <VerificationVideo/>}
            </InfiniteScroll>
        </div>
    );
}

export default FavoriteUsers;