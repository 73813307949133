import React, {useContext, useEffect} from 'react';
import Header from '../../Layouts/header';
import Sidebar from "../../Layouts/Sidebar";
import Footer from "../../Layouts/footer";
import Content from "../../Layouts/Content";
import {AuthContext} from "../../../Context/AuthProvider";
import database, {auth, getTokens,messaging} from "../../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import DeletedAccountAlertModal from "../../Layouts/DeletedAccountAlertModal";
import WelcomePage from "../../Layouts/WelcomePage";
function HomePage() {
    const {loginUser, requestDate, RequestShift4,myFavoriteClone,myFavorite,setMyFavoriteClone,deletedAccountAlertState, genderr,token,setToken,waitAllow, setWaitAllow,setDownMobilMenu,DiscountModalSettings,
        NotificationAlertSettings,
        WebAppModalSettings,
        VerificationVideoModalSettings } = useContext(AuthContext);
    let nowDate = new Date()
    useEffect( () => {
        // window.scrollTo(0, 0);
        setDownMobilMenu(false)
        if (auth.currentUser !== null) {
            setMyFavoriteClone([...myFavorite])
            getTokens().then((payload)=>{
                database.collection("users").doc(auth.currentUser.uid).set({token : payload},{merge : true})
                if (payload === null)
                {
                    setWaitAllow(false)
                }
                else if(payload !== null)
                {
                    setWaitAllow(true)

                }
                })
            if (loginUser?.DiscountModal === undefined)
            {
                database.collection("users").doc(auth.currentUser.uid).set({DiscountModal : {views : 0, viewsDate : serverTimestamp()}} , {merge : true})
            }
            if (loginUser?.NotificationAlertModal=== undefined)
            {
                database.collection("users").doc(auth.currentUser.uid).set({NotificationAlertModal : {views : 0, viewsDate : serverTimestamp()}} , {merge : true})
            }
            if (loginUser?.gender === "female")
            {
                if (loginUser?.VerificationVideoModal === undefined)
                {
                    database.collection("users").doc(auth.currentUser.uid).set({VerificationVideoModal : {views : 0, viewsDate : serverTimestamp()}} , {merge : true})
                }
            }
            if (loginUser?.is_premium === true && requestDate !== null) {
                const diffInMs = nowDate.getTime() - requestDate.getTime();
                const diffInHours = diffInMs / (1000 * 60 * 60);
                if (diffInHours >= 5) {
                    RequestShift4(loginUser.subscribeId)
                } else {
                }
            }
        }
    }, [loginUser?.DiscountModal,loginUser?.NotificationAlertModal,loginUser?.VerificationVideoModal]);

    return (
        <div className="lg:w-full mx-auto lg:h-full xs:w-full xs:h-full md:justify-center " id="header">
            <div>
                <Header/>
            </div>
            {localStorage.getItem("token") === null ?
                <WelcomePage/>
                :
              <>
                  <div className="flex justify-end lg:w-full  mx-auto overflow-hidden my-4 xs:my-0 space-x-1 ">
                      <div className="flex lg:w-1/4 md:hidden xs:hidden ">
                          <Sidebar/>
                      </div>
                      <div className=" flex lg:overflow-hidden lg:w-full md:w-full xs:w-full ">
                          <Content/>
                      </div>
                  </div>
                  <div className="xs:hidden">
                      <Footer/>
                  </div>
                  {deletedAccountAlertState && <DeletedAccountAlertModal/>}
              </>

            }
        </div>
    );
}
export default HomePage;