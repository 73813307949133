import React, {useContext, useEffect, useState} from 'react';
import {BsFillCheckCircleFill, BsPlayCircleFill} from "react-icons/bs"
import {useLocation, useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {HiOutlineDotsVertical} from "react-icons/hi";
import StateContext from "../../Context/StateContext";
import ReportUser from "./ReportUser";
import Block from "./Block";
import database, {auth} from "../../Controller/Firebase/firebase";
import UserPhotos from "./UserPhotos";
import {ChatContext} from "../../Context/ChatContext";
import {AuthContext} from "../../Context/AuthProvider";
import ReportAndBlockCard from "./ReportAndBlockCard";
import VerificationVideoQuery from "./VerificationVideoQuery";
import chatSvg from "../Images/chat.svg"
import favIconWhite from "../Images/favIconWhite.svg"
import favIconGradient from "../Images/favIconGradient.svg"
import VerificationVideo from "./VerificationVideo";
import loadingPhoto from "../Images/loadingPhoto.gif"
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import blueDiamond from "../Images/blueDiamond.png"
import pinkDiamond from "../Images/pinkDiamond.png"

const AnotherProfileContent = () => {
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [openUserPhoto, setOpenUserPhoto] = useState(false);
    const location = useLocation()
    const [reportsAndBlockCard, setReportsAndBlockCard] = useState(false);
    const [report, setReport] = useState(false);
    const [block, setBlock] = useState(false);
    const [premium, setPremium] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [name, setName] = useState([]);
    const [userID, setUserID] = useState([]);
    const [age, setAge] = useState([]);
    const [bio, setBio] = useState([]);
    const [city, setCity] = useState([]);
    const [profileImage, setProfileImage] = useState([]);
    const [selectPhoto, setSelectPhoto] = useState();
    const [verificationVideo, setVerificationVideo] = useState([]);
    const [gender, setGender] = useState([]);
    let navigate = useNavigate();

    const {
        goChat, femaleVerificationVideo, VerifactionVideoToggle,
        videoOpenState
    } = useContext(ChatContext);

    const {loginUser, myFavorite, FavOrNot, showVerificationVideoQuery} = useContext(AuthContext);
    let dataUser = {};
    const [data, setData] = useState();

    function openReportAndBlockCard() {
        setReportsAndBlockCard(!reportsAndBlockCard)
    }

    const handleGoBack = () => {
        navigate(-1);
    };
    // const userPhotos = async () => {
    //     const photosTemp = [];
    //     await database.collection("users")
    //         .doc(dataUser["userID"])
    //         .collection("profileImages")
    //         .where("isReview", "==", true)
    //         .onSnapshot((snapshot) => {
    //             snapshot.forEach((doc) => {
    //                 photosTemp.push(doc.data().url);
    //             });
    //             setPhotos(photosTemp);
    //         });
    // }
    const userPhotos = async (userID) => {
        const photosTemp = [];
        const unsubscribe = database
            .collection("users")
            .doc(userID)
            .collection("profileImages")
            .where("isReview", "==", true)
            .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                    photosTemp.push(doc.data().url);
                });
                setPhotos(photosTemp);
                unsubscribe(); // Fotoğraflar yüklendiğinde snapshot dinleyicisini kaldırıyoruz
            });
    };
    useEffect(() => {
            if (location.state === null)
            {
                let userId = location.pathname.replace(/^\/user\//, '');
                database.collection("users").doc(userId).get().then((res)=>{
                    setPremium(res.data()["is_premium"])
                    setName(res.data()["name"])
                    setUserID(res.data()["userID"])
                    setAge(res.data()["age"])
                    setCity(res.data()["location"])
                    setProfileImage(res.data()["profileImage"])
                    setBio(res.data()["bio"])
                    setVerificationVideo(res.data()["verificationVideo"])
                    setGender(res.data()["gender"])
                    setData(res.data())

                })
                userPhotos(userId)
            }
            else
            {
                if (auth.currentUser === null) {
                    dataUser = (location.state.userData);
                    setPremium(dataUser["is_premium"])
                    setName(dataUser["name"])
                    setUserID(dataUser["userID"])
                    setAge(dataUser["age"])
                    setCity(dataUser["location"])
                    setProfileImage(dataUser["profileImage"])
                    setBio(dataUser["bio"])
                    setVerificationVideo(dataUser["verificationVideo"])
                    setGender(dataUser["gender"])
                } else {

                    dataUser = (location.state.userData);
                    // setPhotos(dataUser["images"])
                    setPremium(dataUser["is_premium"])
                    setName(dataUser["name"])
                    setUserID(dataUser["userID"])
                    setAge(dataUser["age"])
                    setCity(dataUser["location"])
                    setProfileImage(dataUser["profileImage"])
                    setBio(dataUser["bio"])
                    setVerificationVideo(dataUser["verificationVideo"])
                    setGender(dataUser["gender"])


                }
                setData(location.state.userData)
                userPhotos(dataUser["userID"])

            }
        window.scrollTo(0, 0);

    }, [loginUser]);

    function loginToggle() {
        navigate("/login", {replace: true})
    }
    function reportToggle() {
        setReport(!report)
    }
    function blockToggle() {
        setBlock(!block)
    }
    function openPhoto(i) {
        setOpenUserPhoto(!openUserPhoto)
        setSelectPhoto(i)
    }
    return (
        <div className=" w-full xs:mx-auto  xs:w-11/12 xs:h-screen  scroll-auto  flex  flex-col gap-y-5 ">
            {showVerificationVideoQuery && <VerificationVideoQuery/>}
            <div className="flex items-center text-2xl text-black justify-between my-2 lg:hidden  md:hidden ">
                <BiArrowBack onClick={handleGoBack}/>
                <div
                    className=" flex justify-end text-center font-bold text-black hover:text-orange-600  text-end  text-2xl">
                    {auth.currentUser === null
                        ?
                        <button onClick={loginToggle}><HiOutlineDotsVertical/></button>
                        :
                        <button onClick={openReportAndBlockCard}><HiOutlineDotsVertical/></button>
                    }
                </div>
            </div>
            {mediaQuery.matches ?
                <div className="w-full flex flex-col items-center justify-end  gap-y-3 text-[#D81E71]    ">
                    <div className="flex gap-x-2 items-center justify-center ">
                        <img src={profileImage}
                             className="w-[107px] h-[107px] rounded-full border border-black object-cover"
                             alt=""/>
                        <div className=" flex flex-col">
                            <p className="font-bold text-xl flex items-center gap-x-3 text-black">{name} {premium === true &&
                                <img src={gender === "female" ? pinkDiamond : blueDiamond} className="w-6 h-6 "
                                     alt=""/>}</p>
                            <p className="font-bold">Age: <span className="text-black font-semibold">{age}</span></p>
                            <p className="font-bold">Location: <span className="text-black font-semibold">{city.length > 8 ? city.slice(0, 10) + "..." : city}</span></p>
                        </div>
                    </div>
                    {bio ?
                        <div className="Bio flex   gap-x-2 justify-start w-full items-center  overflow-hidden">
                            <p className="font-bold  text-lg  ">Bio </p>
                            <p className="w-full h-auto overflow-hidden break-words text-black font-semibold">{bio}</p>
                        </div>
                        :
                        ""
                    }
                    <div className="flex flex-col w-full rounded-2xl text-white">
                        <div
                            className={` w-full flex items-center  justify-center rounded-t-2xl text-white  bg-[#8C0149D4]   py-2 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none " : "  bg-opacity-80 "}`}>
                            {verificationVideo === "null" || !verificationVideo ? <p>Waiting for verification video</p>
                                : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                     className="flex  w-full justify-center items-center gap-x-2">
                                    <BsPlayCircleFill className="text-lg animate-pulse"/>

                                    Verification
                                    video <BsFillCheckCircleFill className="text-green-500 text-lg"/></p>
                            }
                        </div>
                        <div
                            className={`flex w-full items-center justify-center bg-black rounded-b-2xl py-1 text-lg ${gender === "male" && " rounded-2xl "}`}>
                            <button
                                onClick={(e) => {
                                    auth.currentUser === null ? navigate("/login") : FavOrNot(userID,{data: data , id : userID})
                                }}
                                className={`w-1/2 flex items-center justify-center gap-x-1 border-r-2 border-[#D81E71] `}>
                                {myFavorite.some(r => r.id === userID) ?
                                    <img className="w-7 h-7 xs:w-9 xs:h-9 " src={favIconGradient} alt=""/> :
                                    <img className="w-7 h-7 xs:w-9 xs:h-9 " src={favIconWhite} alt=""/>}Fav
                            </button>

                            <button onClick={(e) => {
                                auth.currentUser === null ? navigate("/login") : goChat(e, location.state.userData, location.state.userData?.token)
                            }}
                                    className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                <img src={chatSvg} className="w-6 h-6 xs:w-8 xs:h-8" alt=""/>Chat
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div className="w-full flex flex-col xs:flex-col gap-y-2 xs:gap-y-3 ">
                    <div className="w-full flex justify-between ">
                        <div
                            className="flex  w-full   items-center justify-start gap-x-3  overflow-hidden">
                            <div className="flex w-auto h-full items-center justify-between ">
                                <img src={profileImage} className="w-40 h-40 object-cover  rounded-full " alt=""/>
                            </div>
                            <div
                                className="flex flex-col text-[#D81E71] xs:w-full  text-lg xs:text-sm w-[70%] gap-y-3 ">
                                <div>

                                    <p className="font-bold text-2xl text-[#D81E71] flex items-center gap-x-3 xs:text-xl">{name}
                                        {premium === true && <img src={gender === "female" ? pinkDiamond : blueDiamond}
                                                                  className="w-6 h-6 drop-shadow-xl" alt=""/>}
                                    </p>
                                    <div className="flex gap-x-2">
                                        <p className="font-bold">Age: </p>
                                        <p className="text-black">{age}</p>
                                    </div>

                                    <div  className="flex gap-x-2">
                                        <p className="font-bold">Location: </p>
                                        <p className="text-black">{city}</p>
                                    </div>
                                    <>
                                        {
                                            bio?.length === 0 ? "" :
                                                <div className="flex  gap-x-2  w-full xs:w-full xs:gap-y-0 overflow-hidden">
                                                    <p className="xs:text-sm font-bold className">Bio:</p>
                                                    <p className="xs:text-sm w-full text-black  h-auto break-words overflow-hidden">
                                                        {bio}
                                                    </p>
                                                </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="w-full xs:w-full h-full flex flex-col  items-center justify-start  ">
                            <div
                                className={`w-full flex justify-end items-end xs:hidden  ${auth.currentUser === null && "hidden"}`}>
                                <HiOutlineDotsVertical onClick={openReportAndBlockCard}
                                                       className={`text-2xl font-bold hover:cursor-pointer `}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-1/3 rounded-2xl text-white ">
                        <div
                            className={` w-full flex items-center text-sm  justify-center rounded-t-2xl text-white  bg-[#8C0149D4]   py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none " : "  bg-opacity-80 "}`}>
                            {verificationVideo === "null" || !verificationVideo ?
                                <p>Waiting for verification video</p>
                                : <button onClick={() => VerifactionVideoToggle(verificationVideo)}
                                          className="flex  w-full justify-center items-center gap-x-2">
                                    <BsPlayCircleFill className="text-lg animate-pulse"/>
                                    Verification
                                    video <BsFillCheckCircleFill className="text-green-500 text-lg"/>
                                </button>
                            }
                        </div>
                        <div
                            className={`flex  w-full items-center justify-center bg-black rounded-b-2xl py-1 text-lg ${gender === "male" && " rounded-2xl "}`}>
                            <button
                                onClick={(e) => {
                                    auth.currentUser === null ? navigate("/login") : FavOrNot(userID,{data: data , id : userID})
                                }}
                                className={`w-1/2 flex items-center justify-center gap-x-1 border-r-2 border-[#D81E71]  `}>
                                {myFavorite.some(r => r.id === userID) ?
                                    <img className="w-7 h-7 xs:w-9 xs:h-9 " src={favIconGradient} alt=""/> :
                                    <img className="w-7 h-7 xs:w-9 xs:h-9 " src={favIconWhite} alt=""/>}Fav
                            </button>
                            <button onClick={(e) => {
                                auth.currentUser === null ? navigate("/login") : goChat(e, location.state.userData, location.state.userData?.token)
                            }}
                                    className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                <img src={chatSvg} className="w-6 h-6 xs:w-8 xs:h-8" alt=""/>Chat
                            </button>
                        </div>
                    </div>
                </div>

            }
            <div className="grid grid-cols-4 xs:grid-cols-2 gap-y-2 gap-x-2  w-full ">
                {photos.map((img, i) => (
                        <LazyLoadImage
                            onClick={() => openPhoto(i)}
                            key={i}
                            src={photos[i]}
                            effect="blur"
                            alt={photos[i]}
                            className=" w-80 h-80 xs:w-full xs:h-48 object-cover border-2  hover:cursor-pointer  border-[#D81E71]  rounded-2xl"
                            placeholderSrc={loadingPhoto}
                        />
                ))}
            </div>
            <StateContext.Provider value={{
                report,
                setReport,
                reportToggle,
                block,
                setBlock,
                blockToggle,
                openPhoto,
                openUserPhoto,
                setOpenUserPhoto,
                photos,
                selectPhoto,
                name,
                userID,
                reportsAndBlockCard,
                openReportAndBlockCard, VerifactionVideoToggle, femaleVerificationVideo
            }}>
                {reportsAndBlockCard && <ReportAndBlockCard/>}
                {openUserPhoto && <UserPhotos/>}
                {report && <ReportUser/>}
                {block && <Block/>}
                {videoOpenState && <VerificationVideo/>}
            </StateContext.Provider>
        </div>
    );
}
export default AnotherProfileContent;