import React, {useEffect,useContext} from 'react';
import Header from "../../Layouts/header";
import Sidebar from "../../Layouts/Sidebar";
import Footer from "../../Layouts/footer";
import AllNewUsers from "../../Layouts/AllNewUsers";
import {AuthContext} from "../../../Context/AuthProvider";

function AllUsers() {
    // const {myFavorite,setMyFavoriteClone} = useContext(AuthContext);
    //
    // useEffect(() => {
    //     setMyFavoriteClone([...myFavorite])
    // }, []);

    return (
        <div className="w-full mx-auto lg:h-full md:justify-center " id="header">
            <Header/>
            <div className="flex justify-end lg:w-full  mx-auto overflow-hidden my-4 space-x-1 xs:space-x-0 ">
                <div className="flex lg:w-1/4 md:hidden xs:hidden ">
                    <Sidebar/>
                </div>
                <div className=" flex lg:overflow-hidden lg:w-full md:w-full xs:w-full ">
                    <AllNewUsers/>
                </div>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default AllUsers;