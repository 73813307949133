import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import database, {auth} from "../../Controller/Firebase/firebase";
import 'firebase/functions';
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {
    BsChatLeftDots,
    BsFillCameraVideoFill,
    BsFillChatDotsFill,
    BsFillCheckCircleFill,
    BsFillPatchCheckFill, BsPlayCircleFill
} from "react-icons/bs";
import {ImFire, ImLocation2} from "react-icons/im";
import moment from "moment";
import VerificationVideo from "./VerificationVideo";
import Login from "./LoginContent";
import StateContext from "../../Context/StateContext";
import {AuthContext} from "../../Context/AuthProvider";
import {ChatContext} from "../../Context/ChatContext";
import VerificationVideoQuery from "./VerificationVideoQuery";
import newUserLogo from "../Images/newUsersLogo.svg";
import premiumIconWhite from "../Images/premiumIconWhite.png";
import locationIconWhite from "../Images/locationIconWhite.svg";
import favIconGradient from "../Images/favIconGradient.svg";
import favIconWhite from "../Images/favIconWhite.svg";
import chatSvg from "../Images/chat.svg";
import pinkDiamond from "../Images/pinkDiamond.png";
import blueDiamond from "../Images/blueDiamond.png";

function FashionCitiesContent() {
    const person = auth.currentUser;
    const location = useLocation()
    let navigate = useNavigate();
    let dataCity = location.state.data
    let city = dataCity["cityId"]
    let temp = []
    const [user, setUser] = useState([]);
    const{goUserProfile,myFavorite,FavOrNot,showVerificationVideoQuery,loginUser,myBlockList,OnlineOrOffline,myFavoriteClone,
        newUsers} = useContext(AuthContext);
    let gender = loginUser?.gender === "female" ? "male" : "female"
    const {goChat,femaleVerificationVideo, setFemaleVerificationVideo,VerifactionVideoToggle,
        videoOpenState , setVideoOpenState} = useContext(ChatContext);
        const showUsers = async () => {
            const q = await query(collection(database, "users"), where("fashionShows", "array-contains-any", [city]));
            onSnapshot(q, async (res) => {
                res.docs.forEach(doc=>{
                    if (doc.data().gender === gender)
                    {
                        temp.push({id : doc.id , data : doc.data()})
                    }
                })
                setUser(temp)
            })
    }


    useEffect(() => {
        showUsers()
    }, []);
    return (
        <div className="w-full space-y-10">
            {showVerificationVideoQuery && <VerificationVideoQuery/>}

            <div className="flex gap-2.5 px-2  h-20 justify-center">
                <div
                    className=" w-64 h-full  relative justify-center items-center bg-opacity-25 rounded-xl overflow-hidden hover:opacity-60 duration-700 hover:cursor-context-menu ">
                    <img
                        src={dataCity["image"]}
                        className="absolute w-full h-full object-cover  duration-1000 " alt=""/>
                    <p className="absolute bg-[#8C0149] opacity-80 text-white w-full bottom-0 text-center font-bold text-lg ">{dataCity["name"]} Fashion Show</p>
                    {/*<h1 className=" absolute h-7 w-full text-md  font-bold text-gray-300 lg:my-6 xs:my-7 md:my-6 bg-[#8C0149] opacity-80  text-center">*/}
                    {/*    <p>{dataCity["name"]} Fashion Show</p>*/}
                    {/*</h1>*/}
                </div>
            </div>
            {user.length === 0 && <div className="w-full text-center text-red-600 font-bold text-lg animate-pulse">! Sorry, No Users Found for Selected Fashion Show !</div>}
            <div
                className={`grid lg:grid-cols-4 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5   `}
            >
                {user.map(({
                                    id,
                                    viewCount,
                                    isSeen,
                                    viewTime,
                                    favTrue,
                                    data: {
                                        name,
                                        age,
                                        location,
                                        profileImage,
                                        userID,
                                        gender,
                                        verificationVideo,
                                        lastSeen,is_premium,token
                                    }
                                }, index) => (
                    <div className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                         key={index}
                    >                        <img onClick={()=> goUserProfile(user[index].data,loginUser?.name,loginUser?.profileImage)} src={profileImage} className=" absolute w-full h-full border-2 border-[#D81E71] object-cover rounded-2xl" alt=""/>
                        {newUsers.find(el => el.id === userID) &&
                            <div className="absolute  w-full top-3 rotate-12">
                                <div className="flex w-full items-center justify-start ">
                                    <img src={newUserLogo}
                                         className="w-14 h-14 " alt=""/>
                                </div>
                            </div>
                        }
                        <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                            <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(user[index].data, loginUser?.name, loginUser?.profileImage)}>
                                <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                    {20 > OnlineOrOffline(lastSeen) ?
                                        <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                        <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                    {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                    {is_premium === true &&
                                        <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                </div>
                                {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                    <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                    <p className={`w-full `}>
                                        {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                    </p>
                                </div>}
                            </div>
                            <div
                                className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : " bg-[#D81E71] bg-opacity-80 "}`}>
                                {verificationVideo === "null" || !verificationVideo ?
                                    <p className="text-sm">Waiting for verification video</p>
                                    : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                         className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                        <BsPlayCircleFill className="text-lg animate-pulse"/>

                                        Verification
                                        Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                }
                            </div>
                            <div
                                className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">
                                <button onClick={() => FavOrNot(id,user[index])}
                                        className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                    {myFavoriteClone.find(r => r.id === id) ?
                                        <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                        <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                </button>

                                <button onClick={(e) => goChat(e, user[index].data, token)}
                                        className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                    <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {videoOpenState && <VerificationVideo/>}

        </div>
    );
}

export default FashionCitiesContent;