import React, {createContext, useEffect, useCallback, useState, useContext} from 'react';
import database, {auth, messaging, remoteConfig} from "../Controller/Firebase/firebase";
import {getFunctions, httpsCallable} from "firebase/functions";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {fetchAndActivate, getValue} from "firebase/remote-config";
import axios from "axios";
import {serverTimestamp, Timestamp} from "firebase/firestore";


export const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [planId, setPlanId] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [amount, setAmount] = useState(null);
    const [trick, setTrick] = useState(false);
    let navigate = useNavigate();
        let now = moment()
        const [user, setUser] = useState(null);
    const [termsOfService, setTermsOfService] = useState(false);
    const [deletedModalOpen, setDeletedModalOpen] = useState(false);
    const [deletedAccountAlertState, setDeletedAccountAlertState] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [DiscountModalSettings, setDiscountModalSettings] = useState({});
    const [searchResultUser, setSearchResultUser] = useState([]);
    const [NotificationAlertSettings, setNotificationAlertSettings] = useState({});
    const [VerificationVideoModalSettings, setVerificationVideoModalSettings] = useState({});
    const [WebAppModalSettings, setWebAppModalSettings] = useState([]);
    const [staySafe, setStaySafe] = useState(false);
    const [successfullTravel, setSuccessfullTravel] = useState(false);
    const [prevLocationPath, setPrevLocationPath] = useState(null);
    const [discountModalViewsLimit, setDiscountModalViewsLimit] = useState(null);
    const [NotificationAlertViewsLimit, setNotificationAlertViewsLimit] = useState(null);
    const [VerificationVideoAlertViewsLimit, setVerificationVideoAlertViewsLimit] = useState(null);
    const [travelUsers, setTravelUsers] = useState([]);
        const [myFavorite, setMyFavorite] = useState([]);
        const [myBlockList, setMyBlockList] = useState([])
        const [requestDate, setRequestDate] = useState(null);
        const [blobVideo, setBlobVideo] = useState(null);
        const [signUpVerificationVideoSaved, setSignUpVerificationVideoSaved] = useState(false);
        const [monthly_premium_fee, setMonthly_premium_fee] = useState(null);
        const [three_monthly_premium_fee, setThree_monthly_premium_fee] = useState(null);
        const [six_monthly_premium_fee, setSix_monthly_premium_fee] = useState(null);
        const [yearly_premium_fee, setYearly_premium_fee] = useState(null);
        const [travel_fee, setTravel_fee] = useState(null);
        const [state, setState] = useState(false);
        const [allUser, setAllUser] = useState([]);
        const [notificationAlertState, setNotificationAlertState] = useState(false);
        const [lastVisible2, setLastVisible2] = useState("");
        const mediaQuery = window.matchMedia('(max-width: 897px)')
        const [waitAllow, setWaitAllow] = useState(null);
        const [openMakeWebAppModal, setOpenMakeWebAppModal] = useState(false);
        const [openBrowserSettingForNotification, setOpenBrowserSettingForNotification] = useState(false);
        const [lastVisibleListenFav, setLastVisibleListenFav] = useState("");
        const [lastVisible3, setLastVisible3] = useState("");
        const [lastVisible, setLastVisible] = useState("");
        let [totalLength, setTotalLength] = useState(null);
        const [signUpVerificationVideoUrl, setSignUpVerificationVideoUrl] = useState(null);
    const [brandOrShow, setBrandOrShow] = useState(false);
    const [openRejectVideoModal, setOpenRejectVideoModal] = useState(false);
    let [totalLengthListenFav, setTotalLengthListenFav] = useState(null);
        const [hasMore, setHasMore] = useState(true);
        const [newUsersHasMore, setNewUsersHasMore] = useState(true);
        const [travelUsersHasMore, setTravelUsersHasMore] = useState(true);
        const [listenFavHasMore, setListenFavHasMore] = useState(true);
        const [newUsers, setNewUsers] = useState([]);
        const [loading, setLoading] = useState(false);
        const [verificationVideoProfileViewerPremission, setVerificationVideoProfileViewerPremission] = useState(false);
        const [verificationVideoChatPremission, setVerificationVideoChatPremission] = useState(false);
        let [showVerificationVideoQuery, setShowVerificationVideoQuery] = useState(false);
        const [downMobilMenu, setDownMobilMenu] = useState(false);
        const [discountPremium, setDiscountPremium] = useState([]);
        const [showDiscountPremiumModal, setShowDiscountPremiumModal] = useState(false);
        const [videoWaitingForApproval, setVideoWaitingForApproval] = useState(true);
        const [token, setToken] = useState(null);
    const [myFavoriteClone, setMyFavoriteClone] = useState([]);
    const [settingsModal, setSettingsModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [successfullPremium, setSuccessfullPremium] = useState(false);
    const [cancelPremium, setCancelPremium] = useState(false);
    const [monthlyPlanId, setMonthlyPlanId] = useState(null);
    const [threeMonthlyPlanId, setThreeMonthlyPlanId] = useState(null);
    const [sixMonthlyPlanId, setSixMonthlyPlanId] = useState(null);
    const [dscnt_50_MonthlyPlanId, setDscnt_50_MonthlyPlanId] = useState(null);
    const [dscnt_50_ThreeMonthlyPlanId, setDscnt_50_ThreeMonthlyPlanId] = useState(null);
    const [dscnt_50_SixMonthlyPlanId, setDscnt_50_SixMonthlyPlanId] = useState(null);
    const functions = getFunctions();
        let temp2 = []

        async function RemoteConfigSettings() {
            remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 dakika test için
            fetchAndActivate(remoteConfig)
                .then(() => {
                    const val2 = getValue(remoteConfig, "verificationVideo_chat_premission")
                    const proileViewerPremission = getValue(remoteConfig, "verificationVideo_profileViewer_premission")
                    setVerificationVideoProfileViewerPremission(proileViewerPremission["_value"])
                    const chatPremission = getValue(remoteConfig, "verificationVideo_chat_premission")
                    setVerificationVideoChatPremission(chatPremission["_value"])
                    const dscViewsLimit = getValue(remoteConfig, "discountModalViewsLimit")
                    setDiscountModalViewsLimit(dscViewsLimit["_value"])
                    const notificationLimit = getValue(remoteConfig, "NotificationAlertViewsLimit")
                    setNotificationAlertViewsLimit(notificationLimit["_value"])
                    const vrfctnVideoLimit = getValue(remoteConfig, "VerificationVideoAlertViewsLimit")
                    setDiscountModalViewsLimit(vrfctnVideoLimit["_value"])
                })
                .catch((err) => {
                });
        }
        async function DiscountFunction(createdAt) {
            let diffInDays;
            let end = moment(createdAt?.seconds * 1000)
            diffInDays = now.diff(end, 'days');
            if (diffInDays >= 1 && diffInDays < 2) {
                await database.collection("productPrices").doc("discount50").get().then(r => {
                    setDiscountPremium({discount: "50", data: r.data()})
                    setShowDiscountPremiumModal(!showDiscountPremiumModal)
                })
            }
                // if (diffInDays > 3 && diffInDays <= 5) {
            //     await database.collection("productPrices").doc("discount10").get().then(r => {
            //         setDiscountPremium({discount: "10", data: r.data()})
            //         setShowDiscountPremiumModal(!showDiscountPremiumModal)
            //     })
            // }
            // else if (diffInDays > 5 && diffInDays <= 7) {
            //     await database.collection("productPrices").doc("discount30").get().then(r => {
            //         setDiscountPremium({discount: "30", data: r.data()})
            //         setShowDiscountPremiumModal(!showDiscountPremiumModal)
            //
            //     })
            // }
            // else if (diffInDays > 7 && diffInDays <= 10) {
            //     await database.collection("productPrices").doc("discount50").get().then(r => {
            //         setDiscountPremium({discount: "50", data: r.data()})
            //         setShowDiscountPremiumModal(!showDiscountPremiumModal)
            //     })
            // }
            else {
                setShowDiscountPremiumModal(null)
            }
        }

        async function FavOrNot(id,data) {
            if (auth.currentUser === null) {
                navigate("/login");
            } else {
                if (myFavorite.some((r) => r.id === id)) {
                    let updatedTemp = myFavoriteClone.filter((item) => item.id !== id)
                    setMyFavoriteClone(updatedTemp)
                    await database
                        .collection("users")
                        .doc(auth.currentUser.uid)
                        .collection("favorites")
                        .doc(id)
                        .delete()
                        .then((r) => {
                        })
                        .catch((error) => {

                        });
                } else {
                    setMyFavoriteClone([...myFavoriteClone, data]);
                    await database
                        .collection("users")
                        .doc(auth.currentUser.uid)
                        .collection("favorites")
                        .doc(id)
                        .set({favDate: new Date()})
                        .catch((error) => {

                        });
                }
            }
        }

        async function goUserProfile(userData, loginUserName, loginUserProfileImage) {
            if (userData.userID ==="1stHA3nyeJNm3zA9xA2qla50Np23")
            {
                return
            }
            navigate(`/user/${userData["userID"]}`, {state: {userData}})
            if (auth.currentUser !== null) {
                database.collection("users").doc(userData["userID"]).collection("profileViewers").doc(auth.currentUser.uid).get().then(async (res) => {
                    if (res.data() === undefined) {
                        await database.collection("users").doc(userData["userID"]).collection("profileViewers").doc(auth.currentUser.uid).set({
                            "viewTime": new Date(),
                            "isSeen": true,
                            viewCount: 1,
                            "viewerId": auth.currentUser.uid
                        })
                    } else {
                        const myViewerViewCount = res.data()["viewCount"]
                        await database.collection("users").doc(userData["userID"]).collection("profileViewers").doc(auth.currentUser.uid).update({
                            "viewTime": new Date(),
                            viewCount: myViewerViewCount + 1,
                            "isSeen": true
                        })
                    }
                })
                let notificationData = {
                    title: "Model Club",
                    body: `${loginUserName} viewed your profile!`,
                    token: userData["token"],
                    image: loginUserProfileImage,
                    click_action: `https://staging.modelclub.com`
                }
                const requestBackEnd = await httpsCallable(functions, 'sendNotification')(notificationData)
                const result = requestBackEnd.data["isCompleted"]
            }
        }
        const ListenToFav = async () => {
            const snapshot = await database.collection('users').doc(auth.currentUser.uid).collection("favorites").get()
            setTotalLengthListenFav(snapshot.size);
            await database
                .collection("users")
                .doc(auth.currentUser.uid)
                .collection("favorites")
                .orderBy("favDate", "desc")
                .startAfter(lastVisibleListenFav)
                .limit(200)
                .onSnapshot(async (querySnapshot) => {
                    const temp = [];
                    querySnapshot.forEach(async (doc) => {
                        const userDoc = await database.collection("users").doc(doc.id).get();
                        const userData = userDoc.data();
                        temp.push({id: userDoc.id, data: userData});
                        // Set lastVisible to the last document in the current query
                        setLastVisibleListenFav(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    })
                    ;
                    myFavorite.forEach((favorite) => {
                        temp.push(favorite);
                    });
                    setMyFavorite(temp);
                    if (myFavorite.length === totalLengthListenFav) {
                        setListenFavHasMore(false);
                    }
                });
        };
        const ListenToBlock = () => {
            database.collection("users").doc(auth.currentUser.uid).collection("blockList").onSnapshot(res => {
                temp2 = []
                res.forEach(el => {
                    temp2.push(el.id)
                })
                setMyBlockList(temp2)
            })
        }

        async function RequestShift4(subId, premiumUntil,userID) {

            if (premiumUntil !== undefined)
            {
                let diffInDays;
                let premiumEndDate = moment(premiumUntil?.seconds * 1000)
                diffInDays = premiumEndDate.diff(now, 'days');
                if (diffInDays < 0)
                {
                    await database.collection("users").doc(userID).update({is_premium : false})
                }
                return
            }
            let data = {subscribeId: subId}
            const requestBackEnd = await httpsCallable(functions, 'Shift4Query')(data)
            setRequestDate(new Date())
        }

    const [whyRejectVideo, setWhyRejectVideo] = useState(null);
    const VerificationVideoState = async () => {
            const getDoc = await database.collection("VerificationVideo").doc(auth.currentUser.uid).get()

            if (!getDoc.exists) {
                setVideoWaitingForApproval(false)
            }
            else {
               if(getDoc.data().isReview === false)
               {
                   setVideoWaitingForApproval(true)
               }
               else{
                   if(getDoc.data().deleted === true)
                   {

                       setVideoWaitingForApproval(false)
                        setWhyRejectVideo(getDoc.data().why)
                       setOpenRejectVideoModal(true)
                   }
                   else {
                       setVideoWaitingForApproval(true)
                       setWhyRejectVideo(null)
                   }
               }
            }
        }

        function OnlineOrOffline(lastSeen) {
            let diffInMinutes;
            let end = moment(lastSeen.seconds * 1000)
            diffInMinutes = now.diff(end, 'minutes');
            return diffInMinutes
        }

        const LastSeenUsers = async (gender) => {
            const userGender = gender === "female" ? "male" : "female";
            // const snapshot = await database.collection('users')
            //     .orderBy('lastSeen', 'desc')
            //     .where('gender', '==', userGender)
            //     .get();
            // setTotalLength(snapshot.size);
            // setTotalLength(snapshot.size);
            await database.collection('users')
                .orderBy('lastSeen', 'desc')
                .where('gender', '==', userGender)
                .where("accountStatus", "==", 1)
                .startAfter(lastVisible)
                .limit(12)
                .get()
                .then((res) => {
                    const temp = [...allUser];
                    setLastVisible(res.docs[res.docs.length - 1]);
                    res.docs.forEach((element) => {
                            temp.push({data: element.data(), id: element.id});
                    });
                    setAllUser(temp);
                    // if (allUser.length === totalLength) {
                    //     setHasMore(false);
                    // }
                });
        };
        const NewUsers = async (gender) => {
            const userGender = gender === "female" ? "male" : "female";
            await database.collection('users')
                .orderBy('createdAt', 'desc')
                .where('gender', '==', userGender)
                .where("accountStatus", "==", 1)

                .startAfter(lastVisible2)
                .limit(12)
                .get()
                .then((res) => {
                    const temp = [...newUsers];
                    setLastVisible2(res.docs[res.docs.length - 1]);
                    res.docs.forEach((element) => {
                        let diffInMinutes;
                        let end = moment(element.data().createdAt.seconds * 1000)
                        let now = moment()
                        diffInMinutes = now.diff(end, 'day');
                        if (diffInMinutes <= 3) {
                            temp.push({data: element.data(), id: element.id})
                        }
                    });
                    setNewUsers(temp);
                    if (newUsers.length) {
                        setNewUsersHasMore(false);
                    }
                });
        }
    const todayServer = Timestamp.fromMillis(Date.now())
        const Travels = async (gender) => {
            const userGender = gender === "female" ? "male" : "female";
            const travelDocs = await database.collection("travels").where("returnDate" , ">=" , todayServer)
                .orderBy("returnDate" , "desc")
                .startAfter(lastVisible3)
                .limit(3)
                .get();
            const temp2 = travelUsers.slice(); // create a copy of user array
            setLastVisible3(travelDocs.docs[travelDocs.docs.length - 1]);



            for (const travelDoc of travelDocs.docs) {
                const userDoc = await database.collection("users")
                    .doc(`${travelDoc.data().userID}`)
                    .get();

                if (userDoc.data()?.gender === userGender) {
                    temp2.push({data: userDoc.data(), travelInfo: travelDoc.data()});
                }
            }
            setTravelUsers(temp2);
            if (travelUsers.length) {
                setTravelUsersHasMore(false);
            }
        };
        async function PremiumPrices() {
            await database.collection("productPrices").doc("prices").get().then((doc) => {
                setMonthly_premium_fee(doc.data()["monthly_premium_fee"])
                setThree_monthly_premium_fee(doc.data()["three_monthly_premium_fee"])
                setSix_monthly_premium_fee(doc.data()["six_monthly_premium_fee"])
                setYearly_premium_fee(doc.data()["yearly_premium_fee"])
                setTravel_fee(doc.data()["travel_fee"])
                setMonthlyPlanId(doc.data()["planIdMonthly"])
                setThreeMonthlyPlanId(doc.data()["planIdThreeMonthly"])
                setSixMonthlyPlanId(doc.data()["planIdSixMonthly"])
                setDscnt_50_MonthlyPlanId(doc.data()["dscnt_50_MonthlyPlanId"])
                setDscnt_50_ThreeMonthlyPlanId(doc.data()["dscnt_50_ThreeMonthlyPlanId"])
                setDscnt_50_SixMonthlyPlanId(doc.data()["dscnt_50_SixMonthlyPlanId"])
            })
        }

        async function PaymentShift4(data) {
            await httpsCallable(functions, 'payment')(data)
            // const requestBackEnd = await httpsCallable(functions, 'payment')(data)
            // const result = requestBackEnd.data["isPremiumCompleted"]
        }

        const sendNotification = async (title, body) => {
            try {
                const payload = {
                    notification: {
                        title,
                        body,
                    },
                    token,
                };
                await axios.post('https://fcm.googleapis.com/fcm/send', JSON.stringify(payload), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'key=AAAARzBi938:APA91bGEU7db1SnCtzcvKaHy6zmdyF20a1vMeuOpPaah5itGKzsSBgp4pghWlW2fV8WTN6vomVlQxYQ9dTMsNblxiRIJSvvYf_On78OCXCSWTF4KS1tnPpC8ZXbG2u-RFXtM67-be_IO'
                    }
                }).then(response => {

                }).catch(error => {
                });
            } catch (error) {
            }
        };
    function isMobileAndroid() {
        const userAgent = navigator.userAgent.toLowerCase();
        return /android/.test(userAgent) && /mobile/.test(userAgent);
    }
        function openWebAppModalFunction() {
                setDownMobilMenu(!downMobilMenu)
                setOpenMakeWebAppModal(!openMakeWebAppModal)
         }
        useEffect(() => {
            const unsubscribe = auth.onAuthStateChanged((authUser) => {
                if (authUser) {
                    database.collection("users").doc(authUser.uid).onSnapshot(doc => {
                        setUser(doc.data())
                        if (doc.data().accountStatus === 99)
                        {
                            setDeletedAccountAlertState(!deletedAccountAlertState)
                            setDownMobilMenu(true)
                        }
                        const gender = doc.data()?.gender || "male";
                        LastSeenUsers(gender);
                        NewUsers(gender)
                        Travels(gender)
                        if (doc.data().gender === "female") {
                            VerificationVideoState()

                        }
                        if (doc.data()["is_premium"] === true) {
                            RequestShift4(doc.data()["subscribeId"],doc.data()["premiumUntil"],doc.data()["userID"])
                        } else {
                            DiscountFunction(doc.data()["createdAt"])
                        }
                    })
                    PremiumPrices()
                    ListenToFav()
                    ListenToBlock()
                    RemoteConfigSettings()

                } else {
                    setUser(null);
                    LastSeenUsers("male")
                    NewUsers("male")
                    Travels("male")
                }
            });
            return () => {
                unsubscribe();
            };
        }, []);
        return (
            <AuthContext.Provider value={{
                loginUser: user,
                OnlineOrOffline,
                Travels,
                successfullTravel, setSuccessfullTravel,
                setLoading,
                loading,
                travelUsers,
                newUsersHasMore,
                newUsers,
                NewUsers,
                state,
                myFavorite,
                allUser,
                deletedModalOpen, setDeletedModalOpen,
                hasMore,
                ListenToFav,
                myBlockList,
                requestDate,
                setRequestDate,
                RequestShift4,
                monthly_premium_fee,
                LastSeenUsers,
                goUserProfile,
                FavOrNot,
                setDownMobilMenu,
                discountPremium,
                downMobilMenu,
                travelUsersHasMore,
                showDiscountPremiumModal,
                setShowDiscountPremiumModal,
                listenFavHasMore,
                three_monthly_premium_fee,
                six_monthly_premium_fee,
                showVerificationVideoQuery,
                setShowVerificationVideoQuery,
                verificationVideoProfileViewerPremission,
                verificationVideoChatPremission,
                videoWaitingForApproval,
                setVideoWaitingForApproval,
                signUpVerificationVideoUrl,
                setSignUpVerificationVideoUrl,
                yearly_premium_fee,
                blobVideo,
                privacyPolicy, setPrivacyPolicy,
                staySafe, setStaySafe,
                setBlobVideo,
                totalLength,
                signUpVerificationVideoSaved,
                setSignUpVerificationVideoSaved,
                openMakeWebAppModal,
                setOpenMakeWebAppModal,
                travel_fee,
                token,
                setToken,
                sendNotification,
                openWebAppModalFunction,
                notificationAlertState,
                setNotificationAlertState
                ,
                openBrowserSettingForNotification,
                setOpenBrowserSettingForNotification,
                termsOfService, setTermsOfService,
                waitAllow,
                setWaitAllow,logoutModal, setLogoutModal,settingsModal, setSettingsModal,
                successfullPremium, setSuccessfullPremium,cancelPremium, setCancelPremium,myFavoriteClone, setMyFavoriteClone,
                monthlyPlanId,
                threeMonthlyPlanId,
                sixMonthlyPlanId,
                dscnt_50_MonthlyPlanId,
                dscnt_50_ThreeMonthlyPlanId,
                dscnt_50_SixMonthlyPlanId,
                brandOrShow, setBrandOrShow,
                planId, setPlanId,
                openPaymentModal, setOpenPaymentModal,
                amount, setAmount,openRejectVideoModal, setOpenRejectVideoModal,
                trick, setTrick,
               prevLocationPath, setPrevLocationPath,discountModalViewsLimit, setDiscountModalViewsLimit,
                NotificationAlertViewsLimit, setNotificationAlertViewsLimit,deletedAccountAlertState, setDeletedAccountAlertState,
                VerificationVideoAlertViewsLimit, setVerificationVideoAlertViewsLimit,searchResultUser, setSearchResultUser,PaymentShift4,whyRejectVideo, setWhyRejectVideo
            }}>
                {children}
            </AuthContext.Provider>
        );
    }
;
export default AuthProvider;