import React, {useContext, useReducer, useState} from 'react';
import Footer from "../../Layouts/footer";
import {IoLocationOutline} from 'react-icons/io5';
import Header from "../../Layouts/header";
import FashionShows from "../../Layouts/FashionShows";
import FashionBrands from "../../Layouts/FashionBrands";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../Context/AuthProvider";
function Shows_Brands() {

    let navigate = useNavigate();
    const {brandOrShow, setBrandOrShow} = useContext(AuthContext);

    function handleGoBack() {
        navigate(-1)
    }
    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center bg-white space-y-5">
            <div className="xs:hidden">
                <Header/>
            </div>
                <div className=" w-full h-auto">
                    <div className="flex items-center text-2xl text-black justify-between my-4 px-4  lg:hidden  md:hidden  ">
                        <BiArrowBack  onClick={handleGoBack} />
                    </div>
                    <div className="border w-full h-14 flex justify-around text-xl bg-white text-black font-bold">
                        <button onClick={()=> setBrandOrShow(true)} className={`border w-full ${brandOrShow ? "bg-[#D81A71] text-white font-bold" : "bg-black text-white font-bold"}`}>
                            Fashion Shows
                        </button>
                        <button onClick={()=> setBrandOrShow(false)} className={`border w-full ${!brandOrShow ? "bg-[#D81A71] text-white font-bold" :" bg-black text-white font-bold" }`}>
                            Fashion Brands
                        </button>
                    </div>
                    <div className="w-full h-5/6 overflow-y-auto" >
                        {brandOrShow ? <FashionShows/>
                            :

                            <FashionBrands/>

                        }
                    </div>
                </div>
            <div className="xs:hidden">
                <Footer/>
            </div>
        </div>
    );
}
export default Shows_Brands;
