import React, {useContext, useState} from 'react';
import Footer from "../../Layouts/footer";
import Header from "../../Layouts/header";
import {AuthContext} from "../../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import PaymentForm from "../../Layouts/PaymentForm";
import premiumIconSvg from "../../Images/premiumIconSvg.svg"
import premiumCard1 from "../../Images/premiumCard1.png"

 function PremiumPage() {
    const {loginUser,monthly_premium_fee,
        three_monthly_premium_fee,
        six_monthly_premium_fee,
        monthlyPlanId,
        threeMonthlyPlanId,
        sixMonthlyPlanId,
       setPlanId,
        openPaymentModal, setOpenPaymentModal,
       setAmount} = useContext(AuthContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    let navigate = useNavigate();


     const [buttonClick, setButtonClick] = useState(false);
    const [plan, setPlan] = useState(null);
    const planId2 = threeMonthlyPlanId
    const planId1 = monthlyPlanId
    const planId3 = sixMonthlyPlanId

    function handleGoBack() {
        navigate(-1)
    }
    function chousePlan(yourPlan, planId, amount) {
        setButtonClick(true)
        const plans = ["MonthPlan1", "MonthPlan3", "MonthPlan6"];
        setAmount(amount)
        setPlan(planId)
        setPlanId(planId)
        if (mediaQuery.matches) {
            document.getElementById("continueButton").style.backgroundColor = "#D81E71"
            plans.forEach((plan) => {
                const el = document.getElementById(plan);
                if (plan === yourPlan) {
                    el.style.transform = "scale(1.1)";
                } else {
                    el.style.transform = "scale(0.9)"
                }
            });
        } else {
            document.getElementById("continueButton").style.backgroundColor = "#D81E71"
            plans.forEach((plan) => {
                const el = document.getElementById(plan);
                if (plan === yourPlan) {
                    el.style.transform = "scale(1.1)";
                } else {
                    el.style.transform = "scale(0.7)"
                    el.style.borderWidth = "0px"; // burada border kalınlığı ayarlanır
                    el.style.boxShadow = "none";
                }
            });
        }
    }



    return (
        <div className="lg:w-full mx-auto  md:justify-center  xs:w-full xs:h-screen">
            <div className="xs:hidden">
                <Header/>
            </div>
            <>
                {mediaQuery.matches ?
                    <div className="flex flex-col items-center w-full">

                        <div className="w-full  h-10  flex items-center justify-start text-black text-xl ">
                            <BiArrowBack className=" mx-5 text-2xl" onClick={handleGoBack}/>
                        </div>
                        {loginUser === null ? ""
                            :
                            <div className="w-full h-full flex flex-col justify-center items-center space-y-4">
                                <div className="flex  flex-col gap-y-2 items-center w-full justify-center items-center  text-[#D81E71] ">
                                    <div className="overflow-hidden flex justify-center w-1/3  h-full items-center">
                                        <img src={loginUser.profileImage} alt=""
                                             className="w-20 h-20 object-cover rounded-full border-2 border-black"/>
                                    </div>
                                    <div className="flex text-sm items-center w-2/3  h-full text-ellipsis">
                                        {loginUser.is_premium === true ?
                                            <div className="flex flex-col items-center gap-y-3 justify-center font-bold">
                                                <span className="text-xl">Dear {loginUser.name}</span>
                                                <p className="text-center">You have premium features. You can switch to another premium option if you wish.</p>

                                            </div>
                                            :
                                            <div className="flex flex-col items-center gap-y-3 justify-center font-bold ">
                                                <span className="text-xl">Dear <span className="font-extrabold">{loginUser.name}</span></span>
                                                <p className="text-center ">You
                                                    are using Model Club with our Free Plan and missing many oppurtunities.</p>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div
                                    className=" h-auto p-2 w-3/4 bg-gradient-to-r from-[#FF0084]  to-fuchsia-950 rounded-xl flex flex-col justify-center items-center text-white text-sm  ">
                                    <p className="text-lg font-bold ">Be Premium</p>
                                    <span className="border-b border-white w-2/3"/>
                                    <div className="w-full flex flex-col items-center justify-center">
                                        <p className="flex items-center "> Higher Listing</p>
                                        <p className="flex items-center "> Unlimited Chat</p>
                                        <p className="flex items-center "> Unlimited Verification Video</p>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col items-center justify-center gap-y-1">
                                    <p className="font-bold text-xl text-[#D81E71]">Choose Your Plan</p>
                                    <div id="MonthPlan1" className="flex flex-col items-center justify-center w-5/6  gap-y-1"  onClick={() => chousePlan("MonthPlan1", planId1, monthly_premium_fee)}>
                                        <div className=" w-full flex flex-col items-center justify-center bg-gradient-to-r from-[#FF0084]  to-fuchsia-950 text-white rounded-xl py-1">
                                            <p className="font-light text-lg">1 Monthly</p>
                                            <p className="text-2xl font-bold">€ {monthly_premium_fee}</p>
                                        </div>
                                    </div>
                                    <div id="MonthPlan3" className="flex flex-col items-center justify-center w-5/6  gap-y-1"   onClick={() => chousePlan("MonthPlan3", planId2, three_monthly_premium_fee)}>
                                        <div className=" w-full flex flex-col items-center justify-center bg-gradient-to-r from-[#FF0084]  to-fuchsia-950 text-white rounded-xl py-1">
                                            <p className="font-light text-lg">3 Monthly</p>
                                            <p className="text-2xl font-bold">€ {three_monthly_premium_fee}</p>
                                        </div>
                                    </div>
                                    <div  id="MonthPlan6" className="flex flex-col items-center justify-center w-5/6  gap-y-1" onClick={() => chousePlan("MonthPlan6", planId3, six_monthly_premium_fee)}>

                                        <div className=" w-full flex flex-col items-center justify-center bg-gradient-to-r from-[#FF0084]  to-fuchsia-950 text-white rounded-xl py-1">
                                            <p className="font-light text-lg">6 Monthly</p>
                                            <p className="text-2xl font-bold">€ {six_monthly_premium_fee}</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={` w-full justify-center items-center  flex ${!buttonClick && "hidden"}`}>
                                    <button id="continueButton" onClick={()=>setOpenPaymentModal(!openPaymentModal)}
                                            className="px-10 py-2 rounded-2xl bg-gray-700 text-white font-bold">Continue
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className="flex justify-end lg:w-full  mx-auto overflow-hidden my-4 xs:space-x-0 ">

                        <div className="  lg:overflow-hidden lg:w-full md:w-full xs:w-full ">
                            {loginUser === null ? " " :
                                <div className="flex w-full flex justify-center ">
                                    <img src={loginUser.profileImage} alt=""
                                         className="w-40 h-40 object-cover rounded-full border-2 border-black "/>
                                    <div className="mx-10 text-xl font-bold my-10 text-[#D81E71]">
                                        <p className="">Dear {loginUser.name}</p>
                                       <div className="text-lg">
                                           {loginUser.is_premium === true ? <p>You have premium features. You can switch to another premium option if you wish.</p>
                                               :
                                               <>
                                                   <p>You are using Model Club with our Free Plan and missing many
                                                       oppurtunities...</p>
                                                   <p>Premium Plans are requiring.</p>
                                               </>
                                           }
                                       </div>

                                    </div>
                                </div>
                            }
                            <div
                                className=" h-auto h-80 flex my-5  items-center justify-center  gap-x-10 ">
                                <div id="MonthPlan1"
                                     onClick={() => chousePlan("MonthPlan1",planId1, monthly_premium_fee)}
                                     className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                    <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                    <div className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                        <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                        <p className="text-xl">Monthly</p>
                                        <p className="text-3xl">€ {monthly_premium_fee}</p>
                                        <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                            <p>Higher Listing</p>
                                            <p>Unlimited Chat</p>
                                            <p>Unlimited Verification Video</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="MonthPlan3"
                                     onClick={() => chousePlan("MonthPlan3", planId2, three_monthly_premium_fee)}
                                     className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                    <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                    <div className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                        <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                        <p className="text-xl">3 Monthly</p>
                                        <p className="text-3xl">€ {three_monthly_premium_fee}</p>
                                        <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                            <p>Higher Listing</p>
                                            <p>Unlimited Chat</p>
                                            <p>Unlimited Verification Video</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="MonthPlan6"
                                     onClick={() => chousePlan("MonthPlan6", planId3, six_monthly_premium_fee)}
                                     className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                    <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                    <div className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                        <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                        <p className="text-xl"> 6 Monthly</p>
                                        <p className="text-3xl">€ {six_monthly_premium_fee}</p>
                                        <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                            <p>Higher Listing</p>
                                            <p>Unlimited Chat</p>
                                            <p>Unlimited Verification Video</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className={` w-full justify-center items-center  py-10 flex ${!buttonClick && "hidden"}`}>
                                <button id="continueButton" onClick={()=>setOpenPaymentModal(!openPaymentModal)}
                                        className="px-10 py-2 rounded-2xl  border-2  text-white font-bold  ">Continue
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </>
            <div className="xs:hidden">
                <Footer/>
            </div>
            {openPaymentModal === true && <PaymentForm/>}
        </div>
    );
}

export default PremiumPage;