import React, {useContext, useEffect, useState} from 'react';
import database, {auth, remoteConfig} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {getValue} from "firebase/remote-config";
import {fetchAndActivate} from "firebase/remote-config";
import {getFunctions, httpsCallable} from "firebase/functions";
import {BiArrowBack} from "react-icons/bi";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "../../Context/AuthProvider";

function FashionBrands() {
    const {prevLocationPath, setPrevLocationPath} = useContext(AuthContext)
    const [fashionBrands, setFashionBrands] = useState([]);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [addSelectedBrands, setAddSelectedBrands] = useState(false);
    let temp = []
    let imagesTemp = []
    const navigate =  useNavigate()

    let mySelects = []
    const [MyBrands, setMyBrands] = useState([]);
    const [select, setSelect] = useState(false);
    let [value, setValue] = useState(null);
    const FashionBrands = () => {
        database.collection("FashionBrands").onSnapshot((res) => {
            res.docs.forEach((doc) => {
                temp.push({data: doc.data(), id: doc.id})
            })
            setFashionBrands(temp)
        })
    }
    const location = useLocation()
    const functions = getFunctions();
    const addFashionBrand = httpsCallable(functions, 'addFashionBrand');
    const removeFashionBrand = httpsCallable(functions, 'removeFashionBrand');
    const [images, setImages] = useState([]);
    useEffect(() => {
        if (auth.currentUser === null) {
            return
        }
        database.collection("users").doc(auth.currentUser.uid).collection("MyBrands").onSnapshot((res) => {
            mySelects = []
            res.docs.forEach((doc) => {
                mySelects.push({data: doc.data(), id: doc.id})
            })
            setMyBrands(mySelects)

        })
        fetchAndActivate(remoteConfig)
            .then(() => {
                const abc = getValue(remoteConfig, `fashion_brands_selected_limit`)
                setValue(abc["_value"])
            })
            .catch((err) => {
                // ...
            });

        FashionBrands()

    }, [select, auth.currentUser]);
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    async function SaveDatabase() {
        const kullaniciRef = database.collection("users").doc(auth.currentUser.uid);
        const myBrandsRef = kullaniciRef.collection("MyBrands");

        try {
            // MyBrands koleksiyonundaki belge kimliklerini al
            const myBrandsSnapshot = await myBrandsRef.get();
            const existingBrandIds = myBrandsSnapshot.docs.map((doc) => doc.data().id);

            // Belge kimliklerini kontrol et ve silme veya ekleme işlemi yap
            for (let i = 0; i < MyBrands.length; i++) {
                const brandId = MyBrands[i].id;
                const brandDocId = existingBrandIds.includes(brandId) ? existingBrandIds.find((id) => id === brandId) : database.collection("users").doc().id;

                if (existingBrandIds.includes(brandId)) {
                    // Eğer brandId'ye ait bir belge kimliği varsa, ekleme yapma
                } else {
                    // Yeni belgeyi ekle
                    await myBrandsRef.doc(brandId).set({
                        name: MyBrands[i].data.name,
                        image: MyBrands[i].data.image,
                        id: brandId,
                        addDate: serverTimestamp()
                    });

                }
            }
            // MyBrands koleksiyonundaki mevcut belge kimlikleri içinde bulunmayan belge kimliklerini sil
            const deletePromises = myBrandsSnapshot.docs
                .filter((doc) => !MyBrands.some((brand) => brand.id === doc.data().id))
                .map((doc) => doc.ref.delete());

            await Promise.all(deletePromises);

            setAddSelectedBrands(!addSelectedBrands)
            await delay(4000)
            if (prevLocationPath === "/my-profile")
            {
                navigate("/my-profile")
            }
            else{
                navigate("/")
            }


        } catch (error) {
        }
    }

    async function addOrDeleteBrands(fashionBrand, id) {
        let newData = {id: id, data: {name: fashionBrand.data.name, image: fashionBrand.data.image, id: id}}
        if (MyBrands.find(item => item.id === id)) {
            if (mediaQuery.matches) {
                let updatedArray = MyBrands.filter(item => item.id !== id)
                setMyBrands(updatedArray)
            } else {
                await database.collection("users").doc(auth.currentUser.uid).collection("MyBrands").doc(id).delete().then(
                    removeFashionBrand({brandId: id})
                )
            }

        } else {

            if (MyBrands.length + 1 <= value) {
                if (mediaQuery.matches) {
                    setMyBrands(prevState => [...prevState, newData])

                } else {
                    await database.collection("users").doc(auth.currentUser.uid).collection("MyBrands").doc(id).set({
                        name: fashionBrand.data.name,
                        image: fashionBrand.data.image,
                        id: id,
                        addDate: serverTimestamp()
                    }).then(setSelect(true)).then(
                        addFashionBrand({brandId: id})
                    )
                }

            }

        }

    }

    return (
        <div>
            <div className={"w-full flex-shrink  text-black font-bold"}>
                <p className="text-center">Maximum selectable limit : <span
                    className="text-[#D81A71] text-lg">{value}</span></p>
                <p className="text-center">Remaining limit : <span
                    className="text-[#D81A71] text-lg ">{value - MyBrands.length}</span></p>
            </div>
            <div className="grid lg:grid-cols-4 xs:grid-cols-1 md:grid-cols-3 gap-2 ">
                {fashionBrands.map(({data: {name, image}, id}, index) => (
                    <button className="w-full border flex flex-col h-auto items-center overflow-hidden" key={index}
                            onClick={() => addOrDeleteBrands(fashionBrands[index], id)}>
                        {
                            MyBrands.find(item => item.id === id) ?
                                <div
                                    className="w-full flex h-10 justify-center bg-red-600 text-white items-center font-bold">
                                    <p>REMOVE</p>
                                </div>
                                :
                                <div
                                    className="w-full flex h-10 justify-center bg-black text-white items-center font-bold">
                                    {MyBrands.length.toString() === value ?
                                        <p className="animate-pulse text-[#D81A71]">YOU CAN'T CHOOSE MORE</p> :
                                        <p>Add to Profile</p>}
                                </div>
                        }
                        <div className="w-full h-40">
                            <img src={image} className="object-contain w-full h-full hover:scale-90  duration-500"/>
                        </div>
                        <div
                            className=" h-10 bg-[#8C0149] w-full flex items-center justify-center text-white font-bold">
                            <p>{name}</p>
                        </div>
                    </button>
                ))}
            </div>

            {addSelectedBrands ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/3 h-1/4 xs:w-11/12 xs:h-1/4 bg-black rounded-2xl overflow-hidden justify-center space-y-1">

                            <div className=" w-full flex h-full overflow-hidden items-center justify-center p-2">
                                <div className=" w-full h-3/4 overflow-hidden text-[#D81E71] text-lg text-center font-bold items-center flex justify-center">
                                    <p>Your selected brands have been successfully added to your profile.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {mediaQuery.matches && <button onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                           className="block h-auto gap-y-3 text-white gap-y-1 fixed bottom-0 p-2 w-full bg-black flex flex-col items-center justify-center">
                <p className="flex  gap-x-1">{MyBrands.length >= value ?
                    <p className=" font-bold">You can`t choose more</p> :
                    <p>Chosen Brands <span className="font-bold text-red-600">  {MyBrands.length}  </span> | {value}
                    </p>}  </p>
                <div className="flex items-center justify-center w-screen border-black h-auto gap-x-3 ">
                    {MyBrands.map(({id, data: {name, image}}, index) => (
                        <img key={index} src={image}
                             className="w-12 h-12 bg-white border-2 rounded-xl border-black object-contain" alt=""/>

                    ))}
                </div>
                <button onClick={SaveDatabase} className={`py-1 px-3 bg-[#D81A71] rounded-full text-white ${MyBrands.length === 0 && "hidden"}`}>Add Selected
                </button>
            </button>

            }
        </div>
    );
}

export default FashionBrands;