import React, {useContext, useEffect, useRef, useState} from 'react';
import Message from './Message';
import {ChatContext} from '../../Context/ChatContext';
import database, {auth} from '../../Controller/Firebase/firebase';
import moment from "moment";

function Messages() {
    const {data} = useContext(ChatContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')

    const [msgs, setMsgs] = useState([]);
    let listen;
    let messagesRef;
    const [limit, setLimit] = useState(30);
    const ref = useRef();

    const [showModal, setShowModal] = useState(false);

    const [loadBoolean, setLoadBoolean] = useState(false);

    function CalculateTimeString(a) {
        let now = moment(new Date());
        let messageTimeLive;
        let end = moment(a.seconds * 1000); // another date
        let duration = moment.duration(now.diff(end));
        let days = duration.asDays().toFixed();
        if (0 <= days && days < 1) {
            messageTimeLive = moment(a.seconds * 1000).format('HH:mm')
        }
        if (1 <= days && days < 2) {
            messageTimeLive = "Yesterday"
        }
        if (2 <= days && days < 7) {
            messageTimeLive = moment(a.seconds * 1000).format('HH:mm,dddd')
        }
        if (7 <= days && days < 365) {
            messageTimeLive = moment(a.seconds * 1000).format('HH:mm,MMMM d')
        }
        if (365 <= days) {
            messageTimeLive = moment(a.seconds * 1000).format('HH:mm,MMMM d,YYYY')
        }
        return messageTimeLive
    }

    const fetchAllMessages = async () => {
        setMsgs([]);
        messagesRef = await database
            .collection('rooms')
            .doc(`${data.chat.id}`)
            .collection('messages')
            .orderBy('updatedAt', 'desc')
            .limit(limit)
            .get();
        await setMsgs(
            messagesRef.docs
                .map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))
                .sort((a, b) => a.updatedAt - b.updatedAt)
        );
    };
    const fetchLastMessage = async () => {
        listen = await database
            .collection('rooms')
            .doc(`${data.chat.id}`)
            .collection('messages')
            .orderBy('updatedAt', 'desc')
            .limit(1)
            .onSnapshot(async (messages) => {
                if (!messages.empty) {
                    await setMsgs((prevState) => {
                        let newMessage = [...prevState];
                        if (!newMessage.some((val) => val.id === messages.docs[0].id)) {
                            setLoadBoolean(false);
                            return [...newMessage, messages.docs[0].data()];
                        } else {
                            return newMessage;
                        }
                    });
                }
            });
    };
    useEffect(() => {
        fetchAllMessages();
    }, [limit]);

    useEffect(() => {
        fetchAllMessages().then(() => fetchLastMessage());
        return () => {
            if (listen) {
                listen();
            }
        };
    }, [data.chat.id]);

    function loadMore() {
        setLimit(prevState => prevState + 10)

    }

    // function handleScroll(e) {
    //     let element = e.target
    //     if(element.scrollTop===0)
    //     {
    //         setLimit(prevState => prevState + 4)
    //     }
    // }

    return (
        <div
            className="w-full bg-[#FBFBFB] h-full overflow-y-scroll "
        >
        {/*    <div*/}
        {/*    className="w-full bg-[#ddddf7] h-full p-2 overflow-y-scroll " onScroll={handleScroll}*/}
        {/*>*/}


                <div className="w-full flex items-center justify-center">
                    {msgs.length > 30 && <button onClick={loadMore} className="text-[#D81E71] underline font-bold">Load More</button>}

                </div>
                {msgs.map((message, index) => (
                    <Message key={index} message={message} loadBoolean={loadBoolean} />
                ))}


        </div>
    );
}

export default Messages;