import React from 'react';
import Sidebar from "../../Layouts/Sidebar";
import Footer from "../../Layouts/footer";
import AnotherProfileContent from "../../Layouts/AnotherProfileContent";
import Header from "../../Layouts/header";
import {MdOutlineArrowBackIos} from "react-icons/md"


function AnotherProfile() {
    return (
        <div className="lg:w-full xs:w-full mx-auto lg:h-auto md:justify-center  ">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex justify-end lg:w-full mx-auto my-4 xs:my-0 space-x-4 xs:space-x-0">
                <div className="flex lg:w-1/4 md:hidden xs:hidden ">
                    <Sidebar/>
                </div>
                <div className=" flex lg:overflow-hidden  lg:w-full md:w-full xs:w-full  ">
                    <AnotherProfileContent/>
                </div>
            </div>
        </div>
    );
}
export default AnotherProfile;