import React, {useContext, useEffect, useRef, useState} from "react";
import {ChatContext} from "../../Context/ChatContext";
import moment from "moment";
import database, {auth} from "../../Controller/Firebase/firebase";
import {BiArrowBack} from "react-icons/bi";

const Message = ({message, loadBoolean,contentRef}) => {
    const {data} = useContext(ChatContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const ref = useRef();
    const [showModal, setShowModal] = useState(false);
    const [AuthCurrentUser, setAuthCurrentUser] = useState([]);
    let owner;
    let other;
    function CalculateTimeString() {
        let now = moment(new Date());
        let messageTimeLive;
        let end = moment(message.updatedAt.seconds * 1000); // another date
        let duration = moment.duration(now.diff(end));
        let days = duration.asDays().toFixed();
        if (0 <= days && days < 1) {
            messageTimeLive = moment(message.updatedAt.seconds * 1000).format('HH:mm')
        }
        if (1 <= days && days < 2) {
            messageTimeLive = "Yesterday"
        }
        if (2 <= days && days < 7) {
            messageTimeLive = moment(message.updatedAt.seconds * 1000).format('HH:mm,dddd')
        }
        if (7 <= days && days < 365) {
            messageTimeLive = moment(message.updatedAt.seconds * 1000).format('HH:mm,MMMM d')
        }
        if (365 <= days) {
            messageTimeLive = moment(message.updatedAt.seconds * 1000).format('HH:mm,MMMM d,YYYY')
        }
        return messageTimeLive
    }

    useEffect(() => {
        database.collection("users").doc(auth.currentUser.uid).onSnapshot((doc) => {
            setAuthCurrentUser(doc.data())
        })
        if (loadBoolean === false) // eğer Messages componentinde eski mesajları yüklemek isterlerse scrollu aldığım propsa göre kontrol ediyorum
        {
            ref.current?.scrollIntoView({behavior: "smooth"})

        }
    }, [message, message.messageId]);
    return (
        <div
            ref={ref}
            className={`message ${message.authorId === auth.currentUser.uid ? "owner" : "other"}`}
        >
            {mediaQuery.matches ? ""
                :
                <div className="messageInfo">
                    {/*<img*/}
                    {/*    src={*/}
                    {/*        message.authorId === auth.currentUser.uid*/}
                    {/*            ? AuthCurrentUser.profileImage*/}
                    {/*            : data.chat.otherUser.profileImage*/}
                    {/*    }*/}
                    {/*    alt=""*/}
                    {/*/>*/}
                </div>
            }
            {message.type === "text" ?
                <div className={`messageContent overflow-hidden w-auto`}>
                    <p className={`flex flex-col break-words w-auto xs:w-auto bg-[#8da4f1] text-sm `}
                    >{message.text}
                    </p>
                    <span className="text-xs text-[#858383]  ">
                            {CalculateTimeString()}
                    </span>
                </div>

                :
                <div
                    className="flex flex-col w-60 h-auto   rounded-tr-lg rounded-tl-lg rounded-bl-lg overflow-hidden">
                       <button onClick={() => setShowModal(true)}>
                           <img src={message.img}
                                className="w-full max-h-64 object-cover rounded-tr-lg rounded-tl-lg rounded-bl-lg" alt=""/>
                       </button>
                       <p className="text-xs text-[#858383] text-end ">
                           {CalculateTimeString()}
                       </p>
                    {showModal ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                            >
                                <div className=" flex flex-col w-1/3 h-full xs:w-full xs:h-5/6 bg-white rounded-2xl overflow-hidden justify-center space-y-1">
                                    <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                        <button className=" text-white text-xl px-2 rounded-full"
                                                onClick={()=>setShowModal(false)}><BiArrowBack/>
                                        </button>
                                    </div>
                                    <div className=" w-full flex h-full overflow-hidden items-center justify-center p-2">
                                        <div className=" w-full h-3/4 overflow-hidden items-center flex justify-center">
                                            <img src={message.img} className="w-auto h-auto object-cover" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div>
            }
        </div>
    )
}
export default Message