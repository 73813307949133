import React from 'react';
import Header from "../../Layouts/header";
import {GiCommercialAirplane} from "react-icons/gi";
import Sidebar from "../../Layouts/Sidebar";
import ShowAllTravels from "../../Layouts/ShowAllTravels";
import Footer from "../../Layouts/footer";
import SearchResult from "../../Layouts/SearchResult";
import {BiArrowBack} from "react-icons/bi"
import {useNavigate} from "react-router-dom";


function Search() {
    const navigate = useNavigate()
    function handleGoBack() {
        navigate(-1)
    }

    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center  ">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex lg:hidden md:hidden items-center text-2xl text-black  p-3 "
            >
                <button onClick={handleGoBack} className=" px-2 py-1">
                    <BiArrowBack className=""  />
                </button>
            </div>
            <div className="flex justify-end lg:w-full  mx-auto overflow-hidden my-4 xs:my-1 space-x-1 xs:space-x-0 ">
                {/*<div className="flex lg:w-1/5 md:w-0  xs:w-0  invisible  md:invisible lg:visible ">*/}
                {/*    <Sidebar/>*/}
                {/*</div>*/}
                <div className="  lg:overflow-hidden lg:w-full md:w-full xs:w-full  ">
                    <SearchResult/>
                </div>
            </div>
        </div>
    );
}

export default Search;