import React, {useContext, useState, useEffect, useRef} from 'react';
import mobilLogo from '../Images/modelClubMobilSvg.svg';
import webLogo from '../Images/modelClubWebSvg.svg';
import viewedIcon from "../Images/profileViewerSvg.svg"
import {Link, useNavigate, useLocation, NavLink} from "react-router-dom";
import database, {auth} from "../../Controller/Firebase/firebase";
import {BsFillChatDotsFill} from "react-icons/bs";
import {FaHome, FaUserAlt} from "react-icons/fa";
import {SlDiamond} from "react-icons/sl";
import {AiOutlineSearch} from "react-icons/ai";
import {AuthContext} from "../../Context/AuthProvider";
import favIconWhite from "../Images/favIconWhite.svg"
import facIconGradient from "../Images/favIconGradient.svg"
import loginIcon from "../Images/HeaderImages/login.png"
import click from "../Images/HeaderImages/click.svg"
import registerIcon from "../Images/HeaderImages/register.png"
import logoutIcon from "../Images/HeaderImages/logoutIcon.svg"
import premiumIconSvg from "../Images/premiumIconSvg.svg"
import LogoutModal from "./LogoutModal";

function Header() {

    const {
        loginUser,
        downMobilMenu,
        setDownMobilMenu,
        openMakeWebAppModal,
        openWebAppModalFunction,
        setOpenMakeWebAppModal,
        logoutModal, setLogoutModal
    } = useContext(AuthContext);
    let navigate = useNavigate();
    const [signup, setSignup] = useState(false);
    const person = auth.currentUser;
    const [profileViewerCount, setProfileViewerCount] = useState([]);
    let temp = [];
    const [or, setOr] = useState(false);
    let temp2 = []
    const [count, setCount] = useState();
    let add = 0
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const location = useLocation();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    function loginToggle() {
        navigate("/login", {replace: true})
    }

    function signupToggle() {
        setSignup(!signup)
    }

    function logout() {
        setLogoutModal(!logoutModal)
    }

    const profileViewer = () => {
        database.collection("users").doc(auth.currentUser.uid).collection("profileViewers").onSnapshot(res => {
            temp2 = []
            res.forEach(doc => {
                if (doc.data()["isSeen"] === true) {
                    temp2.push(doc.data()["isSeen"])
                }
                setProfileViewerCount(temp2)
            })
        })
    }
    const isWebApp = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || (window.matchMedia('(display-mode: fullscreen)').matches) || (window.matchMedia('(display-mode: minimal-ui)').matches);
    useEffect(() => {
        if (auth.currentUser !== null) {
            let person = auth.currentUser.uid
            database.collection("rooms").where("userIds", "array-contains-any", //Chat gelen mesaj bildirimi
                [auth.currentUser.uid]).onSnapshot(snapshot => {
                temp = []
                for (let i = 0; i < snapshot.docs.length; i++) {
                    temp.push(snapshot.docs[i].data()[person])
                }
                add = 0
                for (let j = 0; j < temp.length; j++) {
                    if (isNaN(temp[j])) {
                        continue;
                    }
                    add += Number(temp[j])
                }
                setCount(add)
            })
            profileViewer()
        }
    }, [profileViewerCount.length, loginUser, auth.currentUser]);
    function goToHomePage() {
        if (location.pathname === "/") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        } else {
            navigate("/")
        }
    }
    return (
        <>
            {mediaQuery.matches ?

                <div id="MobileMenum" className={`flex flex-col  gap-y-2 ${downMobilMenu && "hidden"}`}>
                    {!isWebApp && (
                        <button
                            onClick={openWebAppModalFunction}
                            className={` ${localStorage.getItem('token') === null && "hidden"} h-[30px] w-full flex items-center justify-center bg-gradient-to-l gap-x-3 from-[#EB3349] to-[#F45C43] text-white font-bold text-lg animate-pulse`

                        }
                        >
                            Make Web App
                            <img src={click} alt=""/>
                        </button>
                    )}
                    {
                        localStorage.getItem('token') === null ?
                            <div className="w-full  h-12 flex justify-around items-center bg-black ">
                                <Link to="/"><img src={webLogo} className="w-auto h-7" alt=""/></Link>
                            </div>
                            :
                            <div className="w-full  h-12 flex justify-around items-center  ">
                                <Link to="/"><img src={mobilLogo} className="w-auto h-7 " alt=""/>
                                </Link>
                                <div className="flex space-x-4 items-center text-2xl">
                                    <Link to="/premium"><SlDiamond className="text-[#D81E71] text-xl font-bold"/>
                                    </Link>
                                    <Link to="/viewed">
                                        {profileViewerCount.length === 0
                                            ?
                                            <div className="flex">
                                                <img src={viewedIcon} className="w-[20px] h-[20px]" alt=""/></div>
                                            :
                                            <div className=" flex justify-around animate-pulse ">
                                                <img src={viewedIcon} className="w-[20px] h-[20px]" alt=""/>
                                                <span
                                                    className="-my-1 text-xs text-red-600 font-bold">{profileViewerCount.length}</span>
                                            </div>
                                        }
                                    </Link>
                                    <Link to="/search_filter"><AiOutlineSearch
                                        className="text-[#D81E71] font-bold text-2xl"/> </Link>

                                </div>
                            </div>
                    }
                    <div className="fixed bottom-0 left-0 right-0  w-full h-[10%]  bg-black z-50 p-1">
                        {localStorage.getItem('token') === null ?
                            <div
                                className="flex text-white justify-center items-center space-x-20  font-bold items-start h-full ">
                                <button onClick={() => navigate("/signUp")} className="flex gap-x-1"><img
                                    src={registerIcon} className="" alt=""/>Sign Up
                                </button>
                                <button className="flex gap-x-1" onClick={loginToggle}><img src={loginIcon} alt=""/>Login
                                </button>
                            </div>
                            :
                            <div className="flex text-white justify-around text-2xl  items-center w-full -my-2 h-full ">
                                <FaHome onClick={goToHomePage}
                                        className={`${location.pathname === "/" && "text-[#D81E71] "}`}/>
                                {/*<ImFire  className={`${location.pathname === "/favorites" && "text-red-700"}`}/>*/}
                                <NavLink exact to="/favorites"
                                         className={location.pathname === '/favorites' ? 'active' : ''}>{location.pathname === '/favorites' ?
                                    <img src={facIconGradient} alt="" className="w-8 h-8" onClick={scrollToTop}/> :
                                    <img src={favIconWhite} alt="" className="w-8 h-8"/>}</NavLink>
                                <NavLink exact to="/chat">
                                    {count === 0 || count === undefined
                                        ?
                                        <div className="flex">
                                            <BsFillChatDotsFill className="text-white "/>
                                        </div>
                                        :
                                        <div className="flex">
                                            <BsFillChatDotsFill className="text-[#D81E71] animate-pulse"/>
                                            <p className="text-xs text-[#D81E71] ">
                                                {count}
                                            </p>
                                        </div>
                                    }
                                </NavLink>
                                <Link to="/my-profile"><FaUserAlt
                                    className={`${location.pathname === "/my-profile" && "text-[#D81E71] "}`}/></Link>
                                <button onClick={logout}><img src={logoutIcon} className="w-7 h-7" alt=""/></button>
                            </div>
                        }
                    </div>
                </div>
                :
                <div
                    className="relative lg:max-w-screen-lg lg:mx-auto lg:h-20 overflow-hidden md:h-16 md:w-full  sm:h-14 xs:h-10 xs:w-full border-2 border-black ">
                    <div className="absolute w-full h-full my-auto ">
                        <div
                            className="flex lg:justify-between p-[75px] lg:my-auto items-center lg:py-6 md:py-3 md:justify-around sm:justify-around sm:py-2 xs:justify-between bg-black">
                            <div
                                className="text-red-800 flex items-center  font-bold italic sm:text-2xl md:text-2xl xs:text-2xl space-x-8">
                                <button onClick={()=>{
                                location.pathname === "/" ? window.location.reload() : navigate("/")
                                }
                                }><img src={webLogo} className="w-[180px] h-full" alt=""/> </button>

                            </div>
                            <div className="space-x-3  sm:text-md xs:text-sm">
                                {
                                    localStorage.getItem('token') === null ?
                                        <div className="flex space-x-6">
                                            <Link to="/signUp" className="text-white font-bold">Sign Up</Link>
                                            <button
                                                className="bg-[#D81E71] min-w-min w-32 text-white rounded-md border-2 border-white font-bold "
                                                onClick={loginToggle}>Login
                                            </button>
                                        </div>
                                        :
                                        <div className=" flex space-x-10 items-center  text-white justify-between ">
                                            <div
                                                className="flex items-center text-white font-bold space-x-6   xs:hidden ">
                                                <img src={premiumIconSvg} className="w-7 h-7 hover:cursor-pointer "
                                                     alt="" onClick={() => navigate("/premium")}/>
                                                {/*<Link to="/premium"*/}
                                                {/*      className="text-white text-xl text-white underline font-bold text-white "><GiQueenCrown/></Link>*/}
                                                <Link to="/viewed">
                                                    <div className="flex space-x-1">
                                                        {profileViewerCount.length === 0
                                                            ?
                                                            <span className={`${location.pathname === "/viewed" && "text-[#D81E71] "}`}>Profile Viewers</span>
                                                            :
                                                            <>
                                                            <span
                                                                className="flex  justify-center text-md flex items-center   w-6 h-6 rounded-full bg-[#D81E71] animate-pulse">{profileViewerCount.length}</span>
                                                                <span className="text-[#D81E71]">Profile Viewers</span>
                                                            </>
                                                        }
                                                    </div>
                                                </Link>
                                                <Link to="/chat">
                                                    <div className="flex">
                                                        {count === 0 || count === undefined
                                                            ?
                                                            <span className={`${location.pathname === "/chat" && "text-[#D81E71] "}`}>Chats</span>
                                                            :
                                                            <>
                                                            <span
                                                                className="text-md flex  justify-center items-center  rounded px-1  w-6 h-6 rounded-full  bg-[#D81E71] animate-pulse">{count}</span>
                                                                <span className="text-[#D81E71]">Chats</span>
                                                            </>
                                                        }
                                                    </div>
                                                </Link>

                                                <Link to="/favorites"><span className={`${location.pathname === "/favorites" && "text-[#D81E71] "}`}>Favorites</span></Link>
                                                <Link to="/my-profile"><span className={`${location.pathname === "/my-profile" && "text-[#D81E71] "}`}>My Profile</span></Link>
                                                <button
                                                    className="bg-[#D81E71]  min-w-min w-32 text-white rounded-md border-2 border-white font-bold "
                                                    onClick={logout}>Sign Out
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {logoutModal && <LogoutModal/>}

            {/*<StateContext.Provider value={{signup, setSignup, signupToggle}}>*/}
            {/*    <Sign/>*/}
            {/*</StateContext.Provider>*/}
        </>
    );
}

export default Header;