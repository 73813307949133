import React, {useContext} from 'react';
import {AuthContext} from "../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";

function ChatNavbar() {
    const {loginUser} = useContext(AuthContext);
    const navigate = useNavigate()
    function goBack() {
        navigate(-1)
    }
    return (
        <div className="w-full p-3 h-20 xs:h-[10%] flex items-center justify-center xs:justify-between   ">
            <div className=" lg:hidden md:hidden">
                <BiArrowBack className=" text-2xl" onClick={goBack}/>
            </div>

            <p className=" lg:w-full text-center  text-2xl text-black font-semibold">Chats</p>
            <span/>
        </div>
    );
}

export default ChatNavbar;