import React, {useContext, useEffect, useState} from 'react';
import {ChatContext} from "../../Context/ChatContext";
import database, {auth} from "../../Controller/Firebase/firebase";
import moment from "moment/moment";
import {collection, doc, limit, onSnapshot, orderBy, query, updateDoc, where} from "firebase/firestore";
import SwipeToDelete from "react-swipe-to-delete-ios";
import StateContext from "../../Context/StateContext";
import DeleteRooms from "./DeleteRooms";
import {AuthContext} from "../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
import VerificationVideoQuery from "./VerificationVideoQuery";

function Chats() {
    const {data, setDeleteRoomsBoolean, deleteIdRoom, data2, data3, state,chatPermission,otherUserActiveChatRoom, setOtherUserActiveChatRoom,} = useContext(ChatContext);
    let navigate = useNavigate();
    const [chats, setChats] = useState([])
    const {dispatch, action} = useContext(ChatContext);
    const [deleteRoomId, setDeleteRoomId] = useState(null);
    const [open, setOpen] = useState(false);
    const [namee, setNamee] = useState(null);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [limitData, setLimitData] = useState(11);
    const [collectionLength, setCollectionLength] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const {loginUser,showVerificationVideoQuery, setShowVerificationVideoQuery} = useContext(AuthContext);


    let definedValuesOnly;
    let temp = []
    let authPerson = auth.currentUser
    let blockList = []
    let now = moment(new Date());
    const ListenToBlock = async () => {
        await database.collection("users").doc(auth.currentUser.uid).collection("blockList").onSnapshot(res => {
            blockList = []
            res.forEach(el => {
                blockList.push(el.id)
            })
        })
    }
    function CalculateTimeString(time) {
        let messageTimeLive;
        let end = moment(time * 1000); // another date
        let duration = moment.duration(now.diff(end));
        let days = duration.asDays().toFixed();
        if (0 <= days && days < 1) {
            messageTimeLive = moment(time * 1000).format('HH:mm')
        }
        if (1 <= days && days < 2) {
            messageTimeLive = "Yesterday"
        }
        if (2 <= days && days < 7) {
            messageTimeLive = moment(time * 1000).format('dddd')
        }
        if (7 <= days && days < 365) {
            messageTimeLive = moment(time * 1000).format('MMM d')
        }
        if (365 <= days) {
            messageTimeLive = moment(time * 1000).format('MMMM d, YYYY')
        }
        return messageTimeLive
    }
    const GetChats = async () => {
        await ListenToBlock()
        const rooms = await database.collection("rooms")
            .orderBy("updatedAt", "desc")
            .where("userIds", "array-contains-any", [auth.currentUser.uid])
            .limit(limitData)
            .get()
        const allChats = rooms.docs.map(async room => {
            const roomData = room.data();
            const roomId = room.id;
            const userIds = roomData.userIds
            const otherUserId = userIds.find(id => id !== auth.currentUser.uid)
            if (!roomData.metadata.deletedIds.includes(auth.currentUser.uid) && !blockList.includes(otherUserId)) {
                const user = await database.collection("users").doc(`${otherUserId}`).get()
                const otherUserData = {
                    name: user.data()?.name,
                    profileImage: user.data()?.profileImage,
                    userID: user.data()?.userID,
                    token:user.data()?.token,
                    age:user.data()?.age,
                    bio:user.data()?.bio,
                    location:user.data()?.location,
                    premium:user.data()?.is_premium,
                    gender:user.data()?.gender,
                    verificationVideo:user.data()?.verificationVideo,
                    activeChatRoom : user.data()?.activeChatRoom
                }
                return {
                    data: roomData,
                    id: roomId,
                    otherUser: otherUserData,
                    count: roomData[auth.currentUser.uid],
                    messageTime: CalculateTimeString(roomData?.updatedAt.seconds)
                }
            }
        })
        temp.push(await Promise.all(allChats))
        definedValuesOnly = temp[0].filter(val => val !== undefined);
        await setChats(await definedValuesOnly)
        OnsnapShotChats();
    }
    const OnsnapShotChats = async () => {
        const q = query(
            collection(database, "rooms"),
            where("userIds", "array-contains-any", [auth.currentUser.uid]),
            orderBy("updatedAt", "desc"),
            limit(1)
        );
        onSnapshot(q, async rooms => {
            if (rooms.docs.length > 0) {
                const roomData = rooms.docs[0].data();
                if (roomData.updatedAt === null) return
                const updatedChat = definedValuesOnly.map(chat => {
                    if (chat.id === rooms.docs[0].id) {
                        return {
                            ...chat,
                            data: roomData,
                            messageTime: CalculateTimeString(roomData.updatedAt.seconds),
                            count: roomData[auth.currentUser.uid]
                        };
                    }
                    return chat;
                });
                setChats(updatedChat);
                setChats(updatedChat.sort((a, b) => b.data.updatedAt - a.data.updatedAt));
            }
        });
    };
    useEffect(() => {
        GetChats()
        if (data.chat !== "null")
        {
            database.collection("users").doc(data.chat.otherUser.userID).onSnapshot((snapshot)=>{
                setOtherUserActiveChatRoom(snapshot.data()?.activeChatRoom)
            })
        }
    }, [data2.deleteCount, data3.newChatCount, limitData, data.chat,auth.currentUser]);
    const handleScroll = (e) => {
        if (e.target.scrollTop > e.target.dataset.scrollTop) {
            e.target.dataset.scrollTop = e.target.scrollTop;
            setLimitData(prevLimit => prevLimit + 11);
        }
    };

    const handleSelect = async (u) => {
        // if (selectedChat && selectedChat.id === u.id) return;
        if (u.data.receiverId === "1stHA3nyeJNm3zA9xA2qla50Np23")
        {
            setSelectedChat(u);
            setDeleteRoomsBoolean(false);
            const docRef2 = "rooms/" + u.id;
            await updateDoc(doc(database, docRef2), {
                [authPerson.uid]: 0
            })
            await database.collection("users").doc(auth.currentUser.uid).set({activeChatRoom : u.id},{merge : true})
            if (mediaQuery.matches) {
                document.getElementById("ChatSidebar").style.display = "none";
                document.getElementById("Chat").style.display = "flex";
            }
            dispatch({type: "CHANGE_USER", payload: u})
        }
        else if (chatPermission === false)
        {
            if (loginUser.is_premium === false)
            {
                navigate("/premium")
            }
            if (loginUser.verificationVideo === "null")
            {
                setShowVerificationVideoQuery(!showVerificationVideoQuery)
            }
            return
        }
            setSelectedChat(u);
            setDeleteRoomsBoolean(false);
            const docRef2 = "rooms/" + u.id;
            await updateDoc(doc(database, docRef2), {
                [authPerson.uid]: 0
            })
        await database.collection("users").doc(auth.currentUser.uid).set({activeChatRoom : u.id},{merge : true})
            if (mediaQuery.matches) {
                document.getElementById("ChatSidebar").style.display = "none";
                document.getElementById("Chat").style.display = "flex";
            }
            dispatch({type: "CHANGE_USER", payload: u})
    }
    function OpenDeleteToggle(id, name) {
        setOpen(!open)
        setDeleteRoomId(id)
        setNamee(name)
    }
    return (
        <div id="a" onScroll={handleScroll} data-scroll-top="0" className=" flex-shrink w-full h-[93%]  overflow-auto  bg-white">
            {chats.length === 0 && <p className="text-lg text-[#D81A71] font-bold flex flex-col justify-center w-full  text-center"> <br/>
                <a href="/" className="text-sm underline animate-pulse">
                    No chats yet.
                    Start a conversation now!</a> </p>}
            {showVerificationVideoQuery && <VerificationVideoQuery value={showVerificationVideoQuery} />}

            {chats.map(({
                                id,
                                data: {lastMessage, updatedAt, authorName},
                                otherUser: {name, profileImage, userID,token,activeChatRoom},
                                messageTime,
                                count,
                            }, index) => (
                    <div
                        className={`roomButton flex  border-b-2  justify-between cursor-pointer ${id === deleteIdRoom && "hidden"}`}
                        id={id}

                        key={id} >
                        {mediaQuery.matches ?
                            <div className="w-full p-2 ">
                                <SwipeToDelete
                                    onDeleteConfirm={() => OpenDeleteToggle(id, name)} // required
                                    // optional
                                    // default
                                    className=" "
                                    height={100}
                                    transitionDuration={250}
                                    deleteWidth={75}
                                    deleteText="Delete"
                                    rtl={false}

                                >
                                    <div className="bg-white h-full w-full flex flex-col px-2" onClick={(e) => {  handleSelect(chats[index]);
                                    }}>
                                        <div className="flex justify-between  items-center w-full">
                                            <div className="flex gap-x-3 items-center w-[75%]   font-semibold text-black">
                                                <img src={profileImage} className="w-14 h-14 rounded-full" alt=""/>
                                                <p >{name}</p>
                                            </div>
                                            <div className="flex w-[25%] justify-center ">
                                                <p className="text-xs text-gray-400 font-semibold ">{messageTime}</p>
                                            </div>
                                        </div>
                                        <div className="flex w-full items-center justify-between ">
                                            <div className="flex  w-[75%]  ">
                                                <p className="text-m w-full text-gray-500  overflow-hidden whitespace-nowrap text-ellipsis">{lastMessage}</p>
                                            </div>
                                            <div className="w-[25%]  flex justify-center">
                                                {count !== 0 ?
                                                    <span
                                                        className={" items-center flex text-white border-2 justify-center rounded-full h-6 w-6  bg-[#D81A71] text-sm font-bold "}>{count}</span>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="flex border-2  justify-between w-full h-full  overflow-hidden bg-white">*/}
                                    {/*    <img src={profileImage} alt=""*/}
                                    {/*         className="w-[50px] h-[50px]  rounded-full object-cover"/>*/}
                                    {/*    <div className="overflow-hidden  w-3/5 ">*/}
                                    {/*        <span className="text-md">{name}</span>*/}
                                    {/*        <p className="text-xs text-gray-300  w-full overflow-hidden whitespace-nowrap text-ellipsis">{lastMessage}</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="flex space-x-2 items-center">*/}
                                    {/*        <span className="text-gray-300 text-md">{messageTime}</span>*/}
                                    {/*        {count !== 0 ?*/}
                                    {/*            <span*/}
                                    {/*                className={"mx-1 items-center flex justify-center rounded-full h-5 w-5  bg-blue-500 text-sm font-bold text-black"}>{count}</span>*/}
                                    {/*            :*/}
                                    {/*            ""*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </SwipeToDelete>
                            </div>

                            :
                            <div
                                onClick={(e) => {  handleSelect(chats[index])}}
                                className={`border-2 flex flex-col gap-y-2 text-black hover:text-white w-full hover:bg-[#D81A71] p-5  ${loginUser?.activeChatRoom === id && "bg-[#D81A71] text-white"}`}>
                                <div className="flex justify-between items-center ">
                                    <div className="flex w-[75%] gap-x-3  font-semibold items-center ">
                                        <img src={profileImage} className="w-14 h-14 object-cover rounded-full" alt=""/>
                                        <p className="">{name}</p>
                                    </div>
                                    <div className="flex w-[25%] justify-center ">
                                        <p className="text-xs  ">{messageTime}</p>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center  ">
                                    <div className="flex  w-[75%]   ">
                                        <p className="text-sm w-full  overflow-hidden whitespace-nowrap text-ellipsis">{lastMessage}</p>
                                    </div>
                                    <div className="w-[25%] flex justify-center" >
                                        {count !== 0 ?
                                            <span
                                                className={" items-center flex text-white border-2 justify-center rounded-full h-6 w-6  bg-[#D81A71] text-sm font-bold "}>{count}</span>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                ))}
                <StateContext.Provider value={{open, setOpen, OpenDeleteToggle, deleteRoomId}}>
                    <DeleteRooms/>
                </StateContext.Provider>
        </div>
    );
}
export default Chats;