import React, {useContext, useEffect, useRef, useState} from 'react';
import {BiArrowBack} from "react-icons/bi"
import StateContext from "../../Context/StateContext";
import UploadPhoto from "./UploadPhoto";
import Modal from "react-modal";
import database, {auth, getTokens} from "../../Controller/Firebase/firebase";
import {Link, useNavigate} from "react-router-dom";
import {GiCommercialAirplane} from "react-icons/gi";
import {HiOutlineDotsVertical} from "react-icons/hi";
import AddTravel from "./AddTravel";
import {AuthContext} from "../../Context/AuthProvider";
import EditProfile from "./EditProfile";
import UserPhotos from "./UserPhotos";
import {AiFillSetting} from "react-icons/ai"
import SettingsModal from "./SettingsModal";
import {BsFillCheckCircleFill, BsTrash} from "react-icons/bs";
import {FiSettings} from "react-icons/fi";
import {IoMdPhotos} from "react-icons/io";
import {GiQueenCrown} from "react-icons/gi";
import moment from "moment";
import premiumGold from "../Images/premiumGold.svg";
import favIconGradient from "../Images/favIconGradient.svg";
import favIconWhite from "../Images/favIconWhite.svg";
import chatSvg from "../Images/chat.svg";
import VerificationVideo from "./VerificationVideo";
import {LazyLoadImage} from "react-lazy-load-image-component";
import loadingPhoto from "../Images/loadingPhoto.gif";
import PremiumSettings from "./PremiumSettings";
import {ChatContext} from "../../Context/ChatContext";
import ReactPlayer from "react-player";
import jaguar from "../Images/jaguar.png"
import pinkDiamond from "../Images/pinkDiamond.png";
import blueDiamond from "../Images/blueDiamond.png";


Modal.setAppElement('#root')
const MyProfileContent = () => {
    const {loginUser, token, settingsModal, setSettingsModal, prevLocationPath, setPrevLocationPath} = useContext(AuthContext);
    const {VerifactionVideoToggle} = useContext(ChatContext);
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openUserPhoto, setOpenUserPhoto] = useState(false);
    const [userID, setUserID] = useState(null);
    const [openUpload, setOpenUpload] = useState(false)
    const [openSettings, setOpenSettings] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [selectPhoto, setSelectPhoto] = useState(null)
    const [url, setUrl] = useState(null);
    const [photos, setPhotos] = useState([]);
    const [myBrands, setMyBrands] = useState([]);
    const [femaleVerificationVideo, setFemaleVerificationVideo] = useState(null);
    const [myShows, setMyShows] = useState([]);
    const [openTravelToggle, setOpenTravelToggle] = useState(false);
    const [deleteTravel, setDeleteTravel] = useState(false);
    const [deleteTravelCountry, setDeleteTravelCountry] = useState(false);
    const [deleteTravelId, setDeleteTravelId] = useState(null);
    const [deleteTravelReturnDate, setDeleteTravelReturnDate] = useState(null);
    const [deleteTravelFlightDate, setDeleteTravelFlightDate] = useState(null);
    const [myTravelsInfo, setMyTravelsInfo] = useState([]);
    const [state, setState] = useState(false);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [myVerificationVideo, setMyVerificationVideo] = useState(false);
    let myBrandsTemp = []
    let myShowsTemp = []
    const [unConfrimedPhotosCount, setUnConfrimedPhotosCount] = useState("");
    let myTravelsTemp = []
    const [openUnconfirmedPhoto, setOpenUnconfirmedPhoto] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();

    function openPhoto(i) {
        setOpenUserPhoto(!openUserPhoto)
        setSelectPhoto(i)
        setUserID(loginUser.userID)
    }

    const userPhotos = async () => {
        const photosTemp = [];
        let unconfirmedPhotoTemp = []
        const docSize = await database.collection("users").doc(auth.currentUser.uid).collection("profileImages").get()
        await database.collection("users")
            .doc(auth.currentUser.uid)
            .collection("profileImages")
            .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                    if (doc.data().isReview === true) {
                        photosTemp.push(doc.data().url);

                    } else {
                        unconfirmedPhotoTemp.push(doc.data())
                    }
                });
                setPhotos(photosTemp);
                setUnConfrimedPhotosCount(unconfirmedPhotoTemp)
            });
    }

    // function VerifactionVideoToggle(verificationVideo) {
    //     setState(!state)
    //     setFemaleVerificationVideo(verificationVideo)
    // }

    useEffect(() => {
        if (auth.currentUser === null) {
            return
        }
        getTokens().then((payload) => {
            database.collection("users").doc(auth.currentUser.uid).set({token: payload}, {merge: true})
        })
        userPhotos()
        // setPhotos(loginUser?.images)
        database.collection("travels").where("userID", "==", auth.currentUser.uid).orderBy("createdAt", "desc").onSnapshot((doc) => {
            myTravelsTemp = []
            doc.forEach(el => {
                if (el.data().isDeleted === false) {
                    myTravelsTemp.push({data: el.data(), id: el.id})
                }
            })
            setMyTravelsInfo(myTravelsTemp)
        })
        database.collection("users").doc(loginUser?.userID).collection("MyBrands").onSnapshot(snapshot => {
            snapshot.docs.forEach((doc) => {
                myBrandsTemp.push({data: doc.data(), id: doc.id})
            })
            setMyBrands(myBrandsTemp)
        })
        database.collection("users").doc(loginUser?.userID).collection("MyShows").onSnapshot(snapshot => {
            snapshot.docs.forEach((doc) => {
                myShowsTemp.push({data: doc.data(), id: doc.id})
            })
            setMyShows(myShowsTemp)
        })

    }, [auth.currentUser, loginUser, photos.length]);

    function OpenSettings() {
        setOpenSettings(!openSettings)
    }

    function DeleteTravel(id, country, flightDate, returnDate) {
        setDeleteTravel(!deleteTravel)
        setDeleteTravelId(id)
        setDeleteTravelCountry(country)
        setDeleteTravelFlightDate(moment(flightDate.seconds * 1000).format('L'))
        setDeleteTravelReturnDate(moment(returnDate.seconds * 1000).format('L'))
    }


    function EditProfileModal() {
        setShowModal(!showModal)
    }

    function uploadPhotoToggle() {
        setOpenUpload(!openUpload);
        setUrl(null)
    }

    function addTravelDetails() {
        setOpenTravelToggle(!openTravelToggle)
    }

    function handleGoBack() {
        navigate(-1)
    }

    function ChangeBrandsShows() {
        navigate("/shows-brands")
        setPrevLocationPath("/my-profile")
    }

    async function confirmDeleteTravel(deleteTravelId) {
        document.getElementById("deleteTravelButton").style.display = "none"
        await database.collection("travels").doc(deleteTravelId).update({isDeleted: true}).then(async () => {
            document.getElementById("deleteConfirm").style.display = "none"
            document.getElementById("deleteComplete").style.display = "flex"
            await delay(2000)
            setDeleteTravel(false)
        })
    }

    const [unConfirmedOpenUrl, setUnConfirmedOpenUrl] = useState("");

    function OpenUnconfirmedPhoto(url) {
        setOpenUnconfirmedPhoto(!openUnconfirmedPhoto)
        setUnConfirmedOpenUrl(url)
    }

    return (
        <>
            {loginUser !== null &&
                <div
                    className=" w-full xs:mx-auto  xs:w-full overflow-hidden p-3 xs:h-full xs:px-3 xs:p-0   flex  flex-col gap-y-5 ">
                    {mediaQuery.matches ?
                        <div className="w-full flex flex-col items-center justify-end   text-[#D81E71]  ">
                            <div
                                className="flex w-full  items-center text-2xl text-black justify-between lg:hidden  md:hidden  ">
                                <BiArrowBack onClick={handleGoBack}/>
                                <div
                                    className=" flex justify-end text-center font-bold text-black hover:text-orange-600  text-end  text-2xl">
                                    {auth.currentUser !== null
                                        &&
                                        <button onClick={() => setSettingsModal(!settingsModal)}><FiSettings/></button>
                                    }
                                </div>
                            </div>
                            <div className="flex gap-x-2 items-center justify-center ">
                                <div className="flex gap-x-2 items-center justify-center ">
                                    <img src={loginUser?.profileImage}
                                         className="w-[107px] h-[107px] rounded-full border border-black object-cover"
                                         alt=""/>
                                    <div className=" flex flex-col">

                                        <p className="font-bold text-xl flex items-center gap-x-3 text-black">
                                            {loginUser?.name.split(" ")[0].length > 10 ? loginUser?.name.split(" ")[0].slice(0, 9) + "..." : loginUser?.name.split(" ")[0]}
                                            {loginUser?.is_premium === true &&
                                            <img src={loginUser?.gender === "female" ? pinkDiamond : blueDiamond}
                                                 className="w-6 h-6 "
                                                 alt=""/>}</p>
                                        <p className="font-bold">Age: <span
                                            className="text-black font-semibold">{loginUser?.age}</span></p>
                                        <p className="font-bold">Location: <span
                                            className="text-black font-semibold">{loginUser?.location.length > 9 ? loginUser?.location.slice(0, 10) + "..." : loginUser?.location}</span></p>
                                    </div>
                                </div>
                            </div>
                            {loginUser?.bio ?
                                <div className="Bio flex   gap-x-2 justify-start w-full items-center  overflow-hidden">
                                    <p className="font-bold  text-lg  ">Bio </p>
                                    <p className="w-full h-auto overflow-hidden break-words text-black font-semibold">{loginUser?.bio}</p>
                                </div>
                                :
                                ""
                            }

                            <div className="flex flex-col w-full rounded-2xl text-white">
                                <div
                                    className={` w-full flex items-center  justify-center rounded-t-2xl text-white  bg-[#8C0149D4]   py-2 ${loginUser.gender === "male" && "hidden"}  ${(loginUser.verificationVideo === "null" || loginUser.verificationVideo === undefined) ? "animate-none " : "animate-pulse  bg-opacity-80 "}`}>
                                    {loginUser.verificationVideo === "null" || !loginUser.verificationVideo ?
                                        <p>Waiting for verification video</p>
                                        : <button onClick={() => setMyVerificationVideo(!myVerificationVideo)}
                                                  className="flex  w-full justify-center items-center gap-x-2">Verification
                                            video <BsFillCheckCircleFill className="text-green-500 text-lg"/></button>
                                    }
                                </div>
                                {myVerificationVideo ? (
                                    <>

                                        <div
                                            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
                                            <div
                                                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/2 bg-white overflow-hidden shadow-2xl rounded-2xl">
                                                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                                    <button className=" text-white text-xl px-2 rounded-full"
                                                            onClick={() => setMyVerificationVideo(!myVerificationVideo)}>
                                                        <BiArrowBack/>
                                                    </button>
                                                </div>
                                                <div className="w-full h-full flex flex-col">
                                                    <div className="w-full h-full bg-gray-700 flex overflow-hidden p-2">

                                                        <ReactPlayer url={loginUser?.verificationVideo}
                                                                     playing
                                                                     loop

                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        :
                        <div className="w-full flex flex-col xs:flex-col gap-y-2 xs:gap-y-3  ">
                            <div className="w-full flex">
                                <div
                                    className="w-full flex justify-between ">
                                    <div
                                        className="flex  w-full   items-center justify-start gap-x-3  overflow-hidden">
                                        <div className="flex w-auto h-full items-center justify-between ">
                                            <img src={loginUser?.profileImage}
                                                 className="w-40 h-40 object-cover   rounded-full " alt=""/>
                                        </div>
                                        <div
                                            className="flex flex-col text-[#D81E71] xs:w-full  text-lg xs:text-sm w-[70%] gap-y-3 ">
                                            <div>

                                                <p className="font-bold text-2xl text-[#D81E71] flex items-center gap-x-3 xs:text-xl">{loginUser?.name}
                                                    {loginUser?.is_premium === true && <img
                                                        src={loginUser?.gender === "female" ? pinkDiamond : blueDiamond}
                                                        className="w-6 h-6 drop-shadow-xl" alt=""/>}
                                                </p>
                                                <div className="flex gap-x-2">
                                                    <p className="font-bold">Age: </p>
                                                    <p className="text-black">{loginUser?.age}</p>
                                                </div>

                                                <div className="flex gap-x-2">
                                                    <p className="font-bold">Location: </p>
                                                    <p className="text-black">{loginUser?.location}</p>
                                                </div>
                                                <>
                                                    {
                                                        loginUser?.bio?.length === 0 ? "" :
                                                            <div
                                                                className="flex  gap-x-2  w-full xs:w-full xs:gap-y-0 overflow-hidden">
                                                                <p className="xs:text-sm font-bold className">Bio:</p>
                                                                <p className="xs:text-sm w-full text-black  h-auto break-words overflow-hidden">
                                                                    {loginUser?.bio}
                                                                </p>
                                                            </div>
                                                    }
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div*/}
                                    {/*    className={` w-full flex items-center text-sm  justify-center rounded-t-2xl text-white  bg-[#8C0149D4]   py-1 ${loginUser.gender === "male" && "hidden"}  ${(loginUser.verificationVideo === "null" || loginUser.verificationVideo === undefined) ? "animate-none " : "animate-pulse  bg-opacity-80 "}`}>*/}
                                    {/*    {loginUser.verificationVideo === "null" || !loginUser.verificationVideo ?*/}
                                    {/*        <p>Waiting for verification video</p>*/}
                                    {/*        :*/}
                                    {/*        <button onClick={() => setMyVerificationVideo(!myVerificationVideo)}*/}
                                    {/*                className="flex  w-full justify-center items-center gap-x-2">Verification*/}
                                    {/*            video <BsFillCheckCircleFill*/}
                                    {/*                className="text-green-500 text-lg"/></button>*/}
                                    {/*    }*/}
                                    {/*</div>*/}


                                </div>
                                <div className="w-full xs:w-full h-full flex flex-col  items-center justify-start  ">
                                    <div
                                        className={`w-full flex justify-end items-end xs:hidden  ${auth.currentUser === null && "hidden"}`}>
                                        <FiSettings onClick={() => setSettingsModal(!settingsModal)}
                                                    className={`text-2xl font-bold hover:cursor-pointer `}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/3 rounded-2xl text-white">
                                <div
                                    className={` w-full flex items-center text-sm  justify-center rounded-t-2xl text-white  bg-[#8C0149D4]   py-1 ${loginUser.gender === "male" && "hidden"}  ${(loginUser.verificationVideo === "null" || loginUser.verificationVideo === undefined) ? "animate-none " : "animate-pulse  bg-opacity-80 "}`}>
                                    {loginUser.verificationVideo === "null" || !loginUser.verificationVideo ?
                                        <p>Waiting for verification video</p>
                                        : <button onClick={() => setMyVerificationVideo(!myVerificationVideo)}
                                                  className="flex  w-full justify-center items-center gap-x-2">Verification
                                            video <BsFillCheckCircleFill className="text-green-500 text-lg"/>
                                        </button>
                                    }
                                </div>
                            </div>
                            {myVerificationVideo ? (
                                <>

                                    <div
                                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
                                        <div
                                            className="lg:w-1/3 lg:h-3/5 mx-auto relative  overflow-hidden rounded-xl">
                                            <div
                                                className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                                <button
                                                    className=" text-white text-xl px-2 rounded-full"
                                                    onClick={() => setMyVerificationVideo(!myVerificationVideo)}>
                                                    <BiArrowBack/>
                                                </button>
                                            </div>
                                            <div className="w-full h-full flex flex-col">
                                                <div
                                                    className="w-full h-full bg-gray-700 flex overflow-hidden p-2">

                                                    <ReactPlayer url={loginUser?.verificationVideo}
                                                                 playing
                                                                 loop

                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </div>
                    }
                    {unConfrimedPhotosCount.length > 0 &&
                        <div id="waitingPhotos"
                             className=" w-full flex flex-col  font-bold text-green-700 scroll-auto ">
                            <p>You
                                have {unConfrimedPhotosCount.length} photos pending approval.</p>
                            <div className="flex gap-x-2 overflow-x-auto ">
                                {unConfrimedPhotosCount && unConfrimedPhotosCount.map((img, i) => {
                                    return (

                                        <LazyLoadImage
                                            onClick={() => OpenUnconfirmedPhoto(img.url)} key={i}
                                            src={img.url}
                                            className=" w-20 h-20  xs:w-16 xs:h-16 object-cover border-2  hover:cursor-pointer  border-[#D81E71]  rounded-2xl"
                                            placeholderSrc={loadingPhoto}
                                            effect="blur"
                                        />
                                    )
                                })}
                            </div>

                        </div>}
                    {openUnconfirmedPhoto ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
                                <div
                                    className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-2/3 xs:w-11/12 shadow-2xl xs:h-1/2 bg-white overflow-hidden shadow-2xl rounded-2xl">
                                    <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                        <button className=" text-white text-xl px-2 rounded-full"
                                                onClick={() => setOpenUnconfirmedPhoto(!openUnconfirmedPhoto)}>
                                            <BiArrowBack/>
                                        </button>
                                    </div>
                                    <div
                                        className="bg-black  flex flex-col gap-y-5 w-full h-full   items-center justify-center gap-x-5 text-white">
                                        <div className=" w-11/12 h-4/5 overflow-hidden ">
                                            <img src={unConfirmedOpenUrl} className="w-full h-full object-contain "
                                                 alt=""/>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                    <div className="grid grid-cols-4 xs:grid-cols-2  gap-3   ">
                        <button onClick={uploadPhotoToggle}
                                className="w-full h-80 xs:w-full xs:h-48 border-2 border-[#D81E71] flex flex-col items-center justify-center  rounded-xl bg-black text-white   ">
                            <IoMdPhotos className="text-6xl"/>
                            <p className=" font-bold">Add Photo</p>
                        </button>
                        {photos && photos.map((img, i) => {
                            return (
                                <LazyLoadImage
                                    onClick={() => openPhoto(i)} key={i}
                                    src={img}
                                    className=" w-full h-80 xs:w-full xs:h-48 object-cover border-2  hover:cursor-pointer  border-[#D81E71]  rounded-2xl"
                                    placeholderSrc={loadingPhoto}
                                    effect="blur"
                                />
                            )
                        })}
                    </div>

                    <div className="flex w-full  h-20 justify-start items-center gap-x-2  overflow-x-auto ">
                        <div className=" xs:w-full flex  h-full ">
                            <button className={`flex w-44 h-full  border-4  border-[#980150] flex-col items-center text-sm  rounded-lg bg-black text-white font-normal  justify-center px-1 rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl
                                                 duration-500 `} onClick={() => addTravelDetails()}>
                                <div
                                    className="h-full w-full  rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl transition-all">
                                    <div className=" h-full justify-center items-center flex flex-col">
                                        <GiCommercialAirplane className="w-full text-white text-xl"/>
                                        <p className="font-bold text-white">Promote Your Travel</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        {myTravelsInfo.map(({data: {country, flightDate, returnDate}, id}, index) => (
                            <div
                                className=" flex w-44 h-full  border-4  border-[#980150] flex-col items-center text-sm  rounded-lg bg-black text-white font-normal  justify-center px-1"
                                key={index}>
                                <p className="w-full  justify-end flex text-[#D81E71] font-bold"><BsTrash
                                    className="w-4 h-4 hover:cursor-pointer"
                                    onClick={() => DeleteTravel(id, country, flightDate, returnDate)}/></p>
                                <p className="text-lg">{country}</p>
                                <p className="flex drop-shadow-md"><span
                                    className="text-white">{moment(flightDate.seconds * 1000).format('L')}</span>
                                    <span className="text-black">-</span> <span
                                        className="text-[#D81E71]">{moment(returnDate.seconds * 1000).format('L')}</span>
                                </p>
                            </div>
                        ))}
                        {deleteTravel ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
                                    <div
                                        className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/2 xs:w-11/12 shadow-2xl xs:h-1/2 bg-white overflow-hidden shadow-2xl rounded-2xl">
                                        <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                            <button className=" text-white text-xl px-2 rounded-full"
                                                    onClick={() => setDeleteTravel(false)}><BiArrowBack/>
                                            </button>
                                        </div>
                                        <div
                                            className="bg-black flex flex-col gap-y-5 w-full h-full   items-center justify-center gap-x-5 text-white">
                                            <p className="text-[#D81E71] font-bold text-white text-center">
                                                Are you sure you want to delete this flight plan?
                                            </p>
                                            <div
                                                className="flex flex-col border-2 border-[#D81E71] items-center text-sm  rounded-lg bg-black text-white font-normal h-20 justify-center px-1">
                                                <p className="text-lg">{deleteTravelCountry}</p>
                                                <p className="flex drop-shadow-md"><span
                                                    className="text-white">from {deleteTravelFlightDate}</span>
                                                </p>
                                                <p
                                                    className="text-white">to {deleteTravelReturnDate}</p>
                                            </div>

                                            <div className="flex items-center justify-center w-full gap-x-10">
                                                <button id="deleteConfirm"
                                                        className="px-5 py-0.5 bg-[#D81E71] rounded-full text-white font-bold"
                                                        onClick={() => confirmDeleteTravel(deleteTravelId)}>Yes
                                                </button>
                                                <button
                                                    id="deleteTravelButton"
                                                    className="flex px-5 py-0.5 bg-[#D81E71] rounded-full text-white font-bold"
                                                    onClick={() => setDeleteTravel(false)}>No
                                                </button>
                                            </div>
                                            <span id="deleteComplete"
                                                  className="text-green-600 text-xl font-bold hidden animate-bounce">Deleted Successful</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    </div>

                    {/*<>*/}
                    {/*    {(myBrands.length === 0 && myShows.length) === 0 && (*/}
                    {/*        <button className={`flex w-44 h-20  border-4  border-[#980150] flex-col items-center text-sm  rounded-lg bg-black text-white font-normal  justify-center px-1 rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl*/}
                    {/*                             duration-500 `} onClick={() => navigate("/shows-brands")}>*/}
                    {/*            <div*/}
                    {/*                className="h-full w-full  rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl transition-all">*/}
                    {/*                <div className=" h-full justify-center items-center flex flex-col">*/}
                    {/*                    <p className="font-bold text-white">Add Fashion Events or Favorite Brands</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </button>*/}
                    {/*    ) }*/}
                    {/*</>*/}
                    <>

                            <div className="w-full h-auto border-black flex-col space-y-1  text-white ">
                                <p className=" border-b-2 mx-2 text-black font-bold">My Brands</p>
                                <div className="grid grid-cols-10 lg:gap-x-1 xs:grid-cols-3 xs:gap-y-1 xs:gap-x-0  ">
                                    <button onClick={ChangeBrandsShows}
                                            className="w-28 h-20 rounded-2xl bg-black border-2 border-[#D81E71]">
                                        <p className="font-bold">Add Brand</p>
                                    </button>
                                    {
                                        myBrands.map(({data: {name, image}, id}, index) => (
                                            <div
                                                className="flex flex-col  overflow-hidden p-1 w-28 h-20 rounded-xl border-2 border-[#D81E71]"
                                                key={index}>
                                                <img
                                                    className="w-full h-full object-contain "
                                                    src={image} alt=""/>
                                                {/*<p className=" text-white whitespace-nowrap bottom-0 text-ellipsis  w-full text-center bg-[#D81E71]  text-sm font-bold  ">{name}</p>*/}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                    </>
                    <>

                        <div className="w-full h-auto border-black flex-col space-y-1  text-white ">
                            <p className=" border-b-2 mx-2 text-black font-bold">My Fashion Shows</p>
                            <div className="grid grid-cols-10 lg:gap-x-1 xs:grid-cols-3 xs:gap-y-1 xs:gap-x-0  ">
                                <button onClick={ChangeBrandsShows}
                                        className="w-28 h-20 rounded-2xl bg-black border-2 border-[#D81E71]">
                                    <p className="font-bold">Add Fashion Shows</p>
                                </button>
                                {
                                    myShows.map(({data: {name, image}, id}, index) => (
                                        <div
                                            className="relative  overflow-hidden w-28 h-20 rounded-xl border-2 border-[#D81E71]"
                                            key={index}>
                                            <img
                                                className="w-full absolute object-cover h-full"
                                                src={image}
                                                alt=""/>
                                            <p className="absolute bottom-0 text-white whitespace-nowrap text-ellipsis  w-full text-center bg-[#D81E71] text-sm font-bold text-red-200">{name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                </div>
            }
            {settingsModal && <SettingsModal/>}
            <StateContext.Provider value={{
                openUpload,
                setOpenUpload,
                uploadPhotoToggle,
                selectPhoto,
                openTravelToggle,
                setOpenTravelToggle,
                addTravelDetails,
                setShowModal,
                showModal,
                EditProfileModal,
                openPhoto,
                openUserPhoto,
                setOpenUserPhoto,
                photos,
                userID,
                openSettings,
                OpenSettings,
                state, VerifactionVideoToggle, femaleVerificationVideo
            }}>
                {openUserPhoto && <UserPhotos/>}
                {openUpload && <UploadPhoto/>}
                {/*{openSettings && <SettingsModal/>}*/}
                {openTravelToggle && <AddTravel/>}
                {state && <VerificationVideo/>}
            </StateContext.Provider>
        </>
    );
}
export default MyProfileContent;