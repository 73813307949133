import React, {Fragment, useContext, useEffect, useState} from 'react';
import {BsFillFilterSquareFill, BsFillEyeFill, BsChevronDown} from 'react-icons/bs';
import {Menu, Transition} from '@headlessui/react'
import {Link, useNavigate} from 'react-router-dom';
import database, {auth} from "../../Controller/Firebase/firebase";
import {BiArrowBack} from "react-icons/bi";
import countries from "../Layouts/countries.json"
import {getFunctions, httpsCallable} from "firebase/functions";
import {AuthContext} from "../../Context/AuthProvider";
import {GiQueenCrown} from "react-icons/gi";
import chatIcon from "../Images/HeaderImages/chat.png"
import favIcon from "../Images/HeaderImages/fav.png"
import premiumIcon from "../Images/HeaderImages/premium.png"
import profileIcon from "../Images/HeaderImages/profile.png"
import profileViewerIcon from "../Images/HeaderImages/profileViewer.png"
import Slider from '@mui/material/Slider';
import {createTheme, ThemeProvider} from '@mui/material/styles';

function Sidebar() {
    const functions = getFunctions();
    let navigate = useNavigate();
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const person = auth.currentUser;
    let temp = []
    let temp2 = []
    const theme = createTheme({
        components: {
            MuiSlider: {
                styleOverrides: {
                    thumb: {
                        color: '#D81E71',
                    },
                    track: {
                        color: '#D81E71',
                    },
                    rail: {
                        color: '#D81E71',
                    },
                },
            },
        },
    });
    const [country, setCountry] = useState("")
    const [travels, setTravels] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLocation, setSearchLocation] = useState([]);
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const [fashionBrands, setFashionBrands] = useState([]);
    const [fashionShows, setFashionShows] = useState([]);
    const [state, setState] = useState(false);
    const {
        loginUser,
        successfullPremium,
        setSuccessfullPremium,
        cancelPremium,
        setCancelPremium,
        searchResultUser,
        setSearchResultUser,
    } = useContext(AuthContext);
    const [state2, setState2] = useState(false);
    const [showAllBrands, setShowAllBrands] = useState(false);
    const handleCountryClick = (locationName, locationLat, locationLon) => {
        setLat(locationLat)
        setLon(locationLon)
        setState(false)
        document.getElementById("search").value = locationName
    };

    function brandsDiv() {
        setShowAllBrands(!showAllBrands)
    }

    async function goSearchResult(minAge, maxAge) {
        setSearchResultUser([])
        const inputMinAge = Number(minAge);
        const inputMaxAge = Number(maxAge);
        let searchLocation = document.getElementById("search").value

        if (lat === 0 && searchLocation.trim() !== "") {
            document.getElementById("AgeError").style.display = "flex";
            document.getElementById("AgeError").innerText = "Please select your location from the list"
        }
        if (searchLocation.trim() === "") //Sadece age search
        {
            if (inputMinAge < 18 || inputMaxAge < 18 || inputMaxAge <= inputMinAge) // Hatalı yaş
            {
                document.getElementById("AgeError").style.display = "flex";
                document.getElementById("AgeError").innerText = "Age range must be between 18-65"

            } else {
                await database.collection("searchReports").add({type: "Age", minAge, maxAge})

                navigate(`/search/search-result`, {
                    state: {
                        minAge: inputMinAge,
                        maxAge: inputMaxAge,
                        country: searchLocation,
                        lat: lat,
                        lon: lon
                    }
                });

            }
        }
        if (lat > 0 && searchLocation.trim() !== "") {
            await database.collection("searchReports").add({
                type: "Country&Age",
                location: searchLocation,
                minAge,
                maxAge
            })
            navigate(`/search/search-result`, {
                state: {
                    minAge: inputMinAge,
                    maxAge: inputMaxAge,
                    country: searchLocation,
                    lat: lat,
                    lon: lon
                }
            });
        }
    }

    const handleInputChange = async (country) => {
        setLat(0)
        let data = {loc: country}
        const requestBackEnd = await httpsCallable(functions, 'searchLocation')(data)
        setSearchLocation(requestBackEnd.data)
        if (country.trim() === "") {
            setState(false)
        } else {
            setState(true)
        }
    };
    const handleInputChangeTravel = async (country) => {
        setLat(0)
        let data = {loc: country}
        const requestBackEnd = await httpsCallable(functions, 'searchLocation')(data)
        setSearchLocation(requestBackEnd.data)
        if (country.trim() === "") {
            setState2(false)
        } else {
            setState2(true)

        }
    };
    const handleCountryClickTravel = (locationName, locationLat, locationLon) => {
        setCountry(locationName)
        setLat(locationLat)
        setLon(locationLon)
        setState2(false)
        document.getElementById("travel").value = locationName
    };


    const FashionBrands = () => {
        database.collection("FashionBrands").get().then((res) => {
            res.docs.forEach((doc) => {
                temp2.push({data: doc.data(), id: doc.id})
            })
            setFashionBrands(temp2)
        })
    }
    const FashionShows = () => {
        database.collection("FashionShows").get().then((res) => {
            res.docs.forEach((doc) => {
                temp.push({data: doc.data(), id: doc.id})
            })
            setFashionShows(temp)
        })
    }
    useEffect(() => {
        FashionBrands()
        FashionShows()
    }, [loginUser?.name]);

    function goBrands(data, id) {
        navigate(`/fashion-brands/${data["name"]}`, {state: {data}})
        window.scroll(0, 0)
    }

    function goFashionShows(data, id) {
        navigate(`/fashion-shows/${data["name"]}`, {state: {data}})
        window.scroll(0, 0)

    }

    function handleGoBack() {
        navigate(-1)
    }

    async function goTravels(data) {
        setSearchResultUser([])

        let searchTravel = document.getElementById("travel").value

        if (searchTravel.trim() === "")
            return
        if (searchTravel.trim() !== "" && lat === 0) {
            document.getElementById("travelError").innerText = "Please select your location from the list"
        } else {

            await database.collection("searchReports").add({type: "travel", location: searchTravel})
            navigate(`/search/search-result`, {state: {travels: searchTravel, lat: lat, lon: lon}});
        }
    }

    const [minAge, setMinAge] = useState(18)
    const [maxAge, setMaxAge] = useState(65)
    const [value, setValue] = useState([18, 65]);
    const handleChange = (event, newValue) => {
        setMinAge(newValue[0])
        setMaxAge(newValue[1])
        setValue(newValue);
    };


    return (
        <>
            {mediaQuery.matches ?
                <div className="flex flex-col gap-y-10 py-2 w-full h-full">
                    <div className="w-full flex justify-start items-center text-2xl px-2">
                        <BiArrowBack onClick={() => navigate(-1)}/>
                        <p className="mx-auto text-2xl font-bold text-[#D81E71]">Search</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-y-3 ">
                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white border-2  w-5/6 mx-auto border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-3 `}>
                            <div className="flex flex-col items-center justify-center       ">
                                <div className="flex  justify-between w-full ">
                                    <p>Age</p>
                                </div>

                            </div>
                            <div className="w-full flex items-center gap-x-5">
                                <span>{value[0]}</span>
                                <ThemeProvider theme={theme}>
                                    <Slider
                                        value={value}
                                        min={18}
                                        max={65}
                                        onChange={handleChange}
                                        color="secondary"
                                    />
                                </ThemeProvider>
                                {/*<Slider*/}
                                {/*    value={value}*/}
                                {/*    min={18}*/}
                                {/*    max={65}*/}
                                {/*    onChange={handleChange}*/}
                                {/*    color={"secondary"}*/}
                                {/*/>*/}
                                <span>{value[1]}</span>
                            </div>
                            <p>Location</p>
                            <input
                                type="text"
                                id="search"
                                className="  py-1 w-full rounded-md text-start px-3  text-gray-400 bg-black border border-[#D81E71] "
                                placeholder="Enter a city"
                                autoComplete="off"
                                onChange={(event) => handleInputChange(event.target.value.toLowerCase())}
                            />
                            {state && (
                                <div
                                    className="h-auto border-2 border-black rounded-md flex  w-full text-center overflow-hidden mx-auto"
                                >
                                    <div className="flex flex-col w-full">
                                        {searchLocation.map((item, index) => (
                                            <p
                                                key={index}
                                                className="border-b-2 hover:bg-gray-500 hover:text-white hover:cursor-pointer"
                                                onClick={() =>
                                                    handleCountryClick(
                                                        searchLocation[index].n, searchLocation[index].l.lat, searchLocation[index].l.lon
                                                    )
                                                }
                                            >
                                                                <span
                                                                    className="whitespace-nowrap overflow-hidden">{item.c}, {item.n}</span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div id="AgeError"
                                 className="w-full text-center text-red-600 justify-center font-bold hidden">
                                <p>Please enter valid value
                                </p>
                            </div>
                            <button onClick={() => goSearchResult(minAge, maxAge, country)}
                                    className="w-full bg-[#D81E71] text-white font-bold  border-2 border-white rounded-xl py-1">Search
                            </button>
                        </div>

                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white   w-5/6 mx-auto border-2 border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-3 `}>
                            <p>Travels</p>
                            <input
                                type="text"
                                id="travel"
                                className="  py-1 w-full rounded-md text-start px-3  text-gray-400 bg-black border border-[#D81E71] "
                                placeholder="Enter a city"
                                autoComplete="off"
                                onChange={(event) => handleInputChangeTravel(event.target.value.toLowerCase())}
                            />
                            {state2 && (
                                <div
                                    className="h-auto border-2 border-black rounded-md flex  w-full text-center overflow-hidden mx-auto">

                                    <div className="flex flex-col w-full">
                                        {searchLocation.map((item, index) => (
                                            <p
                                                key={index}
                                                className="border-b-2 hover:bg-gray-500 hover:text-white  hover:cursor-pointer"
                                                onClick={() =>
                                                    handleCountryClickTravel(
                                                        searchLocation[index].n, searchLocation[index].l.lat, searchLocation[index].l.lon
                                                    )
                                                }
                                            >
                                                                <span
                                                                    className="whitespace-nowrap">{item.c}, {item.n}</span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <span id="travelError" className="text-center text-[#D81E71] font-bold"/>

                            <button onClick={() => goTravels(country)}
                                    className="w-full bg-[#D81E71] text-white font-bold  border-2 border-white rounded-xl py-1">Search
                            </button>


                        </div>

                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white border-2    w-5/6 mx-auto border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-2 `}>
                            <p>Brands</p>
                            <div onClick={brandsDiv}
                                 className="flex w-full items-center rounded-md justify-center border py-1 border-[#D81E71] hover:cursor-pointer">
                                Choose Brands
                                <BsChevronDown className="text-white h-5 w-5 mx-2"/>
                            </div>
                            {showAllBrands &&
                                <div
                                    className="w-full flex  duration-300 flex-col h-44 overflow-hidden  overflow-y-scroll  items-center justify-start border-2 border-[#D81E71] rounded-md">
                                    {fashionBrands.map(({data: {name, image}, id}, index) => (
                                        <div key={index}
                                             onClick={() => goBrands(fashionBrands[index].data, id)}
                                             className="w-full flex hover:bg-gray-400 space-x-2 hover:cursor-pointer  ">
                                            <img src={image} alt=""
                                                 className="w-7 h-7 object-contain "/>
                                            <p className="text-md">{name}</p>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="flex flex-col gap-y-7 p-3 w-full h-screen">
                    {
                        loginUser !== null
                        &&
                        <div
                            className={`flex flex-col items-center justify-center text-[#D81E71] font-bold gap-y-4  `}>
                            <div className="w-full flex flex-col items-center justify-center gap-y-1">
                                <img onClick={() => navigate("/my-profile")} src={loginUser?.profileImage}
                                     className="w-20 h-20 border-2 border-black rounded-full object-cover hover:cursor-pointer"
                                     alt=""/>
                                <p>{loginUser?.name}</p>
                            </div>
                            <div className="flex flex-col gap-y-2 text-sm font-bold text-[#D81E71]  items-start w-1/2">
                                <Link to="/premium">
                                    <div className="flex gap-x-2 items-center hover:font-extrabold duration-75"><img
                                        src={premiumIcon}
                                        className="w-[14px] h-[14px]"
                                        alt=""/> Premium
                                    </div>
                                </Link>
                                <Link to="/viewed">
                                    <div className="flex gap-x-2 items-center hover:font-extrabold"><img
                                        src={profileViewerIcon}
                                        className="w-[14px] h-[14px]"
                                        alt=""/> Profile Viewers
                                    </div>
                                </Link>
                                <Link to="/chat">
                                    <div className="flex gap-x-2 items-center hover:font-extrabold"><img src={chatIcon}
                                                                                                         className="w-[14px] h-[14px]"
                                                                                                         alt=""/> Chats
                                    </div>
                                </Link>
                                <Link to="/favorites">
                                    <div className="flex gap-x-2 items-center hover:font-extrabold"><img src={favIcon}
                                                                                                         className="w-[14px] h-[14px]"
                                                                                                         alt=""/> Favorites
                                    </div>
                                </Link>
                                <Link to="/my-profile">
                                    <div className="flex gap-x-2 items-center hover:font-extrabold"><img
                                        src={profileIcon}
                                        className="w-[14px] h-[14px]"
                                        alt=""/> My Profile
                                    </div>
                                </Link>

                            </div>
                        </div>
                    }

                    <div className="flex flex-col items-center justify-center gap-y-3">
                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white border-2  w-3/4 mx-auto border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-3 `}>
                            <div className="flex flex-col items-center justify-center       ">
                                <div className="flex  justify-between w-full ">
                                    <p onClick={() => setCancelPremium(!cancelPremium)}>By Age</p>
                                </div>

                            </div>
                            <div className="w-full flex items-center gap-x-5">
                                <span>{value[0]}</span>
                                <ThemeProvider theme={theme}>
                                    <Slider
                                        value={value}
                                        min={18}
                                        max={65}
                                        onChange={handleChange}
                                        color="secondary"
                                    />
                                </ThemeProvider>
                                {/*<Slider*/}
                                {/*    value={value}*/}
                                {/*    min={18}*/}
                                {/*    max={65}*/}
                                {/*    onChange={handleChange}*/}
                                {/*    color={"secondary"}*/}
                                {/*/>*/}
                                <span>{value[1]}</span>
                            </div>
                            <p>By Location</p>
                            <input
                                type="text"
                                id="search"
                                className="  py-1 w-full rounded-md text-start px-3  text-gray-400 bg-black border border-[#D81E71] "
                                placeholder="Enter a city"
                                autoComplete="off"
                                onChange={(event) => handleInputChange(event.target.value.toLowerCase())}
                            />
                            {state && (
                                <div
                                    className="h-auto border-2 border-black rounded-md flex  w-44 overflow-hidden"
                                >
                                    <div className="flex flex-col w-full">
                                        {searchLocation.map((item, index) => (
                                            <p
                                                key={index}
                                                className="border-b-2 hover:bg-gray-500 hover:text-white hover:cursor-pointer"
                                                onClick={() =>
                                                    handleCountryClick(
                                                        searchLocation[index].n, searchLocation[index].l.lat, searchLocation[index].l.lon
                                                    )
                                                }
                                            >
                                                                <span
                                                                    className="whitespace-nowrap overflow-hidden">{item.c}, {item.n}</span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div id="AgeError"
                                 className="w-full text-center text-red-600 justify-center font-bold hidden">
                                <p>Please enter valid value
                                </p>
                            </div>
                            <button onClick={() => goSearchResult(minAge, maxAge, country)}
                                    className="w-full bg-[#D81E71] text-white font-bold  border-2 border-white rounded-xl py-1">Search
                            </button>
                        </div>

                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white  w-3/4 mx-auto border-2 border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-3 `}>
                            <p>By Travels</p>
                            <input
                                type="text"
                                id="travel"
                                className="  py-1 w-full rounded-md text-start px-3  text-gray-400 bg-black border border-[#D81E71] "
                                placeholder="Enter a city"
                                autoComplete="off"
                                onChange={(event) => handleInputChangeTravel(event.target.value.toLowerCase())}
                            />
                            {state2 && (
                                <div
                                    className="h-auto border-2 border-black rounded-md flex  w-44 overflow-hidden">

                                    <div className="flex flex-col w-full">
                                        {searchLocation.map((item, index) => (
                                            <p
                                                key={index}
                                                className="border-b-2 hover:bg-gray-500 hover:text-white  hover:cursor-pointer"
                                                onClick={() =>
                                                    handleCountryClickTravel(
                                                        searchLocation[index].n, searchLocation[index].l.lat, searchLocation[index].l.lon
                                                    )
                                                }
                                            >
                                                                <span
                                                                    className="whitespace-nowrap">{item.c}, {item.n}</span>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <span id="travelError" className="text-center text-[#D81E71] font-bold"/>
                            <button onClick={() => goTravels(country)}
                                    className="w-full bg-[#D81E71] text-white font-bold  border-2 border-white rounded-xl py-1">Search
                            </button>


                        </div>

                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white border-2   w-3/4 mx-auto border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-2 `}>
                            <p>By Brands</p>
                            <div onClick={brandsDiv}
                                 className="flex w-full items-center rounded-md justify-center border py-1 border-[#D81E71] hover:cursor-pointer">
                                Choose Brands
                                <BsChevronDown className="text-white h-5 w-5 mx-2"/>
                            </div>
                            {showAllBrands &&
                                <div
                                    className="w-full flex  duration-300 flex-col h-44 overflow-hidden  overflow-y-scroll  items-center justify-start border-2 border-[#D81E71] rounded-md">
                                    {fashionBrands.map(({data: {name, image}, id}, index) => (
                                        <div key={index}
                                             onClick={() => goBrands(fashionBrands[index].data, id)}
                                             className="w-full flex hover:bg-gray-400 space-x-2 hover:cursor-pointer  ">
                                            <img src={image} alt=""
                                                 className="w-7 h-7 object-contain  bg-white"/>
                                            <p className="text-md">{name}</p>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div
                            className={`flex flex-col rounded-3xl h-auto text-white border-2 text-center   w-3/4 mx-auto border-[#D81E71] bg-black p-6 font-bold text-sm  gap-y-2 `}>
                            <p>Tips</p>
                            <div
                                className="flex flex-col w-full items-center  justify-center gap-y-2  py-1 text-[#D81E71] underline  ">
                                <button onClick={() => navigate("/tipsForWoman")}>Tips for Woman</button>
                                <button onClick={() => navigate("/tipsForMan")}>Tips for Man</button>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Sidebar;