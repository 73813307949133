import React, {useContext, useState} from 'react';
import Modal from "react-modal";
import StateContext from "../../Context/StateContext";
import UserPhotos from "./UserPhotos";
import UploadPhoto from "./UploadPhoto";
import AddTravel from "./AddTravel";
import EditProfile from "./EditProfile";
import PremiumSettings from "./PremiumSettings";
import {AuthContext} from "../../Context/AuthProvider";
import {BiArrowBack} from "react-icons/bi";

function SettingsModal() {
    const context = useContext(StateContext);
    const [showModal, setShowModal] = useState(false);
    const [openPremiumSettings, setOpenPremiumSettings] = useState(false);
    const {loginUser,settingsModal, setSettingsModal} = useContext(AuthContext);

    function OpenPremiumSettings() {
        setOpenPremiumSettings(!openPremiumSettings)
    }

    function EditProfileModal() {
        setShowModal(!showModal)
    }

    return (
        <Modal
            isOpen={settingsModal}
            onRequestClose={()=>setSettingsModal(!settingsModal)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={()=>setSettingsModal(!settingsModal)}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                    <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={EditProfileModal} >Edit Profile
                    </button>
                            {loginUser.is_premium === true &&<button onClick={OpenPremiumSettings} className={`border-2 bg-[#D81E71] px-4 py-0.5 rounded-full 
                            ${loginUser?.premium_Expiry_Date !== undefined && "hidden"}
                            `}>Premium Settings</button>}
                </div>
            </div>
            <StateContext.Provider value={{
                setShowModal,
                showModal,
                EditProfileModal,
                OpenPremiumSettings,
                openPremiumSettings
            }}>
                {showModal && <EditProfile/>}
                {openPremiumSettings && <PremiumSettings/>}
            </StateContext.Provider>
        </Modal>

    );
}

export default SettingsModal;
