import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-modal";
import StateContext from "../../Context/StateContext";
import {PlusIcon} from "@heroicons/react/solid";
import {getDownloadURL, ref, uploadBytes,uploadString} from "firebase/storage";
import database, {auth, storage} from "../../Controller/Firebase/firebase";
import {AuthContext} from "../../Context/AuthProvider";
import  Resizer from 'react-image-file-resizer';
import testPhoto from "../Images/testPhoto.png"
import {serverTimestamp} from "firebase/firestore";
import TermsOfService from "./TermsOfService";
Modal.setAppElement('#root')
function UploadPhoto() {
    const [image, setImage] = useState(null)
    const{loginUser} = useContext(AuthContext);
    const [url, setUrl] = useState(null);
    const [resizedUrl, setResizedUrl] = useState('');

    const [resizedDataUrl, setResizedDataUrl] = useState(''); // Ekledik


    const {termsOfService, setTermsOfService} = useContext(AuthContext)
    const handleImageChange = async (e) => {
        const selectedFile = e.target.files[0];
        const imageUrl = URL.createObjectURL(selectedFile);
        const img = new Image();
        img.src = imageUrl;
        img.onload = async () => {
            if (img.naturalWidth > 700) {
                const resizedImageUri = await resizeImage(selectedFile, 700, img.naturalHeight);
                setUrl(imageUrl);
                setResizedUrl(resizedImageUri);
                setResizedDataUrl(resizedImageUri); // Base64 verisi direkt olarak atanıyor
            } else {
                setUrl(imageUrl);
            }
        };
    };
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                'JPEG',
                55,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });
    };

    async function uploadPhoto() {
        document.getElementById("uploadButton").disabled = true
        document.getElementById("uploadButton").innerText = "Please Wait..."
        if (resizedDataUrl) {
            const fileName = Math.random().toString(36).slice(2);

            const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}/${fileName}`); // Yükleme yolu
            await uploadString(storageRef, resizedDataUrl, 'data_url'); // Base64 verisini yükle
           await getDownloadURL(storageRef).then(async (url) => {
               await database.collection("users").doc(auth.currentUser.uid).collection("profileImages").add({
                   "url": url,
                   "createdAt": new Date(),
                   "isReview": false
               })
               await database.collection("PhotoApprovals").doc(auth.currentUser.uid).set({
                   approvalNecessary: true,
                   userID: auth.currentUser.uid,
                   name: loginUser.name,
                   gender: loginUser.gender,
                   age: loginUser.age,
                   approvalRequestTime: serverTimestamp(),
                   profileImage: loginUser.profileImage,
               })
               close();
           }).catch(error => {
           })
        }
    };

    const context = useContext(StateContext);
    function close() {
        context.uploadPhotoToggle()
        setUrl(null)
    }
    return (
        <div>
            <Modal
                isOpen={context.openUpload}
                onRequestClose={context.uploadPhotoToggle}
                className=" ">
                <div className="relative py-2 bg-gradient-to-br  ">
                    <div className="relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40 ">
                        <div className="m-auto md:w-8/12 lg:w-6/12 xl:w-6/12 h-screen  ">
                            <div className="rounded-xl bg-white shadow-xl ">
                                <div className="p-6 sm:p-16 ">
                                    <div className="flex float-right space-y-4">
                                        <button
                                            className="flex rounded-full  px-2  text-white font-bold bg-pink-500 text-lg"
                                            onClick={close}>X
                                        </button>
                                    </div>
                                    <div className="mt-16 grid space-y-4" type="file">
                                        <p className="text-2xl text-red-800 font-bold text-center text-3xl italic">Upload</p>
                                        {url === null
                                            ?
                                            <button
                                                className="mx-auto border-2 w-32 h-32 rounded-full border-black border-dashed">
                                                < label className="mx-auto text-red-800">
                                                    <label htmlFor="image_uploads[]"><PlusIcon
                                                        className="mx-auto w-10 h-10 hover:cursor-pointer"/><p

                                                        className="hover:cursor-pointer">Add Photo</p></label>

                                                    <input type="file" id="image_uploads[]" multiple accept="image/*"
                                                           className="hidden" onChange={handleImageChange}/>
                                                </label>
                                            </button>
                                            :
                                            <div className="w-full border-b">
                                                <label htmlFor="image_uploads[]"
                                                       className="flex w-44 py-0.5 justify-center hover:cursor-pointer mx-auto my-2 border-2 bg-orange-600 text-white text-center font-bold px-2 rounded-full"
                                                       onChange={handleImageChange}>Change the photo</label>
                                                <input type="file" id="image_uploads[]" accept="image/*"
                                                       className="hidden" onChange={handleImageChange}/>
                                                <img src={url} alt="" className="w-40 h-40 mx-auto object-cover"
                                                />
                                                <button id="uploadButton" onClick={uploadPhoto}
                                                        className=" mx-auto flex  my-2 py-2 px-2 border-2 rounded-md bg-green-900 text-white font-bold">Upload the
                                                    photo
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="border-b text-center my-4">
                                        <p className="text-red-800 font-bold">Select the image on your device</p>
                                        <p className="text-black font-bold">Must be JPG or PNG</p>
                                    </div>
                                    <div className="my-5 text-center text-black font-bold space-y-4">
                                        <p>Don`t upload nude, partially nude, discriminatory, unlawful, infringing,
                                            hateful, pornographic or sexually
                                            suggestive photos.</p>
                                        <p>You can not use any copyrighted images.</p>
                                        <p>We may delete any inappropriate photos or may block your account that does
                                            NOT comply with our <br/>
                                            <button onClick={()=>setTermsOfService(!termsOfService)} className="text-red-800 font-bold underline">term & services</button>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {termsOfService && <TermsOfService/>}

            </Modal>
        </div>
    );
}

export default UploadPhoto;