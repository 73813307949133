import React, {createContext, useContext, useEffect, useState} from 'react'
import {Route, Routes,Navigate} from "react-router";
import {BrowserRouter as Router, useParams, MemoryRouter, HashRouter} from "react-router-dom";
import HomePage from "../Pages/HomePage/homePage";
import Viewed from "../Pages/Viewed/Viewed";
import Shows_Brands from "../Pages/Shows&Brands/Shows_Brands";
import Favorites from "../Pages/Favorites/Favorites";
import MyProfile from "../Pages/MyProfile/MyProfile";
import AnotherProfile from "../Pages/AnotherProfile/AnotherProfile";
import PremiumPage from "../Pages/Premium/PremiumPage";
import SignUp from "../Pages/SignUp/signUp";
import FashionCitiesShow from "../Pages/FashionShows/FashionCitiesShow";
import FashionBrandsShow from "../Pages/FashionBrands/FashionBrandsShow";
import Login from "../Pages/Login/Login";
import Travels from "../Pages/Travels/Travels";
import Test from "./test";
import AllUsers from "../Pages/AllUsers/AllUsers";
import ChatPages from "../Pages/Chat/ChatPages";
import SearchFilter from "../Pages/Search&Filter/search_filter";
import Search from "../Pages/Search/Search";
import Page404 from "./Page404";
import TipsForMan from "./TipsForMan";
import TipsForWoman from "./TipsForWoman";
import TermsOfService from "../Pages/TermsOfServices/termsofservices";
import DatingSafetyTips from "../Pages/DatingSafetyTips/datingSafetyTips";
import PrivacyPolicy from "../Pages/privacyPolicy/privacyPolicy";
function RouteS() {
    const token = localStorage.getItem('token');
        return (
            <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/chat" element={token!== null ? <ChatPages /> : <Navigate to="/login" replace />} />
                    <Route path="/viewed" element={token !== null ? <Viewed /> : <Navigate to="/login" replace />} />
                    <Route path="/favorites" element={token !== null ? <Favorites /> : <Navigate to="/login" replace />} />
                    <Route path="/my-profile" element={token!==null ? <MyProfile /> : <Navigate to="/login" replace />} />
                    <Route path="/shows-brands" element={token !== null ? <Shows_Brands /> : <Navigate to="/login" replace />} />
                    <Route path="/premium" element={token !== null ? <PremiumPage /> : <Navigate to="/login" replace />} />
                    <Route path={`/user/:userID`} element={<AnotherProfile/>}/>
                    <Route path={'/fashion-shows/:name'} element={<FashionCitiesShow/>}/>
                    <Route path={'/fashion-brands/:name'} element={<FashionBrandsShow/>}/>
                    <Route path="/premium" element={<PremiumPage/>}/>
                    <Route path="/test" element={<Test/>}/>
                    <Route path="/signUp" element={<SignUp/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/travels" element={<Travels/>}/>
                    <Route path="/tipsForMan" element={<TipsForMan/>}/>
                    <Route path="/tipsForWoman" element={<TipsForWoman/>}/>
                    <Route path={'/search/travel/:name'} element={<Search/>}/>
                    <Route path={'/search/search-result'} element={<Search/>}/>
                    <Route path="/all-users" element={<AllUsers/>}/>
                    <Route path="/search_filter" element={<SearchFilter/>}/>
                    <Route path="/termsOfServices" element={<TermsOfService/>}/>
                    <Route path="/datingSafetyTips" element={<DatingSafetyTips/>}/>
                    <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<Page404/>}/>
            </Routes>
        )
}

export default RouteS;