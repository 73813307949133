// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// import App from "./App";

// import { BrowserRouter as Router } from "react-router-dom";
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <Router>
//         <AuthProvider>
//             <ChatContextProvider>
//                 <App/>
//             </ChatContextProvider>
//         </AuthProvider>
//     </Router>
//
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ChatContextProvider} from "./Context/ChatContext";
import AuthProvider from "./Context/AuthProvider";

ReactDOM.hydrate(
    <BrowserRouter>
        <AuthProvider>
            <ChatContextProvider>
                <App/>
            </ChatContextProvider>
        </AuthProvider>
    </BrowserRouter>
    , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

