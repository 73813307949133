import React from 'react';
import Header from "../../Layouts/header";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";

function PrivacyPolicy() {
    const navigate = useNavigate()
    window.scroll(0,0)

    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center  ">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex lg:hidden md:hidden items-center text-2xl text-black  p-3">
                <button onClick={()=>navigate(-1)} className=" px-2 py-1">
                    <BiArrowBack className=""  />
                </button>
            </div>
            <div className="my-2 flex justify-center w-full h-full ">
                <div className="flex flex-col w-4/5 h-full gap-y-3 ">
                    <p className="text-2xl font-bold text-[#D81E71] mx-auto">Privacy Policy</p>
                    <p>By entering
                        and/or registering on the Site you give your consent to and accept
                        the following conditions :</p>
                    <p>
                    </p>
                    <p><b>1.
                        Age restrictions</b></p>
                    <p>1.1 You must be
                        eighteen or over to enter and register as a member to use this Site.
                        Children under eighteen years of age are prohibited from using our
                        Site or any of our services. We ask that children would not submit
                        information to us. Membership in the service is void where
                        prohibited.</p>
                    <p>
                    </p>
                    <p>1.2 By using
                        and/or viewing this Site you represent and warrant under penalty of
                        perjury the following:
                    </p>
                    <p>
                    </p>
                    <p>1.2.1 That you
                        have the right, authority, and capacity to enter into this agreement
                        and to abide by all of the terms and conditions of this agreement and
                        that you are at least 18-years old.</p>
                    <p>
                    </p>
                    <p>1.2.2 That you
                        will not permit any person(s) under the age of majority to have
                        access to any of the materials contained within this website.
                    </p>
                    <p>
                    </p>
                    <p>1.2.3 That you
                        represent and warrant that you have not and will not use and/or view
                        the Site(s) in a restricted location - namely a place, country, or
                        location in which doing so would, or could be deemed a violation of
                        any law, regulation, rule, ordinance, edict or custom.</p>
                    <p>
                    </p>
                    <p><b>2. Information
                        we collect</b></p>
                    <p>
                    </p>
                    <p>2.1. During the
                        registration process for this Site, you must provide us with certain
                        personally identifiable information including, but not limited to,
                        your name, address, email address, phone number and credit card and
                        bank information . This information is used to contact the user about
                        the services on our site for which they have expressed interest.
                    </p>
                    <p>
                    </p>
                    <p>2.1.1. During
                        registration we will give you opportunity to provide us with
                        additional information, such as your interests, hobbies, occupation
                        and other.</p>
                    <p>
                    </p>
                    <p>2.1.2. In some
                        cases we may ask for additional information for security reasons or
                        to provide specific services to you.</p>
                    <p>
                    </p>
                    <p>2.1.3. Once you
                        register you can visit your profile at any time to add or remove
                        supplementary personal information about yourself. You can add basic
                        information about yourself, such as information about your hometown,
                        family, relationships or other specific views. You can also add other
                        information about yourself including your activities, interests,
                        contact information, as well as more information about your education
                        and occupation.</p>
                    <p>
                    </p>
                    <p>2.1.4. If a
                        user's personal information changes (such as your zip code), or if a
                        user no longer desires our service, we will endeavor to provide a way
                        to correct, update or remove that user's personal data provided to
                        us. This can usually be done at the member information page or by
                        contacting our Website administrators.</p>
                    <p>
                    </p>
                    <p>2.1.5. We reserve
                        the right to change certain settings of your account concerning news
                        subscription, banners, product offerings and other means of
                        fulfillment of this Site's content and services in certain
                        circumstances, related to marketing and promotional activities.</p>
                    <p>
                    </p>
                    <p>2.2. We may
                        retain the details of transactions or payments you make on this Site.
                        However, we will only keep your payment source account number with
                        your consent .</p>
                    <p>
                    </p>
                    <p>2.3. Cookie
                        information. Cookies are small pieces of data that are stored by a
                        user's web browser on the user's hard drive for a period of time.
                        Cookies may record information a user accesses on one webpage to
                        simplify subsequent interactions with that website by the same user,
                        or to use the information to streamline the user's transactions on
                        related webpages.</p>
                    <p>
                    </p>
                    <p>2.3.1. Cookies
                        make it easier for a user to move from webpage to webpage and to
                        complete transactions over the Internet.</p>
                    <p>
                    </p>
                    <p>2.3.2. We use
                        &quot;cookies&quot; to make this Site easier for you to use, to make
                        our advertising better, and to protect both you and this Site.</p>
                    <p>
                    </p>
                    <p>2.3.3. This Site
                        also uses &quot;cookies&quot; to store and sometimes track
                        information to make your online experience easier and more
                        personalized.</p>
                    <p>
                    </p>
                    <p>2.3.4. Most major
                        web browsers are set up so that they will initially accept cookies,
                        but you may modify your computer's preferences to issue you an alert
                        when a cookie is downloaded, or to disable the ability of third
                        parties to download a cookie to you. If you choose to reject all
                        cookies, there might be areas of this Site that may not function
                        properly.</p>
                    <p>
                    </p>
                    <p>2.4. We reserve
                        the right to use your IP address to determine and publicly share your
                        location.</p>
                    <p>
                    </p>
                    <p>2.5. If You send
                        us personal correspondence, such as emails or letters, or if other
                        users this Site or third parties send us correspondence about your
                        activities or postings on this Site, we have a right to collect such
                        information into a file that is accessed only by authorized our
                        personnel or representatives. We may also collect comments about you
                        made by other users of the Site.</p>
                    <p>
                    </p>
                    <p>2.6. There might
                        be a function in this Site, under which you may choose to use our
                        referral service to tell a friend or friends about us. In this case,
                        we will ask you for your friend's name and email address and email
                        your friend an invitation to him or her to visit the Site. We
                        maintain this information for the sole purpose of sending email and
                        tracking the status of the referral. Your friend or friends may
                        contact us to request that we remove their email address from our
                        records.</p>
                    <p>
                    </p>
                    <p>2.7. This Site
                        may contain links to other sites. You shall be aware that we are not
                        responsible for the privacy practices of such other sites. We
                        encourage our users to be aware when they leave our site and to read
                        the privacy statements of each and every web site that collects
                        personally identifiable information. This privacy statement applies
                        solely to information collected by this Web site.</p>
                    <p>
                    </p>
                    <p><b>3.
                        How we use collected information.</b></p>
                    <p>
                    </p>
                    <p>3.1. We use the
                        information we collect to try to provide a safe, efficient, and
                        customized experience. The main aim of use of collected information
                        is to provide our services and features to you, to measure and
                        improve those services and features, and to provide you with customer
                        support.
                    </p>
                    <p>
                    </p>
                    <p>3.2. We use
                        collected information internally to improve our marketing efforts,
                        analyze the Site usage, improve our content and product offerings and
                        customize the content of the Site and layout. These uses allows us to
                        improve this Site and better tailor your online experience.
                    </p>
                    <p>
                    </p>
                    <p>3.3. We may use
                        collected information to deliver information that, in some cases, is
                        targeted to your interests, such as banners and promotions.
                    </p>
                    <p>
                    </p>
                    <p>3.4. We may also
                        send you periodic informational updates via email, provided telephone
                        numbers and/or all other contact sources.
                    </p>
                    <p>
                    </p>
                    <p>3.5. We may use
                        collected information to resolve disputes, troubleshoot problems and
                        enforce any agreements, policies and rules governing the use of this
                        Site.</p>
                    <p>
                    </p>
                    <p><b>4.
                        Disclosure of collected information</b></p>
                    <p>
                    </p>
                    <p>4.1. We DO NOT
                        sell or rent any of your personal information to third parties for
                        marketing purposes without your permission. However, we do provide
                        collected information to our service providers (e.g., credit card
                        processing companies, billing services) as reasonably necessary or
                        appropriate to provide services on the Site.</p>
                    <p>
                    </p>
                    <p>4.2. We do
                        disclose information in the aggregate to advertisers and for other
                        marketing and promotional purposes. However, in these situations, we
                        DO NOT disclose information in any form that could be used to
                        identify you personally such as password of your account of the Site,
                        credit card number and bank account number.</p>
                    <p>
                    </p>
                    <p>4.3. In response
                        to compulsory (required) governmental and/or third party inquiries,
                        we may disclose your personal information to comply with a court
                        order, subpoena, and search warrant.</p>
                    <p>
                    </p>
                    <p>4.4. We expressly
                        reserve the right to disclose your personal information when we have
                        a good faith belief that disclosure is necessary to protect our
                        rights, or to enforce our agreements, policies, and rules governing
                        your use of the Site, or to cooperate voluntarily with law
                        enforcement inquiries.</p>
                    <p>
                    </p>
                    <p>4.5. If the
                        ownership of all or substantially all of our business changes, we may
                        transfer your information to the new owner so that the service can
                        continue to operate. In such a case, your information would remain
                        subject to the promises made in any pre-existing Privacy Policy.</p>
                    <p>
                    </p>
                    <p><b>5.
                        Your use of members' information</b></p>
                    <p>
                    </p>
                    <p>5.1. You may not
                        use another members' information, whether private or not, for
                        commercial purposes, to spam, to harass, or to make unlawful threats.
                        We reserve the right to terminate those who misuse other members'
                        information or who otherwise violate any user agreements, policies,
                        or rules governing the use of the Site.</p>
                    <p>
                    </p>
                    <p><b>6.
                        Member disputes.</b></p>
                    <p>
                    </p>
                    <p>6.1. You are
                        solely responsible for your interactions with other members. Because
                        we are not involved in member interactions, in the event that you
                        have a dispute with one or more members or those who have posted,
                        viewed, or used information on this Site, you release us, including
                        our officers, directors, agents, subsidiaries and employees, from
                        claims, demands and damages (actual and consequential) of every kind
                        and nature, known and unknown, suspected and unsuspected, disclosed
                        and undisclosed, arising out of or in any way connected with such
                        disputes.</p>
                    <p>
                    </p>
                    <p><b>7.
                        Security</b></p>
                    <p>
                    </p>
                    <p>7.1. This Site
                        use reasonable security measures to help protect and prevent the
                        loss, misuse, and alteration of the information under our control.</p>
                    <p>
                    </p>
                    <p><b>8.
                        Disclaimers</b></p>
                    <p>
                    </p>
                    <p>8.1. We and our
                        officers, directors, agents, subsidiaries and employees are not
                        responsible for any incorrect or inaccurate content posted on the
                        Site, whether caused by users, members or by any of the equipment or
                        programming associated with or utilized in the service, nor for the
                        conduct of any user and/or member whether online or offline.</p>
                    <p>
                    </p>
                    <p>8.2. We assume no
                        responsibility for any error, omission, interruption, deletion,
                        defect, delay in operation or transmission, communications line
                        failure, theft or destruction or unauthorized access to, or
                        alteration of, user and/or member communications.</p>
                    <p>
                    </p>
                    <p>8.3. We are not
                        responsible for any problems or technical malfunction of any
                        telephone network or lines, computer on-line-systems, servers or
                        providers, computer equipment, software, failure of email or players
                        on account of technical problems or traffic congestion on the
                        Internet or at any website or combination thereof, including injury
                        or damage to users and/or members or to any other person's computer
                        related to or resulting from participating or downloading materials
                        in connection with the site and/or in connection with the service.</p>
                    <p>
                    </p>
                    <p>8.4. Under no
                        circumstances we will be responsible for any loss or damage resulting
                        from anyone's use of the Site or the service and/or any content
                        posted on the site or transmitted to members.</p>
                    <p>
                    </p>
                    <p><b>9.
                        Limitation on liability</b></p>
                    <p>
                    </p>
                    <p>9.1. Except in
                        jurisdictions where such provisions are restricted (and in that event
                        liability is disclaimed to the fullest extent permitted by law), in
                        no event we shall be liable to you or any third person for any
                        indirect, consequential, exemplary, incidental, special or punitive
                        damages, including also lost profits arising from your use of the
                        Site.</p>
                    <p>
                    </p>
                    <p>
                        <b>10. Reservations to this
                            Privacy Policy</b></p>
                    <p>
                    </p>
                    <p>10.1. The Site
                        reserves its right, in their sole and absolute discretion, to revise,
                        amend, modify or revoke this Privacy Policy at any time and in any
                        manner to the fullest extent permitted by law. Changes to this
                        Privacy Policy will be effective by posting revisions on the Site's
                        Privacy Policy page.</p>
                    <p>
                    </p>
                    <p>10.2. Any changes
                        posted on this page will be effective from the date of posting.</p>
                    <p>
                    </p>
                    <p>10.3. It is your
                        responsibility to check Privacy Policy and terms of use of the Site.</p>
                </div>

            </div>
        </div>

    );
}

export default PrivacyPolicy;