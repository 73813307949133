import React, {useContext} from 'react';
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import database, {auth} from "../../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import ReportUser from "./ReportUser";

function Block() {
    const context = useContext(StateContext);
    let navigate = useNavigate();
    async function block() {
        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
        ).then();
        await database.collection("users").doc(auth.currentUser.uid).collection("blockList").doc(context.userID).set({
            "blockTime": new Date(),
            "otherID": context.userID,
            "blockerID": auth.currentUser.uid
        }).then(
            await database.collection("users").doc(context.userID).collection("blockList").doc(auth.currentUser.uid).set({
                "blockTime": new Date(),
                "otherID": context.userID,
                "blockerID": auth.currentUser.uid
            })
        ).then(
            document.getElementById("buttons").style.display = "none",
        document.getElementById("complate").innerText = "Blocking done",
            await delay(1000),
            context.blockToggle,
            navigate("/", {replace: true})
        )
    }
    return (
        <Modal
            isOpen={context.block}
            onRequestClose={context.blockToggle}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={context.blockToggle}> <BiArrowBack/>
                    </button>
                </div>

                <div className="bg-black flex flex-col w-full h-full    items-center justify-center gap-y-5  text-white">
                    <p className=" text-center text-xl font-bold text-white">This user will be blocked. <br/> Are you sure?</p>
                   <div id="buttons" className="flex gap-x-5 w-full items-center justify-center">
                       <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={context.blockToggle}>Cancel</button>
                       <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={block} >Block</button>
                   </div>
                                                <span className="flex justify-center">
                                        <span className="text-xl text-green-500 animate-bounce font-bold text-center italic" id="complate"/>
                                   </span>
                </div>

            </div>

        </Modal>
        // <div>
        //     <Modal
        //         isOpen={context.block}
        //         onRequestClose={context.blockToggle}
        //         className="w-screen">
        //         <div className="relative py-16 bg-gradient-to-br  ">
        //             <div className="relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40">
        //                 <div className="m-auto md:w-8/12 lg:w-6/12 xl:w-6/12 ">
        //                     <div className="rounded-xl bg-white shadow-xl">
        //                         <div className="p-6 sm:p-16">
        //                             <div className="flex float-right space-y-4">
        //                                 <button
        //                                     className="flex rounded-full  px-2  text-white font-bold bg-red-600 text-lg"
        //                                     onClick={context.blockToggle}>Close
        //                                 </button>
        //                             </div>
        //                             <div className="mt-16 grid space-y-4">
        //                                 <p className="text-2xl text-red-800 font-bold text-center text-3xl italic">Block</p>
        //                                 <p className="text-center text-xl font-bold text-black">This user will be
        //                                     blocked. <br/> Are you sure?</p>
        //                             </div>
        //                             <div className="mt-5 grid space-y-4 hidden" id="blockSuccessful">
        //                                 <p className="text-2xl text-green-700 font-bold text-center text-2xl italic">The
        //                                     operation is successful, this user will no longer appear</p>
        //                             </div>
        //                             <div className="flex justify-center my-10 space-x-10">
        //                                 <button onClick={context.blockToggle}
        //                                         className="px-2 py-1 bg-gray-600 text-white font-bold rounded-md">Cancel
        //                                 </button>
        //                                 <button className="px-2 py-1 bg-red-600 text-white font-bold rounded-md"
        //                                         onClick={block}>Block
        //                                 </button>
        //                             </div>

        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </Modal>
        // </div>
    );
}
export default Block;