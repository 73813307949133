import React, {useContext, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {AuthContext} from "../../Context/AuthProvider";
import {BsFillCameraVideoFill} from "react-icons/bs";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import database, {auth, storage} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {BiArrowBack} from "react-icons/bi";


function VerificationVideoQuery({value}) {
    let {showVerificationVideoQuery,setShowVerificationVideoQuery,downMobilMenu,loginUser,
        signUpVerificationVideoSaved, setSignUpVerificationVideoSaved,setDownMobilMenu,setVideoWaitingForApproval,signUpVerificationVideoUrl,blobVideo, setBlobVideo, setSignUpVerificationVideoUrl} = useContext(AuthContext);
    const [stepOne, setStepOne] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [permission, setPermission] = useState(null);
    const [stream, setStream] = useState(null);
    const fileName = Math.random().toString(36).slice(2);

    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);
    const [counter, setCounter] = useState(0);
    const [recording, setRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null); // En son kaydedilen Blob
    const [videoBlobUrl, setVideoBlobUrl] = useState(null)
    const [maxRecordingTime, setMaxRecordingTime] = useState(0);
    const MAX_RECORDING_DURATION = 10;// Maksimum kayıt süresi (saniye)
    const videoRef = useRef({});
    const [retakeVideo, setRetakeVideo] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const counterInterval = useRef(null);
    let verificationVideoFilePath;

    async function TakePermission() {
        try {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            setStream(null)
            const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            videoRef.current.srcObject = newStream;
            setStream(newStream);
            setPermission(true);
        } catch (err) {
            setPermission(false);
            setError(!error)
            setErrorText(err)
        }
    }
    async function RetakeVideo() {
        setVideoBlobUrl(null)
        setRetakeVideo(false)
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null)
        const newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        videoRef.current.srcObject = newStream;
        setStream(newStream);
        setPermission(true);
    }

    const [startSecondState, setStartSecondState] = useState(false);

    function startRecording() {
        setCounter(0)
        setSignUpVerificationVideoUrl(null)
        recordedChunksRef.current = [];
        countdown()
        mediaRecorderRef.current = new MediaRecorder(stream);
        // mediaRecorderRef.current.ondataavailable = handleDataAvailable;
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);

        mediaRecorderRef.current.onstop = handleRecordingStop;
        mediaRecorderRef.current.start();
        setRecording(true);
    }

    function stopRecording() {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setRecording(false);
        setStream(null); // Stream değerini null yap
    }
    useEffect(() => {
        if (videoRef.current) {
            if (videoBlobUrl) {
                videoRef.current.src = videoBlobUrl;
            } else {
                videoRef.current.srcObject = stream;
            }
        }

    }, [stream, videoBlobUrl,retakeVideo,videoRef.current]);

    const close = async () => {
        setShowVerificationVideoQuery(!showVerificationVideoQuery)
        setDownMobilMenu(!downMobilMenu)
        await database.collection("users").doc(auth.currentUser.uid).update({
            VerificationVideoModal: {
                views: loginUser?.VerificationVideoModal?.views + 1,
                viewsDate: serverTimestamp()
            }
        })
    };
    async function handleDataAvailable(event) {
        if (event.data.size > 0) {
            recordedChunksRef.current.push(event.data);
        }
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    function countdown() {
        // Aralık fonksiyonunu kullanarak her saniye geriye say
        const interval = setInterval(() => {
            setCounter(prevState => prevState+1);
            if (counter >= 3) {
                clearInterval(interval); // Geri sayım tamamlandı, aralığı temizle
            }
        }, 1000);
    }
    async function handleRecordingStop() {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        if (mediaRecorderRef.current.state === 'inactive') {
            const blob = await new Blob(recordedChunksRef.current, { type: 'video/mp4' });
            const videoUrl = URL.createObjectURL(blob);
            setBlobVideo(blob);
            setVideoBlobUrl(videoUrl);
            setSignUpVerificationVideoUrl(videoUrl)
            setRetakeVideo(true)
        }
    }
    function StepOne(){
        setStepOne(!stepOne)
        setDownMobilMenu(!downMobilMenu)
    }
    function CloseErrorModal() {
        setShowVerificationVideoQuery(!showVerificationVideoQuery)
        setDownMobilMenu(!downMobilMenu)
    }
    async function saveVideo() {

        if (videoBlobUrl !== null) {
            document.getElementById("waiting").style.display = "flex"
            document.getElementById("saveButton").style.display = "none"
            document.getElementById("retakeButton").style.display = "none"

            if(value === undefined)
            {
                verificationVideoFilePath = ref(storage, `verificationVideo/${auth.currentUser.uid}/${fileName}`)
                await uploadBytes(verificationVideoFilePath, blobVideo).then(async () => {
                    await getDownloadURL(verificationVideoFilePath).then(async (url) => {
                        await database.collection("VerificationVideo").doc(auth.currentUser.uid).set({
                            url: url,
                            createdAt: serverTimestamp(),
                            isReview: false,
                            deleted:false,
                        })
                    })
                }).then(async () => {
                    document.getElementById("waiting").innerText = "Successful..."
                    setVideoWaitingForApproval(true)
                    await delay(1000)
                    close()
                }).catch(async () => {
                    document.getElementById("waiting").innerText = "Please try again later\n"
                    document.getElementById("waiting").style.color = "red"
                    await delay(1000)
                    close()
                })
            }
            else {
                document.getElementById("waiting").innerText = "Successful..."
                setVideoWaitingForApproval(true)
                setSignUpVerificationVideoSaved(true)
                await delay(1000)
                close()
            }
        }
    }
    return (
        <Modal
            isOpen={showVerificationVideoQuery}
            onRequestClose={close}
            className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="w-1/4   h-1/2 flex flex-col xs:w-11/12 shadow-2xl xs:h-1/2  bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="flex flex-col h-full overflow-hidden bg-black ">
                    <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                        <button className=" text-white text-xl px-2 rounded-full"
                                onClick={() => setShowVerificationVideoQuery(!showVerificationVideoQuery)}><BiArrowBack/>
                        </button>

                    </div>
                    <div className="flex flex-col gap-y-10 w-full h-full items-center justify-center text-white">
                        <div className="flex flex-col gap-y-4 justify-center text-center w-full font-bold p-2">
                            <p className="text-[#D81E71] text-2xl animate-pulse">Verification Video Needed</p>
                            <p>We noticed that you haven't uploaded your verification video yet. To unlock additional features and benefits, we kindly request that you record and upload your verification video.
                            </p>
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <button  onClick={StepOne} className=" bg-[#D81E71] font-bold text-white text-lg px-5 py-0.5 rounded-full">Next</button>
                        </div>
                    </div>
                </div>
            </div>
            {stepOne ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/4 h-1/2 xs:w-11/12 xs:h-5/6 mx-auto bg-black rounded-2xl overflow-hidden justify-center space-y-1">
                            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                <button className=" text-white text-xl px-2 rounded-full"
                                        onClick={StepOne}><BiArrowBack/>
                                </button>
                            </div>
                            <div className=" w-full flex flex-col text-center gap-y-5 h-full overflow-hidden text-white font-bold items-center justify-center p-2">
                                <p className="text-[#D81E71] text-xl ">Verification Video Guidelines </p>
                                <p>Ensure your verification video follows our content guidelines. Avoid nudity, discrimination, unlawful activities, infringement, hate speech, or explicit material.
                                    We prioritize a safe and respectful environment. Violating videos may be removed, and repeated violations could result in account restrictions.
                                    Thank you for your cooperation.</p>
                                <div className="w-full flex items-center justify-center">
                                    <button  onClick={TakePermission} className=" bg-[#D81E71] font-bold text-white text-lg px-5 py-0.5 rounded-full">Next</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : <div></div>}
            <>
                {error !== true
                }
            </>
            {permission === true ? (
                    <>
                        <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="flex flex-col w-1/4 h-3/4 xs:w-full xs:h-5/6 bg-black rounded-2xl overflow-hidden justify-center space-y-1">
                                <div className="w-full flex justify-start p-1 bg-[#D81E71]">
                                    <button
                                        className="text-white text-xl px-2 rounded-full"
                                        onClick={() => {
                                            if (stream) {
                                                stream.getTracks().forEach(track => track.stop());
                                                close();
                                            }
                                            setPermission(false);
                                        }}
                                    >
                                        <BiArrowBack />
                                    </button>
                                </div>
                                <div className="w-full flex flex-col text-center gap-y-10 h-full overflow-hidden text-white font-bold items-center justify-center p-2">
                                    {videoBlobUrl === null ?
                                        <video ref={videoRef} autoPlay playsInline muted className="w-full h-[80%] "/>
                                        :

                                        <video src={videoBlobUrl} autoPlay loop playsInline  controls className="w-full h-60 "/>
                                    }
                                    {!recording ? (
                                            <>
                                                {retakeVideo === true ?
                                                    <div className="flex flex-col gap-y-10 text-white">
                                                        <p id="waiting" className=" hidden text-green-600 font-bold animate-pulse">Please Waiting</p>
                                                        <button id="saveButton" className="flex justify-center bg-green-600 text-white font-bold px-3 py-2 rounded-xl" onClick={saveVideo}>Save</button>
                                                        <button id="retakeButton" className={`flex bg-gray-600 justify-center text-white font-bold px-3 py-2 rounded-xl`} onClick={()=>RetakeVideo()}>Retake</button>
                                                    </div>
                                                    :
                                                    <button className={`px-10 bg-green-600 text-white rounded-2xl py-1${!stream && "hidden"}`} onClick={()=>startRecording()}>
                                                        Start Record
                                                    </button>
                                                }
                                            </>
                                        )
                                        : (
                                            <div className="flex flex-col gap-y-3">
                                                {/*<button className="px-10 bg-red-600 text-white rounded-2xl py-1" onClick={stopRecording}>*/}
                                                {/*    Stop Record*/}
                                                {/*</button>*/}
                                                <p> Recording : <span className="text-red-600 animate-pulse">{counter} </span> | 10
                                                </p>
                                                {counter === 10 && stopRecording()}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                )
                :
                <div>
                </div>
            }
            {error ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className=" flex flex-col w-1/4 h-1/2 xs:w-11/12 xs:h-5/6 mx-auto bg-black rounded-2xl overflow-hidden justify-center space-y-1">
                            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                <button className=" text-white text-xl px-2 rounded-full"
                                        onClick={CloseErrorModal}><BiArrowBack/>
                                </button>
                            </div>
                            <div className=" w-full flex flex-col text-center gap-y-5 h-full overflow-hidden text-white font-bold items-center justify-center p-2">
                                <span className=" text-[#D81E71] text-xl "> Please enable camera and microphone access in your browser settings.</span>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : <div></div>}
        </Modal>
    );
}
export default VerificationVideoQuery;