import React, {useEffect,useState} from 'react';
import Sidebar from "../../Layouts/Sidebar";
import Footer from "../../Layouts/footer";
import {BsFillStarFill} from "react-icons/bs";
import Header from "../../Layouts/header";
import {GiCommercialAirplane} from 'react-icons/gi';

import ShowAllTravels from "../../Layouts/ShowAllTravels";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";

const Travels = ()=> {
    let navigate = useNavigate();

    return (
        <div className="lg:w-full xs:w-full mx-auto lg:h-auto md:justify-center  ">
            <div className="w-full flex justify-start items-center text-2xl p-4 lg:hidden md:hidden">
                <BiArrowBack onClick={()=>navigate(-1)}/>
            </div>
            <div className="xs:hidden">
                <Header/>
            </div>
            <button
                className="inline-flex w-full h-16 text-xl text-blue-600 justify-center items-center space-x-3 my-4 font-bold ">
                <GiCommercialAirplane className="w-7 h-7 "/>
                <p>All Travels</p>
            </button>
            <div className="flex justify-end lg:w-full  mx-auto  my-4  xs:space-x-0">
                <div className="flex lg:w-1/4 md:hidden xs:hidden ">
                    <Sidebar/>
                </div>
                <div className="  lg:overflow-hidden lg:w-full md:w-full xs:w-full  ">
                    <ShowAllTravels/>
                </div>
            </div>
        </div>
    );
}

export default Travels;