import React from 'react';
import Header from "./header";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";
import tips1 from  "../Images/photo1.jpg"
import tips3 from  "../Images/photo3.jpg"
import tips4 from  "../Images/photo4.jpg"
import tips5 from  "../Images/photo5.jpg"

function TipsForWoman() {

    const navigate = useNavigate()
    return (
        <div className="lg:w-full mx-auto h-full lg:h-screen md:justify-center  text-white overflow-hidden overflow-y-scroll">
            <div className="xs:hidden"><Header/></div>
            <div
                className="flex w-full  items-center text-2xl text-black justify-between lg:hidden  md:hidden p-2  ">
                <BiArrowBack onClick={()=>navigate(-1)} />
            </div>
            <div className="flex flex-row w-full text-black">
                <div className="flex flex-col  w-2/3 font-semibold px-16 py-5 gap-y-3">
                     <h1 className="text-lg font-bold">When Meeting Wealthy Men: 5 Golden Rules to Keep in
                         Mind</h1>
                     <h2>
                         Every encounter brings the thrill of meeting new people, often mixed with a
                         touch of nervousness. Especially when it comes to dating wealthy men, a
                         plethora of questions and uncertainties can cloud our minds. Successfully
                         navigating this exciting experience and leaving an unforgettable impression
                         requires paying attention to essential tips. In this post, we will share five golden
                         rules to keep in mind when dating wealthy men. From your image and
                         communication skills to your self-assurance and grace, these insights encompass
                         every aspect that will help your date progress smoothly and leave a lasting
                         impact. So, if you're ready, let's equip ourselves with this knowledge and step
                         into the captivating world of affluence.
                     </h2>
                     <h1 className="text-lg font-bold"> Image is Everything: Creating an Unforgettable First Impression
                     </h1>
                     <h2>
                         When getting ready for your luxury date with wealthy men, the significance of
                         your first impression and image cannot be underestimated. Remember, those
                         initial few minutes can leave a powerful impact on the other person and set the
                         tone for the rest of the relationship. From your clothing choices to your body
                         language, it's crucial to represent yourself in the best possible way. Elegance,
                         sophistication, and self-assurance are the keys to success when dating a wealthy
                         man. However, don't hesitate to stay true to yourself and embrace your unique
                         personality. Ensure that the image you portray truly reflects the authentic you, as
                         sincerity always enhances attractiveness. Get ready to take that first step and
                         capture the attention of affluent men with an unforgettable first impression on
                         your luxury date!
                     </h2>
                     <h1 className=" text-lg font-bold">Confidence: An Irresistible Source of Charm</h1>
                     <h2>
                         When dating wealthy men, confidence becomes the cornerstone of an irresistible
                         charm. Self-assurance sends a powerful message to those around you, reflecting
                         your belief in yourself. Confidence not only helps you exude an attractive
                         demeanor and make an impression on others but also draws individuals who dare
                         to venture into a potential relationship with you. Feeling valuable and significant
                         also impacts your interactions with others. Don't forget to showcase your inner
                         strength through body language: stand tall, make eye contact, and don't forget
                         to smile. By doing so, when on dates with wealthy men, exude an impressive
                         level of self-confidence, never forgetting your worth, and be ready to enchant the
                         other person.
                     </h2>

                     <h1 className="text-lg font-bold">Be Knowledgeable: The Art of Building Connections Through Common
                         Interests</h1>
                     <h2>
                         When seeking love with wealthy men, it's essential to stand out not only through
                         your appearance and confidence but also as an intelligent and knowledgeable
                         individual. Being well-informed about shared interests and topics allows you to
                         forge deep connections during your conversations. By conducting prior research
                         and staying updated on current subjects, you'll capture the other person's
                         interest and encourage more meaningful interactions. However, remember that
                         sharing genuine interests holds much greater value than resorting to lies or
                         pretense. Additionally, being open to learning new things enhances the respect
                         the other person will have for you. As a knowledgeable and engaging individual,
                         you'll master the art of building connections with wealthy men, leaving an
                         unforgettable impression in their minds while seeking love.
                     </h2>

                     <h1 className="text-lg font-bold">The Art of Utilizing Generosity: Making Polite Requests</h1>
                     <h2>
                         When dating wealthy men, using generosity wisely and gracefully can offer
                         significant advantages. However, an important point to remember is to avoid
                         exploiting generosity for personal gain. Understanding the other person's
                         boundaries and comfort level before making any requests is crucial. Displaying a
                         generous attitude also reinforces your independence and ability to be self-
                         sufficient. Making requests with grace and respect contributes to building a
                         balanced and solid foundation for the relationship. Just like in any relationship,
                         sincerity and honesty always take precedence in matters of generosity and
                         requests. Preserve your elegance when expressing yourself, and learn to
                         appreciate every moment. By skillfully mastering the art of employing generosity,
                         you can establish a strong foundation in your relationship with wealthy men.
                     </h2>

                     <h1 className="text-lg font-bold">Never Forget Your Worth: Be an Independent and Free Individual</h1>
                     <h2>
                         When dating wealthy men, never forgetting your worth and being an independent
                         individual are of vital importance for a healthy and successful relationship.
                         Focusing on your own goals and passions independently of the other person
                         grants you strength and self-assurance. Stepping into a relationship while
                         safeguarding your identity and priorities attracts respect and interest from those
                         who wish to be with you. Recognizing your own value enables the other person to
                         respect and appreciate you even more. Additionally, taking on a balanced role as
                         an independent and free individual opens the doors to a contented partnership.
                         Remember, a relationship should complement your life, but it shouldn't be your
                         sole focus. Love and value yourself, learn to be happy independently, and as a
                         result, you'll establish a strong foundation in your relationship with wealthy men
                         while retaining control of your life!
                     </h2>
                </div>
                <div className="flex flex-col  gap-y-12 w-1/3 justify-center items-center py-6">
                    <img src={tips1} className="w-80 h-80 object-cover rounded-3xl -rotate-12" alt=""/>
                    <img src={tips3} className="w-80 h-80 object-cover rounded-3xl rotate-12" alt=""/>
                    <img src={tips4} className="w-80 h-80 object-cover rounded-3xl -rotate-12 " alt=""/>
                    <img src={tips5} className="w-80 h-80 object-cover rounded-3xl rotate-12" alt=""/>
                </div>
            </div>


        </div>
    );
}

export default TipsForWoman;