import React, {useEffect, useContext} from 'react';
import Header from "../../Layouts/header";
import ChatHome from "../../Layouts/ChatHome";
import database, {auth, getTokens} from "../../../Controller/Firebase/firebase";
import {ChatContext} from "../../../Context/ChatContext";
import {AuthContext} from "../../../Context/AuthProvider";

function hideAddressBar() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return;
    }

    if (!window.location.hash) {
        window.scrollTo(0, 1);
        setTimeout(function () {
            if (!window.pageYOffset) {
                window.scrollTo(0, 1);
            }
        }, 0);
    }
}

function ChatPages() {
    const {data, chatPermission} = useContext(ChatContext);
    const {loginUser, requestDate, RequestShift4} = useContext(AuthContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const nowDate = new Date()
    useEffect(() => {
        window.scrollTo(0, 0);
        if (loginUser !== null) {

            if (loginUser.is_premium === true && requestDate !== null) {
                const diffInMs = nowDate.getTime() - requestDate.getTime();
                const diffInHours = diffInMs / (1000 * 60 * 60);
                if (diffInHours >= 5) {
                    RequestShift4(loginUser.subscribeId)
                } else {
                }
            }
        }
        if (mediaQuery.matches) {
            if (data.chat !== "null") {
                setTimeout(() => {
                    const chatSidebar = document.getElementById("ChatSidebar");
                    const chat = document.getElementById("Chat");
                    if (chatSidebar && chat) {
                        chatSidebar.style.display = "none";
                        chat.style.display = "flex";
                    }
                }, 100);
                hideAddressBar();
            }
        }
        return () => {
            data.chat = "null"
        }
    }, [loginUser]);

    return (
        <div className="w-full h-screen xs:space-y-0  flex flex-col overflow-hidden ">
            <div className="w-full mx-auto xs:hidden ">
                <Header/>
            </div>
            <div className="flex flex-col w-full h-full xs:w-full xs:h-screen overflow-hidden   mx-auto    ">
                <div className="overflow-hidden  w-full h-full overflow-hidden ">
                    <ChatHome/>
                </div>
            </div>
        </div>
    );
}

export default ChatPages;