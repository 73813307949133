import React, {useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import photo from "../Images/bg.png"
import photoMobile from "../Images/bgMobile.png"

function WelcomePage() {
    const navigate = useNavigate()
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [loading, setLoading] = useState(false);
    function goSignUp(){
        setLoading(!loading)
        navigate("/signUp")
    }
    return (
        <>
            {mediaQuery.matches ?
                <div className="w-full h-screen flex flex-col bg-black text-white gap-y-3">
                    <div className="w-full relative h-1/3 ">
                        <img src={photoMobile} alt="welcome"   className="absolute w-full h-full object-cover "/>
                        <p className="absolute flex flex-col text-[#DD3A82] text-lg  w-full  bottom-0 justify-center  items-center font-serif font-bold">Discover a World of Connections</p>
                    </div>
                    <p className="flex w-full text-center px-10">Unleash the power of ModelClub and open the door to a world of exciting possibilities. Register now to meet incredible people waiting to connect with you.</p>
                    <div className="w-full flex justify-center">
                        <button onClick={goSignUp} className=" bg-[#D81E71] text-xl font-bold px-6 py-1 rounded-2xl border-2 animate-pulse" >Register Now !</button>
                    </div>
                    <div className="absolute bottom-0 flex my-12 h-20 w-full bg-black border-b-2 border-t-2 border-[#D81E71] justify-around items-center text-white">
                        <button onClick={()=>navigate("/termsOfServices")}>Terms of Service</button>
                        <button onClick={()=>navigate("/privacyPolicy")}>Privacy Policy</button>
                        <button onClick={()=>navigate("/datingSafetyTips")}>Dating Safety Tips</button>
                    </div>
                </div>
                :
                <div className="w-full h-screen relative overflow-hidden ">
                    <img src={photo} alt="welcome"  className="w-full h-full object-cover"/>
                    <div className="absolute flex flex-col text-white  w-1/2 h-screen bottom-0 justify-center gap-y-20 items-center   ">
                        <div className="w-full flex flex-col text-center gap-y-5 font-serif ">
                            <p className="text-5xl text-white font-bold ">Discover A World Of Connections</p>
                            <p className="text-xl">Unleash the power of ModelClub and open the door to a world of exciting possibilities. Register now to meet incredible people waiting to connect with you. </p>
                        </div>
                        {
                            loading ?
                                <div className="flex flex-col ">
                                    <div className="w-full flex justify-center  ">
                                        <span className="w-10 h-10 border-8 border-[#DD3A82] border-dotted rounded-full animate-[spin_1s_ease-in-out_infinite]  duration-700"/>
                                    </div>
                                    <p className="text-xl text-[#DD3A82] animate-pulse font-semibold">Please Wait</p>
                                </div>
                                :
                                <button onClick={goSignUp} className=" bg-[#D81E71] text-xl font-bold px-6 py-1 rounded-2xl border-2 animate-pulse" >Register Now !</button>
                        }
                    </div>
                    <div className="absolute text-white font-semibold bottom-0 h-20 w-full  flex bg-black items-center justify-center">
                       <div className="flex w-3/5 items-center justify-around h-full">
                           <button onClick={()=>navigate("/termsOfServices")}>Terms of Service</button>
                           <button onClick={()=>navigate("/privacyPolicy")}>Privacy Policy</button>
                           <button onClick={()=>navigate("/datingSafetyTips")}>Dating Safety Tips</button>
                       </div>
                    </div>

                </div>

            }
        </>
    );
}

export default WelcomePage;