import React, {useContext, useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import loading from "../Images/loading.gif"
import {BsFillCameraVideoFill, BsFillChatDotsFill} from "react-icons/bs";
import {TbArrowTopCircle} from "react-icons/tb";
import {ImLocation2} from "react-icons/im";
import {BsFillCheckCircleFill, BsPlayCircleFill} from "react-icons/bs";
import {HiFire} from "react-icons/hi";
import {ImFire} from "react-icons/im";
import database, {auth} from "../../Controller/Firebase/firebase";
import {AuthContext} from "../../Context/AuthProvider";
import {ChatContext} from "../../Context/ChatContext";
import moment from "moment";
import StateContext from "../../Context/StateContext";
import VerificationVideo from "./VerificationVideo";
import {GiQueenCrown} from "react-icons/gi";
import premiumIconWhite from "../Images/premiumIconWhite.png"
import locationIconWhite from "../Images/locationIconWhite.svg"
import newUserLogo from "../Images/newUsersLogo.svg";
import favIconWhite from "../Images/favIconWhite.svg"
import favIconGradient from "../Images/favIconGradient.svg"
import chatSvg from "../Images/chat.svg"
import {LazyLoadImage} from "react-lazy-load-image-component";
import loadingPhoto from "../Images/loadingPhoto.gif";
import blueDiamond from "../Images/blueDiamond.png"
import pinkDiamond from "../Images/pinkDiamond.png"


function Test() {
    const {
        myBlockList,
        myFavorite,
        newUsers,
        loginUser,
        LastSeenUsers,
        hasMore,
        myFavoriteClone,
        allUser,
        OnlineOrOffline,
        goUserProfile,
        FavOrNot,
    } = useContext(AuthContext);

    const {goChat,VerifactionVideoToggle,
        videoOpenState} = useContext(ChatContext);
    return (
        <InfiniteScroll
            dataLength={allUser.length}
            next={() => LastSeenUsers(loginUser ? loginUser.gender : "male")}
            hasMore={hasMore}
            loader={<img className={`w-12 h-12  object-cover mx-auto`} src={loading}/>}
            endMessage={
                <div className="w-full  flex space-x-1 justify-center items-center items-center">
                    <button onClick={() => {
                        window.scrollTo(0, 0);
                    }
                    } className="text-xl flex text-blue-500 font-bold mx-auto animate-pulse items-center underline">back
                        to up
                    </button>
                </div>
            }
        >
            <div
                className={`grid lg:grid-cols-4 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5   `}
            >
                {allUser.map(({
                                  id,
                                  viewCount,
                                  isSeen,
                                  viewTime,
                                  favTrue,
                                  data: {
                                      name,
                                      age,
                                      location,
                                      profileImage,
                                      userID,
                                      gender,
                                      verificationVideo,
                                      lastSeen,
                                      is_premium,
                                      token
                                  }

                              }, index) => (
                    <div className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                        key={index}
                    >
                        <img
                            onClick={() => goUserProfile(allUser[index].data, loginUser?.name, loginUser?.profileImage)}
                            src={profileImage}
                            className=" absolute w-full h-full  border-2 border-[#D81E71] object-cover rounded-2xl"
                            alt=""/>
                        {newUsers.find(el => el.id === userID) &&
                            <div className="absolute  w-full top-3 rotate-12">
                                <div className="flex w-full items-center justify-start ">
                                    <img src={newUserLogo}
                                         className="w-14 h-14 " alt=""/>
                                </div>
                            </div>
                        }
                        <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                            <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(allUser[index].data, loginUser?.name, loginUser?.profileImage)}>
                                <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                    {20 > OnlineOrOffline(lastSeen) ?
                                        <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                        <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                    {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                    {is_premium === true &&
                                        <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                </div>
                                {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                    <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                    <p className={`w-full `}>
                                        {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                    </p>
                                </div>}
                            </div>
                            <div
                                className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : " bg-[#D81E71] bg-opacity-80 "}`}>
                                {verificationVideo === "null" || !verificationVideo ?
                                    <p className="text-sm">Waiting for verification video</p>
                                    : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                         className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                        <BsPlayCircleFill className="text-lg animate-pulse"/>
                                        Verification
                                        Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                }
                            </div>
                            <div
                                className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">
                                <button onClick={() => FavOrNot(id,allUser[index])}
                                        className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                    {myFavoriteClone.find(r => r.id === id) ?
                                        <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                        <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                </button>
                                <button onClick={(e) => goChat(e, allUser[index].data, token)}
                                        className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                    <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                {videoOpenState && <VerificationVideo/>}
        </InfiniteScroll>
    );
}
export default Test;