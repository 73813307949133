import React, {useContext, useEffect, useState} from 'react';
import database, {auth, remoteConfig} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {fetchAndActivate, getValue} from "firebase/remote-config";
import {getFunctions,httpsCallable} from "firebase/functions";
import {AuthContext} from "../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
function FashionShows() {
    const [fashionShows, setFashionShows] = useState([]);
    let mySelects = []
    const [MyShows, setMyShows] = useState([]);
    const [select, setSelect] = useState(false);
    let [value, setValue] = useState(null);
    const [addSelectedShows, setAddSelectedShows] = useState(false);

    let temp = []
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const {prevLocationPath, setPrevLocationPath} = useContext(AuthContext)
    const navigate =  useNavigate()

    const FashionShows = () => {
        database.collection("FashionShows").get().then((res) => {
            res.docs.forEach((doc) => {
                temp.push({data: doc.data(), id: doc.id})
            })
            setFashionShows(temp)
        })
    }
    const functions = getFunctions();
    const addFashionShow = httpsCallable(functions, 'addFashionShow');
    const removeFashionShow = httpsCallable(functions, 'removeFashionShow');
    useEffect(() => {
       if (auth.currentUser === null)
       {
           return
       }
        database.collection("users").doc(auth.currentUser.uid).collection("MyShows").onSnapshot((res) => {
            mySelects = []
            res.docs.forEach((doc) => {
                mySelects.push({data: doc.data(), id: doc.id})
            })
            setMyShows(mySelects)
        })
        fetchAndActivate(remoteConfig)
            .then(() => {
                const val = getValue(remoteConfig, "fashion_shows_cities_selected_limit")
                setValue(val["_value"])
            })
            .catch((err) => {
            });
        FashionShows()
    }, [select,auth.currentUser]);
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    async function SaveDatabase() {
        const userRef = database.collection("users").doc(auth.currentUser.uid);
        const myShowsRef = userRef.collection("MyShows");

        try {
            // MyBrands koleksiyonundaki belge kimliklerini al
            const myShowsSnapshot = await myShowsRef.get();
            const existingShowIds = myShowsSnapshot.docs.map((doc) => doc.data().id);

            // Belge kimliklerini kontrol et ve silme veya ekleme işlemi yap
            for (let i = 0; i < MyShows.length; i++) {
                const showId = MyShows[i].id;
                const brandDocId = existingShowIds.includes(showId) ? existingShowIds.find((id) => id === showId) : database.collection("users").doc().id;

                if (existingShowIds.includes(showId)) {
                    // Eğer brandId'ye ait bir belge kimliği varsa, ekleme yapma
                } else {
                    // Yeni belgeyi ekle
                    await myShowsRef.doc(showId).set({
                        name: MyShows[i].data.name,
                        image: MyShows[i].data.image,
                        id: showId,
                        addDate: serverTimestamp()
                    });

                }
            }
            // MyBrands koleksiyonundaki mevcut belge kimlikleri içinde bulunmayan belge kimliklerini sil
            const deletePromises = myShowsSnapshot.docs
                .filter((doc) => !MyShows.some((show) => show.id === doc.data().id))
                .map((doc) => doc.ref.delete());

            await Promise.all(deletePromises);

            setAddSelectedShows(!addSelectedShows)
            await delay(4000)
            if (prevLocationPath === "/my-profile")
            {
                navigate("/my-profile")
            }
            else{
                navigate("/")
            }


        } catch (error) {
        }
    }

    async function addOrDeleteShows(fashionShows, id) {
        let newData = {id: id, data: {name: fashionShows.data.name, image: fashionShows.data.image, id: id}}
        if (MyShows.find(item => item.id === id)) {
            if (mediaQuery.matches) {
                let updatedArray = MyShows.filter(item => item.id !== id)
                setMyShows(updatedArray)
            } else {
                await database.collection("users").doc(auth.currentUser.uid).collection("MyShows").doc(id).delete().then(
                    removeFashionShow({showId: id})
                )
            }

        } else {

            if (MyShows.length + 1 <= value) {
                if (mediaQuery.matches) {
                    setMyShows(prevState => [...prevState, newData])

                } else {
                    await database.collection("users").doc(auth.currentUser.uid).collection("MyShows").doc(id).set({
                        name: fashionShows.data.name,
                        image: fashionShows.data.image,
                        id: id,
                        addDate: serverTimestamp()
                    }).then(setSelect(true)).then(
                        addFashionShow({showId: id})
                    )
                }

            }

        }

    }

    return (
        <div>
            <div className={"w-full flex-shrink  text-black font-bold"}>
                <p className="text-center">Maximum selectable limit : <span className="text-[#D81A71] text-lg">{value}</span></p>
                <p className="text-center">Remaining limit : <span className="text-[#D81A71] text-lg ">{value - MyShows.length}</span> </p>
            </div>
            <div className="grid lg:grid-cols-4 xs:grid-cols-1 md:grid-cols-3 gap-2  ">
                {fashionShows.map(({data: {name, image}, id}, index) => (
                    <button className="w-full border flex-shrink items-center overflow-hidden" key={index}
                            onClick={() => addOrDeleteShows(fashionShows[index], id)}>
                        {
                            MyShows.find(item => item.id === id) ?
                                <div className="w-full flex h-10 justify-center bg-red-600 text-white items-center font-bold">
                                    <p>REMOVE</p>
                                </div>
                                :
                                <div className="w-full flex h-10 justify-center bg-black text-white items-center font-bold">

                                {MyShows.length.toString() === value ?
                                        <p className="animate-pulse text-red-700">YOU CAN'T CHOOSE MORE</p> :
                                        <p>Add to Profile</p>}
                                </div>
                        }
                        <div className="w-full h-40">
                            <img
                                src={image}
                                className="object-cover w-full h-full hover:scale-105  duration-500"/>
                        </div>
                        <div className=" h-10 bg-[#8C0149]  flex items-center justify-center text-white font-bold">
                            <p>{name} Fashion Shows</p>
                        </div>
                    </button>
                ))}
            </div>
            {addSelectedShows ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className=" flex flex-col w-1/3 h-1/4 xs:w-11/12 xs:h-1/4 bg-black rounded-2xl overflow-hidden justify-center space-y-1">

                            <div className=" w-full flex h-full overflow-hidden items-center justify-center p-2">
                                <div className=" w-full h-3/4 overflow-hidden text-[#D81E71] text-lg text-center font-bold items-center flex justify-center">
                                    <p>Your selected fashion shows have been successfully added to your profile.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {mediaQuery.matches && <button onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                           className="block h-auto gap-y-3 text-white gap-y-1 fixed bottom-0 p-2 w-full bg-black flex flex-col items-center justify-center">
                <p className="flex  gap-x-1">{MyShows.length >= value ?
                    <p className=" font-bold">You can`t choose more</p> :
                    <p>Chosen Brands <span className="font-bold text-red-600">  {MyShows.length}  </span> | {value}
                    </p>}  </p>
                <div className="flex items-center justify-center w-screen border-black h-auto gap-x-3 ">
                    {MyShows.map(({id, data: {name, image}}, index) => (
                        <img key={index} src={image}
                             className="w-12 h-12 bg-white border-2 rounded-xl border-black object-contain" alt=""/>

                    ))}
                </div>
                <button onClick={SaveDatabase} className={`py-1 px-3 bg-[#D81A71] rounded-full text-white ${MyShows.length === 0 && "hidden"}`}>Add Selected
                </button>
            </button>

            }
        </div>
    );
}
export default FashionShows;