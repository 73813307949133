import React, {useContext} from 'react';
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {AuthContext} from "../../Context/AuthProvider";
import moment from "moment"

function CancelPremiumModal() {
    const {cancelPremium, setCancelPremium,loginUser} = useContext(AuthContext)
    return (
        <Modal
            isOpen={cancelPremium}
            onRequestClose={()=>setCancelPremium(!cancelPremium)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/2 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={()=>setCancelPremium(!cancelPremium)}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                    <div className="flex flex-col items-center justify-center gap-y-2 w-full text-center ">

                        <p className="text-2xl">Subscription Cancellation Successful!</p>
                        <p className="text-lg text-[#D81E71] font-semibold text-center">
                            Access to all features will continue until the end of your current subscription period. We value your support and appreciate your time with us.
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CancelPremiumModal;