import React, {useContext, useEffect, useRef, useState} from 'react';
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import {AuthContext} from "../../Context/AuthProvider";
import database, {auth} from "../../Controller/Firebase/firebase";

import {BiArrowBack} from "react-icons/bi";
import {getFunctions, httpsCallable} from "firebase/functions";


function EditProfile() {
    const context = useContext(StateContext);
    const functions = getFunctions();
    const {loginUser,settingsModal, setSettingsModal} = useContext(AuthContext);
    const [bio, setBio] = useState(loginUser?.bio);
    const [remainingChars, setRemainingChars] = useState(200);
    const [videoUrl, setVideoUrl] = useState()
    const [selectedVerifactionVideo, setSelectedVerifactionVideo] = useState(null);
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const [state, setState] = useState(false);
    const [searchLocation, setSearchLocation] = useState([]);
    const [location, setLocation] = useState(loginUser?.location);
    const [geohash, setGeohash] = useState(null);
    const [lastLocation, setLastLocation] = useState(null);

    const [state2, setState2] = useState(false);

    const handleChange = (event) => {
        setBio(event.target.value);
    };
    let nameRef = useRef(loginUser.name);
    let ageRef = useRef(null);
    let locationRef = useRef(null);
    let verificationVideoRef = useRef(null);
    let bioRef = useRef(null);

    const [age, setAge] = useState(loginUser?.age);
    const [name, setName] = useState(loginUser?.name);

    async function SaveChanges() {
        let span = document.getElementById("clickButton")
        bioRef = document.getElementById("bio").value
        nameRef = document.getElementById("name").value
        // ageRef = document.getElementById("age").value
        locationRef = document.getElementById("location").value

        if (name === loginUser.name && bio === loginUser?.bio  && location === loginUser?.location && age === loginUser?.age ) {
                span.style.display = "flex"
                span.innerText = "You didn't change anything"
                span.style.color = "#D81E71"
        }
        else {
            if(parseInt(age) <18 || parseInt(age) >65 )
            {
                span.style.display = "flex"
                span.innerText = "Age range must be between 18-65"
                span.style.color = "#D81E71"
            }
            else {
                if (name !== "")
                {
                    database.collection("users").doc(auth.currentUser.uid).update({name: name})
                }
                if (bio !== "")
                {
                    database.collection("users").doc(auth.currentUser.uid).update({bio: bio})
                }
                    if (parseInt(age) >=18 && parseInt(age) <=65)
                    {
                        database.collection("users").doc(auth.currentUser.uid).update({age: age})

                    }
                if (lastLocation === null && location !== loginUser?.location)
                {
                    document.getElementById("locationErr").style.display = "flex"
                    return
                }
                else {
                    database.collection("users").doc(auth.currentUser.uid).update({location: lastLocation === null ? location : lastLocation , lat , lon, geohash })
                }
                span.style.display = "flex"
                span.innerText = "Completed"
                span.style.color = "green"
                setSettingsModal(!settingsModal)
            }
        }
    }
    const handleCountryClick = (locationName, locationLat, locationLon,geohash) => {
        setLat(locationLat)
        setLon(locationLon)
        setGeohash(geohash)
        setState(false)
        document.getElementById("location").value = locationName
        setLocation(locationName)
        setLastLocation(locationName)
    };
    const handleVerificationVideoChange = (e) => {
        setSelectedVerifactionVideo(e.target.files[0]);
    };
    const handleInputChange = async (country) => {
        let data = {loc: country}
        const requestBackEnd = await httpsCallable(functions, 'searchLocation')(data)
        setSearchLocation(requestBackEnd.data)
        if (country.trim() === "") {
            setState(false)
        } else {
            setState(true)
        }
    };
    useEffect(() => {
        setRemainingChars(200 - bio.length);
    }, [bio, remainingChars]);
    return (
        <Modal
            isOpen={context.showModal}
            onRequestClose={context.EditProfileModal}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-4/5 xs:w-11/12 shadow-2xl xs:h-full bg-white text-white overflow-hidden shadow-2xl rounded-md">
                <div className="w-full  flex justify-start p-1 w bg-[#D81E71]">
                    <button className=" text-white px-2 "
                            onClick={context.EditProfileModal}><BiArrowBack/>
                    </button>
                </div>
                {auth.currentUser !== null &&
                    <div className="w-full h-full p-2 overflow-scroll ">
                        <div className=" shadow-md items-center justify-center text-center flex flex-col gap-y-4">
                            <h4 className="w-full text-2xl font-medium text-[#D81E71]">Edit Profile</h4>
                            <div className="flex flex-col">
                                <label className="  font-medium text-[#D81E71] ">
                                    Name </label>
                                <input type="text"
                                       className="block w-full px-4 py-2 mt-2 text-base  text-white placeholder-gray-400 bg-black border border-gray-300 rounded-md focus:outline-none focus:border-green-700"
                                       // placeholder={loginUser.name}
                                       value={name}
                                       id="name"
                                       onChange={(e)=>setName(e.target.value)}
                                       />
                            </div>
                            <div className="flex flex-col">
                                <label className=" font-medium text-[#D81E71]">Age</label>
                                <input type="number"
                                       className="block w-full px-4 py-2 mt-2 text-base placeholder-gray-400 bg-black border border-gray-300 rounded-md focus:outline-none focus:border-green-700"
                                       value={age}
                                       id="age"
                                       onChange={(e)=>setAge(e.target.value)}
                                       />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    className="font-medium text-[#D81E71]">Location</label>
                                <input
                                    type="text"
                                    id="location"
                                    className="  py-1 w-full rounded-md text-white placeholder-gray-400 text-start px-3  text-gray-400 bg-black border border-[#D81E71] "
                                    value={location}
                                    autoComplete="off"
                                    onChange={(e)=> {
                                        setLocation(e.target.value)
                                        handleInputChange(e.target.value.toLowerCase())
                                    }}
                                />
                                {state && (
                                    <div
                                        className="h-auto border-2 border-black rounded-md flex  w-full text-center overflow-hidden mx-auto"
                                    >
                                        <div className="flex flex-col w-full">
                                            {searchLocation.map((item, index) => (
                                                <p
                                                    key={index}
                                                    className="border-b-2 bg-black hover:bg-gray-500  hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleCountryClick(
                                                            searchLocation[index].n, searchLocation[index].l.lat, searchLocation[index].l.lon,searchLocation[index].g
                                                        )
                                                    }
                                                >
                                                                <span
                                                                    className="whitespace-nowrap text-white overflow-hidden">{item.c}, {item.n}</span>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}

                            </div>
                            <span id="locationErr" className="text-[#D81E71]  font-bold hidden">Please select your location from the list</span>
                            <div className="flex flex-col">
                                <label className=" font-medium text-[#D81E71]">Bio</label>
                                <div className="flex-col">
                                    <textarea
                                        className="block w-full px-5 py-2 mt-2 text-base placeholder-gray-400 bg-black border border-gray-300 rounded-md focus:outline-none focus:border-green-700"
                                        id="bio"
                                        onChange={handleChange}
                                        maxLength={200}
                                        rows={4}
                                        value={bio}/>
                                    <p className="text-sm text-right text-gray-400">{remainingChars} characters
                                        remaining</p>
                                </div>

                            </div>

                            <span id="clickButton" className="text-lg animate-bounce hidden"/>

                            <button className=" bg-[#D81E71] px-2 text-white font-bold py-2 rounded-md my-8"
                                    onClick={SaveChanges}>Save Changes
                            </button>
                        </div>
                    </div>

                }
            </div>
        </Modal>
    );
}

export default EditProfile;