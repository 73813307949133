import React, {useEffect, useState} from 'react';
import loading from "../Images/loading.gif"
import "../../App.css"

function Loading() {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        setStartAnimation(true);
    }, []);
    const text = 'Model Club';

    return (
        <div className="w-full h-screen flex flex-col  bg-white  items-center justify-center">
         <div className="flex">
             {text.split('').map((letter, index) => (
                 <span
                     key={index}
                     className={`flex font-extrabold text-5xl  ${index <= 5 ? 'text-black' : 'text-pink-500'} ${
                         startAnimation ? 'animate-loading' : ''
                     }`}
                     style={{animationDelay: `${index * 0.1}s`}}
                 >
                    {letter}
                </span>
             ))}
         </div>
            <div>
                <img src={loading} className="w-12 h-auto" alt=""/>
            </div>
        </div>
    );
}

export default Loading;