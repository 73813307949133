import React from 'react';
import Header from "./header";
import {BiArrowBack} from "react-icons/bi";
import tips1 from "../Images/photo1.jpg";
import tips3 from "../Images/photo3.jpg";
import tips4 from "../Images/photo4.jpg";
import tips5 from "../Images/photo5.jpg";
import {useNavigate} from "react-router-dom";

function TipsForMan() {
    const navigate = useNavigate()
    return (
        <div
            className="lg:w-full mx-auto h-full lg:h-screen md:justify-center  text-white overflow-hidden overflow-y-scroll">
            <div className="xs:hidden"><Header/></div>
            <div
                className="flex w-full  items-center text-2xl text-black justify-between lg:hidden  md:hidden p-2  ">
                <BiArrowBack onClick={() => navigate(-1)}/>
            </div>
            <div className="flex flex-row w-full text-black">
                <div className="flex flex-col  w-2/3 font-semibold px-16 py-5 gap-y-3">
                    <h1 className="text-lg font-bold">Secrets of the Art of Flirting: Strategies That Make Men
                        Irresistible</h1>
                    <h2>
                        Relationships form one of the most valuable and colorful aspects of human life.
                        Enriching our hearts, infusing days with meaning, and adorning our memories
                        with unforgettable moments, this special domain beckons us to expand it, forge
                        new connections, and win someone's heart. At this juncture, many men seek the
                        secrets to effectively and sincerely reach out while sending friendship requests or
                        attempting to flirt. In this very blog, we are here to shed light on the path to
                        success for men aspiring to master the art of flirting. We will share some special
                        tactics, ranging from a warm smile to meaningful eye contact, engaging
                        conversations to authentic demeanor. Are you ready for an enjoyable journey
                        into the secrets of the art of flirting? Let's embark on this delightful voyage
                        together!
                    </h2>
                    <h1 className="text-lg font-bold"> Warmth and Sincerity
                    </h1>
                    <h2>
                        Warmth and sincerity form fundamental keys to effective flirting. Approach with a
                        genuine and warm smile right from the first encounter. This instantly puts the
                        other person at ease and leaves a positive impression. Communicate with
                        sincerity, expressing your emotions openly. Avoiding any pretense lays the
                        foundation for meaningful relationships. This tactic is simple yet powerful.
                    </h2>
                    <h1 className="text-lg font-bold">Establishing a Connection with Effective Eye Contact</h1>
                    <h2>
                        Effective eye contact stands as one of the potent tools in the art of flirting. Look
                        into the other person's eyes with sincerity and attentiveness. It serves as a
                        natural means of expressing emotions. Making eye contact establishes a
                        meaningful connection and reinforces a sense of trust. However, avoid overdoing
                        it and be mindful not to make the other person uncomfortable. Striking the right
                        balance and being gentle in maintaining eye contact are keys to establishing a
                        powerful mutual connection.
                    </h2>

                    <h1 className="text-lg font-bold">Engaging and Entertaining Conversations</h1>
                    <h2>
                        Engaging and entertaining conversations play a significant role in the art of
                        flirting. Adopt a humorous approach that enlivens communication and leaves a
                        positive impression on the other person. Utilize a witty language, discuss
                        common interests, and actively listen to the other person. Engaging
                        conversations add a fun and effective dimension to flirting, enhancing mutual
                        attraction.
                    </h2>

                    <h1 className="text-lg font-bold">Body Language and Allure</h1>
                    <h2>
                        Confident posture and natural gestures are key elements to impress the other
                        person. A warm smile supported by eye contact, gentle touches, and
                        attentiveness to your speech create an attractive appearance and effective
                        communication. Speak positively and openly through your body language while
                        also trying to understand the signals from the other person. Remember, your
                        body language is a powerful tool to establish a strong connection in the art of
                        flirting.
                    </h2>

                    <h1 className="text-lg font-bold">Focusing on the Other Person and Active Listening</h1>
                    <h2>
                        In the process of flirting, showing special interest in the other person and actively
                        listening fosters the formation of a strong bond. Pay attention to their stories,
                        value their thoughts, and try to understand their emotions. Displaying empathy
                        and genuine interest makes the other person feel special and contributes to the
                        development of the relationship. Be two-way communicators, continually asking
                        questions to the other person while also expressing your own thoughts. Striking
                        this balance in communication is the key to an effective flirting process.
                    </h2>

                    <h1 className="text-lg font-bold">Sharing Interests and Passions</h1>
                    <h2>
                        In the process of flirting, sharing your interests and passions with the other
                        person is a crucial way to find common ground and establish a deep connection.
                        Speaking passionately about your interests, sharing them, and listening
                        attentively to the other person with equal care contribute to the deepening of the
                        relationship. Common interests enliven dull moments and aid in creating new
                        experiences and memories.
                    </h2>

                    <h1 className="text-lg font-bold">Impressing the Other Person with Compliments</h1>
                    <h2>
                        Compliments are a powerful communication tool in the art of flirting. Sincerely
                        appreciating and highlighting the special aspects of the other person makes them
                        feel valued. However, compliments should be genuine and not exaggerated. By
                        noticing and expressing the positive qualities of the other person, you can
                        effectively establish a connection. Remember, heartfelt and honest compliments
                        leave a positive impact on the other person and support the development of the
                        relationship.
                    </h2>

                    <h1 className="text-lg font-bold">Natural and Genuine Demeanor</h1>
                    <h2>
                        A natural and genuine demeanor is the key to leaving an impressive and
                        appealing impression in the art of flirting. Embrace who you truly are and avoid
                        presenting a fake persona. Your natural smile, sincere gestures, and authentic
                        behavior instill a sense of trust and comfort in the other person. Embracing
                        genuineness establishes the foundation of the relationship while aiding in forming
                        a true connection with the other person.
                    </h2>

                    <h1 className="text-lg font-bold">Positive and Charming Personality Expression</h1>
                    <h2>
                        A positive and charming personality expression creates a captivating impact in
                        the art of flirting. Avoid negativity, approach situations with a positive outlook,and maintain a positive energy. A cheerful, jovial, and amiable attitude becomes
                        the key to impressing the other person. A good sense of humor and positive
                        energy not only influence people around you but also boost the other person's
                        confidence in you.
                    </h2>
                </div>
                <div className="flex flex-col  gap-y-12 w-1/3 justify-center items-center py-6">
                    <img src={tips1} className="w-80 h-80 object-cover rounded-3xl -rotate-12" alt=""/>
                    <img src={tips3} className="w-80 h-80 object-cover rounded-3xl rotate-12" alt=""/>
                    <img src={tips4} className="w-80 h-80 object-cover rounded-3xl -rotate-12 " alt=""/>
                    <img src={tips5} className="w-80 h-80 object-cover rounded-3xl rotate-12" alt=""/>
                </div>
            </div>


        </div>
    );
}

export default TipsForMan;