import React, {useContext, useEffect, useRef, useState} from 'react';
import {MdArrowBackIos} from "react-icons/md"
import {ChatContext} from "../../Context/ChatContext";
import {BsTrash} from "react-icons/bs";
import StateContext from "../../Context/StateContext";
import DeleteRooms from "./DeleteRooms";
import Messages from "./Messages";
import ChatInput from "./ChatInput";
import {BiArrowBack} from "react-icons/bi";
import {AuthContext} from "../../Context/AuthProvider";
import database, {auth} from "../../Controller/Firebase/firebase";

function Chat() {
    const [deleteRoomId, setDeleteRoomId] = useState(null);
    const deleteRef = useRef();
    const [open, setOpen] = useState(false);
    const [deleteIsComplated, setDeleteIsComplated] = useState(null);
    const [namee, setNamee] = useState(null);
    let {data,dispatch,goChat} = useContext(ChatContext);
    const {loginUser,goUserProfile,deletedModalOpen, setDeletedModalOpen} = useContext(AuthContext)
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    async function BackToRooms() {
        await database.collection("users").doc(auth.currentUser.uid).set({activeChatRoom: "null"}, {merge: true})
        if (mediaQuery.matches) {
            document.getElementById("ChatSidebar").style.display = "flex";
            document.getElementById("Chat").style.display = "none";
        }
        dispatch({type: "CHANGE_USER", payload: data.chat = "null"})
    }
    function OpenDeleteToggle(id, name) {
        setDeletedModalOpen(!deletedModalOpen)
        // setOpen(true)
        setDeleteRoomId(id)
        setNamee(name)
    }
    return (
        <div  className={`w-full h-full gap-y-2 p-5 xs:p-0   flex flex-col bg-[#FBFBFB]  overflow-hidden ${mediaQuery.matches && "chatPages overflow-hidden "}`}>
            <div className={` w-full flex p-3 items-center xs:bg-[#545456] xs:rounded-none   justify-between bg-white shadow-md h-[15%] xs:h-[10%] rounded-2xl`}>
                <BiArrowBack onClick={BackToRooms} className=" lg:hidden md:hidden text-black text-2xl  "/>
                <div className="flex gap-x-3 items-center  hover:cursor-pointer "  onClick={()=>{ goUserProfile(data.chat?.otherUser,loginUser?.name,loginUser?.profileImage)}}>
                    <img src={data.chat?.otherUser.profileImage} className="w-14 h-14 xs:w-12 xs:h-12 object-cover rounded-full" alt=""/>
                    <p className="text-xl xs:text-lg font-semibold text-black xs:text-white">{data.chat?.otherUser.name}</p>
                </div>
                <div className="border bg-[#8B8B8B33] xs:bg-white w-10 h-10 xs:w-8 xs:h-8 rounded-full flex items-center justify-center  ">
                        <BsTrash className="hover:cursor-pointer xs:text-[#545456] text-2xl xs:text-lg  rounded-full " onClick={() => OpenDeleteToggle(data.chat.id, data.chat.otherUser.name)}/>
                </div>
            </div>
            <div className="content flex w-full xs:p-2 h-[75%] ">
                <Messages/>
            </div>
            <div className=" flex w-full h-[10%] xs:p-2 ">
                <ChatInput/>
            </div>
            <StateContext.Provider value={{
                open,
                setOpen,
                OpenDeleteToggle,
                deleteRoomId,
                namee,
                deleteRef,
                deleteIsComplated,
                setDeleteIsComplated
            }}>
                {<DeleteRooms/> }
            </StateContext.Provider>
        </div>
    );
}
export default Chat;
