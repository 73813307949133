import React from 'react';
import Header from "../../Layouts/header";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate} from "react-router-dom";

function DatingSafetyTips() {
    const navigate = useNavigate()
    window.scroll(0,0)


    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center  ">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex lg:hidden md:hidden items-center text-2xl text-black  p-3">
                <button onClick={()=>navigate(-1)} className=" px-2 py-1">
                    <BiArrowBack className=""  />
                </button>
            </div>
            <div className="my-2 flex justify-center w-full h-full ">
                <div  className="flex flex-col w-4/5 h-full gap-y-3 ">
                    <p className="text-2xl font-bold text-[#D81E71] mx-auto">Dating Safety Tips
                    </p>
                   <p><b>Meeting new people can be thrilling, but it's essential to prioritize your safety when interacting with individuals you've yet to get to know well. Whether you're exchanging initial messages or planning a face-to-face meeting, safeguarding yourself is paramount. Although we can't control the actions of others, there are proactive measures you can take to ensure your safety while enjoying your experience on ModelClub.
                   </b></p>
                    <p><b>Online Safety:</b></p>
                    <li>
                        <b>Guard Your Finances:</b>
                        <p>Never send money, especially via wire transfer, even if someone claims to be in dire straits. Wiring money is akin to handing over cash—reversing the transaction or tracing its destination is exceedingly challenging. Avoid sharing information that could compromise your financial accounts. If another user solicits money from you, please report it to us promptly.</p>
                    </li>

                    <li>
                        <b>Secure Your Personal Details: </b>
                        <p>Protect your personal information, such as your social security number, home or workplace address, and the specifics of your daily routine (e.g., where you regularly go). If you're a parent, exercise caution when discussing your children on your profile and in initial conversations. Refrain from divulging details like your children's names, school information, or their ages and genders.</p>
                    </li>

                    <li>
                        <b>Stay on Our Platform:  </b>
                        <p>Maintain your conversations within the ModelClub platform while getting to know someone. Users with ill intentions often attempt to shift conversations to text, messaging apps, email, or phone calls prematurely.</p>
                    </li>

                    <li>
                        <b>Exercise Caution with Long-Distance and Overseas Connections:  </b>
                        <p>Be vigilant regarding individuals who claim to be from your country but are stranded elsewhere, especially if they request financial assistance to return home. Exercise caution if someone refuses to meet in person or engage in a phone/video call—it could be a sign of deception. If someone avoids your questions or pushes for a serious relationship without taking the time to get to know you first, consider it a red flag.</p>
                    </li>

                    <li>
                        <b>Report Suspicious or Offensive Behavior:  </b>
                        <p>Trust your instincts. If someone crosses the line, we want to know about it. Block and report individuals who violate our terms, including those who request money, underage users, those who engage in harassment or offensive messaging, inappropriate behavior during or after in-person meetings, fraudulent profiles, spam, or solicitations with links to commercial websites. You can report concerns about suspicious behavior from any profile page or messaging window.</p>
                    </li>

                    <li>
                        <b>Safeguard Your Account:   </b>
                        <p>Always select a robust password and exercise caution when logging in from public or shared computers. ModelClub will never request your username and password via email. If you receive such an email, report it immediately.</p>
                    </li>

                    <p><b>Meeting in Person:</b></p>
                    <li>
                        <b>Take Your Time: </b>
                        <p>Before agreeing to meet or chat off ModelClub, invest time in getting to know the other person. Don't hesitate to ask questions to screen for potential red flags or dealbreakers. A phone or video call can serve as a useful screening tool before an in-person meeting.</p>
                    </li>

                    <li>
                        <b>Meet in Public, Stay in Public: </b>
                        <p>For initial meetings, choose populated, public places—never your home, your date's home, or any other private location. If your date insists on going to a private location, end the date.</p>
                    </li>

                    <li>
                        <b>Inform Friends and Family: </b>
                        <p>Share your plans, including the date, time, and location, with a trusted friend or family member. Ensure your cell phone is fully charged and keep it with you at all times.</p>
                    </li>

                    <li>
                        <b>Control Your Transportation:</b>
                        <p>We empower you to control your transportation to and from your date so that you can leave whenever you feel comfortable. If you're driving yourself, consider having a backup plan, like a ride-sharing app or a friend who can pick you up.</p>
                    </li>

                    <li>
                        <b>Know Your Limits: </b>
                        <p>Be aware of how drugs or alcohol affect you individually; they can impair your judgment and alertness. If your date pressures you to consume substances or drink more than you're comfortable with, stand your ground and end the date.</p>
                    </li>

                    <li>
                        <b>Never Leave Drinks or Personal Items Unattended:  </b>
                        <p>Always be aware of the source of your drink and keep it in sight at all times—only accept drinks directly from the bartender or server. Some substances used for sexual assault are odorless, colorless, and tasteless. Keep your phone, purse, wallet, and items containing personal information on your person.
                            Trust Your Instincts: If you ever feel uncomfortable, it's perfectly acceptable to end the date early. In fact, it's encouraged. If your intuition signals that something is amiss or you feel unsafe, seek assistance from the bartender or server.</p>
                    </li>

                </div>

            </div>
        </div>
    );
}

export default DatingSafetyTips;