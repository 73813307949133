import React, {useEffect, useState, useContext} from 'react';
import database, {auth} from "../../Controller/Firebase/firebase";
import {
    BsFillCameraVideoFill,
    BsFillChatDotsFill,
    BsFillCheckCircleFill,
    BsFillPatchCheckFill,
    BsPlayCircleFill
} from "react-icons/bs";
import {ImFire, ImLocation2} from "react-icons/im";
import {GiCommercialAirplane, GiQueenCrown} from "react-icons/gi";
import moment from "moment";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {ChatContext} from "../../Context/ChatContext";
import {useNavigate} from "react-router-dom";
import StateContext from "../../Context/StateContext";
import VerificationVideo from "./VerificationVideo";
import InfiniteScroll from "react-infinite-scroll-component";
import {AuthContext} from "../../Context/AuthProvider";
import loading from "../Images/loading.gif"
import VerificationVideoQuery from "./VerificationVideoQuery";
import newUserLogo from "../Images/newUsersLogo.svg";
import premiumIconWhite from "../Images/premiumIconWhite.png";
import locationIconWhite from "../Images/locationIconWhite.svg"
import favIconGradient from "../Images/favIconGradient.svg";
import favIconWhite from "../Images/favIconWhite.svg";
import chatSvg from "../Images/chat.svg";
import pinkDiamond from "../Images/pinkDiamond.png";
import blueDiamond from "../Images/blueDiamond.png";

function ShowAllTravels() {
    const {
        Travels,
        loginUser,
        travelUsers,
        travelUsersHasMore,
        myFavorite,
        OnlineOrOffline,
        myBlockList,
        FavOrNot,
        myFavoriteClone,
        goUserProfile,
        newUsers,
        showVerificationVideoQuery
    } = useContext(AuthContext);
    const {goChat,femaleVerificationVideo, setFemaleVerificationVideo,VerifactionVideoToggle,
        videoOpenState , setVideoOpenState} = useContext(ChatContext);

    return (
        <InfiniteScroll dataLength={travelUsers.length} next={() => Travels(loginUser ? loginUser.gender : "male")}
                        hasMore={travelUsersHasMore}
                        loader={< img className={`w-12 h-12 object-cover mx-auto`} src={loading}/>}>
            {showVerificationVideoQuery && <VerificationVideoQuery/>}

            <div
                className={`grid lg:grid-cols-4 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5   `}
            >
                {travelUsers.map(({
                               id,
                               viewCount,
                               isSeen,
                               viewTime,
                               favTrue,
                               data: {
                                   name,
                                   age,
                                   location,
                                   profileImage,
                                   userID,
                                   gender,
                                   verificationVideo,
                                   lastSeen,
                                   is_premium,
                                   token
                               },
                                      travelInfo:{
                                   country,flightDate,returnDate
                               }

                           }, index) => (
                    <div className="flex flex-col w-72 h-80 xs:w-11/12 xs:h-96 items-center justify-center mx-auto" key={index} >
                        <div className="w-full flex flex-col items-center justify-center  h-auto bg-black text-white text-sm rounded-t-2xl border-2 border-[#D81E71] border-b-0 ">
                            <p className=" font-bold">{country}</p>
                            <div className="flex gap-x-2 font-bold">
                                <p className="text-green-600">from</p>
                                <p> {moment(flightDate.seconds * 1000).format('L')}</p>
                                <p className="text-red-600">to</p>

                                <p>{moment(returnDate.seconds * 1000).format('L')}</p>
                            </div>
                        </div>

                        <div className={`relative w-72 h-80  xs:mx-auto xs:w-full xs:h-96  hover:cursor-pointer justify-center items-center flex   ${myBlockList.includes(id) && "hidden"} `}
                        >
                            <img onClick={()=> goUserProfile(travelUsers[index].data,loginUser?.name,loginUser?.profileImage)} src={profileImage} className=" absolute border-t-0 w-full h-full border-2 border-[#D81E71] object-cover rounded-b-2xl" alt=""/>
                            {newUsers.find(el => el.id === userID) &&
                                <div className="absolute  w-full top-3 rotate-12">
                                    <div className="flex w-full items-center justify-start ">
                                        <img src={newUserLogo}
                                             className="w-14 h-14 " alt=""/>
                                    </div>
                                </div>
                            }
                            <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                                <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(travelUsers[index].data, loginUser?.name, loginUser?.profileImage)}>
                                    <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                        {20 > OnlineOrOffline(lastSeen) ?
                                            <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                            <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                        {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                        {is_premium === true &&
                                            <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                    </div>
                                    {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                        <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                        <p className={`w-full `}>
                                            {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                        </p>
                                    </div>}
                                </div>
                                <div
                                    className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : " bg-[#D81E71] bg-opacity-80 "}`}>
                                    {verificationVideo === "null" || !verificationVideo ?
                                        <p className="text-sm">Waiting for verification video</p>
                                        : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                             className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                            <BsPlayCircleFill className="text-lg animate-pulse"/>
                                            Verification
                                            Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                    }
                                </div>
                                <div
                                    className="flex w-full items-center p-2  justify-center bg-black rounded-b-2xl   text-sm ">
                                    <button onClick={() => FavOrNot(userID,travelUsers[index])}
                                            className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                        {myFavoriteClone.find(r => r.id === userID) ?
                                            <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                            <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                    </button>

                                    <button onClick={(e) => goChat(e, travelUsers[index].data, token)}
                                            className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                        <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {videoOpenState && <VerificationVideo/>}

        </InfiniteScroll>
    );
}

export default ShowAllTravels;