import React, {useContext} from 'react';
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import database, {auth} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {BiArrowBack} from "react-icons/bi";
import Block from "./Block";

function ReportUser() {

    const context = useContext(StateContext);

    const reportDetails = ["Scam", "Escort", "Fake Account", "Harrasment"]

    async function report(type) {
        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
        ).then();
        database.collection("users").doc(auth.currentUser.uid).collection("reportList").doc(context.userID).set({
            reporterID: auth.currentUser.uid,
            reportedID: context.userID,
            createdAt: serverTimestamp(),
            isReview: false,
            reportType: type
        }).then(
            document.getElementById("complate").innerText = "Reporting completed successfully...",
            await delay(1000),
            context.reportToggle()
        )
    }

    return (
        <Modal
            isOpen={context.report}
            onRequestClose={context.reportToggle}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/3 xs:w-11/12 shadow-2xl xs:h-1/2 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={context.reportToggle}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex flex-col w-full h-full   items-center justify-center text-white p-3">
                    <p className="text-2xl text-[#D81E71] font-bold text-center  italic underline">Report this user
                        as...</p>
                    <span className="text-xl text-green-500 font-bold text-center italic" id="complate"/>
                    <div className="flex xs:flex-col xs:gap-y-3 w-full h-full items-center justify-center gap-x-5">
                        {reportDetails.map((type, i) => (
                            <button key={i}
                                    className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full"
                                    onClick={() => report(type)}>{type}</button>))}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ReportUser;