import React, {useContext, useEffect} from 'react';
import ChatSidebar from "./ChatSidebar";
import EmptyChat from "./EmptyChat";
import Chat from "./Chat";
import {ChatContext} from "../../Context/ChatContext";

function ChatHome() {
    const {data,deleteRoomsBoolean} = useContext(ChatContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')

    useEffect(() => {
        if (mediaQuery.matches) {
            if(data.chat !== "null")
            {
                document.getElementById("ChatSidebar").style.display = "none";
                document.getElementById("Chat").style.display = "flex";
                document.getElementById("MobileMenum").style.display = "none";
            }
        }
    }, []);
    return (
        <div className="h-full w-full flex flex-row overflow-hidden" id="ChatHome">
            <div className="w-1/4 xs:w-full " id="ChatSidebar">
                <ChatSidebar/>
            </div>
            <div className="w-3/4 xs:hidden xs:w-full " id="Chat">
                {data.chat === "null" || deleteRoomsBoolean === true
                    ?
                    <EmptyChat/>
                    :
                    <Chat/>
                }
            </div>
        </div>
    );
}

export default ChatHome;