import React, {useContext, useEffect, useState} from 'react';

import database, {auth} from "../../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../Context/AuthProvider";

function FashionShowsBrandsBar() {
    const {setPrevLocationPath} = useContext(AuthContext)
    let navigate = useNavigate();
    const [fashionShows, setFashionShows] = useState([]);
    let temp = []
    const FashionShows = () => {
        database.collection("FashionShows").get().then((res) => {
            res.docs.forEach((doc) => {
                temp.push({data :doc.data() , id : doc.id})
            })
            setFashionShows(temp)
        })
    }
    useEffect(() => {
        FashionShows()
    }, [auth.currentUser]);

    function goShows() {
        navigate("/shows-brands")
        setPrevLocationPath("/")

    }

    function goFashionShows(data,id) {
        navigate(`/fashion-shows/${data["name"]}`, {state: {data}})
    }

    return (
        <div className={`h-20  flex gap-x-2 overflow-x-scroll overflow-y-hidden lg:h-28 `}>
            <div className={`flex  h-20 ${auth.currentUser === null && "hidden"}`}>
                <button className=" w-44 h-full  relative text-center justify-center items-center  backdrop border-4 bg-black text-white
                 border-[#980150]  rounded-bl-3xl rounded-tr-3xl hover:border-red-800 hover:rounded-br-3xl hover:rounded-tl-3xl hover:rounded-bl-none  hover:rounded-tr-none  duration-500 "
                        onClick={goShows}>
                    <p className="font-bold text-sm  h-full items-center  flex">Add Fashion Events or Favorite Brands</p>
                </button>
            </div>
            {fashionShows.map(({data:{name,image},id},index)=>(
                <button className="flex gap-2.5   h-20 " key={index}
                onClick={()=>goFashionShows(fashionShows[index].data , id)}
                >
                    <div className=" w-44 h-full  relative justify-center items-center  backdrop">
                        <img
                            src={image}
                            className="absolute w-44 h-full object-cover rounded-xl border-2 border-[#980150]" alt=""/>
                        <p className="absolute  w-full text-white bottom-0 bg-[#8C0149] rounded-b-2xl opacity-80">{name}</p>
                    </div>
                </button>
            ))}
        </div>
    );
}
export default FashionShowsBrandsBar;