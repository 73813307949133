import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-modal";
import {BsFillCameraVideoFill} from "react-icons/bs";
import StateContext from "../../Context/StateContext";
import {BiArrowBack, BiCheck} from "react-icons/bi";
import Sidebar from "./Sidebar";
import {GiQueenCrown} from "react-icons/gi";
import {AuthContext} from "../../Context/AuthProvider";
import {useNavigate} from "react-router-dom";
import database, {auth} from "../../Controller/Firebase/firebase";
import PaymentForm from "./PaymentForm";
import premiumCard1 from "../Images/premiumCard1.png";
import premiumIconSvg from "../Images/premiumIconSvg.svg";
import {serverTimestamp} from "firebase/firestore";

function DiscountModal() {
    // function openPaymentModal() {
    //     setShowPaymentForm(!showPaymentForm)
    //     setTrick(true)
    // }

    const context = useContext(StateContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    const [trick, setTrick] = useState(false);
    const {
        loginUser, monthly_premium_fee, yearly_premium_fee, three_monthly_premium_fee, six_monthly_premium_fee,setOpenPaymentModal,openPaymentModal,
        showDiscountPremiumModal,
        setShowDiscountPremiumModal,
        setDownMobilMenu,
        dscnt_50_MonthlyPlanId,
        dscnt_50_ThreeMonthlyPlanId,
        dscnt_50_SixMonthlyPlanId,
        amount,planId,setAmount,setPlanId,
        downMobilMenu,
        discountPremium
    } = useContext(AuthContext);
    let navigate = useNavigate();
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    // const [amount, setAmount] = useState(null);
    const [buttonClick, setButtonClick] = useState(false);
    const [plan, setPlan] = useState(null);
    const planId1 =  dscnt_50_MonthlyPlanId
    const planId2 = dscnt_50_ThreeMonthlyPlanId
    const planId3 = dscnt_50_SixMonthlyPlanId

    function chousePlan(yourPlan, planId, amount) {
        setButtonClick(true)
        const plans = ["MonthPlan1", "MonthPlan3", "MonthPlan6"];
        setAmount(amount)
        setPlanId(planId)
        setPlan(planId)
        if (mediaQuery.matches) {
            document.getElementById("continueButton").style.backgroundColor = "#D81E71"
            plans.forEach((plan) => {
                const el = document.getElementById(plan);
                if (plan === yourPlan) {
                    el.style.transform = "scale(1.0)";
                    // el.style.borderColor = "green";
                    // el.style.borderWidth = "5px"; // burada border kalınlığı ayarlanır
                    // el.style.boxShadow = "0px 0px 20px 0px black";
                    // el.style.padding = "0px"
                    // el.style.background = "none"
                } else {
                    el.style.transform = "scale(0.8)"
                    // el.style.boxShadow = "none";
                    // el.style.padding = "5px"
                    // el.style.background = "linear-gradient(to left, #fc5c7d, #6a82fb)"
                    // el.style.borderWidth = "0px"
                }
            });
        } else {
            document.getElementById("continueButton").style.backgroundColor = "#D81E71"
            plans.forEach((plan) => {
                const el = document.getElementById(plan);
                if (plan === yourPlan) {
                    el.style.transform = "scale(1.0)";
                    // el.style.borderColor = "green";
                    // el.style.borderWidth = "8px"; // burada border kalınlığı ayarlanır
                    // el.style.boxShadow = "0px 0px 20px 0px black";
                } else {
                    el.style.transform = "scale(0.7)"
                    // el.style.borderWidth = "2px"; // burada border kalınlığı ayarlanır
                    // el.style.boxShadow = "none";
                }
            });
        }
    }
    useEffect(() => {
        setDownMobilMenu(true)
    }, []);

    async function close() {
        setShowDiscountPremiumModal(!showDiscountPremiumModal)
        setDownMobilMenu(false)
        await database.collection("users").doc(auth.currentUser.uid).update({
            DiscountModal: {
                views: loginUser?.DiscountModal?.views + 1,
                viewsDate: serverTimestamp()
            }
        })
    }
    return (
        <Modal
            isOpen={showDiscountPremiumModal}
            onRequestClose={close}
            className="justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {mediaQuery.matches ?
                <div className="w-11/12 h-5/6 border-2 border-[#D81E71] rounded-2xl text-lg bg-black flex flex-col gap-y-4 overflow-hidden overflow-y-scroll ">
                    <div className="flex justify-end  text-[#D81E71] font-bold p-2">
                        <button onClick={close}>X</button>
                    </div>
                    <div className="w-full flex items-center justify-center text-2xl text-white">
                        <p>Dear <span className="font-extrabold">  {loginUser?.name}</span></p>
                    </div>
                    <div className="w-full flex flex-col gap-y-2 items-center justify-center text-white ">
                        <p className="flex items-center  gap-x-2">You Have a
                            <span className="text-2xl font-extrabold text-[#D81E71]">{discountPremium?.discount}% </span>
                            Special Discount
                        </p>
                    </div>
                    <div
                        className=" h-auto p-2 w-4/5 bg-gradient-to-r from-[#FF0084]  to-fuchsia-950 rounded-xl mx-auto flex flex-col justify-center items-center text-white text-sm  ">
                        <p className="text-lg font-bold ">Be Premium</p>
                        <span className="border-b border-white w-2/3"/>
                        <div className="w-full flex flex-col items-center justify-center">
                            <p className="flex items-center "> Higher Listing</p>
                            <p className="flex items-center "> Unlimited Chat</p>
                            <p className="flex items-center "> Unlimited Verification Video</p>
                        </div>
                    </div>
                    <div className="w-full flex text-white font-bold justify-center items-center text-2xl">
                        Choose Your Plan
                    </div>
                    <div className="w-full flex flex-col justify-center items-center gap-y-3">
                        <div id="MonthPlan1"  onClick={() => chousePlan("MonthPlan1", planId1, discountPremium.data["monthly_premium_fee"])} className="w-11/12 flex items-center px-1 justify-center gap-x-3 font-bold border-2 bg-white py-2 rounded-xl border-[#D81E71]" >
                            <p>1 Monthly</p>
                            <p className="line-through decoration-[#D81E71] decoration-2">€ {monthly_premium_fee}</p>
                            <p className="text-2xl text-[#D81E71] font-bold">€ {discountPremium.data?.monthly_premium_fee}</p>
                        </div>

                        <div id="MonthPlan3"  onClick={() => chousePlan("MonthPlan3", planId2, discountPremium.data["three_monthly_premium_fee"])} className="w-11/12 flex items-center px-1 justify-center gap-x-3 font-bold border-2 bg-white  py-2 rounded-xl border-[#D81E71]" >
                            <p>3 Monthly</p>
                            <p className="line-through decoration-[#D81E71] decoration-2">€ {three_monthly_premium_fee}</p>
                            <p className="text-2xl text-[#D81E71] font-bold">€ {discountPremium.data?.three_monthly_premium_fee}</p>
                        </div>

                        <div id="MonthPlan6"  onClick={() => chousePlan("MonthPlan6", planId3, discountPremium.data["six_monthly_premium_fee"])} className="w-11/12 flex items-center px-1 justify-center gap-x-3 font-bold border-2 bg-white py-2 rounded-xl border-[#D81E71]" >
                            <p>6 Monthly</p>
                            <p className="line-through decoration-[#D81E71] decoration-2">€ {six_monthly_premium_fee}</p>
                            <p className="text-2xl text-[#D81E71] font-bold">€ {discountPremium.data?.six_monthly_premium_fee}</p>
                        </div>
                    </div>
                    <div className={` w-full justify-center items-center   flex ${!buttonClick && "hidden"}`}>
                        <button id="continueButton" onClick={()=>setOpenPaymentModal(!openPaymentModal)}
                                className="px-10    rounded-2xl  border-2 py-1  text-white font-bold  ">Continue
                        </button>
                    </div>
                </div>
                :
                <div
                    className="w-3/4 h-3/4 flex flex-col  shadow-2xl   bg-white overflow-hidden shadow-2xl rounded-2xl">
                    <div className="w-full flex justify-start p-1">
                        <button className=" text-[#D81E71] text-xl font-bold px-2 rounded-full" onClick={close}>
                            <BiArrowBack/>
                        </button>
                    </div>
                    <div className="flex flex-col h-4/5 overflow-hidden items-center justify-center my-auto">
                        <div
                            className=" w-full flex  font-bold space-x-3  animate-pulse justify-center  text-center gap-y-3 bg-[#D81E71] text-2xl   text-white font-bold">
                            <p>Dear {loginUser?.name}, </p>
                            <p> You Have a {discountPremium?.discount}% Special Discount
                                For You</p>
                        </div>
                        <div className=" h-auto h-80 flex my-5  items-center justify-center  gap-x-10 ">
                            <div id="MonthPlan1"
                                 onClick={() => chousePlan("MonthPlan1", planId1, discountPremium.data["monthly_premium_fee"])}
                                 className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                <div
                                    className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                    <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                    <p className="text-xl">Monthly</p>
                                    <p className="text-xl line-through">€ {monthly_premium_fee}</p>
                                    <p className="text-3xl font-extrabold">€ {discountPremium.data?.monthly_premium_fee}</p>
                                    <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                        <p>Higher Listing</p>
                                        <p>Unlimited Chat</p>
                                        <p>Unlimited Verification Video</p>
                                    </div>
                                </div>
                            </div>
                            <div id="MonthPlan3"
                                 onClick={() => chousePlan("MonthPlan3", planId2, discountPremium.data["three_monthly_premium_fee"])}
                                 className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                <div
                                    className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                    <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                    <p className="text-xl">Monthly</p>
                                    <p className="text-xl line-through">€ {three_monthly_premium_fee}</p>
                                    <p className="text-3xl font-extrabold">€ {discountPremium.data?.three_monthly_premium_fee}</p>

                                    <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                        <p>Higher Listing</p>
                                        <p>Unlimited Chat</p>
                                        <p>Unlimited Verification Video</p>
                                    </div>
                                </div>
                            </div>
                            <div id="MonthPlan6"
                                 onClick={() => chousePlan("MonthPlan6", planId3, discountPremium.data["six_monthly_premium_fee"])}
                                 className="relative  overflow-hidden hover:scale-110  duration-500     rounded-xl hover:duration-200 hover:cursor-pointer hover:ease-in-out  drop-shadow-2xl  h-80 w-72 border-black  flex  justify-center items-center  ">
                                <img src={premiumCard1} className=" absolute w-full h-full " alt=""/>
                                <div
                                    className="absolute   w-full h-3/4 flex flex-col items-center justify-center gap-y-2 text-white font-bold">
                                    <img src={premiumIconSvg} className="w-12 h-12" alt=""/>
                                    <p className="text-xl">Monthly</p>
                                    <p className="text-xl line-through">€ {six_monthly_premium_fee}</p>
                                    <p className="text-3xl font-extrabold">€ {discountPremium.data?.six_monthly_premium_fee}</p>

                                    <div className="flex flex-col items-center justify-center text-white text-md my-5">
                                        <p>Higher Listing</p>
                                        <p>Unlimited Chat</p>
                                        <p>Unlimited Verification Video</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={` w-full justify-center items-center   flex ${!buttonClick && "hidden"}`}>
                            <button id="continueButton" onClick={()=>setOpenPaymentModal(!openPaymentModal)}
                                    className="px-10    rounded-2xl  border-2 py-1  text-white font-bold  ">Continue
                            </button>
                        </div>
                    </div>
                </div>
            }
            {openPaymentModal === true && <PaymentForm/>}

        </Modal>
    );
}

export default DiscountModal;