import React, {useContext} from 'react';
import {BiArrowBack} from "react-icons/bi";
import StateContext from "../../Context/StateContext";
import ReportUser from "./ReportUser";
import Block from "./Block";
import Modal from "react-modal";
import {AuthContext} from "../../Context/AuthProvider";
import {signOut} from "firebase/auth";
import {auth} from "../../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";

function LogoutModal() {
    const {setLogoutModal, logoutModal} = useContext(AuthContext);
    let navigate = useNavigate();

    function logout() {
        setLogoutModal(!logoutModal)
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.removeItem('token'); // Tokenı localStorage'dan silin
            localStorage.removeItem('uid')
            let userLoginToken = localStorage.getItem('token');
            let userUid = localStorage.getItem('uid');
            navigate("/", {replace: true})
            // data.chat = "null"
            // window.location.reload()
        }).catch((error) => {
            // An error happened.
        });
    }
    return (
        <Modal
            isOpen={logoutModal}
            onRequestClose={()=>setLogoutModal(!logoutModal)}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 flex items-center  flex-col shadow-2xl xs:h-1/3 bg-black  overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={()=>setLogoutModal(!logoutModal)}> <BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex flex-col w-full h-auto my-auto gap-y-10 items-center text-center  text-white">
                    <p className="text-2xl font-bold text-white">Are you sure you want to logout?</p>
                    <div className="flex  gap-x-5">
                        <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={()=>logout()} >Yes</button>
                        <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full"  onClick={()=>setLogoutModal(!logoutModal)} >No</button>
                    </div>
                </div>

            </div>

        </Modal>
    );
}

export default LogoutModal;