import React, {useContext,useState} from 'react';
import StateContext from "../../Context/StateContext";
import video from "../Videos/video.mp4";
import {BiArrowBack} from "react-icons/bi"

import Modal from "react-modal";
import ReportUser from "./ReportUser";
import Block from "./Block";


Modal.setAppElement('#root')

function ReportsAndBlockCard() {
    const context = useContext(StateContext);
    const [report, setReport] = useState(false);
    const [block, setBlock] = useState(false);
    const [userID, setUserID] = useState(context.userID);
    function reportToggle() {
        setReport(!report)
    }
    function blockToggle() {
        setBlock(!block)
    }
return(
    <Modal
        isOpen={context.reportsAndBlockCard}
        onRequestClose={context.openReportAndBlockCard}
        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
    >
        <div
            className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
            <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                <button className=" text-white text-xl px-2 rounded-full"
                        onClick={context.openReportAndBlockCard}> <BiArrowBack/>
                </button>
            </div>
            <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={blockToggle}>Block</button>
                <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={reportToggle}>Report</button>
            </div>

        </div>
        <StateContext.Provider value={{report , block , blockToggle, reportToggle,userID}}>
            {/*<ReportUser/>*/}
            {report && <ReportUser/>}
            {/*<Block/>*/}
            {block && <Block/>}
        </StateContext.Provider>
    </Modal>


    );
}
// <button
//     className="w-24 h-12 bg-gray-600 text-white font-bold rounded-md hover:bg-black" onClick={blockToggle}>Block</button>
// <button
//     className="w-24 h-12 bg-gray-600 text-white font-bold rounded-md hover:bg-black" onClick={reportToggle}>Report</button>
export default ReportsAndBlockCard;