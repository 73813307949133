import React, {useContext, useEffect, useState} from 'react';
import database, {auth} from "../../Controller/Firebase/firebase";
import {
    BsChatLeftDots,
    BsFillCameraVideoFill,
    BsFillChatDotsFill,
    BsFillCheckCircleFill,
    BsFillEyeFill,
    BsFillPatchCheckFill, BsPlayCircleFill
} from "react-icons/bs";
import {ImFire, ImLocation2} from "react-icons/im";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {ChatContext} from "../../Context/ChatContext";
import StateContext from "../../Context/StateContext";
import VerificationVideo from "./VerificationVideo";
import InfiniteScroll from "react-infinite-scroll-component";
import {AuthContext} from "../../Context/AuthProvider";
import VerificationVideoQuery from "./VerificationVideoQuery";
import newUserLogo from "../Images/newUsersLogo.svg";
import premiumIconWhite from "../Images/premiumIconWhite.png";
import locationIconWhite from "../Images/locationIconWhite.svg"
import favIconGradient from "../Images/favIconGradient.svg";
import favIconWhite from "../Images/favIconWhite.svg";
import chatSvg from "../Images/chat.svg";
import profileViewerSvg from "../Images/profileViewerSvg.svg"
import blueDiamond from "../Images/blueDiamond.png"
import pinkDiamond from "../Images/pinkDiamond.png"
import {BiArrowBack} from "react-icons/bi";


function ViewedContent() {
    let temp10 = []
    let temp11 = []
    let temp = [];
    const {
        myFavorite,
        myBlockList,
        loginUser,
        OnlineOrOffline,
        goUserProfile,
        FavOrNot,
        showVerificationVideoQuery,
        newUsers
    } = useContext(AuthContext);
    const [user, setUser] = useState([]);
    const [userName, setUserName] = useState();
    const [state, setState] = useState(false);
    let today = moment(new Date())
    let myViewerViewCount;
    const {goChat,femaleVerificationVideo, setFemaleVerificationVideo,VerifactionVideoToggle,
        videoOpenState , setVideoOpenState} = useContext(ChatContext);
    let navigate = useNavigate();
    const [lastVisible, setLastVisible] = useState("");
    const fetchMoreData = async () => {
        await database
            .collection("users")
            .doc(auth.currentUser.uid)
            .collection("profileViewers")
            .orderBy("viewTime", "desc")
            .startAfter(lastVisible)
            .limit(12)
            .onSnapshot(async (querySnapshot) => {
                temp = [];
                querySnapshot.forEach(async (doc) => {
                        const userDoc = await database.collection("users").doc(doc.id).get();
                        const userData = userDoc.data();
                        if (userData.accountStatus === 1)
                        {
                            temp.push({info: doc.data(), data: userData});
                        }
                    // Set lastVisible to the last document in the current query
                    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                });
                // Add previous favorites to the temp array
                user.forEach((favorite) => {
                    temp.push(favorite);
                });
                // Update the state variable with the updated temp array
                setUser(temp);
            });
    };
    useEffect(() => {
        if (auth.currentUser === null) {
            return
        }
        fetchMoreData()
        return () => {
            if (auth.currentUser !== null) {
                for (let i = 0; i < temp.length; i++) {
                    database.collection("users").doc(auth.currentUser.uid).collection("profileViewers").doc(temp[i].info.viewerId).update({"isSeen": false})
                }
            }

        }
    }, [auth.currentUser]);


    return (
        <div className="flex flex-col w-full       ">
            <div className="flex items-center text-2xl text-black justify-between  px-3 lg:hidden  md:hidden  ">
                <BiArrowBack onClick={()=>navigate(-1)}/>
                <div
                    className="flex w-full h-auto  text-xl text-[#D81E71]  justify-center items-center space-x-3  font-bold">
                    {/*<BsFillEyeFill className="w-8 h-8 text-red-800"/>*/}
                    <img src={profileViewerSvg} className="w-8 h-8" alt=""/>
                    <p>Profile Viewer</p>
                </div>
            </div>

            <InfiniteScroll
                dataLength={user.length}
                next={fetchMoreData}
                hasMore={true}
                loader=""
            >
                {user.length === 0 && <p className="text-xl font-bold text-red-500 text-center animate-pulse">No one has viewed your profile yet.</p>}
                <div
                    className={`grid lg:grid-cols-4 lg:gap-x-1  md:grid-cols-4 md:gap-x-2 xs:gap-y-2 lg:shadow-2x md:mr-3 gap-y-4 xs:gap-y-6  xs:overflow-hidden  py-5   `}
                >
                    {user.map(({
                                   info: {viewerId, isSeen, viewCount, viewTime},
                                   data: {
                                       name,
                                       age,
                                       location,
                                       profileImage,
                                       userID,
                                       gender,
                                       verificationVideo,
                                       lastSeen,
                                       is_premium,
                                       token
                                   }

                               }, index) => (
                        <div key={index}>
                            <div
                                className={`relative w-72 h-80 xs:mx-auto xs:w-11/12 xs:h-96 hover:cursor-pointer ${myBlockList.includes(userID) && "hidden"}`}
                                 >
                                <img
                                    onClick={() => goUserProfile(user[index].data, loginUser?.name, loginUser?.profileImage)}
                                    src={profileImage}
                                    className={` absolute w-full h-full  object-cover rounded-t-2xl ${isSeen === true && ""}`}
                                    alt=""/>
                                <div className="absolute   w-full rounded-2xl ">
                                    <div
                                        className="flex bg-gradient-to-b rounded-2xl from-neutral-700 to-transparent  items-center justify-end gap-x-1 text-white text-xl px-3 py-1">
                                        <BsFillEyeFill/>
                                        {viewCount}
                                    </div>
                                </div>
                                {newUsers.find(el => el.id === userID) &&
                                    <div className="absolute  w-full top-3 rotate-12">
                                        <div className="flex w-full items-center justify-start ">
                                            <img src={newUserLogo}
                                                 className="w-14 h-14 " alt=""/>
                                        </div>
                                    </div>
                                }
                                <div className=" absolute rounded-b-2xl  bg-black bg-opacity-40  flex flex-col w-full bottom-0 items-end justify-end
                                         text-white">
                                    <div className="flex flex-col w-full px-2 py-1 "  onClick={() => goUserProfile(user[index].data, loginUser?.name, loginUser?.profileImage)}>
                                        <div className="w-full flex items-center justify-start  font-semibold gap-x-1 px-0.5 ">
                                            {20 > OnlineOrOffline(lastSeen) ?
                                                <span className={`w-3 h-3 rounded-full border bg-green-700`}/> :
                                                <span className={`w-3 h-3 rounded-full bg-red-600  `}/>}
                                            {name.split(" ")[0].length > 30 ? name.split(" ")[0].slice(0, 32) + "..." : name.split(" ")[0]},{age}
                                            {is_premium === true &&
                                                <img src={gender === "female" ? pinkDiamond : blueDiamond} className="text-white w-5 h-5 " alt=""/>}
                                        </div>
                                        {location.length !== 0 &&  <div className="w-full flex   items-center  text-xs justify-center overflow-hidden  ">
                                            <img src={locationIconWhite} alt="" className="w-4 h-4"/>
                                            <p className={`w-full `}>
                                                {location.length > 30 ? location.slice(0, 32) + "..." : location}
                                            </p>
                                        </div>}
                                    </div>
                                    <div
                                        className={` w-full flex items-center  justify-center     py-1 ${gender === "male" && "hidden"}  ${(verificationVideo === "null" || verificationVideo === undefined) ? "animate-none bg-black bg-opacity-40" : " bg-[#D81E71] bg-opacity-80 "}`}>
                                        {verificationVideo === "null" || !verificationVideo ?
                                            <p className="text-sm">Waiting for verification video</p>
                                            : <p onClick={() => VerifactionVideoToggle(verificationVideo)}
                                                 className="flex  w-full text-sm  justify-center items-center gap-x-2">
                                                <BsPlayCircleFill className="text-lg animate-pulse"/>
                                                Verification
                                                Video <BsFillCheckCircleFill className="text-green-500 text-sm"/></p>
                                        }
                                    </div>
                                    <div
                                        className="flex w-full items-center p-2  justify-center bg-black    text-sm ">
                                        <button onClick={() => FavOrNot(userID)}
                                                className={`w-1/2 flex items-center justify-center gap-x-1  border-r-2 border-[#D81E71]   `}>
                                            {myFavorite.some(r => r.id === userID) ?
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconGradient} alt=""/> :
                                                <img className="w-6 h-6 xs:w-8 xs:h-8 " src={favIconWhite} alt=""/>}Fav
                                        </button>

                                        <button onClick={(e) => goChat(e, user[index].data, token)}
                                                className={`w-1/2   h-full flex items-center justify-center gap-x-2`}>
                                            <img src={chatSvg} className="w-5 h-5 xs:w-7 xs:h-7" alt=""/>Chat
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-72 xs:mx-auto xs:w-11/12  bg-green-700 text-white rounded-b-2xl py-1 items-center justify-center font-bold text-sm">
                                {isSeen === true ?
                                    <p className="animate-pulse">New Viewer</p>
                                    :
                                    <>
                                        {0 <= moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() && moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() < 1
                                            ?
                                            <p>Last View : Today at {moment(viewTime.seconds * 1000).format('HH:mm')}</p>
                                            :
                                            1 <= moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() && moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() < 2
                                                ?
                                                <p>Last View : Yesterday</p>
                                                :
                                                2 <= moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() && moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() < 7
                                                    ?
                                                    <p>Last View : {moment(viewTime.seconds * 1000).format('dddd')}</p>
                                                    :
                                                    7 <= moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() && moment.duration(today.diff(viewTime.seconds * 1000)).asDays().toFixed() < 365
                                                        ?
                                                        <p>Last View
                                                            : {moment(viewTime.seconds * 1000).format('MMMM d')}</p>
                                                        :
                                                        <p>Last View
                                                            :{moment(viewTime.seconds * 1000).format('MMMM d,YYYY')}</p>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                    ))}
                </div>
                <>
                    {showVerificationVideoQuery && <VerificationVideoQuery/>}
                    {videoOpenState && <VerificationVideo/>}
                </>
            </InfiniteScroll>
        </div>

    );
}

export default ViewedContent;