import React, {useContext, useEffect, useRef, useState} from 'react';
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import {GiCommercialAirplane} from 'react-icons/gi';
import moment from "moment";
import database, {auth} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import VerificationVideo from "./VerificationVideo";
import {getFunctions, httpsCallable} from "firebase/functions";
import {AuthContext} from "../../Context/AuthProvider";
import paymentPos from "../Images/post.gif";
import paymentSuccessfull from "../Images/paymentSuccessfull.gif";
import paymentFailed from "../Images/paymentFailed.gif";



function AddTravel() {
    const functions = getFunctions();
    const context = useContext(StateContext);
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);
    const [checkInfo, setCheckInfo] = useState(null);
    const [geohash, setGeohash] = useState(null);
    const [paymentResult, setPaymentResult] = useState(false);
    let [successfullOrFailed, setSuccessfullOrFailed] = useState(null);
    const [country, setCountry] = useState('');
    const [flightDate, setFlightDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expMonth, setExpMonth] = useState('');
    const [expYear, setExpYear] = useState('');
    const [cvc, setCvc] = useState('');
    const [cardholderName, setCardHolderName] = useState('');
    const [paymentStep, setPaymentStep] = useState(false);
    const {loginUser, travel_fee,successfullTravel, setSuccessfullTravel} = useContext(AuthContext);
    const [searchLocation, setSearchLocation] = useState([]);
    const [state, setState] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();

    const handleInputChange = async (country) => {
        setLat(0)
        let data = {loc: country}
        const requestBackEnd = await httpsCallable(functions, 'searchLocation')(data)
        setSearchLocation(requestBackEnd.data)
        if (country.trim() === "") {
            setState(false)

        } else {
            setState(true)
        }
    };
    const handleCountryClick = (locationName,geohash,lat,lon) => {
        setCountry(locationName)
        setGeohash(geohash)
        setLat(lat)
        setLon(lon)
        setState(false)
        document.getElementById("location").value = locationName
    };
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    let today = moment(new Date()).format(`yyyy-MM-DD`); //todays date
    let navigate = useNavigate();

    function nextStep() {
        let country = document.getElementById("location").value
        if (country.trim() === "" || flightDate.trim() === "" || returnDate.trim() === "") {
            document.getElementById("errors").style.display = "flex"
        }
        else if(country.trim() !== "" && lat === 0 )
        {
            document.getElementById("errors").style.display = "flex"
            document.getElementById("errors").innerText = "Please select your location from the list"
        }
        else {
            setPaymentStep(true)

        }

    }

    async function paymentTravel() {
        setSuccessfullOrFailed(null)
        if (
            cardholderName.trim() === "" ||
            cardNumber.trim() === "" ||
            expMonth.trim() === "" ||
            expYear.trim() === "" ||
            cvc.trim() === ""
        ) {
            document.getElementById("error").style.display = "flex";
        } else {
            let data = {
                email: auth.currentUser.email,
                number: cardNumber,
                expMonth: expMonth,
                expYear: expYear,
                cvc: cvc,
                cardholderName: cardholderName,
                customerId : loginUser?.shift4CustomerId,
                amount: travel_fee,
                currency: `EUR`,
                country: country,
                flightDate: moment(flightDate).unix(),
                returnDate: moment(returnDate).unix(),
                geohash : geohash,
                lat : lat,
                lon : lon,
                createdAt: moment().unix()
            }
            setPaymentResult(!paymentResult)
            const requestBackEnd = await httpsCallable(functions, 'travelPayment')(data)
            const result = requestBackEnd.data["travelPaymentCompleted"]
            setSuccessfullOrFailed(result)

            if (result === true)
            {
                setSuccessfullTravel(!successfullTravel)
                await delay(4000)
                context.addTravelDetails()
                navigate("/")
            }
            if(result === false)
            {
                setCheckInfo("Please check your card information")
                await delay(4000)
                setCardHolderName("")
                document.getElementById("cardNumber").value = ""
                document.getElementById("cardHolderName").value = ""
                document.getElementById("securityCode").value = ""
                document.getElementById("expDate").value = ""
                document.getElementById("expYear").value = ""
                document.getElementById("securityCode").value = ""
                setCardNumber("")
                setExpYear("")
                setExpMonth("")
                setCvc("")
                setPaymentResult(false)
            }
        }
    }

    return (
        <Modal
            isOpen={context.openTravelToggle}
            onRequestClose={context.addTravelDetails}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none ">
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-11/12 xs:w-4/5 shadow-2xl xs:h-auto bg-white overflow-hidden rounded-md bg-gradient-to-r from-[#12c2e9] to-[#c471ed] via-[#f64f59]  ">
                <div className="w-full  flex justify-end p-1 xs:h-1/12 bg-white ">
                    <button className="bg-red-600 text-white px-2 rounded-full"
                            onClick={context.addTravelDetails}>X
                    </button>
                </div>
                <div className="w-full   flex flex-col items-center justify-center xs:h-11/12  ">
                    <div
                        className="flex  h-auto w-full p-1 rounded-md  " id="travelInfo">
                        <div
                            className=" w-full h-full p-10 bg-white flex flex-col justify-center w-full items-center space-y-4">
                            <p className="text-3xl text-blue-700 font-bold text-center italic">
                                <GiCommercialAirplane/></p>
                            <div className="flex flex-col  xs:flex-col xs:items-center">
                                <div className={`flex flex-col text-center space-x-4`}>
                                    <p className="text-black font-bold">Where will you go ?</p>
                                    <input type="text"
                                           id="location"
                                           autoComplete="off"
                                           placeholder="Dubai.."
                                           onChange={(event) => handleInputChange(event.target.value.toLowerCase())}
                                           className="border-2 text-green-600 font-bold border-blue-700 rounded-xl px-2 text-center text-black xs:h-8"/>
                                </div>
                                {state && (
                                        <div
                                            className="border-2 border-black w-full rounded-md my-1 "

                                        >
                                            <div className="w-full text-center rounded-md overflow-hidden">
                                                {searchLocation.map((item, index) => (
                                                    <p
                                                        key={index}
                                                        className="border-b-2 hover:bg-gray-500 hover:text-white hover:font-bold hover:cursor-pointer"
                                                        onClick={() =>
                                                            handleCountryClick(searchLocation[index].n,searchLocation[index].g,searchLocation[index].l.lat,
                                                                searchLocation[index].l.lon)}>
                                                        <span className="whitespace-nowrap">{item.c}, {item.n}</span>
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                )}
                            </div>

                            <div className="flex space-x-4">
                                <p className="text-black font-bold">Flight date</p>
                                <input type="date" name="" id="arrivalDate" min={today} dataformatas="DD MM YYYY"
                                       onChange={(event) => setFlightDate(event.target.value)}/>
                            </div>
                            <div className="flex space-x-4">
                                <p className="text-black font-bold">Return date</p>
                                <input type="date" name="" id="departureDate" dataformatas="DD MM YYYY" min={flightDate}
                                       onChange={(event) => setReturnDate(event.target.value)}/>
                            </div>
                            <div className="w-full  justify-center flex text-red-700 font-bold text-sm hidden"
                                 id="errors">
                                <span>Please fill in all fields</span>
                            </div>
                            <div className="flex  py-4 justify-center my-10 space-x-5">
                                <button onClick={nextStep}
                                        className=" px-2 py-1 bg-green-600 text-white font-bold rounded-md">Add to
                                    flight
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {paymentStep ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-11/12 xs:w-4/5 shadow-2xl xs:h-auto bg-white overflow-hidden rounded-md">
                            <div className="w-full  flex justify-end p-1 xs:h-1/12 ">
                                <button className="bg-red-600 text-white px-2 rounded-full"
                                        onClick={() => setPaymentStep(false)}>X
                                </button>
                            </div>
                            <div className="w-full   flex items-center justify-center xs:h-11/12  ">
                                <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700">
                                    <div className="mb-10">
                                        <h1 className="text-center font-bold text-xl uppercase">Secure payment info</h1>
                                    </div>
                                    <p className="text-center  font-bold text-red-500 my-5">{checkInfo}</p>
                                    <div className="mb-3">
                                        <label className="font-bold text-sm mb-2 ml-1">Card Holder Name</label>
                                        <div>
                                            <input
                                                id="cardHolderName"
                                                className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                placeholder="John Walker" type="text" name="cardholderName"
                                                onChange={(event) => setCardHolderName(event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="font-bold text-sm mb-2 ml-1">Card number</label>
                                        <div>
                                            <input
                                                id="cardNumber"
                                                className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                placeholder="0000 0000 0000 0000"
                                                type="text"
                                                name="number"
                                                maxLength="19"
                                                onChange={(event) => {
                                                    // Girilen değeri al
                                                    const input = event.target.value;

                                                    // Girilen değerden boşlukları kaldır
                                                    const cardNumber = input.replace(/\s/g, '');

                                                    // Her 4 karakterden sonra boşluk ekleyerek düzenle
                                                    let formattedCardNumber = '';
                                                    for (let i = 0; i < cardNumber.length; i++) {
                                                        if (i > 0 && i % 4 === 0) {
                                                            formattedCardNumber += ' ';
                                                        }
                                                        formattedCardNumber += cardNumber[i];
                                                    }

                                                    // Düzenlenmiş numarayı input değerine set et
                                                    event.target.value = formattedCardNumber;

                                                    // Düzenlenmiş numarayı state'e kaydet
                                                    setCardNumber(cardNumber);
                                                }}
                                                onBlur={(event) => {
                                                    // Input'tan odak çıktığında, boşlukları kaldırarak düzenleme yap
                                                    const input = event.target.value;
                                                    const cardNumber = input.replace(/\s/g, '');
                                                    event.target.value = cardNumber;
                                                    setCardNumber(cardNumber);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 -mx-2 flex items-end">
                                        <div className="px-2 w-1/2">
                                            <label className="font-bold text-sm mb-2 ml-1">Expiration date</label>
                                            <div>
                                                <select
                                                    id="expDate"
                                                    className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                    name="expMonth"
                                                    onChange={(event) => setExpMonth(event.target.value)}
                                                >
                                                    <option value="">Month</option>
                                                    <option value="01">01</option>
                                                    <option value="02">02</option>
                                                    <option value="03">03</option>
                                                    <option value="04">04</option>
                                                    <option value="05">05</option>
                                                    <option value="06">06</option>
                                                    <option value="07">07</option>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="px-2 w-1/2">
                                            <select
                                                id="expYear"
                                                className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                name="expYear"
                                                onChange={(event) => setExpYear(event.target.value)}
                                            >
                                                <option value="">Year</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2029">2030</option>
                                                <option value="2029">2031</option>
                                                <option value="2029">2032</option>
                                                <option value="2029">2033</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-10">
                                        <label className="font-bold text-sm mb-2 ml-1">Security code</label>
                                        <div>
                                            <input
                                                className="w-32 px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                placeholder="000"
                                                type="number"
                                                id="securityCode" name="cvv"
                                                onChange={(event) => setCvc(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div id="error"
                                         className="w-full flex justify-center items-center text-center text-red-600 font-bold hidden">
                                        <span>Fill in all fields completely</span>
                                    </div>
                                    <div>
                                        <button onClick={paymentTravel}
                                                className="block w-full flex flex-col justify-center items-center max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-1 font-semibold">
                                            <span className="text-lg font-bold">$ {travel_fee}</span>
                                            <p className="text-lg">PAY NOW</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {paymentResult ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className=" flex flex-col w-1/3 h-1/2 xs:w-full flex flex-col xs:h-1/2 bg-white overflow-hidden rounded-2xl overflow-hidden justify-center p-3 space-y-1 justify-center text-center shadow-2xl">
                            {successfullOrFailed === null && <img src={paymentPos} alt=""/>}
                            {successfullOrFailed === true && <img src={paymentSuccessfull} alt=""/>}
                            {successfullOrFailed === false && <div className="flex flex-col gap-y-2 w-full h-full items-center justify-center">
                                <p className="text-lg text-red-500 font-bold text-center">Purchase Failed</p>
                                <img src={paymentFailed} className="w-40 h-40 " alt=""/>
                            </div>}
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </Modal>
    );
}

export default AddTravel;