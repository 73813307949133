import React, {useContext} from 'react';
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import makeWebApp from "../Images/makeWebApp.gif";
import {AuthContext} from "../../Context/AuthProvider";
import database, {auth} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";

function NotificationAlertModal() {
    const {
        notificationAlertState,
        setNotificationAlertState,
        setOpenMakeWebAppModal,
        openMakeWebAppModal,
        setDownMobilMenu,
        downMobilMenu
        ,loginUser,
        openBrowserSettingForNotification,
        setOpenBrowserSettingForNotification
    } = useContext(AuthContext);

    async function Close() {
        setNotificationAlertState(!notificationAlertState)
        await database.collection("users").doc(auth.currentUser.uid).update({NotificationAlertModal : {views : loginUser?.NotificationAlertModal.views + 1 , viewsDate : serverTimestamp()}})
    }

    function openMakeWebApp() {
        setOpenMakeWebAppModal(!openMakeWebAppModal)
        setDownMobilMenu(!downMobilMenu)
    }
    const isWebApp = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');

    return (
        <Modal
            isOpen={notificationAlertState}
            onRequestClose={Close}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl bg-black flex flex-col xs:h-1/2 overflow-hidden shadow-2xl rounded-2xl">

                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={Close}><BiArrowBack/>
                    </button>
                </div>
                <div
                    className="bg-black flex  flex-col h-full w-full gap-y-10 overflow-hidden p-3 items-center justify-center  text-[#D81E71] text-center font-bold">
                    {openBrowserSettingForNotification === true
                    ?
                        <>
                            {isWebApp
                                ?
                               <div className="flex flex-col gap-y-3">
                                   <p>Allow Notifications</p>
                                   <p className="text-white">Discover profile viewers, messages, and dating opportunities! To enable notifications, simply go to your device settings, find our app, and toggle on the 'Allow Notifications' option.
                                   </p>
                                   {/*<button onClick={openMakeWebApp}*/}
                                   {/*        className="bg-gradient-to-l gap-x-3 px-10 py-1 rounded-xl from-[#EB3349] to-[#F45C43] text-white font-bold text-lg animate-pulse">Make*/}
                                   {/*    Web App*/}
                                   {/*</button>*/}
                               </div>
                                :
                                <p>Check the "Allow notifications" option in your browser's settings to receive notifications about your profile viewers and new messages.</p>

                            }
                        </>
                        :
                       <>
                           <p className="x ">Make “web app” to get notifications about your profile viewers and new
                               messages </p>
                           <button onClick={openMakeWebApp}
                                   className="bg-gradient-to-l gap-x-3 px-10 py-1 rounded-xl from-[#EB3349] to-[#F45C43] text-white font-bold text-lg animate-pulse">Make
                               Web App
                           </button>
                       </>
                    }


                </div>
            </div>

        </Modal>
    );
}

export default NotificationAlertModal;