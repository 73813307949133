import React, {useContext, useEffect, useState} from 'react';
import StateContext from "../../Context/StateContext";
import Modal from "react-modal";
import database, {auth} from "../../Controller/Firebase/firebase";
import {doc, updateDoc, deleteField} from "firebase/firestore";
import EmptyChat from "./EmptyChat";
import {ChatContext} from "../../Context/ChatContext";
import {BiArrowBack} from "react-icons/bi";
import EditProfile from "./EditProfile";
import PremiumSettings from "./PremiumSettings";
import {AuthContext} from "../../Context/AuthProvider";

function DeleteRooms() {
    let {  data, setDeleteRoomsBoolean, deletedCount, deleteDispatch,data2} = useContext(ChatContext)
    let { deletedModalOpen, setDeletedModalOpen} = useContext(AuthContext)
    const context = useContext(StateContext);
    const mediaQuery = window.matchMedia('(max-width: 897px)')
    function MobileSliderDelete(){
        context.OpenDeleteToggle()
        if (mediaQuery.matches){
            window.location.reload()
        }
    }
    let deletedIds = []
    function Del() {
        if (mediaQuery.matches) {
            document.getElementById("ChatSidebar").style.display = "flex";
            document.getElementById("Chat").style.display = "none";
            document.getElementById("MobileMenum").style.display = "flex";
        } else {

        }
    }
    async function DeleteChats() {
        deletedIds.push(auth.currentUser.uid)
        const docRef = doc(database, "rooms", context.deleteRoomId)
        await updateDoc(docRef, {metadata: {deletedIds}})
            .then(context.OpenDeleteToggle, setDeleteRoomsBoolean(true))
        deleteDispatch({type: "delete", payload: data2.deleteCount + 1}) //room silindiğinde count artıyor ve "CHATS" componentini render ettiriyor
    }
    return (
        <Modal
            isOpen={deletedModalOpen}
            onRequestClose={context.OpenDeleteToggle}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={context.OpenDeleteToggle}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex flex-col w-full h-full  gap-y-5  items-center justify-center gap-x-5 text-white">
                    <p className="text-xl text-center">Are you sure you want to close your messages with {context.namee}? </p>
                    <div className="flex gap-x-5 w">
                        <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={() => {
                                          DeleteChats();
                                             Del()
                                         }} >Yes</button>
                        <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={MobileSliderDelete} >No</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default DeleteRooms;