import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-modal";
import {BiArrowBack} from "react-icons/bi";
import {AuthContext} from "../../Context/AuthProvider";
import makeWebApp from "../Images/makeWebApp.gif"
import androidMakeWebApp from "../Images/androidMakeWebApp.gif"
import database, {auth} from "../../Controller/Firebase/firebase";
import {serverTimestamp} from "firebase/firestore";


function MakeWebAppModal() {
    const {setOpenMakeWebAppModal,
        openMakeWebAppModal,  setDownMobilMenu,loginUser,
        downMobilMenu} = useContext(AuthContext);
    const userAgent = navigator.userAgent.toLowerCase();
    async function Close() {
        setOpenMakeWebAppModal(!openMakeWebAppModal)
        setDownMobilMenu(!downMobilMenu)
    }
    return (
        <Modal
            isOpen={openMakeWebAppModal}
            onRequestClose={Close}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl bg-black flex flex-col xs:h-[90%] overflow-hidden shadow-2xl rounded-2xl">

                    <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                        <button className=" text-white text-xl px-2 rounded-full"
                                onClick={Close}><BiArrowBack/>
                        </button>
                    </div>
                <div className="bg-black flex  flex-col w-full h-full overflow-hidden p-3 items-center justify-center  text-white">
                    {navigator.userAgent.toLowerCase().includes('android') ?
                        <img src={androidMakeWebApp} alt="" className="w-full h-full object-contain"/> :
                        <img src={makeWebApp} alt="" className="w-full h-full object-contain"/>}
                </div>
            </div>

        </Modal>
    );
}
export default MakeWebAppModal;
