import React, {useContext, useEffect, useState} from 'react';
import {GiCommercialAirplane} from 'react-icons/gi';
import database, {auth, messaging} from "../../Controller/Firebase/firebase";
import {useNavigate} from "react-router-dom";
import StateContext from "../../Context/StateContext";
import AddTravel from "./AddTravel";
import {AuthContext} from "../../Context/AuthProvider";
import moment from "moment/moment";
import {getFunctions, httpsCallable} from "firebase/functions";
import travelIcon from "../Images/travelIcon.png"

function TravelList() {
    let navigate = useNavigate();
    const [openTravelToggle, setOpenTravelToggle] = useState(false);
    const {travelUsers, goUserProfile, token, loginUser} = useContext(AuthContext);
    const functions = getFunctions();

    function addTravelDetails() {
        setOpenTravelToggle(!openTravelToggle)
    }

    function goTravelsPage() {
        navigate("/travels")
    }

    function AddTravelDetails(){
        if(auth.currentUser === null)
        {
            navigate("/login")
        }
        else {
            addTravelDetails()
        }
    }

    return (
        <div className="flex  lg:h-28 gap-x-2 overflow-x-scroll overflow-y-hidden ">
            <div className={`flex  h-20 `}>
                <button className={`w-44 h-full   bg-black border-4  border-[#980150] rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl 
                 duration-500 `} onClick={() => AddTravelDetails()}>
                    <div
                        className="h-full w-full  rounded-bl-3xl rounded-tr-3xl  hover:rounded-bl-none hover:rounded-tr-none  hover:rounded-br-3xl hover:rounded-tl-3xl transition-all">
                        <div className=" h-full justify-center items-center flex flex-col">
                            <img src={travelIcon} className="w-8 h-8" alt=""/>
                            <p className="font-bold text-white text-sm">Promote Your Travel</p>
                        </div>
                    </div>
                </button>
            </div>

                {travelUsers.map(({
                                      data: {name, profileImage, userID},
                                      travelInfo: {country, flightDate, returnDate}
                                  }, index) => (
                    <button
                        onClick={() => goUserProfile(travelUsers[index].data, loginUser?.name, loginUser?.profileImage)}
                        key={index}
                        className="border-2 w-44 h-20   px-2 flex items-center justify-center rounded-xl bg-black border-2 border-[#980150]"
                    >
                        <div className="w-44 h-full flex  flex text-xs">
                            <div className={`w-1/2 h-full  overflow-hidden  text-white flex flex-col gap-y-1 items-center justify-center`}>
                                <img src={profileImage} className="w-10 h-10 object-cover rounded-full border-2 border-white" alt=""/>
                            <p className="w-full  whitespace-nowrap">{name}</p>
                            </div>
                            <div className={`w-1/2 h-full flex flex-col  text-white`}>
                                <div className="w-full h-full flex flex-col  items-end ">
                                    <img src={travelIcon} className="w-4 h-4 mx-1" alt=""/>
                                    <p className="font-bold">{country}</p>
                                    <p>{moment(flightDate.seconds *1000).format('L')}</p>
                                    <p>{moment(returnDate.seconds *1000).format('L')}</p>
                                </div>

                            </div>
                        </div>
                    </button>
                ))}
                <div className={` -2 flex items-center my-auto ${travelUsers.length <= 1 && "hidden"}`}>
                    <div className="    w-full overflow-hidden h-full">
                        <button onClick={goTravelsPage}
                                className=" text-[#D81E71] font-bold text-sm xs:text-md md:text-md underline">Show All
                            Travels
                        </button>
                    </div>
                </div>
            <StateContext.Provider value={{
                openTravelToggle,
                setOpenTravelToggle,
                addTravelDetails
            }}>
                {openTravelToggle && <AddTravel/>
                }
            </StateContext.Provider>
        </div>
    );
}

export default TravelList;