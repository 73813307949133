import React, {useContext} from 'react';

import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../Context/AuthProvider";
import newUserLogo from   "../Images/newUsersLogo.svg"
function NewUserList() {
    const {newUsers} = useContext(AuthContext);
    let navigate = useNavigate();
    function goUserProfile(e, userData) {
        navigate(`/user/${userData["userID"]}`, {state: {userData}})
    }
    function goAllUsersPage() {
        navigate("/all-users")
    }
    return (
        <div className="flex h-auto  items-center overflow-x-scroll overflow-y-hidden  gap-x-2 ">
            <div className={`flex  h-20 `}>
                <button className={`w-20 h-full 
                 `} onClick={goAllUsersPage}>
                    <img src={newUserLogo} alt="" className="w-full h-full"/>
                </button>
            </div>
            <div className={`flex gap-x-2 `}>
                        {
                            newUsers.slice(0,12).map(({id, data: {profileImage}}, index) => (
                                <div key={index} className="  p-0.5 w-20 h-20  rounded-full bg-gradient-to-r from-[#12c2e9] to-[#c471ed] via-[#f64f59] overflow-hidden">
                                    <button className=" flex  bg-white w-full h-full rounded-full " onClick={e => goUserProfile(e, newUsers[index].data)}>
                                              <img src={profileImage} alt="" className="flex rounded-full w-full h-full object-cover w-full h-full"/>
                                    </button>
                                </div>
                            ))
                        }

            </div>
                 <div className="flex items-center  justify-center hover:cursor-pointer  text-center  text-[#D81E71] font-bold"  >
                     <button  onClick={goAllUsersPage} className="text-sm xs:text-md md:text-md underline">All New Users</button>
                 </div>
        </div>





        // <div className="flex  items-center justify-start   h-28  overflow-x-scroll  py-1 xs:px-4 space-x-1 ">
        //     <div className="flex space-x-1 w-auto border ">
        //         <img src={newUserLogo}
        //              className=" " alt=""/>
        //     </div>
        //     <div className="flex  space-x-1 w-auto items-center border">
        //         {
        //             newUsers.slice(0,12).map(({id, data: {profileImage}}, index) => (
        //                 <div key={index} className="  p-0.5 w-20 h-20  rounded-full bg-gradient-to-r from-[#12c2e9] to-[#c471ed] via-[#f64f59] overflow-hidden">
        //                     <button className=" flex  bg-white w-full h-full rounded-full " onClick={e => goUserProfile(e, newUsers[index].data)}>
        //                               <img src={profileImage} alt="" className="flex rounded-full w-full h-full object-cover w-full h-full"/>
        //                     </button>
        //                 </div>
        //             ))
        //         }
        //     </div>
        //     <div className="flex items-center  justify-center hover:cursor-pointer  text-center  text-[#D81E71] font-bold"  >
        //         <button  onClick={goAllUsersPage} className="text-sm xs:text-md md:text-md underline">All New Users</button>
        //     </div>
        // </div>
    );
}
export default NewUserList;