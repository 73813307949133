import React, {useEffect} from 'react';
import ChatNavbar from "./ChatNavbar";
import Chats from "./Chats";

function ChatSidebar() {
    return (
        <div className="flex flex-col bg-white h-full w-full lg:border-r-4 lg:border-black ">
            <ChatNavbar/>
            <Chats/>
        </div>
    );
}

export default ChatSidebar;