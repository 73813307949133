import React from 'react';
import Header from "../../Layouts/header";
import {BiArrowBack} from "react-icons/bi";
import SearchResult from "../../Layouts/SearchResult";
import {useNavigate} from "react-router-dom";

function Termsofservices() {
    const navigate = useNavigate()
    window.scroll(0,0)




    return (
        <div className="lg:w-full mx-auto lg:h-full md:justify-center  ">
            <div className="xs:hidden">
                <Header/>
            </div>
            <div className="flex lg:hidden md:hidden items-center text-2xl text-black  p-3">
                <button onClick={()=>navigate(-1)} className=" px-2 py-1">
                    <BiArrowBack className=""  />
                </button>
            </div>
            <div className="my-2 flex justify-center w-full h-full ">
               <div className="flex flex-col w-4/5 h-full gap-y-3 ">
                   <p className="text-2xl font-bold text-[#D81E71] mx-auto">Terms Of Service</p>
                   <p>
                       <b>By entering and/or registering on the Site you give your consent to and accept the following terms:</b></p>
                   <p>
                       <b>I.
                           General terms.</b></p>
                   <p>
                       1.1. You must be 18 or over to register as a member of or use this Site. Membership in the service is void where it is prohibited. While using and/or viewing this site, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all of its terms and conditions that you are at least 18-years old and that you are not prohibited by law from using the Site.</p>
                   <p>
                       1.2. This Site is intended to serve your entertainment and educational purposes and has no intention to support immoral interests.</p>
                   <p>1.3. You hereby represent and warrant to the Company that: (a) you have the full power and authority to enter into and perform under these Terms and Conditions; (b) your use of this Site will not infringe the copyright, trademark, right of publicity or any other legal right of any third party; (c) you will comply with all applicable laws in using the Site and in engaging in all other activities arising from, relating to or connected with these Terms, including, without limitation, contacting other members and/or viewers of the Site; (d) you own or otherwise have all rights necessary to license the content you submit and that the posting and use of your content by the Company will not infringe or violate the rights of any third party.</p>
                   <p><b>II.
                       Fees and non – commercial use by members and/or viewers</b></p>
                   <p>2.1. The Site and related service usage and/or subscription fees and duration of services were provided to you upon registration and may be changed from time to time upon electronic notice to you. We reserve the right to terminate your access to the site for any reason or no reason and may terminate your membership upon sending notice to you at the email address you provide in your application for membership or such other email address as you may later provide to us. If we terminate your membership because you have breached this Agreement, you will not be entitled to any refund of any unused subscription fees.  We have the right to use credit card processors or banks outside the European Union to process your transactions. In some instances, your bank or credit card issuer may charge you a fee for foreign transactions. Before purchasing any service, please check with your bank or credit card issuer for more information about its policies regarding foreign transaction fees. </p>
                   <p>2.2. According to the fact that some of the services might be charged, the member or user of the Site consents to recurring billing for services/access provided on an ongoing basis. Such recurring billing shall continue until such time as the member or user cancels the service. It is solely the member's or user's responsibility to cancel the service and the payment. </p>
                   <p>2.3. The member or user has a right to cancel the service and require a refund following the conditions of the Refund and Cancellation Policy (Section IX of the Terms and Conditions).  </p>
                   <p>2.4. We reserve the right to change the price for provided services at any time. The members or users, who have been already using our services prior to any changes of prices for provided services, will not be subjected to new prices, unless the case of rejoining the Site. </p>
                   <p>2.5. In case of any violation of legal acts, committed during the process of payments concerning services of the Site, the member or user will be immediately restricted from using the Site and all relevant useful data about member's or user's personal identification information will be shared with legal authorities in cooperation for investigation. </p>
                   <p>2.6. We may use third party companies to process the Site’s payments on the Company’s behalf. These companies are fully responsible for your personal data filled during payment process privacy under their Privacy Policy. </p>
                   <p>2.7. This Site is for the personal use of individual members and viewers only and may not be used in connection with any commercial endeavors. Organizations, companies, and/or businesses may not become members of this Site and shall not use the service or the Site for any purpose including but not limited to: sending unsolicited commercial e-mails to members, advertisements to other web sites and services on this site's chat rooms, or using the member's personal identifying information for commercial purposes. Illegal and/or unauthorized uses of the site, including unauthorized framing of or linking to the site or sending of unsolicited commercial emails will be investigated, and appropriate legal action will be taken, including without limitation, civil, criminal, and injunctive redress and/or termination of a membership at sole discretion of the Company. In order to prevent unsolicited commercial emails or chat messages being sent to its members, the Company may take reasonable steps, including the limiting of the numbers of emails or chat messages sent or received by a member and electronically filtering or throttling or terminating e-mail and chat messages at sole discretion of the Company.</p>
                   <p><b>III.
                       Termination</b></p>
                   <p>3.1. The Company reserves the right to terminate your access to the Site for any reason or no reason (such cause may include your breach of these Terms). The Company may terminate your membership upon sending notice to you to the email address you provide in your application for membership or such other email address as you may later provide to us or via any other method in its sole discretion. If the Company terminates your membership on the Site because you have breached these Terms, in the sole discretion of the Company, you will not be entitled to any refund of any unused subscription fees and thereby you agree to waive any claim to such refund of fees. Upon termination of your membership for any reason or no reason any and all points accumulated in any of the Site's programs shall be terminated with no liability to you. You expressly acknowledge that any program providing points or other credits and your participation in it can be terminated in the sole discretion by the Company at any time with no liability to you whatsoever and that you did not join or remain a member of the Site or any other services in reliance on any such program(s), or the use or availability of such points or credits, or the continuation of the program(s). Points and credits have no monetary value whatsoever and may not be transferred to other persons. Even after your membership is terminated, all provisions of these Terms will remain in effect.</p>
                   <p>3.2. The Company may, in it’s discretion and without liability to you, with or without prior notice and at any time, modify or discontinue, temporarily or permanently, any portion of the Site.</p>
                   <p><b>IV.
                       Responsibility of the members and/or viewers</b></p>
                   <p>4.1. This Site is a venue. Services provided by the Company do not include the introduction of users to one another. This Site is merely a venue for members and/or viewers to learn about one another and, if they wish, arrange stays with one another. The Company is not involved in the actual contact between members and/or viewers. As a result, the Company has no control over the conduct of members and/or viewers or the truth or accuracy of the information that members and/or viewers post on the Site.</p>
                   <p>4.2. The Company cannot and do not confirm purported identity of the members and/or viewers. Although the Company provides tools intended to assist you, you are responsible for determining the identity and suitability of others with whom you may match by means of this Site. The Company does not endorse any persons who use or register for the services which are provided by the Company. The Company does not investigate reputation of the members and/or viewers, conduct, morality, criminal background, or verify the information such of the members and/or viewers may submit to the Site. You shall communicate directly with potential hosts and guests through the tools available on the Site and take the same precautions you would normally take when meeting a stranger in person for the first time.</p>
                   <p>4.3. You shall be solely responsible for your interactions with other members and/or viewers of this Site. The Company will not be responsible for any damage or harm resulting from your interactions with other members and/or viewers of this Site. The  Company reserves the right, but has no obligation, to monitor interactions between you and other members and/or viewers of this Site and to take any other action in good faith to restrict access to or the availability of any material that the Company or another members and/or viewers of this Site may consider obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable.</p>
                   <p>4.4. Whereas this Site is merely a venue, in the event that you have a dispute with other members and/or viewers of this Site, you release the Company and officers, directors, members, employees, agents and affiliates of the Company from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. </p>
                   <p>4.5. In-person meetings which are held by groups of interested members and/or viewers are not sponsored or organized by the Company. Members who organize meetings should make clear in their announcements, invitations and signage that their meetings have not been organized or approved, and are not run, by the Company. The Company cannot guarantee the quality, organization or safety of any in-person meeting (whether or not sponsored by us). Members attend meetings at their own risk.</p>
                   <p>4.6. This Site is created for non-sexual purposes. The Site can not be used for the provision of sexual or erotic services by the member or user of the Site. The Site can not be used as an instrument for sexual or erotic proposals.</p>
                   <p><b>V.
                       Conduct of the members and/or viewers</b></p>
                   <p>5.1. In your use of this Site, you must act responsibly and exercise good judgment. By way of example and not limitation, you shall not:</p>
                   <p>5.1.1. violate any applicable law or regulation;</p>
                   <p>5.1.1. violate any applicable law or regulation;</p>
                   <p>5.1.3. use information obtained through the Site for any unauthorized purpose;</p>
                   <p>5.1.4. interfere with or damage the Site, including, without limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology;</p>
                   <p>5.1.5. use the Site to transmit, distribute, post or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, calling card or account numbers;</p>
                   <p>5.1.6. use the Site in connection with the distribution of spam (which shall be defined as unsolicited bulk e-mail or any other e-mail of a commercial, religious or political or other nature not within the intended purposes of the Site);</p>
                   <p>5.1.7. harass other members and/or viewers of the Site;</p>
                   <p>5.1.8. collect or store any information about other members and/or viewers for purposes other than of the permitted use of the Site;</p>
                   <p>5.1.9. register for more than one member account or register for a member account on behalf of an individual other than yourself;</p>
                   <p>5.1.10. impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity, whether by providing or omitting to provide information;</p>
                   <p>5.1.11. attempt to defeat any security or verification measure relating to use of the Site;</p>
                   <p>5.1.12. assist any third party in doing any of the foregoing;</p>
                   <p>5.1.13. use the Site to transmit, distribute, post or submit any proposals of sexual or erotic content and/ or use the Site to transmit, distribute, post or submit any proposals of sexual or erotic services, works and other offers. </p>
                   <p><b>VI.
                       Privacy</b></p>
                   <p>6.1. This Site is designed to allow members and/or viewers to post photographs and information about themselves for public review and comment. Accordingly, by submitting your photograph and/or any other personal information, you waive any privacy expectations you have with respect to use of such likeness or personal information. If you do not wish to have your picture or information about yourself viewed by or disclosed to others, you shall not use the Site. The Company may collect certain other personal information from you that the Company does not post on the Site. The Company does not intentionally transfer this information to unaffiliated third parties without your consent, but the Company reserves the right to transfer such information without your consent to prevent an emergency, to protect or enforce our rights, to protect or enforce the rights of a third party or in response to a court order or subpoena as otherwise required or permitted by law. The Company also provides this personal information to third-party service providers who help maintain the Site and deliver information and services to you and other members and/or viewers of the Site. </p>
                   <p>6.2. By submitting any content (including without limitation, your photograph) to the Site, you hereby grant the Company a perpetual, worldwide, non-exclusive, royalty-free license to use, reproduce, display, perform, adapt, modify, distribute, have distributed and promote such content in any form, in all media now known or hereinafter created and for any purpose.</p>
                   <p>6.3. You are solely responsible for any content that you submit, post or transmit via the Site. You may not post or submit any content that:</p>
                   <p>6.3.1. is defamatory;</p>
                   <p>6.3.2. may disparage any ethnic, racial, sexual or religious group by stereotypical depiction or otherwise;</p>
                   <p>6.3.3. exploits images or the likeness of individuals under 18 years of age;</p>
                   <p>6.3.4. depicts use of illicit drugs;</p>
                   <p>6.3.5. makes use of offensive language or images;</p>
                   <p>6.3.6. characterizes violence as acceptable, glamorous or desirable;</p>
                   <p>6.3.7. provides a link to any commercial websites;</p>
                   <p>6.3.8. contains any of your personal contact information, except as specifically required or requested as part of your registration on this Site. </p>
                   <p>6.4. The Company has no obligation to post any content from you or anyone else. In addition, the Company may, in it’s sole and unfettered discretion, edit, remove or delete any content that you post or submit.</p>
                   <p>6.5. In using the Site, you may be exposed to content from other users or third parties, either at this Site or through links to third-party websites. The Company does not control, and shall have no responsibility for, third-party content, including material that may be misleading, incomplete, erroneous, offensive, indecent or otherwise objectionable. You must evaluate, and bear all risks associated with, third-party content, including without limitation, profiles of other members and/or viewers of this Site.</p>
                   <p>6.6. You will select a username and password as part of the registration process. You are solely responsible for the confidentiality and use of your username and password. You must: (a) log off from your account at the end of each session on the Site (unless you are logged on your personal portable device); (b) notify the Company immediately of any unauthorized use of your username and password or any other breach of security.</p>
                   <p><b>VII.
                       LIMITATION ON LIABILITY&nbsp;&nbsp;</b></p>
                   <p><b>7.1. THE COMPANY SHALL NOT BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO ANY DIRECT, INDIRECT, INCIDENTAL, GENERAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES) EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING FROM OR RELATING TO: (A) THE USE OR INABILITY TO USE THE SITE; (B) THE COST OF REPLACEMENT OF ANY GOODS, SERVICES OR INFORMATION PURCHASED OR OBTAINED AS A RESULT OF ANY INFORMATION OBTAINED FROM OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SITE; (C) DISCLOSURE OF, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR CONTENT; (D) DAMAGES FOR LOSS OR CORRUPTION OF DATA OR PROGRAMS, SERVICE INTERRUPTIONS OR PROCUREMENT OF SUBSTITUTE SERVICES, EVEN IF WE KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; (E) STATEMENTS, CONDUCT OR OMISSIONS OF ANY SERVICE PROVIDERS OR OTHER THIRD PARTY ON THE SITE; (F) YOUR OR ANYONE ELSE’S CONDUCT OR ACTS IN CONNECTION WITH THE USE OF THE SITE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, DEATH OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER REGISTERED USERS OF OUR SERVICES OR PERSONS INTRODUCED TO YOU BY THE SITE, WHETHER ON-LINE OR OFF-LINE; (G) ANY OTHER MATTER ARISING FROM, RELATING TO OR CONNECTED WITH THE SITE OR THESE TERMS.</b></p>
                   <p><b>7.2. THE COMPANY SHALL NOT BE LIABLE FOR ANY FAILURE OR DELAY IN PERFORMING UNDER THESE TERMS, WHETHER OR NOT SUCH FAILURE OR DELAY IS DUE TO CAUSES BEYOND THE COMPANY’S REASONABLE CONTROL.</b></p>
                   <p><b>7.3. IF YOU USE THE SITE, YOU DO SO AT YOUR SOLE RISK. THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE COMPANY EXPRESSLY DISCLAIM,S AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</b></p>
                   <p><b>7.4. THE COMPANY DOES NOT WARRANT THAT: (A) THE SITE WILL MEET YOUR REQUIREMENTS; (B) THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (C) ANY INFORMATION THAT YOU MAY OBTAIN THROUGH THE SITE WILL BE ACCURATE OR RELIABLE; (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR EXPECTATIONS; (E) ANY INFORMATION YOU PROVIDE OR THE COMPANY COLLECTS WILL NOT BE DISCLOSED TO THIRD PARTIES; (F) ANY ERRORS IN ANY DATA OR SOFTWARE WILL BE CORRECTED. </b></p>
                   <p><b>7.5. IF YOU ACCESS OR TRANSMIT ANY CONTENT THROUGH THE USE OF THE SITE, YOU DO SO AT YOUR OWN DISCRETION AND YOUR SOLE RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE ARISING OUT OF SUCH ACCESS OR TRANSMISSION.</b></p>
                   <p><b>7.6. NO DATA, INFORMATION OR ADVICE OBTAINED BY YOU IN ORAL OR WRITTEN FORM FROM THE COMPANY OR THROUGH OR FROM THE SITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.</b></p>
                   <p><b>VIII.
                       Proprietary rights</b></p>
                   <p>8.1. All material on the Site are protected by rights of publicity, copyright, trademarks, service marks, patents, trade secrets or other proprietary rights and laws, as may be applicable. Except as expressly authorized by the Company, you may not sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from such material. Notwithstanding the foregoing, you may use such material in the course of your normal, personal, non-commercial use of the Site.</p>
                   <p>8.2. You may not systematically retrieve data or other content from the Site to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of "bots" or otherwise. You may not use of any of trademarks of the Company as metatags on other websites, written materials or otherwise. You may not display any portion of the Site in a frame (or any content from the Site through in-line links) without prior written consent of the Company.</p>
                   <p><b>IX.
                       Refund and cancellation policy</b></p>
                   <p>9.1. The Refund and Cancellation Policy is applied both for registered and unregistered users of the Site.</p>
                   <p>9.2. Refund is provided, upon approval, if cancellation is received in 1 (one) calendar day after making payment for the services. If the cancellation is received within this period, user’s access to the Site will be terminated immediately. For example, if the user was charged for the period from 31st of October to 30th of November, the user must make cancellation no later than until 10th of December.</p>
                   <p>9.3. Cancellations received after the stated deadline will not be eligible for a refund.</p>
                   <p>9.4. Cancellation and refund can be made only for the last period that was charged by the Site and paid by the user.  In this case, the user can not make cancellations for the previously charged and paid periods. For example, if the user was charged for the period from 31st of October to 30th of November and then the user was charged for the period from 30th of November to 31st of December, the user can make cancellation only for the period from 30th of November to 31st of December.</p>
                   <p>9.5. All refund requests must be made by the user itself or legal representative of the user.</p>
                   <p>9.6. All refund requests must be made in written via e-mail or fax and must be received by the stated cancellation deadline.</p>
                   <p>9.7. Refunds are not made to the users of the Site which abuse and do not follow the Terms and Conditions of the Site (f.e. use the Site in connection with the distribution of spam), the Privacy Policy of the Site, any applicable law or regulation and/or commit administrative or criminal violations.</p>
                   <p>9.8. If the membership of the user was terminated under Section III of the Terms and Conditions, the user is not entitled to any refund of any unused or used subscription fees.</p>
                   <p>9.9. Refunds are not made to the users of the Site which were reported by other users of the Site and inspection of activities of such the user is initiated.</p>
                   <p>9.10. Refund is made in the same form as the original payment for the services was made. </p>
                   <p><b>X.
                       Pricing and payment processing</b></p>
                   <p>10.1. These Terms shall be interpreted in accordance with the laws of the European Union. These Terms contain the entire agreement, and supersede all prior and contemporaneous understandings, between the parties regarding their subject matter. Failure or delay pf the Company in exercising any right, power or privilege under these Terms shall operate as a waiver thereof. The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.</p>
               </div>

            </div>
        </div>
    );
}

export default Termsofservices;