import React, {useContext} from 'react';
import {AuthContext} from "../../Context/AuthProvider";
import EditProfile from "./EditProfile";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import StaySafe from "./StaySafe";

function Footer() {
    const {termsOfService, setTermsOfService , privacyPolicy, setPrivacyPolicy,
        staySafe, setStaySafe,} = useContext(AuthContext);

    return (
        <footer className="relative lg:max-w-screen-lg mx-auto border-t-2 text-neutral-400 text-center border-z-300 font-serif py-2 ">
            <p><button onClick={()=>setTermsOfService(!termsOfService)} className="underline">Terms & Conditions</button> , <button
                onClick={()=>setPrivacyPolicy(!privacyPolicy)}
                className="underline">Privacy Policy </button> ,
                <button
                    onClick={()=>setStaySafe(!staySafe)}
                    className="underline" >Dating Securely</button> </p>
            <p>All rights reserved by Model Club.</p>
            {termsOfService && <TermsOfService/>}
            {privacyPolicy && <PrivacyPolicy/>}
            {staySafe && <StaySafe/>}

        </footer>

    );
}

export default Footer;