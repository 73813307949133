import React, {useContext, useState} from 'react';
import StateContext from "../../Context/StateContext";
import EditProfile from "./EditProfile";
import Modal from "react-modal";
import {GiQueenCrown} from "react-icons/gi";
import paymentPos from "../Images/post.gif";
import paymentSuccessfull from "../Images/paymentSuccessfull.gif";
import paymentFailed from "../Images/paymentFailed.gif";
import {AuthContext} from "../../Context/AuthProvider";
import {getFunctions, httpsCallable} from "firebase/functions";
import {useNavigate} from "react-router-dom";
import subCancelLoading from "../Images/subCancelLoading.gif"
import subCancelFailed from "../Images/subCancelFailed.gif"
import subCancelSuccesfull from "../Images/subCancelSuccesfull.gif"
import {BiArrowBack} from "react-icons/bi";

function PremiumSettings() {
    const context = useContext(StateContext);
    const [cancelSubscribe, setCanselSubscribe] = useState(false);
    const {
        loginUser, setSettingsModal, settingsModal,setCancelPremium,
        cancelPremium
    } = useContext(AuthContext);
    let [successfullOrFailed, setSuccessfullOrFailed] = useState(null);
    const [paymentCancelResult, setPaymentCancelResult] = useState(false);
    const navigate = useNavigate()
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    ).then();
    const functions = getFunctions();


    function CancelSub() {
        setCanselSubscribe(!cancelSubscribe)
    }

    async function paymentCancel() {
        let data = {
            subscribeId: loginUser.subscribeId,
        }
        setPaymentCancelResult(!paymentCancelResult)
        const requestBackEnd = await httpsCallable(functions, "paymentCancel")(data)
        const result = requestBackEnd.data["cancelCompleted"]
        setSuccessfullOrFailed(result)
        await delay(4000)
        setSettingsModal(!settingsModal)
        if (result === true)
        {
            setCancelPremium(!cancelPremium)
        }
        navigate("/")
    }

    return (
        <Modal
            isOpen={context.openPremiumSettings}
            onRequestClose={context.OpenPremiumSettings}
            className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none "
        >
            <div
                className="lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 shadow-2xl xs:h-1/3 bg-white overflow-hidden shadow-2xl rounded-2xl">
                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                    <button className=" text-white text-xl px-2 rounded-full"
                            onClick={context.OpenPremiumSettings}><BiArrowBack/>
                    </button>
                </div>
                <div className="bg-black flex w-full h-full   items-center justify-center gap-x-5 text-white">
                    <button className="border-2 bg-[#D81E71] px-4 py-0.5 rounded-full" onClick={CancelSub}>Cancel
                        Subscription
                    </button>
                </div>
                {cancelSubscribe ? (
                    <>
                        <div
                            className="justify-center items-center flex flex-col overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
                        >

                            <div
                                className=" lg:w-1/3 md:w-1/3 md:h-4/5 lg:h-1/4 xs:w-11/12 flex flex-col items-center  shadow-2xl xs:h-1/3 text-white overflow-hidden shadow-2xl rounded-2xl bg-black shadow-2xl">
                                <div className="w-full  flex justify-start p-1 bg-[#D81E71] ">
                                    <button className=" text-white text-xl px-2 rounded-full"
                                            onClick={() => setCanselSubscribe(!cancelSubscribe)}><BiArrowBack/>
                                    </button>
                                </div>
                                <div className=" flex flex-col gap-y-5 items-center justify-center w-full h-full p-10 ">
                                    <p className="text-xl font-bold text-center">Are you sure you want to cancel the
                                        subscription?</p>
                                    <div className="flex gap-x-10">
                                        <button className="px-5 bg-[#D81E71] font-bold py-1 text-white rounded-full"
                                                onClick={paymentCancel}>Yes
                                        </button>
                                        <button className="px-5 bg-[#D81E71] font-bold py-1 text-white rounded-full"
                                                onClick={()=>setSettingsModal(!settingsModal)}>No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {paymentCancelResult ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div
                                        className=" flex flex-col w-1/3 h-1/2 xs:w-full flex flex-col xs:h-1/2 bg-white overflow-hidden rounded-2xl overflow-hidden justify-center p-3 space-y-1 justify-center text-center shadow-2xl">
                                        {successfullOrFailed === null && <img src={subCancelLoading} alt=""/>}
                                        {successfullOrFailed === true && <img src={subCancelSuccesfull} alt=""/>}
                                        {successfullOrFailed === false &&
                                            <img src={subCancelFailed} className="w-72 h-72 mx-auto" alt=""/>}
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>
                ) : null}
            </div>

        </Modal>

    );
}

export default PremiumSettings;
// <Modal
//     isOpen={context.openPremiumSettings}
//     onRequestClose={context.OpenPremiumSettings}
//     className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none">
//     <div
//         className="w-1/4 h-1/6 xs:w-4/5 xs:h-52 shadow-2xl xs:h-auto bg-white overflow-hidden rounded-md  ">
//         <div className="w-full h-full flex flex-col items-center space-y-4">
//             <div className=" w-full text-end p-1">
//                 <button onClick={context.OpenPremiumSettings} className="px-2 bg-red-700 text-white rounded-full">X</button>
//             </div>
//             <div className="flex flex-col w-full justify-center items-center space-y-4">
//                 <button className="px-5 bg-red-700 text-white py-1 font-bold rounded-full" onClick={CancelSub}>Cancel Subscription </button>
//             </div>
//         </div>
//         {cancelSubscribe ? (
//             <>
//                 <div
//                     className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none"
//                 >
//
//                     <div className=" flex flex-col w-1/4 h-1/3  xs:w-full  xs:h-1/2 bg-white overflow-hidden rounded-2xl space-y-10 overflow-hidden justify-center   text-center shadow-2xl">
//                         <div className=" text-end">
//                             <button onClick={()=>setCanselSubscribe(!cancelSubscribe)} className="text-end  border px-3 bg-red-700 text-white font-bold rounded-full">X</button>
//                         </div>
//                         <p className="text-xl text-black font-bold">Are you sure you want to cancel the subscription?</p>
//                         <div className="">
//                             <button className="px-2 bg-red-700 font-bold py-1 text-white rounded-full" onClick={paymentCancel}>Yes, Cancel Subscribe</button>
//                         </div>
//                     </div>
//                 </div>
//                 {paymentCancelResult ? (
//                     <>
//                         <div className="justify-center items-center flex overflow-hidden  fixed inset-0 z-50 outline-none focus:outline-none" >
//                             <div className=" flex flex-col w-1/3 h-1/2 xs:w-full flex flex-col xs:h-1/2 bg-white overflow-hidden rounded-2xl overflow-hidden justify-center p-3 space-y-1 justify-center text-center shadow-2xl">
//                                 {successfullOrFailed === null && <img src={subCancelLoading} alt=""/>}
//                                 {successfullOrFailed === true && <img src={subCancelSuccesfull} alt=""/>}
//                                 {successfullOrFailed === false && <img src={subCancelFailed} className="w-72 h-72 mx-auto" alt=""/>}
//                             </div>
//                         </div>
//                     </>
//                 ) : null}
//             </>
//         ) : null}
//     </div>
// </Modal>